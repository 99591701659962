import * as React from "react";
import { AuthenticationHOC } from "../../../components/AuthenticationHOC";
import AwsPublicUpload from "../generic/AwsPublicUpload";
import DialogModal from "../../../components/generics/DialogModal";
import ClientServices from "../../../apiService/ClientService";
import formTools from "../generic/formTools";
import {
  EntityInterface,
  ProductInterface,
  ProductLotInterface
} from "cbd-api-types";
import { Redirect } from "react-router-dom";
import Select, { Creatable } from "react-select";

interface ProductCategories {
  label: string;
  value: string;
}

interface Props {
  clientId: string;
  hideCancel: boolean;
  onSave: (product: ProductInterface) => {};
  parentLots: ProductLotInterface[];
  product: ProductInterface;
  productId: string;
  reloadProduct: (
    product: ProductInterface,
    callback: (product: ProductInterface) => void
  ) => {};
  token: string;
}

class ProductForm extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    let formTool = new formTools(props.token, props.clientId);
    let disabled = false;
    let message = "Save a new Product";
    let lotNotSelected = typeof props.product.latestLot === "undefined"; // product isn't assigned lot
    let lotNotSelectedMessage =
      "Select a test result before making this product available";
    if (typeof props.productId !== "undefined") {
      disabled = true;
      message = null;
    }
    this.state = {
      productTypes: null,
      brandList: false,
      strainList: false,
      newPrimaryPicture: false,
      showcaseImages: false,
      productCategories: formTool.productCategories,
      newGalleryPictures: false,
      message,
      disabled,
      lotNotSelected,
      lotNotSelectedMessage,
      unMutableCopy: null
    };

    formTool.loadProductStrains((results: ProductCategories[]) => {
      results.push({
        label: "Not Available",
        value: ""
      });
      this.setState({ strainList: results });
    });
    formTool.loadProductTypes((results: ProductCategories[]) => {
      this.setState({ productTypes: results });
    });
    ClientServices.loadBrandKeyPair(props.clientId).then(
      brandPairs => {
        let brandList = brandPairs.map((client: EntityInterface) => {
          return { value: client._id, label: client.name };
        });
        this.setState({ brandList });
      },
      rejected => {
        console.warn(rejected);
      }
    );
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    let dataToSend = new FormData(event.target);

    if (dataToSend.get("brand") === "") {
      this.setState({ message: "Brand Name is required" });
    } else if (dataToSend.get("productCategory") === "") {
      this.setState({ message: "Product Category is required" });
    } else if (dataToSend.get("productType") === "") {
      this.setState({ message: "Product Type is required" });
    } else if (
      dataToSend.get("latestLot") === "" &&
      dataToSend.get("available") === "true"
    ) {
      // TODO: CHECK THIS
      this.setState({ message: this.state.lotNotSelectedMessage });
    } else {
      if (dataToSend.get("latestLot") === "") dataToSend.delete("latestLot");
      ClientServices.saveProduct(
        this.props.token,
        this.props.clientId,
        dataToSend,
        this.props.product._id
      ).then(
        results => {
          // reload the product at the parent and then switch the form state with
          // the updated product will then be in props.
          if (typeof this.props.onSave !== "undefined") {
            this.props.onSave(results);
          } else if (this.props.reloadProduct) {
            this.props.reloadProduct(results._id, _ => {
              this.setState({
                disabled: true
              });
            });
          }
        },
        _ => {
          this.setState({
            message: "There was an error saving the Product"
          });
        }
      );
    }
  };

  enableEdit = () => {
    let unMutableCopy = Object.assign({}, this.props.product);

    let lotNotSelected =
      typeof unMutableCopy.latestLot === "undefined" && // product isn't assigned lot
      (unMutableCopy.available === false || // and product currently unavailable
        typeof unMutableCopy._id === "undefined"); // or new product
    this.setState({
      disabled: false,
      lotNotSelected,
      unMutableCopy
    });
  };

  cancelEdit = () => {
    let restoredProduct = Object.assign({}, this.state.unMutableCopy);
    if (typeof this.props.product._id === "undefined") {
      this.setState({ redirect: true });
    } else {
      this.setState({ disabled: true, product: null }, () => {
        this.setState({ product: restoredProduct });
      });
    }
  };

  lotFinder(id: string) {
    return this.props.parentLots.filter(parentLot => {
      return parentLot._id === id;
    });
  }

  filterTestResults = (candidate: any, input: string) => {
    if (input) {
      let arrayLocation = Number.parseInt(candidate.label.key);
      return (
        this.props.parentLots[arrayLocation].testResult.strain
          .toLowerCase()
          .search(input) !== -1 ||
        this.props.parentLots[arrayLocation].testResult.tracabilityParentLotId
          .toLowerCase()
          .search(input) !== -1 ||
        this.props.parentLots[arrayLocation].testResult.tracabilitySampleLotId
          .toLowerCase()
          .search(input) !== -1
      );
    }
    return true;
  };

  handleLotSelect = (e: any) => {
    this.setState({ lotNotSelected: e.value === null });
  };

  closeDialog = () => {
    this.setState({ dialog: undefined });
  };

  deleteProduct = () => {
    this.setState({
      dialog: {
        title: "Delete this product?",
        content:
          "The product will still be available on the site for historical purposes.",
        primary: {
          text: "Cancel",
          action: this.closeDialog
        },
        secondary: {
          text: "Confirm",
          action: this.confirmDeleteProduct
        }
      }
    });
  };

  confirmDeleteProduct = () => {
    ClientServices.deleteProduct(
      this.props.token,
      this.props.clientId,
      this.props.product._id
    ).then(
      _ => {
        this.closeDialog();
        this.setState({
          redirect: true
        });
      },
      _ => {
        this.setState({
          dialog: {
            title: "Error",
            content: "There was an error deleting the Product.",
            primary: {
              text: "OK",
              action: this.closeDialog
            }
          }
        });
      }
    );
  };

  productForm = () => {
    let formTool = new formTools(this.props.token, this.props.clientId);
    let defaultLot: any = { value: null, label: null };
    if (typeof this.props.product.latestLot !== "undefined") {
      defaultLot = this.props.parentLots.filter(parentLot => {
        return parentLot._id === this.props.product.latestLot._id;
      });
      defaultLot = {
        value: defaultLot[0]._id,
        label: (
          <div
            style={{
              display: "flex"
            }}
          >
            <div
              style={{
                width: "15%",
                display: "inline-block",
                marginTop: "auto",
                marginBottom: "auto"
              }}
              dangerouslySetInnerHTML={{
                __html: defaultLot[0].testResult.cannastamp
              }}
            />
            <div
              style={{
                width: "65%",
                display: "inline-block",
                marginTop: "auto",
                marginBottom: "auto"
              }}
            >
              {defaultLot[0].testResult.strain} -
              {defaultLot[0].testResult.tracabilityParentLotId} -
              {defaultLot[0].testResult.tracabilitySampleLotId}
            </div>
          </div>
        )
      };
    }
    return (
      <div className="cb_form">
        <h4 className="cb_form__title">Product Information</h4>

        <h2>{this.state.message}</h2>
        <form className="cb_form__form" onSubmit={this.handleSubmit}>
          <div className="cb_form__row">
            <div className="cb_form__column">
              <div className="cb_input--group">
                {typeof this.props.product._id !== "undefined" && (
                  <input
                    disabled={this.state.disabled}
                    type="hidden"
                    name="_id"
                    value={this.props.product._id}
                  />
                )}
                <input
                  disabled={this.state.disabled}
                  type="hidden"
                  name="producer"
                  value={this.props.clientId}
                />
                <label htmlFor="">Brand Name</label>
                <Select
                  name="brand"
                  id="productBrand"
                  placeholder="Product Brand Name"
                  required={true}
                  isDisabled={this.state.disabled}
                  options={this.state.brandList}
                  {...(typeof this.props.product.brand !== "undefined" && {
                    defaultValue: [
                      {
                        label: this.props.product.brand.name,
                        value: this.props.product.brand._id
                      }
                    ]
                  })}
                />
              </div>
              <div className="cb_input--group">
                {formTool.inputField(
                  "productName",
                  "Product Line (e.g. Sublima Oil)",
                  false,
                  this.state.disabled,
                  this.props.product,
                  36
                )}
              </div>
              <div className="cb_input--group">
                {formTool.inputField(
                  "name",
                  "Product Name or Strain (e.g. Dutch Treat) ",
                  true,
                  this.state.disabled,
                  this.props.product,
                  36
                )}
              </div>
              <div className="cb_input--group">
                {formTool.inputField(
                  "shortDescription",
                  "Short Description",
                  false,
                  this.state.disabled,
                  this.props.product,
                  128
                )}
              </div>
              <div className="cb_input--group">
                {formTool.textField(
                  "longDescription",
                  "Long Description",
                  false,
                  this.state.disabled,
                  this.props.product,
                  3,
                  3
                )}
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Product Category</label>
                <Select
                  name="productCategory"
                  id="productCategory"
                  placeholder="Product Category (flower, concentrate, etc...)"
                  required={true}
                  isDisabled={this.state.disabled}
                  options={this.state.productCategories}
                  {...(typeof this.props.product.productCategory !==
                    "undefined" && {
                    defaultValue: [
                      {
                        label: this.props.product.productCategory,
                        value: this.props.product.productCategory
                      }
                    ]
                  })}
                />
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Product Type</label>
                <Creatable
                  name="productType"
                  id="productType"
                  className="cb_input"
                  required={true}
                  isDisabled={this.state.disabled}
                  placeholder="Product Type (e.g. CO2 Extract)"
                  options={this.state.productTypes}
                  {...(typeof this.props.product.productType !==
                    "undefined" && {
                    defaultValue: [
                      {
                        label: this.props.product.productType,
                        value: this.props.product.productType
                      }
                    ]
                  })}
                />
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Genetic Strain</label>
                <Creatable
                  name="strain"
                  id="strain"
                  className="cb_input"
                  required={false}
                  isDisabled={this.state.disabled}
                  placeholder="Strain"
                  options={this.state.strainList}
                  {...(this.props.product.strain
                    ? {
                        defaultValue: [
                          {
                            label: this.props.product.strain,
                            value: this.props.product.strain
                          }
                        ]
                      }
                    : {
                        defaultValue: [
                          {
                            label: "Not Available",
                            value: ""
                          }
                        ]
                      })}
                />
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Test Result</label>
                <Select
                  name="latestLot"
                  id="latestLot"
                  placeholder="Latest Cannastamp"
                  required={true}
                  isDisabled={this.state.disabled}
                  filterOption={this.filterTestResults}
                  onChange={this.handleLotSelect}
                  options={this.props.parentLots.map((lot, index: number) => {
                    return {
                      value: lot._id,
                      label: (
                        <div
                          key={index}
                          style={{
                            display: "flex"
                          }}
                        >
                          <div
                            style={{ width: "15%", display: "inline-block" }}
                            dangerouslySetInnerHTML={{
                              __html: lot.testResult.cannastamp
                            }}
                          />
                          <div
                            style={{
                              width: "55%",
                              display: "inline-block",
                              height: "20px",
                              marginTop: "auto",
                              marginBottom: "auto"
                            }}
                          >
                            {lot.testResult.strain} -
                            {lot.testResult.tracabilityParentLotId} -
                            {lot.testResult.tracabilitySampleLotId}
                          </div>
                        </div>
                      )
                    };
                  })}
                  {...(typeof this.props.product.latestLot !== "undefined"
                    ? {
                        defaultValue: defaultLot
                      }
                    : {
                        defaultValue: [
                          {
                            label: "Not Available",
                            value: null
                          }
                        ]
                      })}
                />
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Available On Market</label>
                {this.state.lotNotSelected && (
                  <label htmlFor="">{this.state.lotNotSelectedMessage}</label>
                )}
                <Select
                  name="available"
                  id="available"
                  placeholder="Available on market?"
                  required={true}
                  isDisabled={this.state.disabled || this.state.lotNotSelected}
                  options={formTool.productAvailability}
                  {...(typeof this.props.product.available !== "undefined"
                    ? {
                        defaultValue: [
                          {
                            label:
                              this.props.product.available === true
                                ? "Available"
                                : "Unavailable",
                            value: this.props.product.available
                          }
                        ]
                      }
                    : {
                        defaultValue: [
                          {
                            label: "Unavailable",
                            value: false
                          }
                        ]
                      })}
                />
              </div>
            </div>
          </div>
          <div className="cb_input--group">
            <label htmlFor="">Background Image</label>
            <AwsPublicUpload
              name="backgroundImage"
              token={this.props.token}
              clientId={this.props.clientId}
              disabled={this.state.disabled}
              uploadLinks={
                typeof this.props.product.backgroundImage !== "undefined" &&
                this.props.product.backgroundImage !== ""
                  ? [this.props.product.backgroundImage]
                  : []
              }
            />
          </div>
          <div className="cb_input--group">
            <label htmlFor="">Feature Image</label>
            <AwsPublicUpload
              name="featureImage"
              token={this.props.token}
              clientId={this.props.clientId}
              disabled={this.state.disabled}
              uploadLinks={
                typeof this.props.product.featureImage !== "undefined" &&
                this.props.product.featureImage !== ""
                  ? [this.props.product.featureImage]
                  : []
              }
            />
          </div>
          <div className="cb_input--group">
            <label htmlFor="">Image Gallery</label>
            <AwsPublicUpload
              name="mediaGallery"
              token={this.props.token}
              clientId={this.props.clientId}
              disabled={this.state.disabled}
              multiple={true}
              uploadLinks={
                typeof this.props.product.mediaGallery !== "undefined"
                  ? this.props.product.mediaGallery
                  : []
              }
            />
          </div>
          <div style={{ paddingTop: 50 }} className="cb_input--group">
            {!this.state.disabled && (
              <div>
                <h2>{this.state.message}</h2>
                <input
                  style={{ marginRight: 20 }}
                  disabled={this.state.disabled}
                  type="submit"
                  value="submit"
                />
                {this.props.hideCancel !== true && (
                  <a onClick={this.cancelEdit}>Cancel Edit</a>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    );
  };

  productView = () => {
    let blockStyle = { display: "inline-block", height: 40, paddingRight: 20 };
    return (
      <div
        style={{
          display: "inline-block",
          margin: 5,
          padding: 10,
          borderRadius: 10,
          minWidth: "25%",
          border: "2px solid gray"
        }}
      >
        <div>
          <h1>Product Details</h1>
        </div>
        <div />
        <div
          style={{
            margin: 5,
            padding: 10,
            backgroundColor: "#d3d3d34a",
            borderRadius: 10
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: 120,
              margin: 5,
              padding: 10,
              backgroundColor: "#d3d3d34a",
              borderRadius: 10
            }}
          >
            {/* <div style={{ display: "inline-block", width: "100%", height: 20 }}>
            Latest Lot
          </div> */}
            {typeof this.props.product.latestLot !== "undefined" && (
              <div
                style={{ display: "inline-block", width: 100 }}
                dangerouslySetInnerHTML={{
                  __html: this.props.product.latestLot.testResult.cannastamp
                }}
              />
            )}
          </div>

          <div
            style={{
              display: "inline-block",
              width: 500,
              paddingLeft: 30,
              margin: 5,
              padding: 10,
              verticalAlign: "top"
            }}
          >
            <div style={blockStyle}>
              <b>Brand: </b>
              {this.props.product.brand.name}
            </div>
            <div style={blockStyle}>
              <b>Product Line: </b>
              {this.props.product.productName}
            </div>
            <div style={blockStyle}>
              <b>Name/strain: </b>
              {this.props.product.name}
            </div>
            {this.props.product.strain && (
              <div style={blockStyle}>
                <b>strain: </b>
                {this.props.product.strain}
              </div>
            )}
          </div>
        </div>
        <div>
          <div
            style={{
              display: "inline-block",
              margin: 5,
              padding: 10,
              borderRadius: 10,
              minWidth: "25%",
              border: "2px solid lightgray"
            }}
            className="cb_input--group"
          >
            <label htmlFor="">Background Image</label>
            <AwsPublicUpload
              name="backgroundImage"
              token={this.props.token}
              clientId={this.props.clientId}
              disabled={true}
              uploadLinks={
                typeof this.props.product.backgroundImage !== "undefined"
                  ? [this.props.product.backgroundImage]
                  : []
              }
            />
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 5,
              padding: 10,
              borderRadius: 10,
              minWidth: "25%",
              border: "2px solid lightgray"
            }}
            className="cb_input--group"
          >
            <label htmlFor="">Feature Image</label>
            <AwsPublicUpload
              name="featureImage"
              token={this.props.token}
              clientId={this.props.clientId}
              disabled={true}
              uploadLinks={
                typeof this.props.product.featureImage !== "undefined"
                  ? [this.props.product.featureImage]
                  : []
              }
            />
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 5,
              padding: 10,
              minWidth: "50%",
              borderRadius: 10,
              border: "2px solid lightgray"
            }}
            className="cb_input--group"
          >
            <label htmlFor="">Image Gallery</label>
            <AwsPublicUpload
              name="mediaGallery"
              token={this.props.token}
              clientId={this.props.clientId}
              disabled={true}
              multiple={true}
              uploadLinks={
                typeof this.props.product.mediaGallery !== "undefined"
                  ? this.props.product.mediaGallery
                  : []
              }
            />
          </div>
        </div>
        {this.state.disabled && (
          <div
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            {this.props.product.active && (
              <div
                style={{
                  textAlign: "left",
                  flexGrow: 1
                }}
              >
                <h3>
                  <a
                    className="cb_form__enable-form-button"
                    onClick={this.deleteProduct}
                  >
                    Delete Product
                  </a>
                </h3>
              </div>
            )}
            <div
              style={{
                textAlign: "right",
                flexGrow: 1
              }}
            >
              <h3>
                <a
                  className="cb_form__enable-form-button"
                  onClick={this.enableEdit}
                >
                  Edit Product
                </a>
              </h3>
            </div>
          </div>
        )}
        {typeof this.state.dialog !== "undefined" && (
          <DialogModal
            content={this.state.dialog.content}
            open={this.state.dialog}
            onClose={this.closeDialog}
            {...this.state.dialog}
          />
        )}
      </div>
    );
  };

  render() {
    if (
      !this.state.strainList ||
      !this.state.brandList ||
      !this.state.productTypes // ||
      // (this.state.product === null &&
      //   this.props.productId &&
      //   !this.state.product)
    ) {
      return <div>Product form loading</div>;
    } else {
      if (this.state.disabled === true) {
        return (
          <div>
            {this.state.redirect === true ? (
              <Redirect
                push
                to={"/client/" + this.props.clientId + "/manageProducts"}
              />
            ) : (
              this.productView()
            )}
          </div>
        );
      } else {
        return (
          <div>
            {this.state.redirect === true ? (
              <Redirect
                push
                to={"/client/" + this.props.clientId + "/manageProducts"}
              />
            ) : (
              this.productForm()
            )}
          </div>
        );
      }
    }
  }
}

export default AuthenticationHOC()(ProductForm);
