import React, { Component } from "react";
import SearchController from "../components/SearchController";
import CannastampIcon from "../components/product/CannastampIcon";
import { withRouter } from "react-router";

import PublicService from "../apiService/PublicService";
import Slider from "react-slick";
// var async = require("async");
class ResultsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      similarProducts: null,
      activeComparison: null,
      similarArrays: [],
      productFocusPoint: null,
      activeSlide: 0,
      searchString: this.props.match.params.searchString,
      primaryResultcount: 8,
      productsReturned: false
    };
    let searchParams = {
      searchString: this.props.match.params.searchString || ""
    };
    PublicService.strainSearch(searchParams).then(json => {
      let slammedTogether = [];
      slammedTogether.push(...json.available);
      slammedTogether.push(...json.unavailable);
      this.setState({ productsReturned: slammedTogether }, () => {});
    });
  }

  render() {
    if (this.state.productsReturned !== false) {
      return (
        <div>
          <div className="cb_section cb_section--search cb_section--search-results">
            <div className="cb_section__inner">
              <div id="application">
                <SearchController />
              </div>
            </div>
          </div>

          <div id="masthead--results">
            <div className="container-fluid">
              <div className="cb_results-instructions">
                <h1 className="cb_results-instructions__heading">
                  Search results for{" "}
                  <span className="cb_results-instructions__query">
                    {this.state.searchString}
                  </span>
                </h1>
              </div>
              <div className="row">
                <div className="results-carousel">
                  <div className="cb_grid">
                    {this.state.productsReturned.map((product, i) => (
                      <div className="cb_grid-item--4" key={i}>
                        <div className="cb_cannastamp-container__outer" style={{ textAlign: "center" }}>
                          <a href={product.cleanUrl}>
                            <CannastampIcon
                              key={i}
                              testResult={product.latestLot.testResult}
                              size={"100"}
                            />
                          </a><br />
                          <span className="cb_thecannastamp__product-name">
                            {product.name}{" "}
                          </span><br />
                          <span className="cb_thecannastamp__grower-name">
                            by <a>{product.brand.name}</a>
                          </span><br />
                          {product.available === false ? (
                            <a>No Longer In Stores</a>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Loading</div>;
    }
  }
}
export default withRouter(ResultsPage);
