import React, { Component } from "react";
import AdminService from "../apiService/AdminService";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import LotList from "../client/clientComponents/productLot/LotList";
import { AdminApiHOC } from "./AdminApiHOC";
import TestResultList from "../client/clientComponents/testResult/TestResultList";
import "react-table/react-table.css";

class TestResultsManage extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
  }

  // onFetchData for ReactTable
  fetchData(state = {}, instance) {
    this.setState(
      {
        paginate: Object.assign(
          this.props.paginate,
          {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
          }
        )
      },
      () => {
        this.props.getTestResults(true, this.state.paginate, tests => {
          this.props.setTestResults(tests);
        });
      }
    );
  }

  render() {
    return (
      <div>
        <TestResultList
          testResultList={this.props.testResults}
          user={this.props.user}
          fetchData={this.fetchData}
          loading={this.props.loading}
          pages={this.props.paginate.pages}
          {...this.props}
        />
      </div>
    );
  }
}

export default AuthenticationHOC()(AdminApiHOC()(TestResultsManage));
