import * as React from "react";
import { Entity } from "../../apiService/serviceInterfaces";
import AdminService from "../../apiService/AdminService";
// import { ActionMeta } from "react-select/lib/types";

export interface ClientSetPremiumStatusProps {
  // fully formed client object
  client: Entity;
  initiallyDisabled?: boolean;
  token: string;
  reloadParent: (updated: Boolean) => void;
}

interface ClientSetPremiumStatusState {
  disabled: boolean;
  client: Entity;
  warning?: string;
  premiumLevel: "free" | "pro";
  premiumEnabled: boolean;
}

/**
 * This component accepts an entity and a user list. it then manages the state of the entity
 * by setting the admin user on the entity and updating its rendered view.
 */
export default class ClientSetPremiumStatus extends React.Component<
  ClientSetPremiumStatusProps,
  ClientSetPremiumStatusState
> {
  constructor(props: ClientSetPremiumStatusProps) {
    super(props);
    this.state = {
      disabled: this.props.initiallyDisabled
        ? this.props.initiallyDisabled
        : false,
      client: this.props.client,
      premiumLevel: this.props.client.premiumLevel,
      premiumEnabled: this.props.client.premiumEnabled
    };
    this.editEnable = this.editEnable.bind(this);
    this.setPremiumStatus = this.setPremiumStatus.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  private editEnable(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({ disabled: !this.state.disabled });
  }

  private setPremiumStatus(event: React.ChangeEvent<HTMLInputElement>): void {
    if (this.state.premiumLevel === "free")
      this.setState({ premiumLevel: "pro", premiumEnabled: true });
    else this.setState({ premiumLevel: "free", premiumEnabled: false });
  }

  private saveChanges(): void {
    let update = {
      premiumLevel: this.state.premiumLevel,
      premiumEnabled: this.state.premiumEnabled
    };
    AdminService.updateEntity(
      update,
      this.props.client._id,
      this.props.token
    ).then(
      fulfilled => {
        this.props.reloadParent(true);
      },
      rejected => {
        console.warn(rejected);
      }
    );
  }
  private setPremiumForm(): JSX.Element {
    if (this.state.disabled === false) {
      return (
        <div>
          <div>
            Enabled:
            <input
              name="PremiumEnabled"
              type="checkbox"
              checked={this.state.premiumLevel === "pro"}
              onChange={this.setPremiumStatus}
            />
          </div>
          <div>
            <input type="button" value="update" onClick={this.saveChanges} />
          </div>
        </div>
      );
    } else
      return (
        <div>
          <div>
            Pro Enabled:{this.state.premiumLevel === "pro" ? "Yes" : "No"}
          </div>
        </div>
      );
  }
  public render() {
    return (
      <div
        style={{
          width: 200,
          display: "inline-block",
          padding: 20,
          verticalAlign: "top"
        }}
      >
        Admin User
        <input type="button" value="Premium Status" onClick={this.editEnable} />
        {this.setPremiumForm()}
      </div>
    );
  }
}
