import React, { Component } from "react";
import PropTypes from "prop-types";
import Select, { Creatable } from "react-select";
import ClientServices from "../../../apiService/ClientService";
import { AuthenticationHOC } from "../../../components/AuthenticationHOC";
import formTools from "../generic/formTools";
import { Redirect } from "react-router-dom";
import ClientApiHOC from "../generic/ClientApiHOC";
import MenuList from "../../../components/generics/MenuList";
import { Document, Page, pdfjs } from "react-pdf";
import { withStyles } from "@material-ui/core/styles";
import UploadResultCard from "./PremiumUploadStepCards/UploadResultCard";

const styles = {
  uploadCard: {
    display: "inline-block",
    marginLeft: 10
  }
};

class TestResultForm extends Component {
  constructor(props) {
    super(props);
    let disabled = false;
    let message = "";
    let formTool = new formTools(props.token, props.clientId);

    this.state = {
      basic: false,
      sampleType: false,
      productCategories: formTool.productCategories,
      testResult: false,
      strainList: false,
      producerList: false,
      labList: false,
      message,
      disabled,
      unMutableCopy: null,
      showSolvents: true,
      pdfUploaded: !!props.testResultId,
      pdfLinks: [],
      redirect: false,
      testResultId: props.testResultId,
      targetProducts: [{}],
      productList: false,
      // some validation stuff
      strainBgcolor: "white",
      sampleTypeBgcolor: "white",
      sampleTypeCategoryBgcolor: "white",
      testLabBgColor: "white",
      pdfUploadBgColor: "white"
    };
    ClientServices.loadProducersKeyPair().then(
      producerPairs => {
        let producerList = producerPairs.map(client => {
          return { value: client._id, label: client.name };
        });
        this.setState({ producerList });
      },
      rejected => {
        console.warn(rejected);
      }
    );

    ClientServices.loadLabsKeyPair().then(
      labPairs => {
        let labList = labPairs.map(client => {
          return { value: client._id, label: client.name };
        });
        this.setState({ labList });
      },
      rejected => {
        console.warn(rejected);
      }
    );

    formTool.loadProductStrains(results => {
      this.setState({ strainList: results });
    });
    formTool.loadProductTypes(results => {
      this.setState({ sampleType: results });
    });
    this.loadFormData();
  }

  loadFormData() {
    if (typeof this.props.testResultId !== "undefined") {
      let testResultId = this.props.testResultId;
      let clientId = this.props.clientId;
      ClientServices.getTestResultById(
        testResultId,
        this.props.token,
        clientId
      ).then(testResult => {
        this.setState({
          testResult: testResult,
          pdfLinks: testResult.pdfFiles
        });
      });
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    let dataToSend = new FormData(event.target);
    let sampleTypeCategory = dataToSend.get("sampleTypeCategory");
    let sampleType = dataToSend.get("sampleType");
    let strain = dataToSend.get("strain");
    let testLab = dataToSend.get("testLab");
    let errorColor = "#ffa0a0";
    let normalColor = "white";
    let errorState = new Object();
    let normalState = new Object();
    strain === ""
      ? (errorState.strainBgColor = errorColor)
      : (normalState.strainBgColor = normalColor);

    sampleType === ""
      ? (errorState.sampleTypeBgColor = errorColor)
      : (normalState.sampleTypeBgColor = normalColor);

    sampleTypeCategory === ""
      ? (errorState.sampleTypeCategoryBgColor = errorColor)
      : (normalState.sampleTypeCategoryBgColor = normalColor);

    testLab === ""
      ? (errorState.testLabBgColor = errorColor)
      : (normalState.testLabBgColor = normalColor);

    if (Object.keys(errorState).length > 0) {
      return this.setState({
        message: "All Required Fields Must Be Entered",
        ...errorState,
        ...normalState
      });
    } else {
      ClientServices.saveTestResult(
        this.props.token,
        this.props.clientId,
        dataToSend,
        this.props.testResultId
      ).then(
        results => {
          if (this.props.onSave) this.props.onSave(results);
          else
            this.setState({
              // testResult: updatedtestResult,
              redirect: true,
              message: "testResult saved",
              disabled: true,
              ...normalState
            });
        },
        rejected => {
          this.setState({
            message:
              "there was an error saving the testResult" +
              JSON.stringify(rejected),
            ...normalState
          });
        }
      );
    }
  };

  cancelEdit = () => {
    this.setState({ redirect: true });
  };

  onFileChange(fileInputId) {
    this.setState({
      message: (
        <div style={{ width: 50 }}>
          <img src="/assets/images/loading.gif" />
          files are uploading
        </div>
      ),
      uploadDisabled: true
    });
    let formTool = new formTools(this.props.token, this.props.clientId);
    formTool.uploadFilesToS3(fileInputId, results => {
      if (results.error) {
        this.setState({ message: results.error, uploadDisabled: false });
      } else {
        let currentPdfs = this.state.pdfLinks;
        currentPdfs.push(results.uploadLinks);
        this.setState({
          message: null,
          pdfUploaded: results.success,
          pdfLinks: currentPdfs,
          uploadDisabled: false
        });
      }
    });
  }

  deleteFileLink(location) {
    let pdfLinksUpdate = this.state.pdfLinks;
    pdfLinksUpdate.splice(location, 1);
    this.setState({ pdfLinks: pdfLinksUpdate });
  }

  render() {
    let { classes } = this.props;
    let formTool = new formTools(this.props.token, this.props.clientId);
    if (
      this.state.strainList === false ||
      // this.state.sampleType === false ||
      this.state.producerList === false ||
      this.state.labList === false ||
      (this.state.testResult === false &&
        typeof this.state.testResultId !== "undefined") === true
    ) {
      return <div>testResult form loading</div>;
    } else {
      return (
        <div className="cb_form">
          {!!this.state.redirect && (
            <Redirect to={"/client/" + this.props.clientId + "/parentLots"} />
          )}

          <p>
            <b>{this.state.message}</b>
          </p>
          <div className="cb_input--group">
            <div style={{ width: 600 }}>
              <label>Concentration Reporting</label>
              <p style={{ fontSize: 14 }}>
                All concentrations are reported in % of mass. Do not report in
                mg/g. Concentrations of any one terpene above 15% will be
                rejected.
              </p>
            </div>
            <div style={{ width: 600 }}>
              <label style={{ backgroundColor: this.state.pdfUploadBgColor }}>
                PDF Files
              </label>
              <p style={{ fontSize: 14 }}>
                Upload as many files as necessary, including Regulatory
                requirements and Terpenes
              </p>
            </div>
            <input
              className="cb_input"
              type="file"
              id="testFile"
              accept="application/pdf"
              multiple={false}
              onChange={() => {
                this.onFileChange("testFile");
              }}
              disabled={this.state.uploadDisabled === true}
              name="testFile"
            />
          </div>

          <form className="cb_form__form" onSubmit={this.handleSubmit}>
            <div>
              <div className="cb_form__row">
                <div className="cb_form__column">
                  {this.state.pdfLinks.map((link, i) => (
                    <div key={i} className={classes.uploadCard}>
                      <UploadResultCard
                        width="200"
                        uploadFileArray={{
                          pdfFiles: Array.isArray(link) ? link : [link]
                        }}
                        uploadNumber={i}
                        removeFile={() => {
                          this.deleteFileLink(i);
                        }}
                      />
                      <input
                        name={"pdfFiles[" + i + "]"}
                        value={link}
                        type="hidden"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="cb_form__row">
              <div className="cb_form__column">
                {
                  <div className="cb_input--group">
                    <label
                      style={{ backgroundColor: this.state.testLabBgColor }}
                      htmlFor="name"
                    >
                      Lab Source*
                    </label>
                    <Select
                      name="testLab"
                      id="testLab"
                      options={this.state.labList}
                      required={true}
                      placeholder="Select your lab or unknown if not listed"
                      components={{ MenuList }}
                      isDisabled={this.state.disabled}
                      {...(typeof this.state.testResult.testLab !==
                        "undefined" && {
                        defaultValue: {
                          label: this.state.labList.find(
                            x => x.value === this.state.testResult.testLab
                          ).label,
                          value: this.state.testResult.testLab
                        }
                      })}
                    />
                  </div>
                }
                {!this.state.basic && (
                  <div className="cb_input--group">
                    <label
                      style={{ backgroundColor: this.state.sampleTypeBgColor }}
                      htmlFor=""
                    >
                      Sample Material Type*
                    </label>
                    <Creatable
                      name="sampleType"
                      id="sampleType"
                      className="cb_input"
                      required={true}
                      placeholder="Select or Create"
                      components={{ MenuList }}
                      isDisabled={this.state.disabled}
                      options={this.state.sampleType}
                      {...(typeof this.state.testResult.sampleType !==
                        "undefined" && {
                        defaultValue: {
                          label: this.state.testResult.sampleType,
                          value: this.state.testResult.sampleType
                        }
                      })}
                    />
                  </div>
                )}
                <div className="cb_input--group">
                  {this.props.testResultId && (
                    <input
                      disabled={this.state.disabled}
                      type="hidden"
                      name="_id"
                      value={
                        this.props.testResultId ? this.props.testResultId : null
                      }
                    />
                  )}
                  {!this.state.basic && (
                    <input
                      disabled={this.state.disabled}
                      type="hidden"
                      name="approved"
                      value={true}
                    />
                  )}

                  <div>
                    <input
                      type="hidden"
                      name="dataOwner"
                      id="dataOwner"
                      value={this.props.clientId}
                    />
                  </div>
                </div>
                {!this.state.basic && (
                  <div className="cb_input--group">
                    <label
                      style={{ backgroundColor: this.state.strainBgColor }}
                      htmlFor=""
                    >
                      Sample Strain*
                    </label>
                    <Creatable
                      name="strain"
                      id="strain"
                      className="cb_input"
                      required={true}
                      components={{ MenuList }}
                      isDisabled={this.state.disabled}
                      placeholder="Select or Create Strain"
                      options={this.state.strainList}
                      {...(typeof this.state.testResult.strain !==
                        "undefined" && {
                        defaultValue: [
                          {
                            label: this.state.testResult.strain,
                            value: this.state.testResult.strain
                          }
                        ]
                      })}
                    />
                  </div>
                )}

                {formTool.inputField(
                  "tracabilitySampleLotId",
                  "Sample Lot ID",
                  true,
                  this.state.disabled,
                  this.state.testResult
                )}
                {formTool.inputField(
                  "tracabilityParentLotId",
                  "Source (Batch) Lot ID",
                  true,
                  this.state.disabled,
                  this.state.testResult
                )}
              </div>
            </div>
            <div>
              <p>Enter all data in percentages.</p>
            </div>
            {!this.state.basic && <h1>Cannabinoids</h1>}
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbc",
                      "CBC",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbcA",
                      "CBCA",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbd",
                      "CBD",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbdA",
                      "CBDA",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbdV",
                      "CBDV",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbdVA",
                      "CBDVA",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbg",
                      "CBG",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbgA",
                      "CBGA",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbl",
                      "CBL",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cblA",
                      "CBLA",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbn",
                      "CBN",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbnA",
                      "CBNA",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "d8Thc",
                      "D8THC",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "thc",
                      "THC",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "thcA",
                      "THCA",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thcAC4",
                      "THCAC4",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thcV",
                      "THCV",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thcVA",
                      "THCVA",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
            </div>
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "thcTotal",
                      "Total THC",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbdTotal",
                      "Total CBD",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
            </div>
            {!this.state.basic && <h1>Terpenes</h1>}
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "caryophyllene",
                      "Caryophyllene",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "humulene",
                      "Humelene",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "limonene",
                      "Limonene",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "linalool",
                      "Linalool",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "myrcene",
                      "b-Myrcene",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "ocimene",
                      "Ocimene (total)",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "alphaPinene",
                      "a-Pinene",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "betaPinene",
                      "b-Pinene",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "terpinolene",
                      "Terpinolene",
                      true,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
            </div>
            {!this.state.basic && this.state.advanced && (
              <h1>Extended Terpenes</h1>
            )}
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "",
                      "Aromadendrene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "bisabolol",
                      "a-Bisabolol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "",
                      "Borneol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "camphene",
                      "Camphene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "camphor",
                      "Camphor",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "carene",
                      "Carene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "caryophelleneOxide",
                      "Caryophyllene Oxide"
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "aCedrene",
                      "a-Cedrene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cedrol",
                      "Cedrol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "citronellol",
                      "Citronellol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "paraCymene",
                      "para-Cymene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "eucalyptol",
                      "Eucalyptol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "farnesene",
                      "a-Farnesene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "bFarnesene",
                      "b-Farnesene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "fenchol",
                      "Fenchol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "geraniol",
                      "Geraniol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "gernylAcetate",
                      "Gernyl Acetate",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "guaiol",
                      "Guaiol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "Isoborneol",
                      "Isoborneol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "isopulegol",
                      "Isopulegol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "bMaaliene",
                      "b-Malliene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "menthol",
                      "Menthol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "nerol",
                      "Nerol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "transNerolidol",
                      "trans-Nerolidol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cisNerolidol",
                      "cis-Nerolidol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "aPhellandrene",
                      "a-Phellandrene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "phytol1",
                      "Phytol 1",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "phytol2",
                      "Phytol 2",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "pulegone",
                      "Pulegone",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "sabinene",
                      "Sabinene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "sabineneHydrate",
                      "Sabinene Hydrate",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "selinadiene",
                      "Selinadiene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "aTerpinene",
                      "a-Terpinene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "gTerpinene",
                      "gamma(y)-Terpinene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "terpineol",
                      "Terpineol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thujene",
                      "Thujene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thymol",
                      "Thymol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "",
                      "valencene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
            </div>
            <br />
            {!this.state.basic && this.state.advanced && (
              <h1>Residual Solvents</h1>
            )}
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "acetone",
                      "Acetone",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "acetonitrile",
                      "Acetonitrile",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "benzene",
                      "Benzene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nButane",
                      "n-Butane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "carbonTetrachloride",
                      "Carbon Tetrachloride"
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "chloroform",
                      "Chloroform",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "cyclohexane",
                      "Cyclohexane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "dichloromethane",
                      "Dichloromethane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "ethanol",
                      "Ethanol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "ethylAcetate",
                      "EthylAcetate",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "ethyleneOxide",
                      "Ethylene Oxide",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nHeptane",
                      "n-Heptane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nHexane",
                      "n-Hexane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "isobutane",
                      "Isobutane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "isopropanol",
                      "Isopropanol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methanol",
                      "Methanol",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methylbutane2",
                      "2-Methylbutane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methylcyclopentane",
                      "Methylcyclopentane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methylpentane3",
                      "3-Methylpentane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "neopentane",
                      "Neopentane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nPentane",
                      "n-Pentane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "propane",
                      "Propane",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "tetrahydrofuran",
                      "Tetrahydrofuran",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "toluene",
                      "Toluene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "mpXylene",
                      "m,p-Xylene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "oXylene",
                      "o-Xylene",
                      false,
                      this.state.disabled,
                      this.state.testResult,
                      0,
                      100
                    )}
                </div>
                <input
                  type="button"
                  value={this.state.advanced ? "Short Form" : "Extended Data"}
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                />
              </div>
            </div>
            <div className="cb_input--group cb_input--group-submit">
              {!this.state.disabled && (
                <div>
                  {this.props.hideCancel !== true && (
                    <input
                      type="button"
                      value="Cancel"
                      onClick={this.cancelEdit}
                    />
                  )}

                  <input
                    disabled={this.state.disabled}
                    type="submit"
                    value="Save"
                  />
                </div>
              )}
              <p>{this.state.message}</p>
            </div>
          </form>
        </div>
      );
    }
  }
}

TestResultForm.propTypes = {
  productId: PropTypes.string,
  clientId: PropTypes.string,
  onSave: PropTypes.func
};

export default withStyles(styles)(AuthenticationHOC()(TestResultForm));
