import * as React from "react";
import { FilterItem } from "./FilterItem";
import styled from "styled-components";
import { ProductFilterProps } from "../kioskInterfaces/filters";

export class ProductFilter extends React.Component<ProductFilterProps> {
  public render() {
    const { filterCategories } = this.props;
    return (
      <StyledHStack>
        {filterCategories.map((category, index) => (
          <FilterItem key={index} {...category} onClick={this.props.onClick} />
        ))}
      </StyledHStack>
    );
  }
}

const StyledHStack = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 15px;
  }
`;
