import * as React from "react";
import { Grid, Typography, TextField, Button, Switch } from "@material-ui/core";
import { GridSpacing } from "@material-ui/core/Grid";
import AwsImageUpload from "../generic/AwsImageUpload";
import MessagePhoneDemo from "./MessagePhoneDemo";
import {
  UserDocumentInterface,
  EntityDocumentInterface,
  LoyaltyMessageCreateInterface
} from "cbd-api-types";
//import nanoid from "nanoid";
import MemberFilterList, { Paginate } from "./MemberFilterList";
import {
  getLoyaltyMembers,
  GetLoyaltyMembersResponse,
  createLoyaltyMessage
} from "../../../apiService/LoyaltyService";

export interface IMessageCreateProps {
  clientData: EntityDocumentInterface;
  token: string;
  onSend: () => void;
}

export interface IMessageCreateState {
  activeImages: string[];
  messageBody: string;
  messageLink: string;
  messageName: string;
  sendDateTime: Date;
  shortUrlCode: string;
  userList: UserDocumentInterface["_id"][];
  imageShuffleLocation: number;
  shuffle: boolean;
  sendMessagedisabled: boolean;
  scheduled: boolean;
}

class MessageCreate extends React.Component<
  IMessageCreateProps,
  IMessageCreateState
> {
  constructor(props: IMessageCreateProps) {
    super(props);
    const { loyaltyConfig } = this.props.clientData;
    this.state = {
      scheduled: false,
      activeImages: null,
      messageName: null,
      messageLink: null,
      sendDateTime: null,
      shortUrlCode: null,
      userList: [],
      imageShuffleLocation: null,
      shuffle: false,
      sendMessagedisabled: false,
      messageBody: `${
        loyaltyConfig.customWarningText
          ? loyaltyConfig.customWarningText
          : loyaltyConfig.defaultWarningText
      }`
    };
  }

  // private resetState = () => {
  //   const { loyaltyConfig } = this.props.clientData;
  //   this.setState({
  //     activeImages: null,
  //     messageName: null,
  //     messageLink: null,
  //     sendDateTime: null,
  //     shortUrlCode: null,
  //     userList: [],
  //     imageShuffleLocation: null,
  //     shuffle: false,
  //     sendMessagedisabled: false,
  //     messageBody: `${
  //       loyaltyConfig.customWarningText
  //         ? loyaltyConfig.customWarningText
  //         : loyaltyConfig.defaultWarningText
  //     }`
  //   });
  // };

  // private updateUserList = (updatedUsers: UserDocumentInterface) => {};

  private setImage = (files: string[]) => {
    if (files) {
      // firstImage.push(...activeNew);
      // firstImage.push(...activeNew);
      this.setState(
        {
          activeImages: files,
          imageShuffleLocation: 0
        }
        // () => {
        //   // if (!this.state.shuffle) {
        //   //   this.setState({ shuffle: true }, () => {
        //   //     this.shuffleActiveImage();
        //   //   });
        //   // }
        // }
      );
    } else {
      this.setState({
        activeImages: null,
        imageShuffleLocation: null
      });
    }
  };

  private loadUserData = (
    paginate: Paginate,
    cb: (userData: GetLoyaltyMembersResponse) => void
  ) => {
    let { token, clientData } = this.props;
    getLoyaltyMembers(token, clientData._id, paginate)
      .then(res => {
        this.setState({ userList: res.idList });
        cb(res);
      })
      .catch(rejection => {
        console.warn("network failure " + rejection);
        rejection.users = [];
        rejection.pages = 0;
        rejection.idList = null;
        cb(rejection);
      });
  };

  private scheduleMessage = (): void => {
    const { activeImages, messageBody, sendDateTime, userList } = this.state;
    const { clientData, token, onSend } = this.props;
    const theMessage: LoyaltyMessageCreateInterface = {
      confirmationMessage: false,
      messageBody,
      messageMediaAws: activeImages,
      client: clientData._id,
      method: "mms",
      status: "pending",
      userList: userList.map((user: { _id: string }) => {
        return { user: user._id };
      }),
      sendDateTime
    };
    this.setState({ sendMessagedisabled: true }, () => {
      createLoyaltyMessage(token, clientData._id, theMessage)
        .then(success => {
          onSend();
        })
        .catch(failed => {
          this.setState({ sendMessagedisabled: false });
          console.warn(failed);
        });
    });
  };

  private setMessageText = (userText: string) => {
    const { loyaltyConfig } = this.props.clientData;
    let textout = `${userText} ${
      loyaltyConfig.customWarningText
        ? loyaltyConfig.customWarningText
        : loyaltyConfig.defaultWarningText
    }`;
    this.setState({ messageBody: textout });
  };

  public render() {
    let { token, clientData } = this.props;
    const {
      shortUrlCode,
      messageBody,
      userList,
      sendMessagedisabled,
      activeImages,
      scheduled
    } = this.state;

    return (
      <Grid container spacing={16 as GridSpacing}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Send a new Message
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8} md={8}>
          <Typography variant="subtitle1" gutterBottom>
            Text Body
          </Typography>
          <Typography variant="caption" gutterBottom>
            The text that will be sent with your message
          </Typography>
          <textarea
            name="messageBody"
            id="messageBody"
            onChange={e => {
              this.setMessageText(e.target.value);
            }}
          />
          {/* <Typography variant="subtitle1" gutterBottom>
            URL
          </Typography>
          <Typography variant="caption" gutterBottom>
            We can send any link that you like in your message
          </Typography>
          <input
            type="text"
            onChange={e => {
              this.setState({
                messageLink: e.target.value,
                shortUrlCode: nanoid(10)
              });
            }}
          />
          <Typography variant="caption" gutterBottom>
            {shortUrlCode && `http://cstmp.com/${shortUrlCode}`}
          </Typography> */}
          <Typography variant="subtitle1" gutterBottom>
            Delivery Date & Time
          </Typography>
          <Switch
            checked={scheduled}
            onChange={() => this.setState({ scheduled: !scheduled })}
          />
          {scheduled && (
            <TextField
              id="sendDateTime"
              type="datetime-local"
              InputLabelProps={{
                shrink: true
              }}
              onChange={e => {
                this.setState({
                  sendDateTime: new Date(e.target.value)
                });
              }}
            />
          )}

          <Typography variant="subtitle1" gutterBottom>
            Image Select*
          </Typography>
          <Typography variant="caption" gutterBottom>
            Try a transparent background!
          </Typography>
          <AwsImageUpload
            onChange={this.setImage}
            currentFiles={null}
            multiple={true}
            name={"messageMedia"}
            accepted="image/png, image/jpeg"
            token={token}
            clientId={clientData._id}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <MessagePhoneDemo
            messageContent={[
              {
                text: messageBody,
                images: activeImages && activeImages,
                url: shortUrlCode && `https://cstmp.com/${shortUrlCode}`,
                key: "1"
              }
            ]}
          />
          {messageBody && userList && activeImages && (
            <Grid item xs={12} alignItems="center">
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={this.scheduleMessage}
                disabled={sendMessagedisabled}
              >
                <i className="fal fa-comment-dots" />
                <div style={{ paddingLeft: 8 }}> Send Message!</div>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <MemberFilterList
            token={token}
            clientId={clientData._id}
            getData={this.loadUserData}
          />
        </Grid>
      </Grid>
    );
  }
}

export default MessageCreate;
