import React from "react";
import { EntityDocumentInterface, LoyaltyMessagesList } from "cbd-api-types";
import MessageCreate from "../clientComponents/loyalty/MessageCreate";
// import RecentData from "../clientComponents/loyalty/RecentData";
import PointOfferCreate from "../clientComponents/loyalty/PointOfferCreate";
import MessageList from "../clientComponents/loyalty/MessageList";
import { getAllLoyaltyMessages } from "../../apiService/LoyaltyService";
import ManagePointOffers from "./ManagePointOffers";

export interface ManageLoyaltyProps {
  clientData: EntityDocumentInterface;
  match: {
    params: {
      clientId: string;
    };
  };
  token: string;
}

export interface ManageLoyaltyState {
  activeModal: null;
  activeView:
    | "recentData"
    | "memberList"
    | "pointsOffers"
    | "messageHistory"
    | "chats"
    | "createMms"
    | "createPointsOffer"
    | "rewards"
    | "createReward";
  messageData: {
    clientMessagesPending: LoyaltyMessagesList[] | null;
    clientMessagesSent: LoyaltyMessagesList[] | null;
  };
  dataLoading: boolean;
  errorMessage: any;
}

export default class ManageLoyalty extends React.Component<
  ManageLoyaltyProps,
  ManageLoyaltyState
> {
  constructor(props: ManageLoyaltyProps) {
    super(props);
    this.state = {
      activeModal: null,
      activeView: "recentData",
      messageData: {
        clientMessagesPending: null,
        clientMessagesSent: null
      },
      dataLoading: true,
      errorMessage: null
    };
  }

  componentDidMount = () => {
    this.getClientMessages(() => {
      console.log("data loaded");
    });
  };

  // private setModal = (activeModal: ManageLoyaltyState["activeModal"]): void => {
  //   this.setState({ activeModal });
  // };

  private setView = (activeView: ManageLoyaltyState["activeView"]): void => {
    let { dataLoading } = this.state;
    if (dataLoading === false) {
      this.setState({ activeView });
    } else {
      this.setState({ errorMessage: "please wait for data to load" });
    }
  };

  private renderModal = (): JSX.Element | void => {
    let { activeModal } = this.state;
    switch (activeModal) {
    }
  };

  private onMessageCreated = () => {
    this.getClientMessages(() => {
      this.setState({ activeView: "messageHistory" });
    });
  };

  private renderView = (): JSX.Element | void => {
    let { activeView, messageData } = this.state;
    let { clientData, token } = this.props;
    switch (activeView) {
      case "createMms":
        return (
          <MessageCreate
            clientData={clientData}
            token={token}
            onSend={this.onMessageCreated}
          />
        );
      case "createPointsOffer":
        return (
          <PointOfferCreate
            clientData={clientData}
            token={token}
            onComplete={() => {
              this.setState({ activeView: "pointsOffers" });
            }}
          />
        );
      case "pointsOffers":
        return (
          <ManagePointOffers
            match={this.props.match}
            token={token}
            clientData={clientData}
          />
        );
      case "messageHistory":
        return (
          <MessageList
            clientMessagesPending={messageData.clientMessagesPending}
            clientMessagesSent={messageData.clientMessagesSent}
            createMessageTrigger={() => this.setView("createMms")}
          />
        );
      // case "rewards":
      //   return (<RewardsList />);
      // case "createReward":
      //   return (<CreateReward />);
      default:
        // if (dataLoading === false) {
        return (
          <MessageList
            clientMessagesPending={messageData.clientMessagesPending}
            clientMessagesSent={messageData.clientMessagesSent}
            createMessageTrigger={() => this.setView("createMms")}
          />
        );
    }
  };
  private getClientMessages(cb: () => void) {
    this.setState({ dataLoading: true }, () => {
      getAllLoyaltyMessages(this.props.token, this.props.clientData._id)
        .then((messages: any) => {
          let pendingMessages: LoyaltyMessagesList[] = [];
          let sentMessages: LoyaltyMessagesList[] = [];
          messages.map((message: LoyaltyMessagesList) => {
            if (message.status === "pending") {
              pendingMessages.push(message);
            } else {
              sentMessages.push(message);
            }
          });
          this.setState(
            {
              messageData: {
                clientMessagesPending: pendingMessages,
                clientMessagesSent: sentMessages
              },
              dataLoading: false
            },
            () => {
              cb();
            }
          );
        })
        .catch((err: Error) => {
          this.setState({ errorMessage: err });
        });
    });
  }

  public render = () => {
    const { dataLoading, errorMessage } = this.state;
    return (
      <div>
        {this.renderModal()}

        <div className="cb_account__content">
          <div className="cb_account__content-content">
            <div className="cb_data-table">
              <h4 className="cb_data-table__title">Loyalty</h4>

              <div className="cb_data-table__toolbar">
                <div className="cb_data-table__toolbar-left">
                  <div
                    className="cb_data-table__toolbar-button"
                    onClick={() => this.setView("createPointsOffer")}
                  >
                    <i className="fal fa-plus-circle" /> Points Offer
                  </div>
                  <div
                    className="cb_data-table__toolbar-button"
                    onClick={() => this.setView("pointsOffers")}
                  >
                    Offers
                  </div>
                  <div
                    className="cb_data-table__toolbar-button"
                    onClick={() => this.setView("createMms")}
                  >
                    <i className="fal fa-plus-circle" /> Message
                  </div>
                  <div
                    className="cb_data-table__toolbar-button"
                    onClick={() => this.setView("messageHistory")}
                  >
                    Messages
                  </div>
                  <div
                    className="cb_data-table__toolbar-button"
                    onClick={() => this.setView("createReward")}
                  >
                    <i className="fal fa-plus-circle" /> Rewards
                  </div>
                  <div
                    className="cb_data-table__toolbar-button"
                    onClick={() => this.setView("rewards")}
                  >
                    Rewards
                  </div>
                </div>
              </div>
              {errorMessage && <p>{JSON.stringify(errorMessage)}</p>}
              {dataLoading && <p>Data Loading!</p>}
              {this.renderView()}
            </div>
          </div>
        </div>
      </div>
    );
  };
}
