import React, { Component } from "react";
import EntityForm from "../client/clientComponents/entity/EntityForm";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { UserInterface } from "cbd-api-types";

export interface IClaimYourCompanyState {
  clientSelectOptions: boolean;
  redirect: boolean;
}

interface IClaimYourCompanyProps {
  user: UserInterface;
  updateUser: (claimResults: any, token: string) => void;
  token: string;
}

class ClaimYourCompany extends Component<
  IClaimYourCompanyProps,
  IClaimYourCompanyState
> {
  static defaultProps = {
    redirectUrl: "/binder"
  };

  constructor(props: IClaimYourCompanyProps) {
    super(props);

    let redirect = false;
    if (props.user === null || typeof props.user === "undefined") {
      redirect = true;
    }
    this.state = {
      clientSelectOptions: false,
      redirect
    };
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.user === null) {
      this.setState({ redirect: true });
    }
  }

  render() {
    return (
      <div
        style={{ maxWidth: 800, marginLeft: "auto", marginRight: "auto" }}
        className="cb_form"
      >
        <h4 className="cb_form__title">Company Registration Information</h4>
        <EntityForm
          token={this.props.token}
          user={this.props.user}
          // tunnelling to the form
          updateUser={this.props.updateUser}
          accountCreationMode={true}
        />
      </div>
    );
  }
}

export default AuthenticationHOC()(ClaimYourCompany);
