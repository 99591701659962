import * as React from "react";
import { EntityInterface } from "cbd-api-types";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import ClientServices from "../apiService/ClientService";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import { Color, validKioskPin, isValidKioskPin } from '../kiosk/kioskUtilities/constants';

interface Props extends WithStyles<typeof styles> {
  clientData: EntityInterface;
  match: { params: { clientId: string } };
  token: string;
}

interface State {
  cartEnabled: EntityInterface["kioskConfig"]["cartEnabled"];
  pin: EntityInterface["kioskConfig"]["pin"];
  settingChanged: boolean;
  saved: null | {
    cartEnabled?: boolean;
    pin?: boolean;
  };
  error: null | string;
}

const styles = createStyles({
  inputPin: {
    margin: '5px 15px',
  },
  saveButton: {
    color: Color.DarkBlueGray,
    border: `1px solid ${Color.DarkBlueGray}`
  },
  disabledButton: {
    color: Color.LightGray,
    border: `1px solid ${Color.LightGray}`
  },
  messageSuccess: {
    margin: '8px 16px',
  },
  messageError: {
    margin: '8px 16px',
  },
});

class ManageKioskSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cartEnabled: props.clientData.kioskConfig.cartEnabled || false,
      pin: props.clientData.kioskConfig.pin || "",
      settingChanged: false,
      saved: null,
      error: null,
    };

    this.validatePin = this.validatePin.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
  }

  private changeCartEnabled(checked: boolean) {
    this.setState({
      cartEnabled: checked,
      settingChanged: true
    });
  }

  private validatePin(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      pin: validKioskPin(e.target.value),
      settingChanged: true
    });
  }

  private saveSettings() {
    const kioskConfig = {
      cartEnabled: this.state.cartEnabled,
      pin: this.state.pin
    };
    let formData = new FormData();
    formData.append("kioskConfig[cartEnabled]", this.state.cartEnabled ? "true" : "false");
    formData.append("kioskConfig[pin]", this.state.pin);
    if (
      (kioskConfig.pin &&
      isValidKioskPin(kioskConfig.pin)) ||
      kioskConfig.pin === ""
    ) {
      ClientServices.entiyPut(
        this.props.token,
        formData,
        this.props.clientData._id)

      .then(results =>{
        if (results) {
          this.setState({
            saved: {
              pin: true
            },
            error: null
          },
          () => {
            setTimeout(
              () => this.setState({
                saved: null
              }), 5000
            )
          });
        }
      })
      .catch(error => {
        this.setState({
          error: error,
          saved: null
        });
      });
    } else {
      this.setState({
        error: "Invalid PIN. Please use 4 digits."
      });
    }
  }

  public render() {
    const { classes } = this.props;

    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__current">Manage Your Kiosk</li>
            </ul>
            <div className="cb_account__content-header-message">
              <p>
                Configure your Kiosk here.
              </p>
            </div>
          </div>
          <div className="cb_account__content-content">
            <Grid container spacing={16 as GridSpacing} direction="column">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ this.state.cartEnabled }
                      onChange={(_, checked) => this.changeCartEnabled(checked)}
                      color="default"
                    />
                  }
                  label="Enable shopping cart in kiosk?"
                />
                <Typography variant="caption">
                  {"Allow your users to add items to their cart while using your Kiosk"}
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Input
                      value={this.state.pin}
                      onChange={this.validatePin}
                      placeholder="Set your PIN"
                      className={classes.inputPin}
                      inputProps={{
                        pattern: "[0-9]{4}",
                        maxLength: "4",
                      }}
                    />
                  }
                  label="4-Digit PIN"
                />
                <Typography variant="caption">
                  {"Use your PIN to unlock the Kiosk"}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  classes={{ disabled: classes.disabledButton }}
                  disabled={!this.state.settingChanged}
                  onClick={this.saveSettings}
                  className={classes.saveButton}
                >
                  {"Save"}
                </Button>
                {this.state.saved && (
                  <Typography>
                    {"Settings saved"}
                  </Typography>
                )}
                {this.state.error && (
                  <Typography>
                    {"There was an error"}
                    <br/>
                    {this.state.error}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ManageKioskSettings);
