import * as React from "react";
import { Entity } from "../../apiService/serviceInterfaces";
import AdminService from "../../apiService/AdminService";
// import { ActionMeta } from "react-select/lib/types";

export interface ClientSetPremiumCoaLimitProps {
  // fully formed client object
  client: Entity;
  initiallyDisabled?: boolean;
  token: string;
  reloadParent: (updated: Boolean) => void;
}

interface ClientSetPremiumCoaLimitState {
  disabled: boolean;
  client: Entity;
  warning?: string;
  monthlyLimit: number | undefined;
  premiumEnabled: boolean;
}

/**
 * This component accepts an entity and a user list. it then manages the state of the entity
 * by setting the admin user on the entity and updating its rendered view.
 */
export default class ClientSetPremiumCoaLimit extends React.Component<
  ClientSetPremiumCoaLimitProps,
  ClientSetPremiumCoaLimitState
> {
  constructor(props: ClientSetPremiumCoaLimitProps) {
    super(props);
    this.state = {
      disabled: this.props.initiallyDisabled
        ? this.props.initiallyDisabled
        : false,
      client: this.props.client,
      monthlyLimit:
        typeof this.props.client.coaDataEntryService.serviceLimit !==
        "undefined"
          ? this.props.client.coaDataEntryService.serviceLimit
          : 0,
      premiumEnabled:
        typeof this.props.client.coaDataEntryService.enabled !== "undefined"
          ? this.props.client.coaDataEntryService.enabled
          : false
    };
    this.editEnable = this.editEnable.bind(this);
    this.setPremiumStatus = this.setPremiumStatus.bind(this);
    this.setMonthlyLimit = this.setMonthlyLimit.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  private editEnable(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({ disabled: !this.state.disabled });
  }

  private setPremiumStatus(event: React.ChangeEvent<HTMLInputElement>): void {
    let checkedState = event.target.checked;
    this.setState({ premiumEnabled: checkedState });
  }

  private setMonthlyLimit(event: React.ChangeEvent<HTMLInputElement>): void {
    let newLimit = parseInt(event.target.value);
    this.setState({ monthlyLimit: newLimit });
  }
  private saveChanges(): void {
    let update = {
      "coaDataEntryService.enabled": this.state.premiumEnabled,
      "coaDataEntryService.serviceLimit": this.state.monthlyLimit
    };
    AdminService.updateEntity(
      update,
      this.props.client._id,
      this.props.token
    ).then(
      fulfilled => {
        this.props.reloadParent(true);
      },
      rejected => {
        console.warn(rejected);
      }
    );
  }
  private setPremiumForm(): JSX.Element {
    if (this.state.disabled === false) {
      return (
        <div>
          <div>
            Enabled:
            <input
              name="coaPremiumEnabled"
              type="checkbox"
              checked={this.state.premiumEnabled}
              onChange={this.setPremiumStatus}
            />
          </div>
          <div>
            Monthly Limit{" "}
            <input
              name="coaMonthlyLimit"
              type="number"
              defaultValue={this.state.monthlyLimit.toString()}
              step="1"
              min="0"
              max="200"
              onChange={this.setMonthlyLimit}
            />
          </div>
          <div>
            <input type="button" value="update" onClick={this.saveChanges} />
          </div>
        </div>
      );
    } else
      return (
        <div>
          <div>Enabled:{this.state.premiumEnabled === true ? "Yes" : "No"}</div>
          <div>Monthly Limit {this.state.monthlyLimit}</div>
        </div>
      );
  }
  public render() {
    return (
      <div
        style={{
          width: 400,
          display: "inline-block",
          padding: 20,
          verticalAlign: "top"
        }}
      >
        Admin User
        <input
          type="button"
          value="Change Premium COA Limits"
          onClick={this.editEnable}
        />
        {this.setPremiumForm()}
      </div>
    );
  }
}
