import { oAuthMode } from "../configurator";
import validator, { isMobilePhone, isEmail } from "validator";
const remoteConfig = oAuthMode(window.location.href);
const baseAPI = remoteConfig.baseUrl + "consumer";
const authBaseApi = remoteConfig.baseUrl;

const UserService = {
  validateSession(token, user) {
    return new Promise((resolve, reject) => {
      fetch(authBaseApi + `authentication/validateUser/` + token)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserBinder(token, user) {
    var body = { Token: token, User: user };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/getBinder`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserBinderOnProduct(token, user, productId) {
    var body = { Token: token, User: user };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/user/product/` + productId + `/binder`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  rateBinderItem(token, user, binderItem) {
    var body = {
      Token: token,
      user: user,
      binderItem: binderItem
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/rateBinderItem`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  userSignUp(req, res) {
    return new Promise((resolve, reject) => {
      var error = false;
      var errorMessage = "";
      if (req.body.passwordVer !== req.body.password) {
        errorMessage = " *The passwords you entered did not match.*";
        error = true;
      } else if (req.body.password === "") {
        errorMessage = " *You must provide a password.*";
        error = true;
      } else if (!validator.isEmail(req.body.email)) {
        errorMessage += " *You must provide a valid E-Mail address.*";
        error = true;
      }
      // if any of the error conditions are true, throw it now before continuing
      if (error === true) {
        var errorData = {
          error: { message: errorMessage }
        };
        reject(errorData);
      } else {
        fetch(`${authBaseApi}authentication/signup`, {
          method: "post",
          body: JSON.stringify(req.body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(data => {
            return resolve(data);
          })
          .catch(err => reject(err));
      }
    });
  },

  userSignIn(req, res) {
    return new Promise((resolve, reject) => {
      var error = false;
      var errorMessage = "";
      if (!validator.isEmail(req.body.email)) {
        errorMessage += "*E-Mail address is not valid*";
        error = true;
      }
      if (error === true) {
        var errorData = {
          error: true,
          errorMessage: errorMessage
        };
        reject(errorData);
      } else {
        fetch(`${authBaseApi}authentication/signin`, {
          method: "POST",
          body: JSON.stringify(req.body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => {
            reject(err);
          });
      }
    });
  },

  userChangePassword(token, req, res) {
    return new Promise((resolve, reject) => {
      var error = false;
      var errorMessage = "";
      if (req.body.password === "") {
        errorMessage = "You must provide your current password.";
        error = true;
      } else if (req.body.passwordNew === "") {
        errorMessage = "You must provide a new password.";
        error = true;
      } else if (req.body.passwordVer === "") {
        errorMessage = "You must confirm your new password.";
        error = true;
      } else if (req.body.passwordVer !== req.body.passwordNew) {
        errorMessage = "The new passwords you entered did not match.";
        error = true;
      }
      if (error === true) {
        var errorData = {
          error: true,
          errorMessage: errorMessage
        };
        reject(errorData);
      } else {
        fetch(`${baseAPI}/` + token + `/changePassword`, {
          method: "POST",
          body: JSON.stringify(req.body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => {
            reject(err);
          });
      }
    });
  },

  updateUser(token, userId, req, res) {
    return new Promise((resolve, reject) => {
      var errorMessage;
      if (req.body.email === "" || !isEmail(req.body.email)) {
        errorMessage = "You must provide a valid email.";
      } else if (req.body.phone !== "" && !isMobilePhone(req.body.phone)) {
        errorMessage = "Phone numbers must be valid.";
      }
      if (errorMessage) {
        var errorData = {
          error: true,
          errorMessage: errorMessage
        };
        reject(errorData);
      } else {
        fetch(`${baseAPI}/` + token + `/user/` + userId, {
          method: "PUT",
          body: JSON.stringify(req.body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => {
            reject(err);
          });
      }
    });
  },

  deleteUser(userId, token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/user/` + userId, {
        method: "DELETE",
        body: null,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  addProductToCannabinder(testResult, token) {
    // accepts a product and a user token. token is decoded and
    var body = { testResult: testResult, token: token };
    return new Promise((resolve, reject) => {
      if (token === null || token === "") {
        reject("incomplete request");
      } else {
        fetch(`${baseAPI}/` + token + `/addToBinder`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => {
            reject(err);
          });
      }
    });
  },

  removeBinderItem(binderItem, token) {
    // accepts a product and a user token. token is decoded and
    var body = { binderItem: binderItem, Token: token };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/removeBinderItem`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  getBlogList() {
    return new Promise((resolve, reject) => {
      fetch(baseAPI + `/blogs/`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  getProduct(productId, relatedLotId = "") {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/product/${productId}/${relatedLotId}`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  passwordReset(email) {
    return new Promise((resolve, reject) => {
      if (validator.isEmail(email)) {
        fetch(`${authBaseApi}authentication/passwordReset/` + email)
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => reject(err));
      } else {
        reject({ error: true, errorMessage: "Please enter a valid email." });
      }
    });
  },

  userRecoverPassword(token, req) {
    return new Promise((resolve, reject) => {
      var error = false;
      var errorMessage = "";
      if (req.body.email === "") {
        errorMessage = "You must provide your current email.";
        error = true;
      } else if (!validator.isEmail(req.body.email)) {
        errorMessage = "You must provide a valid email.";
        error = true;
      } else if (req.body.passwordNew === "") {
        errorMessage = "You must provide a new password.";
        error = true;
      } else if (req.body.passwordVer === "") {
        errorMessage = "You must confirm your new password.";
        error = true;
      } else if (req.body.passwordVer !== req.body.passwordNew) {
        errorMessage = "The new passwords you entered did not match.";
        error = true;
      }
      if (error === true) {
        var errorData = {
          error: true,
          errorMessage: errorMessage
        };
        reject(errorData);
      } else {
        fetch(`${authBaseApi}authentication/updatePassword/` + token, {
          method: "POST",
          body: JSON.stringify(req.body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => {
            reject(err);
          });
      }
    });
  }
};

export default UserService;
