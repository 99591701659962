import React, { Component } from "react";
import AdminService from "../apiService/AdminService";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { AdminApiHOC } from "./AdminApiHOC";
import "react-table/react-table.css";
import { MasterPageSpinner } from "../components/master-page-spinner/MasterPageSpinner";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import DataEntryTestResultForm from "./adminComponents/DataEntryTestResultForm";
import AdminDataEntryService from "../apiService/AdminDataEntryService";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = theme => ({
  root: {
    flexGrow: 1,

    position: "fixed",
    top: 70,
    bottom: 0,
    left: 0,
    right: 0,
    height: "auto"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  paperDocument: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 1300,
    marginLeft: "auto",
    marginRight: "auto"
  },
  paperStaticTop: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 650,
    margin: 20,
    position: "static"
  },
  movingDiv: {
    zIndex: 10,
    flexGrow: 1,
    paddingTop: 350
  },
  submitButtonGrid: {
    marginTop: 40
  },
  feedbackGrid: {
    marginTop: 40
  },
  startNewEntryGrid: {
    marginTop: 40
  },
  pdfPage: {
    margin: "auto",
    width: 1200
  }
});

class TestDataEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingList: false,
      activeLot: false,
      floatZIndex: 11,
      message: false
    };
    if (this.props.mode === "entry") {
      this.initializerEntry();
    } else {
      this.initializerReview();
    }

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  initializerEntry = () => {
    this.props.checkServiceToken().then(results => {
      // not doign anything with the results. its a new token. not sure why we aren't setting it
      // todo: make sure that the token system is working correctly.
      this.setState({ activeLot: false }, () => {
        AdminService.getPendingTestEntries(this.props.token).then(
          pendingLotList => {
            // let activeLot;
            let pending = pendingLotList;
            let unclaimedLots = pending.filter(lot => {
              return typeof lot.processingInfo.reviewUser === "undefined";
            });
            if (unclaimedLots.length === 0) {
              this.setState({
                message: "There is no data to enter"
              });
            } else {
              let workingLot = pendingLotList.find(lot => {
                return lot.processingInfo.entryUser === this.props.user._id;
              });
              if (workingLot) {
                this.setState({
                  activeLot: workingLot
                });
              } else {
                this.checkoutLotForEntry(
                  unclaimedLots[0]._id,
                  this.props.user._id,
                  results => {
                    this.setState({
                      activeLot: unclaimedLots[0]
                    });
                  }
                );
              }
            }
          },
          reject => {
            console.warn(reject);
          }
        );
      });
    });
  };

  initializerReview = () => {
    this.props.checkServiceToken().then(results => {
      // not doign anything with the results. its a new token. not sure why we aren't setting it
      // todo: make sure that the token system is working correctly.
      this.setState({ activeLot: false }, () => {
        AdminService.getPendingTestReview(this.props.token).then(
          pendingLotList => {
            let pending = pendingLotList;
            let unclaimedLots = pending.filter(lot => {
              return (
                typeof lot.processingInfo.reviewUser === "undefined" ||
                lot.processingInfo.reviewUser === this.props.user._id
              );
            });
            if (unclaimedLots.length === 0) {
              this.setState({
                message: "There is nothing to do"
              });
            } else {
              let workingLot = pendingLotList.find(lot => {
                return lot.processingInfo.reviewUser === this.props.user._id;
              });
              if (workingLot) {
                this.setState({
                  activeLot: workingLot
                });
              } else {
                this.checkoutLotForReview(
                  unclaimedLots[0]._id,
                  this.props.user._id,
                  results => {
                    this.setState({
                      activeLot: unclaimedLots[0]
                    });
                  }
                );
              }
            }
          },
          reject => {
            console.warn(reject);
          }
        );
      });
    });
  };

  handleKeyDown(event) {
    if (event.key.toString() === "`") {
      this.setState({ floatZIndex: -1 });
    }
  }
  handleKeyUp(event) {
    if (event.key.toString() === "`") {
      this.setState({ floatZIndex: 11 });
    }
  }

  sendLotUpdate = (update, lotId, callBack) => {
    AdminDataEntryService.updateTestLot(this.props.token, lotId, update).then(
      resolved => {
        callBack(resolved);
      },
      rejected => {
        callBack(rejected);
      }
    );
  };

  checkoutLotForEntry = (lotId, callback) => {
    let update = {
      "processingInfo.entryStartDate": new Date(),
      "processingInfo.entryUser": this.props.user._id
    };
    this.sendLotUpdate(update, lotId, results => {
      if (results._id) {
        this.setState({ activeLot: results });
      } else {
        this.setState({ message: "Error " + results.toString() });
      }
    });
  };

  checkoutLotForReview(lotId, callback) {
    let update = {
      "processingInfo.reviewStartDate": new Date(),
      "processingInfo.reviewUser": this.props.user._id
    };
    this.sendLotUpdate(update, lotId, results => {
      if (results._id) {
        this.setState({ activeLot: results });
      } else {
        this.setState({ message: "Error " + results.toString() });
      }
    });
  }

  submitLotForReview = () => {
    let update = {
      "processingInfo.status": "review",
      "processingInfo.entryStopDate": new Date(),
      "processingInfo.entryUser": this.props.user._id
    };
    this.sendLotUpdate(update, this.state.activeLot._id, cb => {
      this.initializerEntry();
    });
  };

  approveData = () => {
    let update = {
      "processingInfo.status": "completed",
      "processingInfo.reviewOutcome": "accurate",
      "processingInfo.reviewStopDate": new Date()
    };

    this.sendLotUpdate(update, this.state.activeLot._id, cb => {
      this.initializerReview();
    });
  };

  approveDataWithEdits = () => {
    let update = {
      "processingInfo.status": "completed",
      "processingInfo.reviewOutcome": "error"
    };
    this.sendLotUpdate(update, this.state.activeLot._id, cb => {
      this.initializerReview();
    });
  };

  rejectData = rejectReason => {
    let update = {
      "processingInfo.status": "pending",
      "processingInfo.reviewOutcome": "error",
      "processingInfo.errorOccured": true,
      "processingInfo.reviewNotes": rejectReason
    };
    this.sendLotUpdate(update, this.state.activeLot._id, cb => {
      this.initializerReview();
    });
  };

  onDocumentLoadSuccess = ({ numPages }) =>
    this.setState({
      numPages
    });

  render() {
    let { testResult } = this.state.activeLot;
    let { owner } = this.state.activeLot;
    return (
      <>
        <div
          className={this.props.classes.root}
          style={{ zIndex: this.state.floatZIndex }}
        >
          <Paper className={this.props.classes.paperStaticTop}>
            <Grid container spacing={12}>
              <Grid item xs={3}>
                {this.state.activeLot !== false && (
                  <a href={`/client/${owner._id}/parentLots`} target="_blank">
                    {owner.name}
                  </a>
                )}
              </Grid>
              <Grid item xs={9}>
                Hold ` To Show Document
                {this.state.message} <br />
                {testResult &&
                  testResult.pdfFiles.map((awsUrl, key) => {
                    return (
                      <a href={awsUrl} key={key} target="_blank">
                        file #{key + 1}
                      </a>
                    );
                  })}
              </Grid>
              <Grid item xs={12}>
                {this.state.activeLot === false ? (
                  this.state.message !== false ? (
                    this.state.message
                  ) : (
                    <MasterPageSpinner />
                  )
                ) : (
                  <DataEntryTestResultForm
                    testResult={this.state.activeLot.testResult}
                    clientId={this.state.activeLot.owner._id}
                    mode={this.props.mode}
                    approveData={this.approveData}
                    rejectData={this.rejectData}
                    approveDataWithEdits={this.approveDataWithEdits}
                    submitLotForReview={this.approveData}           // TODO: Don't skip review step
                    // submitLotForReview={this.submitLotForReview} // TODO: Don't skip review step
                    reviewNotes={
                      this.state.activeLot.processingInfo.reviewNotes
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
        <div className={this.props.classes.movingDiv}>
          {this.state.activeLot !== false &&
            this.state.activeLot.testResult.pdfFiles.map((pdfFile, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  className={this.props.classes.coaDoc}
                >
                  <Paper className={this.props.classes.paperDocument}>
                    {pdfFile.search(".pdf") === -1 ? (
                      <img src={pdfFile} />
                    ) : (
                      <Document
                        file={pdfFile}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                      >
                        {Array.from(
                          new Array(this.state.numPages),
                          (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              width="1200"
                            />
                          )
                        )}
                      </Document>
                    )}
                  </Paper>
                </Grid>
              );
            })}
        </div>
      </>
    );
  }
}

TestDataEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  token: PropTypes.string,
  lotId: PropTypes.string
};

export default withStyles(styles)(
  AuthenticationHOC()(AdminApiHOC()(TestDataEntry))
);
