import React, { Component } from "react";
import Select from "react-select";
import formTools from "./clientComponents/generic/formTools";
import MenuList from "../components/generics/MenuList";
import YouTube from "react-youtube";

class TrainingPage extends Component {
  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__active">
                <a className="cb_breadcrumb__link">
                  Cannabinder Printed Materials
                </a>
              </li>
              <li className="cb_breadcrumb__delimiter">
                <i className="fal fa-arrow-right" />
              </li>
              <li className="cb_breadcrumb__current" />
            </ul>
            <div className="cb_account__content-header-message">
              <p />
            </div>
          </div>
          <div className="cb_account__content-content">
            <div className="cb_article">
              <h4 className="cb_article__title">Flyer</h4>
              <img
                style={{ height: 300, width: "auto" }}
                src="/assets/images/client/Flyer-web.jpg"
              />
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Flyer.png"
              >
                Download Flyer
              </a>
            </div>
            <div className="cb_article">
              <h4 className="cb_article__title">Product Card 2.25 Square</h4>
              <img
                style={{
                  height: 300,
                  width: "auto",
                  display: "inline-block",
                  padding: 10
                }}
                src="/assets/images/client/Product-Card-2.25x2.25-Front-web.jpg"
              />
              <img
                style={{
                  height: 300,
                  width: "auto",
                  display: "inline-block",
                  padding: 10
                }}
                src="/assets/images/client/Product-Card-2.25x2.25-Back-web.jpg"
              />
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Product+Card+2.25x2.25+-+Front.png"
              >
                Download Front
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Product+Card+2.25x2.25+-+Back.png"
              >
                Download Back
              </a>
            </div>
            <div className="cb_article">
              <h4 className="cb_article__title">Product Card Large</h4>
              <img
                style={{
                  height: 300,
                  width: "auto",
                  display: "inline-block",
                  padding: 10
                }}
                src="/assets/images/client/Product-Card-Large-Front-web.jpg"
              />
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Product+Card+Large+-+Front.png"
              >
                Download Front
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Product+Card+Large+-+Back.png"
              >
                Download Back
              </a>
            </div>
            <div className="cb_article">
              <h4 className="cb_article__title">Product Card Small</h4>
              <img
                style={{
                  height: 300,
                  width: "auto",
                  display: "inline-block",
                  padding: 10
                }}
                src="/assets/images/client/Product-Card-Smal-Front-web.jpg"
              />
              <img
                style={{
                  height: 300,
                  width: "auto",
                  display: "inline-block",
                  padding: 10
                }}
                src="/assets/images/client/Product-Card-Small-Back-web.jpg"
              />
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Product+Card+Small+-+Front.png"
              >
                Download Front
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Product+Card+Small+-+Back.png"
              >
                Download back
              </a>
            </div>
            <div className="cb_article">
              <h4 className="cb_article__title">Table Top Card Large</h4>
              <img
                style={{ height: 300, width: "auto" }}
                src="/assets/images/client/Tabletop-Card-Large-web.jpg"
              />
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Product+Card+Small+-+Back.png"
              >
                Download
              </a>
            </div>
            <div className="cb_article">
              <h4 className="cb_article__title">Table Top Card Small</h4>
              <img
                style={{ height: 300, width: "auto" }}
                src="/assets/images/client/Tabletop-Card-Small-web.jpg"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/Tabletop+Card+-+Small.png"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TrainingPage.propTypes = {};

export default TrainingPage;
