import React, { Component } from "react";
import { withRouter } from "react-router";

class ProducerPage extends Component {
  constructor(props) {
    super(props);
    if (typeof props.user === "object") {
      this.state = {
        redirect: "ClaimYourCompany"
      };
    } else {
      this.state = { redirect: false };
    }
  }
  render() {
    return (
      <div className="cb_page--producers">
        <div className="cb_masthead cb_masthead--producer">
          <div className="cb_section__inner cb_masthead__content">
            <div className="cb_grid">
              <div className="cb_producer-masthead-messaging col-sm-5">
                <h1 className="cb_heading--1 cb_heading--blue">
                  Hey Producers!
                </h1>
                <h2 className="cb_heading--2 cb_heading--white">
                  Cannabinder Connects You!
                </h2>
                <h4 className="cb_heading--5 cb_heading--white">
                  We help you communicate your product with our kiosk sales
                  tools and connect you to your customers with our Loyalty+ MMS
                  platform.
                </h4>
                <br />
                <p>
                  Get started for free today and see your products through the
                  Cannastamp&trade;.
                </p>
              </div>
              <div className="cb_producer-form col-sm-6 col-sm-push-1">
                <div
                  className="cb_section cb_section--producer-features cb_grid"
                >
                  <div className="cb_producer-final-cta-content">
                    <img
                      className="cb_image cb_image--producer-features-image--right"
                      src="/assets/images/comparison-1.png"
                      style={{
                        width: 200,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                      alt=""
                    />
                    <h2 className="cb_ready-to-get-started">
                      Ready to get started?
                    </h2>
                    {typeof this.props.user !== "object" ? (
                      <a
                        className="cb_create-producer-account"
                        href="/signup/ClaimYourCompany/producer"
                      >
                        Sign Up!
                      </a>
                    ) : (
                      <a
                        className="cb_create-producer-account"
                        href="/ClaimYourCompany"
                      >
                        Setup Your Business Account!
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-indicator">
              <a id="scroll-down" className="scroll-indicator__arcb_grids" />
            </div>
          </div>
        </div>
        <div className="cb_template--main-content container-fluid">
          <div className="cb_section cb_section--producers--getting-started cb_grid">
            <div className="container">
              <div className="cb_grid">
                <div className="col-sm-12">
                  <h4 className="cb_heading--3">
                    Getting started with Cannabinder is easy...
                  </h4>
                  <h3 className="cb_heading--1">and Free.</h3>
                </div>
                <div className="cb_getting-started-column col-sm-4">
                  <img
                    className="cb_getting-started-image"
                    src="/assets/images/producers--create-account.png"
                    alt=""
                  />
                  <h3 className="cb_getting-started-header">
                    Sign Up for Free
                  </h3>
                  <p>Request a free producer account on Cannabinder.com.</p>
                  {typeof this.props.user !== "object" ? (
                    <a
                      className="cb_create-producer-account"
                      href="/signup/ClaimYourCompany"
                    >
                      Request Your Free Account Now!
                    </a>
                  ) : (
                    <a
                      className="cb_create-producer-account"
                      href="/ClaimYourCompany"
                    >
                      Setup Your Business Account!
                    </a>
                  )}
                </div>
                <div className="cb_getting-started-column col-sm-4">
                  <img
                    className="cb_getting-started-image"
                    src="/assets/images/producers--upload-certs.png"
                    alt=""
                  />
                  <h3 className="cb_getting-started-header">
                    Upload Your Certs
                  </h3>
                  <p>
                    Upload your cannabis product certificates with cannabinoid
                    and terpene profile data to your profile.
                  </p>
                </div>
                <div className="cb_getting-started-column col-sm-4">
                  <img
                    className="cb_getting-started-image"
                    src="/assets/images/producers--cannastamp-engine.png"
                    alt=""
                  />
                  <h3 className="cb_getting-started-header">Get Your Stamps</h3>
                  <p>
                    The Cannabinder engine creates a unique Cannastamp&trade;
                    for each of your products.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cb_section cb_section--producer-features cb_grid">
            <div className="container">
              <div className="cb_section--inner cb_grid">
                <div className="col-sm-12">
                  <h2 className="cb_producer-features-heading">
                    Cannabinder Pro has a lot to offer.
                  </h2>
                </div>
              </div>
              <div className="cb_section--inner cb_grid">
                <div className="col-sm-6">
                  <img
                    style={{ maxWidth: 250 }}
                    className="cb_image cb_image--producer-features-image--right"
                    src="/assets/images/loyaltyProgram.png"
                    alt=""
                  />
                </div>
                <div className="col-sm-6">
                  <div className="cb_producer-features-content">
                    <div className="cb_badge-group">
                      <div className="cb_badge cb_badge--lavender">Pro</div>
                    </div>
                    <h4>MMS Loyalty+</h4>
                    <p>
                      Easily convert a customer to your own loyalty program at
                      vendor days or through your website with transparent
                      message pricing!
                    </p>
                    <p>
                      With Pro Loyalty+, you start with 3,000 picture
                      messages/mo. and additional messages can be purchased as needed.
                    </p>
                    <p>Drive your fans to your product!</p>
                  </div>
                </div>
              </div>
              <div className="cb_section--inner cb_grid">
                <div className="col-sm-6 col-sm-push-6">
                  <img
                    className="cb_image cb_image--producer-features-image--right"
                    src="/assets/images/producers--certs.png"
                    alt=""
                  />
                </div>
                <div className="col-sm-6 col-sm-pull-6">
                  <div className="cb_producer-features-content">
                    <div className="cb_badge-group">
                      <div className="cb_badge cb_badge--purple">Free</div>
                    </div>
                    <h4>Keep Track of Your Certs</h4>
                    <p>
                      Have a never-ending pile of certificates for your
                      products? We can help with that. Your Producer account
                      doubles as a convenient storage locker for all your
                      product certs.
                    </p>
                    <p>
                      Easily search and pull up certs from previous lots, or
                      upload new ones to create new Cannastamps&trade; and
                      product listings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="cb_section--inner cb_grid">
                <div className="col-sm-6">
                  <img
                    className="cb_image cb_image--producer-features-image--left"
                    src="/assets/images/producers--map.png"
                    alt=""
                  />
                </div>
                <div className="col-sm-6">
                  <div className="cb_producer-features-content">
                    <div className="cb_badge-group">
                      <div className="cb_badge cb_badge--purple">Pro</div>
                      <div className="cb_badge cb_badge--orange">
                        Coming Soon
                      </div>
                    </div>
                    <h4>Where to Buy Your Products</h4>
                    <p>
                      Want your customers to be able to easily find your
                      products in store? We can do that.
                    </p>
                    <p>
                      Make it easy for your customers to buy your products at
                      the dispensaries closest to them.
                    </p>
                  </div>
                </div>
              </div>

              <div className="cb_section--inner cb_grid">
                <div className="col-sm-12">
                  <div className="cb_producer-final-cta-content">
                    <h2 className="cb_ready-to-get-started">
                      Ready to get started?
                    </h2>
                    {typeof this.props.user !== "object" ? (
                      <a
                        className="cb_create-producer-account"
                        href="/signup/ClaimYourCompany/producer"
                      >
                        Sign Up!
                      </a>
                    ) : (
                      <a
                        className="cb_create-producer-account"
                        href="/ClaimYourCompany"
                      >
                        Setup Your Account!
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProducerPage);
