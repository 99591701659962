import React, { Component } from "react";
import formTools from "../generic/formTools";
import { withRouter, Redirect, RouteComponentProps } from "react-router-dom";
import ClientServices from "../../../apiService/ClientService";
import AwsPublicUpload from "../generic/AwsPublicUpload";
import { EntityInterface, UserInterface } from "cbd-api-types";

interface EntityFormProps {
  clientData?: EntityInterface;
  accountCreationMode?: boolean;
  onSave?: (formData: FormData) => void;
  token: string;
  updateUser: (claimResults: any, token: IEntityFormProps["token"]) => void;
  match: { params: any };
  redirectUrl?: string;
  user: UserInterface;
  clientId?: string;
}

export type IEntityFormProps = Exclude<RouteComponentProps, "match"> &
  EntityFormProps;
export interface IEntityFormState {
  redirect: boolean;
  message: string;
  disabled: boolean;
  showSecret: boolean;
}

class EntityForm extends Component<IEntityFormProps, IEntityFormState> {
  static defaultProps = {
    redirectUrl: "/home",
    accountCreationMode: false
  };
  constructor(props: IEntityFormProps) {
    super(props);
    this.state = {
      redirect: false,
      message: null,
      disabled: false,
      showSecret: false
    };
  }
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let dataToSend = new FormData(event.currentTarget);
    let clientId =
      !this.props.clientData || typeof this.props.clientData._id === "undefined"
        ? ""
        : this.props.clientData._id;

    // intercepts the submit function of the button sending the data to the onSave
    // prop for the form. This is how separation of  concerns should be without
    // the form managing saving any data
    // TODO: DEV-273 ticket. remove promises from this section of code and force consuming
    // parent component to handle saving the data.
    if (this.props.onSave) {
      this.props.onSave(dataToSend);
    } else if (this.props.accountCreationMode === false) {
      ClientServices.entiyPut(this.props.token, dataToSend, clientId).then(
        results => {
          this.setState({ redirect: true });
        },
        rejected => {
          this.setState({
            message:
              "there was an error saving the entity" + JSON.stringify(rejected)
          });
        }
      );
    } else {
      ClientServices.claimCompany(this.props.token, dataToSend, clientId).then(
        results => {
          this.props.updateUser(results, this.props.token);
          if (
            this.props.accountCreationMode &&
            dataToSend.get("type") === "producer"
          ) {
            this.props.history.push(
              "/client/" + results.clientAssignment + "/welcome/producer"
            );
          } else {
            this.props.history.push(
              "/client/" + results.clientAssignment + "/manageBrands"
            );
          }
          window.location.reload();
        },
        rejected => {
          this.setState({
            message:
              "there was an error saving the testResult" +
              JSON.stringify(rejected)
          });
        }
      );
    }
  };

  cancelForm = () => {
    this.setState({ redirect: true });
  };

  sendRedirect = (): JSX.Element | void => {
    if (this.state.redirect === true) {
      return <Redirect to={this.props.redirectUrl} />;
    }
  };

  renderImageUpload = (): JSX.Element | void => {
    // Entity should already be created
    // Currently only available for Dispensaries and admins
    const { token, user, match } = this.props;
    if (
      typeof token !== "undefined" &&
      token !== null &&
      token !== "" &&
      typeof match.params.clientId !== "undefined" &&
      match.params.clientId !== null &&
      match.params.clientId !== "" &&
      (user.dispensaryUser === true || user.adminUser === true)
    ) {
      return (
        <div className="cb_input--group">
          <div className="cb_input--group">
            <label htmlFor="">Background Image</label>
            <AwsPublicUpload
              name="backgroundImage"
              token={this.props.token}
              clientId={this.props.match.params.clientId}
              disabled={this.state.disabled}
              uploadLinks={
                typeof this.props.clientData.backgroundImage !== "undefined" &&
                this.props.clientData.backgroundImage !== ""
                  ? [this.props.clientData.backgroundImage]
                  : []
              }
            />
          </div>
          <div className="cb_input--group">
            <label htmlFor="">Feature Image</label>
            <AwsPublicUpload
              name="featureImage"
              token={this.props.token}
              clientId={this.props.match.params.clientId}
              disabled={this.state.disabled}
              uploadLinks={
                typeof this.props.clientData.featureImage !== "undefined" &&
                this.props.clientData.featureImage !== ""
                  ? [this.props.clientData.featureImage]
                  : []
              }
            />
          </div>
          <div className="cb_input--group">
            <label htmlFor="">Image Gallery</label>
            <AwsPublicUpload
              name="mediaGallery"
              token={this.props.token}
              clientId={this.props.match.params.clientId}
              disabled={this.state.disabled}
              multiple={true}
              uploadLinks={
                typeof this.props.clientData.mediaGallery !== "undefined"
                  ? this.props.clientData.mediaGallery
                  : []
              }
            />
          </div>
        </div>
      );
    }
  };

  renderForm = () => {
    let formTool = new formTools(this.props.token, this.props.clientId);
    return (
      <div className="cb_form">
        {this.props.clientData && (
          <div>
            <label>API Key</label>
            <p>{this.props.clientData.apiKey}</p>
            <label onClick={() => this.setState({ showSecret: true })}>
              API Secret - Click to Show
            </label>
            {this.state.showSecret && <p>{this.props.clientData.apiSecret}</p>}
          </div>
        )}
        <form
          className="cb_form__form"
          id="clientForm"
          name="clientForm"
          onSubmit={this.handleSubmit}
        >
          <div className="cb_form__row">
            <div className="cb_form__column">
              {/* {typeof this.props.clientData._id === "string" &&
                formTool.hiddenField("_id", false, this.props.clientData)} */}
              {this.props.user.adminUser !== true &&
                formTool.hiddenField("adminUser", false, {
                  adminUser: this.props.user._id
                })}
              {formTool.inputField(
                "name",
                "Business Name",
                true,
                false,
                this.props.clientData
              )}
              {formTool.selectField(
                "type",
                "Business Type",
                formTool.entityTypesOptions,
                true,
                false,
                this.props.clientData
              )}
              {formTool.inputField(
                "contactName",
                "Primary Contact",
                true,
                false,
                this.props.clientData
              )}
              {formTool.inputField(
                "contactPhone",
                "Primary Contact Phone #",
                true,
                false,
                this.props.clientData
              )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "secondaryContact",
                  "Secondary Contact",
                  false,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "secondaryContactPhone",
                  "Secondary Contact Phone #",
                  false,
                  false,
                  this.props.clientData
                )}
              {formTool.inputField(
                "licenseNumber",
                "Regulatory License Number",
                true,
                false,
                this.props.clientData
              )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "buisnessLicenseNumber",
                  "State or federal buisness ID (UBI, EIN, etc..)",
                  true,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "s2sId",
                  "Seed To Sale Specific ID",
                  false,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "address1",
                  "Regulatory Address Line 1",
                  true,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "address2",
                  "Regulatory Address Line 2",
                  false,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "city",
                  "City",
                  true,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "state",
                  "State",
                  true,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "zip",
                  "ZIP",
                  true,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "phone1",
                  "Publicly Listed Phone Number",
                  true,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "phone2",
                  "Secondary Phone Number",
                  false,
                  false,
                  this.props.clientData
                )}
              {this.props.accountCreationMode !== true &&
                formTool.inputField(
                  "fax",
                  "Fax",
                  false,
                  false,
                  this.props.clientData
                )}
              {this.renderImageUpload()}
            </div>
          </div>
          <input type="submit" value="Continue" />
          <input
            style={{ width: "100%", marginTop: "1rem" }}
            type="button"
            value="Cancel"
            onClick={this.cancelForm}
          />{" "}
        </form>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.sendRedirect()}
        {this.renderForm()}
      </div>
    );
  }
}

export default withRouter(EntityForm);
