import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { ProductInterface, BrandInterface, UserInterface } from "cbd-api-types";
import PublicService from "../apiService/PublicService";

export interface Props extends RouteComponentProps {
  brandId: string;
  user: UserInterface | undefined;
  token: string;
}

export interface State {
  brand: BrandInterface;
  brandProducts: Array<ProductInterface>;
}

class BrandPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      brand: undefined,
      brandProducts: undefined
    };
  }

  componentDidMount() {
    this.loadBrand();
    this.loadBrandProducts();
  }
  private loadBrand(): void {
    (PublicService as any).getBrand(this.props.brandId).then(
      (json: any) => {
        this.setState({ brand: json[0] });
      },
      (error: PromiseRejectionEvent) => {
        console.warn("Error found", error);
      }
    );
  }

  private loadBrandProducts(): void {
    (PublicService as any).getBrandProducts(this.props.brandId).then(
      (json: any) => {
        this.setState({ brandProducts: json });
      },
      (error: PromiseRejectionEvent) => {
        console.warn("Error found", error);
      }
    );
  }

  render() {
    if (this.state.brand) {
      const {
        createdAt,
        featureImage,
        mediaGallery,
        name,
        backgroundImage,
        shortDescription,
        longDescription
        // createdAt,
        // producer
      } = this.state.brand;
      const dateOptions = {
        month: "short",
        year: "numeric"
      };
      const joinedDate = createdAt ? new Date(createdAt) : new Date();
      return (
        <div className="cb_template cb_template--profile">
          <div className="cb_template__section">
            <div className="cb_surface--full-bleed cb_surface--flush">
              <div className="cb_surface__section">
                <div className="cb_profile-masthead">
                  <img
                    className="cb_profile-masthead__image"
                    src={
                      backgroundImage
                        ? backgroundImage
                        : "https://images.unsplash.com/photo-1503401639559-b16332601594?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1568&q=80"
                    }
                  />
                </div>
              </div>
            </div>

            <div className="cb_surface">
              <div className="cb_surface__section">
                <div className="cb_template__content">
                  <div className="cb_template__content-header">
                    <div className="cb_content-header__message">
                      <p>
                        {/* Content header message. May be used for status messages */}
                      </p>
                    </div>
                  </div>

                  <div className="cb_template__main-content">
                    <div className="cb_surface__subsection">
                      <div className="cb_description-group">
                        <h3 className="cb_heading">About {name}</h3>
                        <p>{longDescription}</p>
                      </div>
                    </div>

                    <div className="cb_surface__subsection">
                      <div className="cb_flex-grid cb_flex-grid--product">
                        <h3 className="cb_heading">Other products by {name}</h3>
                        {this.state.brandProducts &&
                          this.state.brandProducts.map(product => {
                            return (
                              <div className="cb_flex-grid__item">
                                <a href={product.cleanUrl}>{product.name}</a>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="cb_surface__subsection">
                      <div className="cb_flex-grid cb_flex-grid--product">
                        {mediaGallery &&
                          mediaGallery.map(mediaItem => {
                            return (
                              <div className="cb_flex-grid__item">
                                <img src={mediaItem} />
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    {/* <div className="cb_surface__subsection">
                      <div className="cb_flex-grid cb_flex-grid--media">
                        <h3 className="cb_heading">About Burnwell Co.</h3>
                        <div className="cb_flex-grid__item">
                          <img src="https://via.placeholder.com/500x300" />
                        </div>
                        <div className="cb_flex-grid__item">
                          <img src="https://via.placeholder.com/300x300" />
                        </div>
                        <div className="cb_flex-grid__item">
                          <img src="https://via.placeholder.com/400x300" />
                        </div>
                        <div className="cb_flex-grid__item">
                          <img src="https://via.placeholder.com/500x300" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="cb_template__navigation">
                  <div className="cb_persona">
                    <img
                      className="cb_persona__image"
                      src={
                        featureImage
                          ? featureImage
                          : "/assets/images/masthead--cannastamp-solid.png"
                      }
                      alt=""
                    />
                    <span className="cb_persona__name">{name}</span>
                    <p className="cb_persona__bio">{shortDescription}</p>
                    <ul className="cb_persona__meta-data">
                      <li>
                        <a className="cb_persona__location-link">Seattle, WA</a>
                      </li>
                      {/* <li>
                        <a className="cb_persona__website-link">burnwell.com</a>
                      </li> */}
                      <li>
                        <p className="cb_persona__joined">
                          {"Joined "}{" "}
                          {joinedDate.toLocaleDateString("en-US", dateOptions)}
                        </p>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="cb_menu">
                    <ul className="cb_menu__list">
                      <li className="cb_menu__list-item">
                        <a className="cb_menu__list-item-link" href="#">
                          Profile Navigation Link
                        </a>
                      </li>
                      <li className="cb_menu__list-item">
                        <a className="cb_menu__list-item-link" href="#">
                          Profile Navigation Link
                        </a>
                      </li>
                      <li className="cb_menu__list-item">
                        <a className="cb_menu__list-item-link" href="#">
                          Profile Navigation Link
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
export default withRouter(BrandPage);
