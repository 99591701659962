import React, { Component } from "react";
import PropTypes from "prop-types";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { AdminApiHOC } from "./AdminApiHOC";
import "react-table/react-table.css";
import { MasterPageSpinner } from "../components/master-page-spinner/MasterPageSpinner";
import { Redirect } from "react-router-dom";
import EntityForm from "../client/clientComponents/entity/EntityForm";
import AdminServices from "../apiService/AdminService";

class CreateEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientData: false,
      redirect: false
    };
  }

  setCompanyType = response => {
    const clientData = Object.assign({}, this.state.clientData, {
      type: response.value
    });
    this.setState({ clientData });
  };

  onSave = response => {
    AdminServices.entityPost(this.props.token, response).then(
      () => {
        this.setState({
          // testResult: updatedtestResult,
          redirect: true,
          message: "client saved"
        });
      },
      rejected => {
        this.setState({
          message:
            "there was an error saving the client" + JSON.stringify(rejected)
        });
      }
    );
  };

  render() {
    if (this.props.isAuthenticated) {
      return (
        <div className="cb_surface">
          {!!this.state.redirect && <Redirect to={"/admin/clients"} />}
          <EntityForm
            token={this.props.token}
            clientData={this.state.clientData}
            accountCreationMode={false}
            user={this.props.user}
            onSave={this.onSave}
          />
        </div>
      );
    }
    return <MasterPageSpinner />;
  }
}

CreateEntity.propTypes = {
  clientId: PropTypes.string
};

export default AuthenticationHOC()(AdminApiHOC()(CreateEntity));
