import React, { Component } from "react";
import SearchController from "../components/SearchController";
import CannastampIcon from "../components/product/CannastampIcon";
import { withRouter } from "react-router";

import PublicService from "../apiService/PublicService";
import Slider from "react-slick";
var async = require("async");
class Terpestival extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      similarProducts: null,
      activeComparison: null,
      similarArrays: [],
      productFocusPoint: null,
      activeSlide: 0,
      searchString: null,
      primaryResultcount: 8,
      productsReturned: false,
      productsCopy: false,
      productsFiltered: false,
      fitler: false
    };

    PublicService.tagSearch().then(json => {
      let sorted = json.sort((a, b) => {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this.setState(
        { productsReturned: sorted, productsCopy: sorted },
        () => {}
      );
    });
  }

  filterResults = filterString => {
    let theFiltered = this.state.productsReturned.filter(product => {
      return product.tags[0] === filterString;
    });
    this.setState({ productsFiltered: theFiltered, filter: filterString });
  };
  render() {
    var searchResultsView;
    var similarResultsView;
    var settings = {
      dots: false,
      afterChange: current => this.setState({ activeSlide: current }),
      infinite: true,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      responsive: [
        {
          breakpoint: 465,
          settings: {
            slidesToShow: 3,
            centerPadding: "40px"
          }
        }
      ]
    };
    if (this.state.productsReturned !== false) {
      return (
        <div>
          <div className="cb_section cb_section--search cb_section--search-results">
            <div className="cb_section__inner">
              <div id="application">
                <h1 style={{ fontSize: 30 }}>
                  Terpestival Competition Entries
                </h1>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      maxWidth: 50,
                      display: "inline-block",
                      margin: 10,
                      cursor: "pointer"
                    }}
                    onClick={() => this.filterResults("Fuel")}
                  >
                    <div
                      style={{
                        maxWidth: 50,
                        maxHeight: 50,
                        display: "inline-block"
                      }}
                    >
                      <img
                        style={{
                          maxWidth: 50,
                          maxHeight: 50
                        }}
                        src="/assets/images/flame.png"
                      />
                    </div>
                    {this.state.filter === "Fuel" ? <b>Fuel</b> : "Fuel"}
                  </div>
                  <div
                    style={{
                      maxWidth: 50,
                      display: "inline-block",
                      margin: 10,
                      cursor: "pointer"
                    }}
                    onClick={() => this.filterResults("Fruit")}
                  >
                    <div
                      style={{
                        maxWidth: 50,
                        maxHeight: 50,
                        display: "inline-block"
                      }}
                    >
                      <img
                        style={{
                          maxWidth: 50,
                          maxHeight: 50
                        }}
                        src="/assets/images/fruit.png"
                      />
                    </div>
                    {this.state.filter === "Fruit" ? <b>Fruit</b> : "Fruit"}
                  </div>
                  <div
                    style={{
                      maxWidth: 50,
                      display: "inline-block",
                      margin: 10,
                      cursor: "pointer"
                    }}
                    onClick={() => this.filterResults("Earth")}
                  >
                    <div
                      style={{
                        maxWidth: 50,
                        maxHeight: 50,
                        display: "inline-block"
                      }}
                    >
                      <img
                        style={{
                          maxWidth: 50,
                          maxHeight: 50
                        }}
                        src="/assets/images/earth.png"
                      />
                    </div>
                    {this.state.filter === "Earth" ? <b>Earth</b> : "Earth"}
                  </div>
                  <div
                    style={{
                      maxWidth: 50,
                      display: "inline-block",
                      margin: 10,
                      cursor: "pointer"
                    }}
                    onClick={() => this.filterResults("Floral")}
                  >
                    <img
                      style={{
                        maxWidth: 50,
                        maxHeight: 50
                      }}
                      src="/assets/images/floral.png"
                    />
                    {this.state.filter === "Floral" ? <b>Floral</b> : "Floral"}
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <a
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      maxHeight: 20,
                      minHeight: 20,
                      paddingBottom: 20
                    }}
                    onClick={() =>
                      this.setState({ productsFiltered: false, filter: false })
                    }
                  >
                    {this.state.productsFiltered !== false && "Clear Filter"}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="masthead--results">
            <div className="container-fluid">
              <div className="row">
                <div className="results-carousel">
                  <Slider {...settings}>
                    {this.state.productsFiltered !== false &&
                      this.state.productsFiltered.map((product, i) => {
                        return (
                          <div key={i}>
                            <div className="cb_cannastamp-container__outer">
                              <a href={product.cleanUrl}>
                                <CannastampIcon
                                  key={i}
                                  testResult={product.latestLot.testResult}
                                  size={"100"}
                                />
                                <span className="cb_thecannastamp__product-name">
                                  {product.name}{" "}
                                </span>
                                <span className="cb_thecannastamp__grower-name">
                                  by <a>{product.brand.name}</a>
                                </span>
                                {product.available === false ? (
                                  <a>No Longer In Stores</a>
                                ) : null}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    {this.state.productsFiltered === false &&
                      this.state.productsReturned.map((product, i) => {
                        return (
                          <div key={i}>
                            <div className="cb_cannastamp-container__outer">
                              <a href={product.cleanUrl}>
                                <CannastampIcon
                                  key={i}
                                  testResult={product.latestLot.testResult}
                                  size={"100"}
                                />
                                <span className="cb_thecannastamp__product-name">
                                  {product.name}{" "}
                                </span>
                                <span className="cb_thecannastamp__grower-name">
                                  by <a>{product.brand.name}</a>
                                </span>
                                {product.available === false ? (
                                  <a>No Longer In Stores</a>
                                ) : null}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Loading</div>;
    }
  }
}
export default withRouter(Terpestival);
