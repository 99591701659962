import React, { Component } from "react";
import BrandList from "./clientComponents/brand/BrandList";
// import { clientApiHOCProps } from "./clientComponents/generic/ClientApiHOC";
import PropTypes from "prop-types";

class ManageProducts extends Component {
  constructor(props) {
    super(props);
    this.state = { clientBrands: null };
    props.getClientBrands(props.match.params.clientId, false, results => {
      this.setState({ clientBrands: results });
    });
  }

  render() {
    const { clientData, match } = this.props;
    const { clientBrands } = this.state;

    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__current">
                {`Manage Brands for `}
                <span className={"cb_breadcrumb__producerName"}>
                  {clientData.name}
                </span>
              </li>
            </ul>
            <div className="cb_account__content-header-message">
              <p>You can see all of your brands in the table below.</p>
            </div>
          </div>
          <div className="cb_account__content-content">
            <div className="cb_data-table">
              <div className="cb_data-table__toolbar">
                <div className="cb_data-table__toolbar-left">
                  <a
                    className="cb_data-table__toolbar-button"
                    href={"/client/" + match.params.clientId + "/manageBrand"}
                  >
                    <i className="fal fa-plus-circle" /> Add a Brand
                  </a>
                </div>
              </div>
              <div className="cb_data-table__table">
                <BrandList brandList={clientBrands} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ManageProducts.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      clientId: PropTypes.string
    })
  })
};

export default ManageProducts;
