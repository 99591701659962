import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-scroll";

export default class ActivityBased extends Component {
  static propTypes = {
    setManualSearchString: PropTypes.func
  };
  render() {
    return (
      <div>
        <div className="cb_section">
          <div
            className="cb_testimonial cb_testimonial--content-left cb_testimonial--cbd"
            style={{ backgroundImage: "url(/assets/images/creative.jpg)" }}
          >
            <div className="cb_testimonial__content-container">
              <div className="cb_testimonial__wedge">
                <div className="cb_testimonial__content">
                  <div className="cb_testimonial__cannastamp">
                    <img
                      className="cannastamp"
                      src="/assets/images/stamp--boggle-dragon.png"
                    />
                    <span className="strain-name">Boggle Dragon</span>
                    <span className="product-type">Flower</span>
                  </div>
                  <h3 className="cb_testimonial__title">Thinking</h3>
                  <p className="cb_testimonial__quote">
                    I love Boggle Dragon for getting creative. It's what I
                    always thought an indica should be... Because of the
                    Cannastamp, I know what to look for when I try new things
                    out. Just look for a lot of purple and pink.
                  </p>
                  <p className="cb_testimonial__byline">- Sasha</p>
                  <div className="cb_testimonial__utility">
                    <Link
                      to="searchController"
                      offset={-200}
                      duration={900}
                      delay={200}
                      smooth={true}
                    >
                      <a
                        className="cb_button"
                        onClick={() =>
                          this.props.setManualSearchString("Boggle Dragon")
                        }
                      >
                        Find your Experience
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cb_testimonial cb_testimonial--content-right cb_testimonial--cbn"
            style={{ backgroundImage: "url(assets/images/relieve.jpg)" }}
          >
            <div className="cb_testimonial__content-container">
              <div className="cb_testimonial__wedge">
                <div className="cb_testimonial__content">
                  <div className="cb_testimonial__cannastamp">
                    <img
                      className="cannastamp"
                      src="assets/images/stamp--grape-ape.png"
                    />
                    <span className="strain-name">Grape Ape</span>
                    <span className="product-type">Flower</span>
                  </div>
                  <h3 className="cb_testimonial__title">Exercise</h3>
                  <p className="cb_testimonial__quote">
                    I’m an athlete, and finding a cannabis product that reduces
                    muscle pain without making me foggy is important.
                    Cannabinder has really helped me figure out what works for
                    me and what doesn’t.
                  </p>
                  <p className="cb_testimonial__byline">- Ryan</p>
                  <div className="cb_testimonial__utility">
                    <Link
                      to="searchController"
                      offset={-200}
                      duration={900}
                      delay={200}
                      smooth={true}
                    >
                      <a
                        className="cb_button"
                        onClick={() =>
                          this.props.setManualSearchString("Grape Ape")
                        }
                      >
                        Find your Experience
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cb_testimonial cb_testimonial--content-left cb_testimonial--caryophyllene"
            style={{ backgroundImage: "url(/assets/images/social.jpg)" }}
          >
            <div className="cb_testimonial__content-container">
              <div className="cb_testimonial__wedge">
                <div className="cb_testimonial__content">
                  <div className="cb_testimonial__cannastamp">
                    <img
                      className="cannastamp"
                      src="assets/images/stamp--blue-dream.png"
                    />
                    <span className="strain-name">Blue Dream</span>
                    <span className="product-type">Extract</span>
                  </div>
                  <h3 className="cb_testimonial__title">Socializing</h3>
                  <p className="cb_testimonial__quote">
                    I used to be pretty anxious in social settings, and some
                    cannabis would make it even worse. I like Cannabinder
                    because I can find what works for me, then find similar
                    products again.
                  </p>
                  <p className="cb_testimonial__byline">- Jessica</p>
                  <div className="cb_testimonial__utility">
                    <Link
                      to="searchController"
                      offset={-200}
                      duration={900}
                      delay={200}
                      smooth={true}
                    >
                      <a
                        className="cb_button"
                        onClick={() =>
                          this.props.setManualSearchString("Blue Dream")
                        }
                      >
                        Find your Experience
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
