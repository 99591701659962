import React, { Component } from "react";

class ContactPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="cb_page--producers">
        <div className="cb_masthead cb_masthead--producer">
          <div className="cb_section__inner cb_masthead__content">
            <div className="cb_grid">
              <div className="cb_producer-masthead-messaging col-sm-5">
                <h1 className="cb_heading--1 cb_heading--blue">
                  Contacting Us
                </h1>
                <h2 className="cb_heading--2 cb_heading--white">You may contact us via email at:</h2>
                <br />
                <h4 className="cb_heading--5 cb_heading--white">
                  support@cannabinder.com
                </h4>
                <br />
              </div>
              <div className="cb_producer-form col-sm-6 col-sm-push-1">
                <div className="cb_section cb_section--producer-features cb_grid">
                  <div className="cb_producer-final-cta-content">
                    <img
                      className="cb_image cb_image--producer-features-image--right"
                      src="/assets/images/comparison-1.png"
                      style={{
                        width: 200,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;