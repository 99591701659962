import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit * 2,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  infoBlocks: {
    paddingTop: 10,
    display: "inline-block",
    width: "85%",
    verticalAlign: "middle"
  },
  ctaDiv: {
    width: "100%"
  },
  ctaActionDiv: {
    display: "inline-block",
    height: "100%",
    width: 75,
    paddingLeft: 10,
    textAlign: "center"
  },
  floatPhoto: {
    width: 75,
    float: "left",
    marginRight: 10
  },
  imageLinkDownloadText: {
    width: 75,
    display: "inline-block",
    fontSize: 12
  },
  downloadWrapper: {
    display: "inline-block",
    width: 75,
    marginRight: 10,
    float: "left",
    textAlign: "center"
  }
});

class UpgradeCTA extends Component {
  constructor(props) {
    super(props);
  }

  imageComponent() {
    const { classes } = this.props;
    if (this.props.ctaImageUrl && this.props.ctaImageLink) {
      return (
        <a href={this.props.ctaImageLink} target={this.props.ctaTarget}>
          <div className={classes.downloadWrapper}>
            <img className={classes.floatPhoto} src={this.props.ctaImageUrl} />
            <div className={classes.imageLinkDownloadText}>Sample Download</div>
          </div>
        </a>
      );
    } else if (this.props.ctaImageUrl) {
      return (
        <img className={classes.floatPhoto} src={this.props.ctaImageUrl} />
      );

      return <img className={classes.floatPhoto} />;
    } else return <div />;
  }

  render() {
    const {
      classes,
      headline,
      body,
      conversion,
      linkUrl,
      linkText
    } = this.props;
    return (
      <div>
        <Paper className={classes.root} elevation={1}>
          <div className={classes.ctaDiv}>
            <Typography variant="h5">{headline}</Typography>
            <Typography variant="p" className={classes.infoBlocks}>
              {this.imageComponent()}
              {body}
            </Typography>
            <div className={classes.ctaActionDiv}>
              <a href={linkUrl}>{linkText}</a>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

UpgradeCTA.propTypes = {
  classes: PropTypes.object.isRequired,
  setMultiple: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  actionUrl: PropTypes.string.isRequired,
  headline: PropTypes.string,
  body: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  ctaImageUrl: PropTypes.string,
  ctaImageLink: PropTypes.string,
  ctaTarget: PropTypes.string
};

export default withStyles(styles)(UpgradeCTA);
