import * as React from "react";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export interface Props {
  url: string;
}
const TestFormWrapper = styled("div")({
  textAlign: "center",
  width: "100%"
});

const ButtonPadding = styled("div")({
  margin: 5
});

export class CompleteWorkflow extends React.Component<Props> {
  public render(): React.ReactChild {
    const { url } = this.props;
    return (
      <TestFormWrapper>
        <Typography component="h3">Your All Setup!</Typography>
        <Typography component="p">
          Lets take a look at your new business account.
        </Typography>
        <ButtonPadding>
          <Button variant={"contained"} color={"primary"} href={url}>
            Go!
          </Button>
        </ButtonPadding>
      </TestFormWrapper>
    );
  }
}
