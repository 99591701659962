import * as React from "react";
import AdminService from "../apiService/AdminService";
import PropTypes from "prop-types";

export const AdminApiHOC = () => WrappedComponent => {
  // Do something with the options here or some side effects
  AdminApiHOC.propTypes = {
    getClients: PropTypes.func,
    getProducts: PropTypes.func,
    getUsers: PropTypes.func,
    getLots: PropTypes.func,
    getTestResults: PropTypes.func,
    setClients: PropTypes.func,
    setProducts: PropTypes.func,
    setUsers: PropTypes.func,
    setLots: PropTypes.func,
    setTestResults: PropTypes.func
  };
  return class AdminApiHOC extends React.Component {
    // Define how your HOC is shown in ReactDevTools

    constructor(props) {
      super(props);
      this.state = {
        clients: undefined,
        products: undefined,
        users: undefined,
        lots: undefined,
        testResults: undefined,
        loading: true,
        paginate: {
          pages: -1,
          page: 0
        }
      };
      this.getClients = this.getClients.bind(this);
      this.getProducts = this.getProducts.bind(this);
      this.getUsers = this.getUsers.bind(this);
      this.getLots = this.getLots.bind(this);
      this.getTestResults = this.getTestResults.bind(this);
      this.setClients = this.setClients.bind(this);
      this.setProducts = this.setProducts.bind(this);
      this.setUsers = this.setUsers.bind(this);
      this.setLots = this.setLots.bind(this);
      this.setTestResults = this.setTestResults.bind(this);
    }

    // Implement other methods here
    getClients(overload = false, paginate = undefined, callback) {
      if (this.state.clients && overload === false) {
        callback(this.state.clients);
      } else {
        this.setState({ loading: true });
        this.props.checkAdminToken().then(() => {
          var body = {
            token: this.props.token,
            user: this.props.user,
            paginate: paginate
          };

          AdminService.getClients(body).then(results => {
            this.setState({
              paginate: {
                pages: results.pages
              }
            });
            callback(paginate ? results.clients : results.reverse());
          });
        });
      }
    }

    getProducts(overload = false, paginate = undefined, callback) {
      if (this.state.products && overload === false) {
        callback(this.state.products);
      } else {
        this.setState({ loading: true });
        this.props.checkAdminToken().then(() => {
          var body = {
            token: this.props.token,
            user: this.props.user,
            paginate: paginate
          };
          AdminService.getProducts(body).then(results => {
            this.setState({
              paginate: {
                pages: results.pages
              }
            });
            callback(paginate ? results.products : results.reverse());
          });
        });
      }
    }

    getUsers(overload = false, paginate = undefined, callback) {
      if (this.state.users && overload === false) {
        callback(this.state.users);
      } else {
        this.setState({ loading: true });
        this.props.checkAdminToken().then(() => {
          var body = {
            token: this.props.token,
            user: this.props.user,
            paginate: paginate
          };

          AdminService.getUsers(body).then(results => {
            this.setState({
              paginate: {
                pages: results.pages
              }
            });
            callback(paginate ? results.users : results.reverse());
          });
        });
      }
    }

    getLots(overload = false, paginate = undefined, callback) {
      if (this.state.lots && overload === false) {
        callback(this.state.lots);
      } else {
        this.setState({ loading: true });
        this.props.checkAdminToken().then(() => {
          var body = {
            token: this.props.token,
            user: this.props.user,
            paginate: paginate
          };

          AdminService.getLots(body).then(results => {
            this.setState({
              paginate: {
                pages: results.pages
              }
            });
            callback(paginate ? results.lots : results.reverse());
          });
        });
      }
    }

    getTestResults(overload = false, paginate = undefined, callback) {
      if (this.state.lots && overload === false) {
        callback(this.state.lots);
      } else {
        this.setState({ loading: true });
        this.props.checkAdminToken().then(() => {
          var body = {
            token: this.props.token,
            user: this.props.user,
            paginate: paginate
          };

          AdminService.getTestResults(body).then(results => {
            this.setState({
              paginate: {
                pages: results.pages
              }
            });
            callback(paginate ? results.testResults : results.reverse());
          });
        });
      }
    }

    setClients(clients) {
      this.setState({
        clients: clients,
        loading: false
      });
    }

    setProducts(products) {
      this.setState({
        products: products,
        loading: false
      });
    }

    setUsers(users) {
      this.setState({
        users: users,
        loading: false
      });
    }

    setLots(lots) {
      this.setState({
        lots: lots,
        loading: false
      });
    }

    setTestResults(testResults) {
      this.setState({
        testResults: testResults,
        loading: false
      });
    }

    render() {
      // Render all your added markup
      return (
        <div>
          {/* render the wrapped component like this, passing the props and state */}
          <WrappedComponent
            getClients={this.getClients}
            getProducts={this.getProducts}
            getUsers={this.getUsers}
            getLots={this.getLots}
            getTestResults={this.getTestResults}
            setClients={this.setClients}
            setProducts={this.setProducts}
            setUsers={this.setUsers}
            setLots={this.setLots}
            setTestResults={this.setTestResults}
            {...this.state}
            {...this.props}
          />
        </div>
      );
    }
  };
};
