import React from "react";
// import stampGenerator from "../../svgTools/stampGenerator";
// import ReactHtmlParser from "react-html-parser";

// var canvasWidth;
// var maxRadius;
// var zeroPercent;
// var baselinePercent;
//
// var innerRadius;
// var center;
// var baselineWidth;
// var baselineRadius;
// var baselineCircleCircum;
// var gapPercent;
// var terpeneIdMin;
// var terpeneOdMax;
// var terpeneSections;

//{this.props.toString()}
const CannastampIcon = props => {
  return (
    <div className="cb_cannastamp-container">
      <a className="cb_cannastamp-product-link">
        <div
          className="cb_cannastamp-render"
          dangerouslySetInnerHTML={{
            __html: props.testResult.cannastamp
          }}
        />
      </a>
    </div>
  );
};

export default CannastampIcon;
