import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HowYouGetYourCOAs from "./PremiumUploadStepCards/HowYouGetYourCOAs";
import UploadResults from "./PremiumUploadStepCards/UploadResults";
import UploadYourCertificates from "./PremiumUploadStepCards/UploadYourCertificates";

const styles = theme => ({
  root: {
    width: "100%"
  },
  buttons: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

function getSteps() {
  return ["About Your COAs", "Upload Your COAs", "Done!"];
}

class PremiumUploadSteps extends React.Component {
  state = {
    activeStep: 0,
    multipleMode: false,
    uploadHistory: []
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  setUploadMode = multipleMode => {
    this.setState({ multipleMode });
    this.handleNext();
  };

  handleUpload = uploadArray => {
    let uploadHistory = this.state.uploadHistory;
    uploadHistory.push(uploadArray);
    this.setState({ uploadHistory });
  };

  getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <HowYouGetYourCOAs
            setMultiple={this.setUploadMode}
            disabled={this.props.disabled}
          />
        );
      case 1:
        return (
          <UploadYourCertificates
            multipleMode={this.state.multipleMode}
            handleUpload={this.handleUpload}
            uploadHistory={this.state.uploadHistory}
            clientId={this.props.clientId}
            token={this.props.token}
          />
        );
      case 2:
        return <UploadResults uploadHistory={this.state.uploadHistory} />;
      default:
        return "Unknown step";
    }
  }
  navigationButtons = step => {
    const { classes } = this.props;
    switch (step) {
      case 0:
        return <div />;
      case 1:
        return (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleBack}
              className={classes.buttons}
            >
              {"Back"}
            </Button>
            {this.state.uploadHistory.length > 0 ? (
              <Button
                className={classes.buttons}
                variant="contained"
                color="primary"
                onClick={this.handleNext}
              >
                {"Finish"}
              </Button>
            ) : (
              <div />
            )}
          </div>
        );
      case 2:
        return <div />;
      default:
        return "Unknown step";
    }
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <div>
            <Typography className={classes.instructions}>
              {this.getStepContent(activeStep)}
            </Typography>
            {this.navigationButtons(activeStep)}
          </div>
        </div>
      </div>
    );
  }
}

PremiumUploadSteps.propTypes = {
  classes: PropTypes.object,
  clientId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default withStyles(styles)(PremiumUploadSteps);
