import * as React from "react";
import ClientService from "../../../apiService/ClientService";
import PropTypes from "prop-types";
import { authenticationHOCPropTypes } from "../../../components/AuthenticationHOC";

export const clientApiHOCProps = Object.assign(
  {
    getClientProducts: PropTypes.func,
    getProductLots: PropTypes.func,
    getClientTestResults: PropTypes.func,
    getParentLots: PropTypes.func,
    getClientLots: PropTypes.func
  },
  authenticationHOCPropTypes
);

export const ClientApiHOC = () => WrappedComponent => {
  // Do something with the options here or some side effects
  ClientApiHOC.propTypes = clientApiHOCProps;
  return class ClientApiHOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        clientProducts: undefined,
        clientProductLots: undefined,
        clientLots: undefined,
        secured: false,
        clientData: false
      };

      if (typeof props.checkUserOnEntity === undefined) {
        throw new Error(
          "ClientApiHOC must be wrapped in the AuthenticationHOC"
        );
      } else {
        props.checkUserOnEntity(props.match.params.clientId).then(response => {
          this.setState({ secured: true });
        });
        this.getClientAccountData(this.props.match.params.clientId, results => {
          this.setState({ clientData: results });
        });
      }
    }
    /**
     * fetches all of the products for a given client
     * @param {string} clientId id of client being accessed
     * @param {boolean} overload forces the function to fetch new data, default false
     * @param {func} callback returns the data to the calling function
     */
    getClientBrands = (clientId, overload = false, callback) => {
      if (this.state.clientProducts && overload === false) {
        callback(this.state.clientProducts);
      } else {
        ClientService.getClientBrands(this.props.token, clientId).then(
          results => {
            results = results.sort(function compare(a, b) {
              var dateA = new Date(a.updatedAt);
              var dateB = new Date(b.updatedAt);
              return dateB - dateA;
            });
            this.setState({ clients: results });
            callback(results);
          }
        );
      }
    };

    /**
     * fetches all of the products for a given client
     * @param {string} clientId id of client being accessed
     * @param {boolean} overload forces the function to fetch new data, default false
     * @param {func} callback returns the data to the calling function
     */
    getClientProducts = (clientId, overload = false, callback) => {
      if (this.state.clientProducts && overload === false) {
        callback(this.state.clientProducts);
      } else {
        ClientService.getClientProducts(this.props.token, clientId).then(
          results => {
            results = results.sort(function compare(a, b) {
              var dateA = new Date(a.updatedAt);
              var dateB = new Date(b.updatedAt);
              return dateB - dateA;
            });
            this.setState({ clients: results });
            callback(results);
          }
        );
      }
    };

    getClientParentLots = (clientId, overload = false, callback) => {
      if (!this.state.clientLots || overload) {
        this.getClientLots(clientId, overload, results => {
          callback(
            results.filter(x => {
              return x.lotType === "parentLot";
            })
          );
        });
      } else {
        callback(
          this.state.clientLots.filter(x => {
            return x.lotType === "parentLot";
          })
        );
      }
    };

    getProductDistribution = (
      clientId,
      productId,
      overload = false,
      callback
    ) => {
      if (!this.state.clientLots || overload) {
        this.getClientLots(clientId, overload, results => {
          callback(
            results.filter(x => {
              return (
                x.lotType === "distribution" && x.product._id === productId
              );
            })
          );
        });
      } else {
        callback(
          this.state.clientLots.filter(x => {
            return x.lotType === "distribution" && x.product._id === productId;
          })
        );
      }
    };

    getProductShipmentsActive = (
      clientId,
      productId,
      overload = false,
      callback
    ) => {
      if (!this.state.clientLots || overload) {
        this.getClientLots(clientId, overload, results => {
          callback(
            results.filter(x => {
              return (
                x.lotType === "shipment" &&
                x.product._id === productId &&
                x.available === true
              );
            })
          );
        });
      } else {
        callback(
          this.state.clientLots.filter(x => {
            return (
              x.lotType === "shipment" &&
              x.product._id === productId &&
              x.available === true
            );
          })
        );
      }
    };
    getProductShipmentsAll = (
      clientId,
      productId,
      overload = false,
      callback
    ) => {
      if (!this.state.clientLots || overload) {
        this.getClientLots(clientId, overload, results => {
          callback(
            results.filter(x => {
              return x.lotType === "shipment" && x.product._id === productId;
            })
          );
        });
      } else {
        callback(
          this.state.clientLots.filter(x => {
            return x.lotType === "shipment" && x.product._id === productId;
          })
        );
      }
    };

    getClientShipmentsActive = (clientId, overload = false, callback) => {
      if (!this.state.clientLots || overload) {
        this.getClientLots(clientId, overload, results => {
          callback(
            results.filter(x => {
              return x.lotType === "shipment" && x.available === true;
            })
          );
        });
      } else {
        callback(
          this.state.clientLots.filter(x => {
            return x.lotType === "shipment" && x.available === true;
          })
        );
      }
    };

    getClientShipmentsAll = (clientId, overload = false, callback) => {
      if (!this.state.clientLots || overload) {
        this.getClientLots(clientId, overload, results => {
          callback(
            results.filter(x => {
              return x.lotType === "shipment";
            })
          );
        });
      } else {
        callback(
          this.state.clientLots.filter(x => {
            return x.lotType === "shipment";
          })
        );
      }
    };

    getClientProductLots = (clientId, overload = false, callback) => {
      if (!this.state.clientLots || overload) {
        this.getClientLots(clientId, overload, results => {
          results.map(x => {});
          callback(
            results.filter(x => {
              return typeof x.product !== "undefined";
            })
          );
        });
      } else {
        this.state.clientLots.map(x => {});
        callback(
          this.state.clientLots.filter(x => {
            return typeof x.product !== "undefined";
          })
        );
      }
    };

    getClientLots = (clientId, overload = false, callback) => {
      if (this.state.clientProducts && overload === false) {
        callback(this.state.clientProducts);
      } else {
        ClientService.getAllClientLots(this.props.token, clientId).then(
          results => {
            results = results.sort(function compare(a, b) {
              var dateA = new Date(a.updatedAt);
              var dateB = new Date(b.updatedAt);
              return dateB - dateA;
            });
            this.setState({ clients: results });
            callback(results);
          }
        );
      }
    };
    /**
     * fetches a product lot for a client, retrieving from a stored copy or from the api
     * @param {string} clientId id of client being accessed
     * @param {string} productId id of the product being accessed
     * @param {boolean} overload forces the function to fetch new data, default false
     * @param {func} callback returns the data to the calling function
     */
    getProductLots = (clientId, productId, overload = false, callback) => {
      if (this.state.clientProductLots[productId] && overload === false) {
        callback(this.state.clientProductLots[productId]);
      } else {
        ClientService.getClientProductLots(this.props.token, clientId).then(
          results => {
            results = results.sort(function compare(a, b) {
              var dateA = new Date(a.updatedAt);
              var dateB = new Date(b.updatedAt);
              return dateB - dateA;
            });
            let mutableCopyProductLots = Object.assign(
              {},
              this.state.clientProductLots
            );
            mutableCopyProductLots[productId] = results;
            this.setState({ clientProductLots: mutableCopyProductLots });
            callback(results);
          }
        );
      }
    };

    getClientTestResults = (clientId, overload = false, callback) => {
      if (this.state.clientTestResults && overload === false) {
        callback(this.state.clientTestResults);
      } else {
        ClientService.getClientTestResults(this.props.token, clientId).then(
          results => {
            results = results.sort(function compare(a, b) {
              var dateA = new Date(a.updatedAt);
              var dateB = new Date(b.updatedAt);
              return dateB - dateA;
            });
            this.setState({ clientTestResults: results });
            callback(results);
          }
        );
      }
    };

    getClientAccountData = (clientId, callback) => {
      ClientService.getEntityData(this.props.token, clientId).then(results => {
        callback(results);
      });
    };

    getClientMessages = (clientId, callback) => {
      ClientService.getEntityMessages(this.props.token, clientId).then(results => {
        callback(results);
      });
    };

    /**
     * removes a product lot from circulation
     */
    expireProductLot() {}

    deployProductLots = (clientId, productLot, callback) => {
      ClientService.saveProductLot(this.props.token, clientId, productLot)
        .then(results => {
          callback(results);
        })
        .catch(rejected => {
          callback({ error: rejected });
        });
    };

    updateProductLot = (clientId, update, lotId, callback) => {
      ClientService.saveProductLot(this.props.token, clientId, update, lotId)
        .then(results => {
          callback(results);
        })
        .catch(rejected => {
          callback({ error: rejected });
        });
    };

    deleteProductLot = (clientId, lotId, callback) => {
      ClientService.deleteLot(this.props.token, clientId, lotId)
        .then(results => {
          callback(results);
        })
        .catch(rejected => {
          callback({ error: rejected });
        });
    };

    deployPrintJob = (clientId, printJob, callback) => {
      ClientService.createPrintJob(this.props.token, clientId, printJob)
        .then(results => {
          callback(results);
        })
        .catch(rejected => {
          callback({ error: rejected });
        });
    };

    updateProduct = (clientId, product, callback) => {
      ClientService.saveProduct(
        this.props.token,
        clientId,
        product,
        product._id
      )
        .then(results => {
          callback(results);
        })
        .catch(rejected => {
          callback({ error: rejected });
        });
    };

    render() {
      // Render all your added markup
      if (this.state.secured && this.state.clientData) {
        return (
          <div>
            {/* render the wrapped component like this, passing the props and state */}
            <WrappedComponent
              getClientUsers={this.getClientUsers}
              getClientProducts={this.getClientProducts}
              getProductLots={this.getProductLots}
              getClientTestResults={this.getClientTestResults}
              getClientParentLots={this.getClientParentLots}
              getClientProductLots={this.getClientProductLots}
              deployProductLots={this.deployProductLots}
              getClientLots={this.getClientLots}
              deployPrintJob={this.deployPrintJob}
              updateProduct={this.updateProduct}
              getClientBrands={this.getClientBrands}
              getProductDistribution={this.getProductDistribution}
              getProductShipmentsActive={this.getProductShipmentsActive}
              getProductShipmentsAll={this.getProductShipmentsAll}
              getClientShipmentsActive={this.getClientShipmentsActive}
              getClientShipmentsAll={this.getClientShipmentsAll}
              updateProductLot={this.updateProductLot}
              deleteProductLot={this.deleteProductLot}
              getClientAccountData={this.getClientAccountData}
              getClientMessages={this.getClientMessages}
              clientData={this.state.clientData}
              {...this.props}
            />
          </div>
        );
      } else {
        return <div />;
      }
    }
  };
};
