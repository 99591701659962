import { oAuthMode } from "../configurator";
const remoteConfig = oAuthMode(window.location.href);
const baseAPI = remoteConfig.baseUrl + "admin";

const AdminService = {
  /**
   * Fetches all clients of the system. Producers, processors, dispensaries, labs
   * @param {source data for getClients to pass in the POST} body
   */
  getClients(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + body.token + `/entitys`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getLots(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + body.token + `/lots`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getTestResults(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + body.token + `/testResults`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  updateClientAdmin(client, userId, token) {
    let body = { client, userId, token };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/setClientAdmin/`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  transferEntityProducts(form, token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/transferEntityProducts/`, {
        method: "POST",
        body: form,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  // need to add a second promise to go out and modify all prouct_lots
  // that have the product ID to update their producer ID
  updateProductOwner(product, clientId, token) {
    let body = { producer: clientId };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/setProductOwner/` + product._id, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getUsers(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + body.token + `/getUsers`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserBinderCount(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + body.token + `/getUserBindercount`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserBinders(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + body.token + `/getUserBinders`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getProducts(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + body.token + `/getProducts`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  reloadProdComps(token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/recalc/`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  validateAdminSession(token, user) {
    var body = { Token: token, User: user };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/validateAdmin`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  validateServiceAdminSession(token, user) {
    var body = { Token: token, User: user };
    return new Promise((resolve, reject) => {
      fetch(
        remoteConfig.baseUrl + `service/` + token + `/validateServiceAdmin`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  updateUser(update, user, token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/user/` + user._id, {
        method: "PUT",
        body: JSON.stringify(update),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  updateEntity(update, clientId, token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/entity/` + clientId, {
        method: "PUT",
        body: JSON.stringify(update),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteUser(userId, token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/user/` + userId, {
        method: "DELETE",
        body: null,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserCsv(token, user) {
    var body = { Token: token, User: user };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/getUserCsv`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "text/csv",
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPendingTestEntries(token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/data-entry/pending`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "the server failed to get the list of pending data"
            );
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getPendingTestReview(token) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/data-entry/review`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "the server failed to get the list of pending data"
            );
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Creates a new entity with a POST promise
   * @param {string} token the users authentication token
   * @param {object} formData the form data coming in and being sent
   * @param {string} id the id of the entity that is being updated, if it is an update
   */
  entityPost(token, formData, id) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/entity/`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to create the client identity");
          }
          return response.json(response);
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default AdminService;
