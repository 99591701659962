import React, { Component } from "react";
import styled from "styled-components";
import NumberEntry from "../components/checkinProcess/NumberEntry";
import CreateAccount, {
  CreateAccountState
} from "../components/checkinProcess/CreateAccount";
import ConfigureAccount from "../components/checkinProcess/ConfigureAccount";
import { EntityInterface, LoyaltyMemberStandardResponse } from "cbd-api-types";
import {
  userIsInLoyaltyPhone,
  userLoyaltySignup,
  userLoyaltyCheckin,
  resetValidationCode,
  userLoyaltyConfirmCode
} from "../../apiService/LoyaltyService";
import { UserInterface } from "cbd-api-types";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Close, Refresh } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import ThankYou from "../components/checkinProcess/ThankYou";

export interface KioskCheckinProps {
  clientId: string;
  token: string;
  setCheckinUser: (loyaltyUser: UserInterface) => void;
  clientData: EntityInterface;
  close: () => void;
}

export interface KioskCheckinState {
  currentStep:
    | "numberEntry"
    | "createAccount"
    | "configureAccount"
    | "thankYou";
  phoneNumber: number;
  phoneNumberRaw: string;
  loyaltyUser: UserInterface;
  error: string;
}

export default class KioskCheckin extends Component<
  KioskCheckinProps,
  KioskCheckinState
> {
  private constructor(props: KioskCheckinProps) {
    super(props);
    this.state = {
      currentStep: "numberEntry",
      phoneNumber: null,
      phoneNumberRaw: null,
      loyaltyUser: null,
      error: null
    };
  }
  // private checkinUser = (phoneNumber: number) => UserDocumentInterface | void {

  // }

  private isUserInLoyalty = (phoneNumber: number, phoneNumberRaw: string) => {
    let { token, clientId } = this.props;
    userIsInLoyaltyPhone(token, clientId, phoneNumber).then(
      (loyalCheckRes: any) => {
        this.setState({ phoneNumber, phoneNumberRaw }, () => {
          if (loyalCheckRes.response.loyaltyStatus === "active") {
            this.checkinUser();
          } else {
            this.setState({ currentStep: "createAccount" });
          }
        });
      },
      () => {
        // todo: do something with this error state.
      }
    );
  };

  private createLoyaltyAccount = (
    zipCode: CreateAccountState["zipCode"],
    age: CreateAccountState["age"]
  ): void => {
    let { token, clientId } = this.props;
    let { phoneNumber } = this.state;
    userLoyaltySignup(
      token,
      clientId,
      phoneNumber,
      zipCode,
      age.toString()
    ).then(
      (signupResults: LoyaltyMemberStandardResponse) => {
        if (signupResults.error) {
          // todo set an error state on this
        } else {
          this.setState({
            loyaltyUser: signupResults.user,
            currentStep: "configureAccount"
          });
        }
      },
      _failed => {
        // todo set an error state
      }
    );
  };

  private checkinUser = (): void => {
    let { token, clientId, setCheckinUser } = this.props;
    let { phoneNumber } = this.state;
    userLoyaltyCheckin(token, clientId, phoneNumber).then(
      (signupResults: any) => {
        if (signupResults.error) {
        } else {
          console.log("successful checkin");
          this.setState({ currentStep: "thankYou" }, () => {
            setTimeout(() => {
              setCheckinUser(signupResults);
            }, 5000);
          });
        }
      },
      failed => {
        // todo set an error state
      }
    );
  };

  private resendActivationCode = (): void => {
    let { token, clientId } = this.props;
    let { phoneNumber } = this.state;
    resetValidationCode(token, clientId, phoneNumber).then(
      (results: LoyaltyMemberStandardResponse) => {
        if (results.error) {
          this.setState({ error: results.error.userMessage });
        } else {
          this.setState({ error: "Your new activation code has been sent" });
        }
      },
      () => {
        this.setState({
          error:
            "We're having a hard time reaching the mother ship, hang in there!"
        });
      }
    );
  };

  private configureLoyaltyAccount = (
    confirmationCode: string,
    categories: string[]
  ) => {
    let { token, clientId } = this.props;
    let { phoneNumber } = this.state;
    userLoyaltyConfirmCode(
      token,
      clientId,
      phoneNumber,
      confirmationCode,
      categories
    ).then(
      (loyalCustomerConfirm: any) => {
        if (loyalCustomerConfirm.response.error) {
          this.setState({
            error: loyalCustomerConfirm.response.error.userMessage
          });
        } else {
          this.checkinUser();
        }
      },
      () => {
        this.setState({
          error:
            "We're having a hard time reaching the mother ship, hang in there!"
        });
      }
    );
  };

  private renderSteps = (): JSX.Element => {
    {
      const { currentStep, phoneNumberRaw } = this.state;
      const { clientData } = this.props;
      const title = `Signup for ${clientData.name} Rewards Program`;
      const subTitle = `Journal Your Experience, Explore Cannabis`;
      const logoUrl = clientData.featureImage;
      switch (currentStep) {
        case "numberEntry":
          return (
            <NumberEntry
              clientData={clientData}
              inLoyalty={this.isUserInLoyalty}
              title={title}
              subTitle={subTitle}
              logoUrl={logoUrl}
            />
          );
        case "createAccount":
          return (
            <CreateAccount
              clientData={clientData}
              phoneNumberRaw={phoneNumberRaw}
              onSubmit={this.createLoyaltyAccount}
              error={this.state.error}
              title={title}
              subTitle={subTitle}
              logoUrl={logoUrl}
            />
          );
        case "configureAccount":
          return (
            <ConfigureAccount
              clientData={clientData}
              resendActivation={this.resendActivationCode}
              onSubmit={this.configureLoyaltyAccount}
              error={this.state.error}
              title={title}
              subTitle={subTitle}
            />
          );
        case "thankYou":
          return (
            <ThankYou
              clientData={clientData}
              title={title}
              subTitle={subTitle}
              logoUrl={logoUrl}
            />
          );
        default:
          return (
            <NumberEntry
              clientData={clientData}
              inLoyalty={this.isUserInLoyalty}
              title={title}
              subTitle={subTitle}
              logoUrl={logoUrl}
            />
          );
      }
    }
  };

  render() {
    const { close } = this.props;

    return (
      <SignupWrapper>
        {this.renderSteps()}
        <StyledBottomNavitation showLabels={true}>
          <BottomNavigationAction
            label="close"
            icon={<Close />}
            onClick={close}
          />
          <BottomNavigationAction
            label="restart"
            icon={<Refresh />}
            onClick={() => {
              this.setState({ currentStep: "numberEntry" });
            }}
          />
        </StyledBottomNavitation>
      </SignupWrapper>
    );
  }
}

const StyledBottomNavitation = withStyles({
  root: {}
})(BottomNavigation);

export const SignupWrapper = styled.div`
  padding-bottom: 5px;
  padding-top: 5px;
  margin-left: 10px;
  margin-right:10px
  width: 100%;
`;
