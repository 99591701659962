import React, { Component } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
import async from "async";
import ClientServices from "../../../apiService/ClientService";
import { Redirect, matchPath } from "react-router-dom";

class BulkUploadProduct extends Component {
  constructor(props) {
    super(props);
    this.state = { message: false, redirect: false };
  }

  bulkUploadProductCsv = event => {
    const fileObject = event.target.files[0];
    Papa.parse(fileObject, { header: true, complete: this.processCsv });
  };

  processCsv = csvResults => {
    let data = csvResults.data;
    let total = data.length;
    let completed = 0;
    let responseResults = [];
    data = data.map(product => {
      product.tags = [product.tags];
      product.producer = this.props.match.params.clientId;
      return product;
    });
    async.mapLimit(
      data,
      3,
      (row, next) => {
        completed++;
        let formMock = new FormData();
        let columnNames = Object.keys(row);
        columnNames.map(columnName => {
          formMock.append(columnName, row[columnName]);
        });
        ClientServices.saveProduct(
          this.props.token,
          this.props.match.params.clientId,
          formMock
        ).then(
          () => {
            responseResults.push(
              <li>
                {row.strain} - {row.tracabilityParentLotId} -{" "}
                {row.tracabilitySampleLotId} Success
              </li>
            );
          },
          rejected => {
            responseResults.push(
              <li>
                {row.strain} - {row.tracabilityParentLotId} -{" "}
                {row.tracabilitySampleLotId} Failed {JSON.stringify(rejected)}
              </li>
            );
          }
        );
        next();
      },
      finished => {
        this.setState({ redirect: true });
      }
    );
  };

  render() {
    if (this.props.user.adminUser === true) {
      return (
        <span style={{ paddingTop: 20 }}>
          {!!this.state.redirect && (
            <Redirect
              to={
                "/client/" +
                this.props.match.params.clientId +
                "/manageProducts/"
              }
            />
          )}
          <label style={{ display: "inline-block" }} htmlFor="csvSelect">
            <a className="cb_data-table__toolbar-button">
              <i className="fal fa-plus-circle" /> CSV Upload Products
            </a>
          </label>
          <input
            id="csvSelect"
            accept=".csv"
            type="file"
            style={{ position: "fixed", top: "-100em" }}
            onChange={this.bulkUploadProductCsv}
          />
          <a
            className="cb_data-table__toolbar-button"
            href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/product-upload-format.csv"
            targe="_blank"
          >
            <i className="fal fa-plus-circle" /> CSV Template File
          </a>
          {this.state.message !== false && (
            <div>
              <span>Results From CSV Upload</span>
              <span>{this.state.message}</span>
            </div>
          )}
        </span>
      );
    } else {
      return <div />;
    }
  }
}

BulkUploadProduct.propTypes = {};

export default BulkUploadProduct;
