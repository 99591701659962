import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

class BlogContent extends Component {
  render() {
    if (this.props.post) {
      return (
        <div className="article" style={{ width: "500px" }}>
          <a href={"/blog/" + this.props.post.ID} className="blackLink">
            {this.props.post.featured_image ? (
              <img
                className="img-responsive webpic"
                alt="article header"
                src={this.props.post.featured_image}
              />
            ) : (
              ""
            )}
            <h1 className="text-center">{this.props.post.title}</h1>
            <div className="content">
              {ReactHtmlParser(this.props.post.content)}
            </div>
          </a>
        </div>
      );
    } else {
      return null;
    }
  }
}
BlogContent.propTypes = {
  post: PropTypes.object
};
export default BlogContent;
