import React, { Component } from "react";
// cookie stuff
import { instanceOf } from "prop-types";
import * as Cookies from "es-cookie";
import { Z_ASCII } from "zlib";

class Over21 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedTerms: false,
      alertMessage: ""
    };
  }
  componentWillMount() {
    // load the beta alert cookie if its available
    if (Cookies.get("legal")) {
      this.setState({ legal: Cookies.get("legal") });
    } else {
      this.setState({ legal: false });
    }
  }

  handleCheckAction = event => {
    if (this.state.acceptedTerms === false) {
      this.setState({
        alertMessage:
          "You must be at least 21 and accept the terms of service and privacy policy to enter this website"
      });
    } else {
      let d = new Date();
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("legal", true, { expires: d, path: "/" });
      this.setState({ legal: true });
    }
  };
  handleBoxCheck = event => {
    this.setState({ acceptedTerms: true });
  };

  render() {
    var Over21NoticeContent = "";
    if (this.state.legal === false) {
      Over21NoticeContent = (
        <div className="cb_modal cb_modal--open cb_modal--age-gate">
          <div className="cb_modal__bg" />
          <div className="cb_modal__window">
            <div className="cb_modal__content">
              <h2 className="cb_modal__heading cb_age-gate__heading">
                Are you over 21?
              </h2>
              <div className="cb_age-gate__input-group">
                <label
                  htmlFor="confirmAge"
                  className="cb_checkbox__label cb_age-gate__checkbox-label"
                >
                  I am at least 21 years old and I accept the{" "}
                  <a href="/privacy">Privacy Policy</a> and the{" "}
                  <a href="/termsofservice">Terms Of Service</a>.
                  <input
                    type="checkbox"
                    className="cb_checkbox cb_age-gate__checkbox"
                    id="confirmAge"
                    checked={this.state.acceptedTerms}
                    onChange={this.handleBoxCheck}
                  />
                  <span className="cb_checkmark" />
                </label>
              </div>
              <button
                className="cb_age-gate__enter-button"
                onClick={this.handleCheckAction}
              >
                Enter
              </button>
              <p className="cb_age-gate__error-message">
                {this.state.alertMessage}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      Over21NoticeContent = <div />;
    }

    return Over21NoticeContent;
  }
}
export default Over21;
