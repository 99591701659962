import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import PremiumUploadSteps from "./clientComponents/testResult/PremiumUploadSteps";
import Typography from "@material-ui/core/Typography";
import UpgradeCTA from "./clientComponents/UpgradeCTA";

export default class PremiumTestUplad extends Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    clientData: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__active">
                <a className="cb_breadcrumb__link">COA Data Entry Service</a>
              </li>
              <li className="cb_breadcrumb__delimiter">
                <i className="fal fa-arrow-right" />
              </li>
            </ul>

            <div className="cb_account__content-header-message">
              <Typography component="p">
                Drag and drop your COA PDFs and Cannabinder will do the data
                entry!
              </Typography>
            </div>
            {this.props.clientData.coaDataEntryService.enabled === false && (
              <div>
                <UpgradeCTA
                  linkUrl={
                    "/client/" +
                    this.props.match.params.clientId +
                    "/premium/signup"
                  }
                  linkText="Go Pro"
                  headline="Tired of doing data entry?"
                  body=" Let the Cannabinder Team help out with Concierge Service. Drag and drop your COA's into Cannabinder and a data specialist will do the data entry for you"
                />
              </div>
            )}
          </div>
          {this.props.clientData.coaDataEntryService.enabled === true && (
            <div className="cb_account__content-content">
              <PremiumUploadSteps
                clientId={this.props.match.params.clientId}
                token={this.props.token}
                disabled={
                  this.props.clientData.coaDataEntryService.enabled === false
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
