import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { UserDocumentInterface, EntityDocumentInterface } from "cbd-api-types";
import { GetLoyaltyMembersResponse } from "../../../apiService/LoyaltyService";

export interface Paginate {
  page: number;
  pageSize: number;
  sorted: any[];
  filtered: any[];
}
export interface IMemberFilterListProps {
  token: string;
  clientId: EntityDocumentInterface["_id"];
  getData: (
    paginate: IMemberFilterListState["paginate"],
    cb: (userData: GetLoyaltyMembersResponse) => void
  ) => void;
  paginate?: Paginate;
}

export interface IMemberFilterListState {
  paginate: {
    page: number;
    pageSize: number;
    sorted: any[];
    filtered: any[];
  };
  message: string;
  userData: UserDocumentInterface[];
  loading: boolean;
  pages: number;
  idList: { _id: any }[];
}

class MemberFilterList extends Component<
  IMemberFilterListProps,
  IMemberFilterListState
> {
  constructor(props: IMemberFilterListProps) {
    super(props);
    this.state = {
      paginate: { page: null, pageSize: null, sorted: null, filtered: null },
      userData: [],
      loading: true,
      message: null,
      pages: 0,
      idList: null
    };
  }

  componentDidMount() {
    this.props.getData(this.state.paginate, userData => {
      if (userData.users !== null && userData.users.length > 0) {
        this.state = {
          userData: userData.users,
          paginate: {
            page: null,
            pageSize: null,
            sorted: null,
            filtered: null
          },
          loading: false,
          message: null,
          pages: userData.pages,
          idList: userData.idList
        };
      } else {
        this.state = {
          message: "User list is empty",
          userData: [],
          paginate: {
            page: null,
            pageSize: null,
            sorted: null,
            filtered: null
          },
          loading: false,
          pages: 0,
          idList: null
        };
      }
    });
  }

  // onFetchData for ReactTable
  private fetchData = (
    newPaging: IMemberFilterListState["paginate"] = {
      page: null,
      pageSize: null,
      sorted: null,
      filtered: null
    },
    _instance?: any
  ) => {
    this.setState(
      {
        paginate: {
          page: newPaging.page,
          pageSize: newPaging.pageSize,
          sorted: newPaging.sorted,
          filtered: newPaging.filtered
        },
        loading: true
      },
      () => {
        this.props.getData(this.state.paginate, userData => {
          if (userData) {
            this.setState({
              userData: userData.users,
              pages: userData.pages,
              idList: userData.idList,
              loading: false
            });
          } else {
            this.setState({
              message: "User list is empty",
              userData: [],
              loading: false
            });
          }
        });
      }
    );
  };

  private tableView = () => {
    let { clientId } = this.props;
    let { pages, userData, loading, message, idList } = this.state;
    return (
      <div>
        <p>{message}</p>
        <p>Selected: {idList && idList.length}</p>
        <ReactTable
          data={userData}
          filterable
          pages={pages}
          manual
          onFetchData={this.fetchData}
          loading={loading}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Contact",
              columns: [
                {
                  accessor: "phone",
                  Header: "#"
                },
                {
                  Header: "First Name",
                  accessor: "firstName"
                },
                {
                  Header: "Points",
                  id: "loyaltyPrograms.points",
                  accessor: (d: UserDocumentInterface) =>
                    d &&
                    d.loyaltyPrograms
                      .find(loyaltyProgram => {
                        return loyaltyProgram.entityId === clientId;
                      })
                      .points.toString()
                },
                {
                  Header: "Email",
                  id: "email",
                  accessor: d => d && d.email
                },
                {
                  Header: "Interests",
                  id: "loyaltyPrograms",
                  accessor: (d: UserDocumentInterface) => {
                    if (d) {
                      let program = d.loyaltyPrograms.find(loyaltyProgram => {
                        return loyaltyProgram.entityId === clientId;
                      });
                      if (program && program.categoryPreferences) {
                        return program.categoryPreferences.map(
                          (category, key) => {
                            return <p key={key}>{category}</p>;
                          }
                        );
                      } else {
                        return "none";
                      }
                    } else {
                      return "none";
                    }
                  }
                }
              ]
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  };

  render() {
    return this.tableView();
  }
}

export default MemberFilterList;
