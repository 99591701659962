import React, { Component } from "react";
import BlogContent from "../components/blog/BlogContent";
import BlogMenu from "../components/blog/BlogMenu";
import UserService from "../apiService/UserService";

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
    let activePostId = 0;
    if (props.match.params.id) {
      activePostId = props.match.params.id;
    }
    UserService.getBlogList().then(json => {
      this.setState({ posts: json, activePostId });
    });
    this.setActivePost = this.setActivePost.bind(this);
  }
  componentDidUpdate() {
    window.onpopstate = e => {
      //your code...
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  setActivePost(id) {
    this.setState({ activePostId: id });
    window.history.pushState("page2", "Title", "/cannatalk/" + id);
  }

  display(posts) {
    return (
      <div className="post" style={{ width: 600, overflow: "hidden" }}>
        <BlogContent
          post={posts.find(post => {
            return post.id === this.state.activePostId;
          })}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="posts" style={{ width: 600, overflow: "hidden" }}>
        <BlogMenu posts={this.state.posts} setActivePost={this.setActivePost} />
        <div>
          <h1 className="sectionTitle">Article</h1>
          {this.display(this.state.posts)}
        </div>
      </div>
    );
  }
}
