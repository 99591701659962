import * as React from "react";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { GettingStarted } from "./clientComponents/producerWelcome/GettingStarted";
import { AddNewBrand } from "./clientComponents/producerWelcome/AddNewBrand";
import { AddNewProduct } from "./clientComponents/producerWelcome/AddNewProduct";
import { AddNewTest } from "./clientComponents/producerWelcome/AddNewTest";
import { CompleteWorkflow } from "./clientComponents/producerWelcome/CompleteWorkflow";
import { ConciergeService } from "./clientComponents/producerWelcome/ConciergeService";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { styled } from "@material-ui/styles";
import {
  ProducerProduct,
  TestResult,
  Brand,
  AuthProps,
  TestResultPostResponse
} from "../apiService/serviceInterfaces";
import WelcomeProgress from "./clientComponents/producerWelcome/WelcomeProgress";
import ClientServices from "../apiService/ClientService";
import async from "async";
import { AppSpinner } from "../components/app-spinner/AppSpinner";

interface OwnProps {
  match: {
    params: {
      clientId: string;
    };
  };
}

type Props = OwnProps & AuthProps;
interface State {
  activeStep: number;
  brandFormRef: any;
  product: ProducerProduct;
  testResult: TestResult;
  brand: Brand;
}

// const PanelFooterButtons = styled("div")({
//   position: "fixed",
//   bottom: 0,
//   left: 0,
//   right: 0,
//   padding: "10px 20px",
//   background: "white",
//   borderTop: "1px solid #CCC",
//   zIndex: 100,
//   "& > *": {
//     marginRight: 20
//   }
// });

const StyledProducerWelcome = styled("div")({
  h1: {
    fontSize: 36
  }
});

const StyledStepContents = styled("div")({
  width: "90%",
  marginLeft: "auto",
  marginRight: "auto"
});

class ProducerWelcome extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeStep: undefined,
      brandFormRef: undefined,
      product: undefined,
      testResult: undefined,
      brand: undefined
    };
    async.parallel(
      [
        callback => {
          ClientServices.getEntityBrandCount(
            this.props.token,
            this.props.match.params.clientId
          ).then(results => {
            if (results > 0) callback(null, true);
            else callback(null, false);
          });
        },
        callback => {
          ClientServices.getEntityProductCount(
            this.props.token,
            this.props.match.params.clientId
          ).then(results => {
            if (results > 0) callback(null, true);
            else callback(null, false);
          });
        },
        callback => {
          ClientServices.getEntityTestResultCount(
            this.props.token,
            this.props.match.params.clientId
          ).then(results => {
            if (results > 0) callback(null, true);
            else callback(null, false);
          });
        }
      ],
      (err, results) => {
        if (err) {
          // no errors currently possible;\
        } else {
          let firstStep = 0;
          if (results[0] === true) firstStep = 2;
          if (results[0] === true && results[1] === true) firstStep = 3;
          if (firstStep === 3) {
            // this is a fun loop. get the products from the client
            // set the active product to the first one and then
            // push the state to where it needs to be to load
            ClientServices.getClientProducts(
              this.props.token,
              this.props.match.params.clientId
            ).then(
              results => {
                this.setState({ product: results[0] }, () => {
                  this.setState({ activeStep: firstStep });
                });
              },
              failure => {
                console.warn(
                  "failed to get test results for client in ProducerWelcome"
                );
              }
            );
          } else {
            this.setState({ activeStep: firstStep });
          }
        }
      }
    );
  }

  private getSteps(): string[] {
    return [
      "Getting Started",
      "Add a Brand",
      "Add a Product",
      "Add a Test",
      "Finish Up"
    ];
  }

  private getStepContent(step: number): React.ReactChild {
    const {
      match: {
        params: { clientId }
      }
    } = this.props;
    const { brand, product, testResult } = this.state;
    switch (step) {
      case 0:
        return <GettingStarted nextStep={this.nextStep} />;
      case 1:
        return <AddNewBrand clientId={clientId} onSave={this.receiveBrand} />;
      case 2:
        return (
          <div>
            <WelcomeProgress clientId={clientId} brandData={brand} />
            <AddNewProduct
              clientId={clientId}
              brandData={brand}
              onSave={this.receiveProduct}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <WelcomeProgress
              clientId={clientId}
              brandData={brand}
              productData={product}
            />
            <AddNewTest
              clientId={clientId}
              onSave={this.receiveTestResult}
              hideCancel={true}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <WelcomeProgress
              clientId={clientId}
              brandData={brand}
              productData={product}
              testData={testResult}
            />
            <CompleteWorkflow
              url={"/client/" + clientId + "/manageProducts/"}
            />
          </div>
        );

      default:
        return <ConciergeService />;
    }
  }

  private receiveBrand = (brandData: Brand) => {
    this.setState({ brand: brandData, activeStep: 2 });
  };

  private receiveProduct = (productData: ProducerProduct) => {
    this.setState({
      product: productData,
      activeStep: 3
    });
  };

  private receiveTestResult = (testResultData: TestResultPostResponse) => {
    this.updateProductWithTest(testResultData, () => {
      this.setState({
        testResult: testResultData,
        activeStep: 4
      });
    });
  };

  private nextStep = () => {
    let { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1
    });
  };

  // private setProduct = (createdProduct: ProducerProduct) => {
  //   this.setState({ product: createdProduct });
  // };

  private updateProductWithTest = (
    testResultPostResponse: TestResultPostResponse,
    callback: () => void
  ) => {
    let productUpdate = {
      latestLot: testResultPostResponse.lotId,
      available: true
    };
    let productId = this.state.product._id;
    const objectToFormData = require("object-to-formdata");
    ClientServices.saveProduct(
      this.props.token,
      this.props.match.params.clientId,
      objectToFormData(productUpdate),
      productId
    ).then(
      results => {
        console.log(results);
      },
      failure => {
        console.warn(failure);
      }
    );
    callback();
    // this.setState({ testResult });
    // create a promise with an update to the product, creating
    // the most recent testResult property and updating it.
    // Wah lah! - you're done
  };
  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    return (
      <>
        <StyledProducerWelcome>
          <span style={{ textAlign: "center" }}>
            <h1>Welcome To Cannabinder</h1>
          </span>

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              // todo: set the icon colors to match the explainer cards
              // let labelColor;
              // switch (index) {
              //   case 2:
              //     labelColor = "green";
              //     break;
              //   case 3:
              //     labelColor = "orange";
              //     break;
              //   default:
              //     labelColor = "blue";
              //     break;
              // }
              return (
                <Step key={index} {...props}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <StyledStepContents>
            {activeStep !== undefined ? (
              this.getStepContent(activeStep)
            ) : (
              <AppSpinner />
            )}
          </StyledStepContents>
        </StyledProducerWelcome>
        {/* <PanelFooterButtons>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={this.nextStep}
            disabled={activeStep === steps.length - 1}
          >
            Submit & Go to Next Step
          </Button>
          <Link>Cancel</Link>
        </PanelFooterButtons> */}
      </>
    );
  }
}

export default AuthenticationHOC()(ProducerWelcome);
