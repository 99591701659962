import React, { Component } from "react";
import PropTypes, { object } from "prop-types";
import { AuthenticationHOC } from "../../../components/AuthenticationHOC";

/***
 * @augments {Component<{clientId: string}>}
 */
class PrimaryNav extends Component {
  static defaultProps = {
    clientData: false
  };
  render() {
    const {
      match: {
        params: { clientId }
      }
    } = this.props;
    return (
      <div className="cb_account__navigation">
        <div className="cb_menu">
          <h3 className="cb_menu__header">Setup</h3>
          <ul className="cb_menu__list">
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/profile"}
              >
                Company Profile
              </a>
            </li>
            {this.props.clientData.type === "dispensary" && (
              <li className="cb_menu__list-item">
                <a
                  className="cb_menu__list-item-link"
                  href={"/client/" + clientId + "/inventory"}
                >
                  Kiosk Inventory
                </a>
              </li>
            )}
            {this.props.clientData.premiumEnabled && (
              <li className="cb_menu__list-item">
                <a
                  className="cb_menu__list-item-link"
                  href={"/client/" + clientId + "/kiosk/settings"}
                >
                  Kiosk Settings
                </a>
              </li>
            )}
            {!this.props.clientData.premiumEnabled &&
              this.props.clientData.type === "dispensary" && (
                <li className="cb_menu__list-item">
                  <a
                    className="cb_menu__list-item-link"
                    href={"/client/" + clientId + "/premium/signup"}
                  >
                    Pro Kiosk Inventory
                  </a>
                </li>
              )}
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/manageBrands"}
              >
                Manage Brands
              </a>
            </li>
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/manageProducts"}
              >
                Manage Products
              </a>
            </li>
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/printStickers/"}
              >
                Label Products
              </a>
            </li>
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/parentLots"}
              >
                Test Results
              </a>
            </li>
          </ul>
        </div>
        <div className="cb_menu">
          <h3 className="cb_menu__header">Pro</h3>
          <ul className="cb_menu__list">
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/premium-coa-entry"}
              >
                COA Data Entry
              </a>
            </li>
            {/* <li className="cb_menu__list-item">
              {!this.props.clientData.premiumEnabled && (
                <a
                  className="cb_menu__list-item-link"
                  href={
                    "/client/" +
                    clientId +
                    "/premium/signup"
                  }
                >
                  Pro Sales Kiosk
                </a>
              )}
            </li> */}
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/whitelabel/" + this.props.match.params.clientId}
              >
                {"Sales Kiosk"}
                {this.props.clientData.premiumEnabled ? "" : " Demo"}
              </a>
            </li>
            <li className="cb_menu__list-item">
              {this.props.clientData.loyaltyEnabled ? (
                <a
                  className="cb_menu__list-item-link"
                  href={"/client/" + clientId + "/loyalty"}
                >
                  Loyalty
                </a>
              ) : (
                <a
                  className="cb_menu__list-item-link"
                  href={"/client/" + clientId + "/premium/signup"}
                >
                  Pro Loyalty
                </a>
              )}
            </li>
          </ul>
        </div>

        <div className="cb_menu">
          <h3 className="cb_menu__header">Resources</h3>
          <ul className="cb_menu__list">
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/training/"}
              >
                Platform Training
              </a>
            </li>
            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href={"/client/" + clientId + "/materials/"}
              >
                Marketing Materials
              </a>
            </li>
          </ul>
        </div>
        <h1>
          {this.props.clientData.name} <br /> UBI:{" "}
          {this.props.clientData.buisnessLicenseNumber}
          <br />
          License: {this.props.clientData.licenseNumber}
        </h1>
      </div>
    );
  }
}

PrimaryNav.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      clientId: PropTypes.string.isRequired
    })
  }),
  user: object
};

export default AuthenticationHOC()(PrimaryNav);

/***
   * removed menu items for reintroduction
   *            <li className="cb_menu__list-item">
              <a className="cb_menu__list-item-link" href="#l">
                Billing
              </a>
            </li>

                        <li className="cb_menu__list-item">
              <a className="cb_menu__list-item-link" href="#">
                Notifications
              </a>
            </li>


            <li className="cb_menu__list-item">
              <a
                className="cb_menu__list-item-link"
                href="/client/upgradeAccount"
              >
                Upgrade Account
              </a>
            </li>

                        <li className="cb_menu__list-item">
              <a className="cb_menu__list-item-link" href="/client/seed2sale">
                Connect to S2S
              </a>
            </li>
                        <li className="cb_menu__list-item">
              <a className="cb_menu__list-item-link" href="#">
                Emails
              </a>
            </li>
   */
