import * as React from "react";
import styled from "styled-components";
import {
  BorderRadius,
  BrandColor,
  Color,
  toPixel
} from "../kioskUtilities/constants";
// import { head } from "lodash";
import CannastampIcon from "../../components/product/CannastampIcon";
import { ProductInterface } from "cbd-api-types";
import { ExploreOutlined } from "@material-ui/icons";

interface Props {
  product: ProductInterface;
  key: any;
  onClick: (product: ProductInterface) => void;
  notInStore?: boolean;
  variant?: "small" | "normal";
}

export class ProductCard extends React.Component<Props> {
  private onClick = (): void => {
    const { onClick, product } = this.props;
    onClick(product);
  };
  public render() {
    const { product, variant = "normal", notInStore = false } = this.props;
    // const lowestPrice = head(msrpPricing);
    return (
      <StyledCard
        onClick={this.onClick}
        variant={variant}
        className={notInStore && "notInStore"}
      >
        {notInStore && (
          <GobalIcon>
            <ExploreOutlined />
          </GobalIcon>
        )}
        <StyledImageWrapper
          featureImage={product.featureImage}
          variant={variant}
        />
        <StyledCannastamp variant={variant}>
          {product.latestLot !== undefined &&
            typeof product.latestLot !== "string" && (
              <CannastampIcon testResult={product.latestLot.testResult} />
            )}
        </StyledCannastamp>
        <h3>{product.name}</h3>
        <span>{product.brand.name}</span>
        <span>{product.productName}</span>
        <span>{product.productType}</span>
        {/* {variant !== "small" && (
          <span>
            from{" "}
            <PriceSpan>{(lowestPrice && lowestPrice.price) || "N/A"}</PriceSpan>
          </span>
        )} */}
      </StyledCard>
    );
  }
}

const COLLAPSED_CARD: number = 200;

const StyledImageWrapper = styled.div<{
  featureImage: any;
  variant: "small" | "normal";
}>`
  width: ${props => (props.variant === "small" ? toPixel(60) : toPixel(160))};
  height: ${props => (props.variant === "small" ? toPixel(60) : toPixel(160))};
  background: url(${props => props.featureImage}) center;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  //box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
`;

// const StyledImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
// `;

const StyledCannastamp = styled.div<{ variant: "small" | "normal" }>`
  width: ${props => (props.variant === "small" ? toPixel(30) : toPixel(80))};
  height: ${props => (props.variant === "small" ? toPixel(30) : toPixel(80))};
  background: ${Color.White};
  border-radius: 50%;
  margin-top: -${props => (props.variant === "small" ? toPixel(30) : toPixel(80))};
  margin-left: ${props =>
    props.variant === "small" ? toPixel(30) : toPixel(80)};
  box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
`;

const StyledCard = styled.div<{ variant: "small" | "normal" }>`
  cursor: pointer;
  width: ${props =>
    props.variant === "small" ? toPixel(100) : toPixel(COLLAPSED_CARD)};
  height: auto;
  background: ${Color.VeryLightGray};
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
  border-radius: ${toPixel(BorderRadius.Medium)};
  & > * {
    margin-bottom: 10px;
  }
  &.notInStore {
    box-shadow: 0 1px 4px 1px ${BrandColor.Blue2};
  }
`;

const GobalIcon = styled.span`
  width: 100%;
  height: 0;
  position: relative;
  margin-bottom: 0;
`;

// const PriceSpan = styled.span`
//   font-weight: bold;
// `;
