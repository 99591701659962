import React, { Component } from "react";
import CannastampIcon from "./CannastampIcon";
import { AuthenticationHOC } from "../AuthenticationHOC";

class SimilarProductDisplay extends Component {
  static defaultProps = {
    similarProductArray: null
  };
  constructor(props) {
    super(props);
  }

  resultsView() {
    var renderElement = [];
    var i = 0;
    this.props.similarProductArray.map((item, index) => {
      if (typeof item.targetTest.lot.product !== "undefined") {
        renderElement.push(
          <div className="cb_cannastamp cb_grid-item--3" key={index}>
            <a href={`/product/${item.targetTest.lot.product._id}/${item.targetTest.lot._id}`}>
              <div className="cb_cannastamp__graph-container">
                <CannastampIcon testResult={item.targetTest} size={"100"} />
              </div>
              <span className="cb_cannastamp__strain-name">
                {item.targetTest.lot.product.name}
              </span>
              <span className="cb_cannastamp__strain-name">
                <p>{item.targetTest.lot.product.productType}</p>
              </span>
              <span className="cb_cannastamp__grower-name">
                {item.targetTest.lot.producer.name}
              </span>
            </a>
          </div>
        );
      }
    });
    return <div>{renderElement}</div>;
  }

  noResultsView() {
    return (
      <div className="cb_product__description-text">
        We didn't find a good match, but we are adding more every day!
      </div>
    );
  }

  render() {
    let view =
      this.props.similarProductArray !== null && this.props.similarProductArray !== undefined && this.props.similarProductArray.length > 0
        ? this.resultsView()
        : this.noResultsView();
    return view;
  }
}

export default AuthenticationHOC()(SimilarProductDisplay);
