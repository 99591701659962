import React, { Component } from "react";
import ThumbsUpDown from "../generics/ThumbsUpDown";
import UserService from "../../apiService/UserService";
import ActivityIconMap from "../util/ActivityIconMap";

// TODO: double click not working to edit
// TODO: complete the loop on saving a record
// TODO: complete the loop on deleting a record
// TODO: make the "add to favorites respond correctly"

class RatingComponent extends Component {
  static defaultProps = {
    noteRows: 2,
    noteCols: 50,
    note: null,
    rating: 0,
    disabled: false,
    addRating: false
  };
  constructor(props) {
    super(props);
    this.state = {
      binderItem:
        Object.keys(this.props.binderItem).length !== 0
          ? this.props.binderItem
          : this.createBinderItem(),
      product: this.props.product,
      testResult: this.props.testResult,
      // lot: this.props.testLot,
      // user: this.props.user,
      // token: this.props.token,
      // disabled: this.props.disabled,
      // disbledAction: this.props.disabledAction,
      // forceParentRender: this.props.forceParentRender,
      hoverValue: null,
      errorMessage: null,
      mode:
        typeof this.props.binderItem.rating !== "undefined" ? "view" : "edit"
    };
  }

  createBinderItem() {
    return {
      usage: null,
      rating: 0,
      note: null,
      public: false,
      user: typeof this.props.user !== "undefined" ? this.props.user._id : null,
      // product id is agnostic of fully populated objects verses Id's
      product:
        typeof this.props.product === "object"
          ? this.props.product._id
          : this.props.product,
      lot: this.props.testLot._id,
      currentNearest: null,
      test: this.props.testResult._id
    };
  }

  rateAgainButton() {
    if (this.state.mode !== "edit" && this.props.addRating === true) {
      return (
        <div className="cb_rating__buttons">
          <button
            className="cb_rating__rate-again-button"
            onClick={this.props.handleRateAgain}
          >
            Rate Another Activity
          </button>
        </div>
      );
    }
  }

  selectList() {
    const optionList = Object.keys(ActivityIconMap);

    let insertList = [];
    let key = 0;
    optionList.map(item => {
      insertList.push(
        <option key={key} value={item}>
          {item}
        </option>
      );
      key++;
    });
    return (
      <div className="cb_rating__form-group">
        <select
          onChange={this.setUsage}
          className="cb_rating__activity-dropdown form-control"
          id="usageSelect"
          value={this.state.binderItem.usage}
        >
          <option selected disabled>
            Select Activity
          </option>
          {insertList}
        </select>
        {this.state.alertActivitySelect}
      </div>
    );
  }
  setBinderProp(prop, value) {
    let dummyState = this.state.binderItem;
    dummyState[prop] = value;
    this.setState({ binderItem: dummyState });
  }

  togglePublicNote() {
    this.setBinderProp("public", !this.state.binderItem.public);
  }

  noteOnChange = event => {
    const noteText = document.getElementById("userNote").value;
    this.setBinderProp("note", noteText);
    let dummyState = this.state.binderItem;
    dummyState.note = noteText;
    this.setState({ binderItem: dummyState });
  };

  thumbsOnChange = value => {
    let dummyState = Object.assign({}, this.state.binderItem);
    dummyState.rating = value;
    this.setState({ binderItem: dummyState });
  };

  setUsage = event => {
    const usage = document.getElementById("usageSelect").value;
    let dummyState = this.state.binderItem;
    dummyState.usage = usage;
    this.setState({ binderItem: dummyState });
  };

  deleteButton() {
    if (typeof this.state.binderItem._id !== "undefined")
      return (
        <button
          className="cb_rating__delete-rating-button "
          onClick={this.removeItemFromBinder}
        >
          Delete
        </button>
      );
  }

  validateBinderItem(item) {
    if (!item.usage || item.rating === 0) return false;
    else return true;
  }

  saveBinderItem = () => {
    if (!this.validateBinderItem(this.state.binderItem)) {
      this.setState({
        errorMessage: "Select a usage and rating before saving!"
      });
    } else {
      UserService.rateBinderItem(
        this.props.token,
        this.props.user,
        this.state.binderItem
      )
        .then(results => {
          // if this has occured a browser session went stale and the window just needs a reload
          if (typeof results.expiredAt !== "undefined")
            this.props.forceParentRender();
          else {
            let mutableBinderItem = this.state.binderItem;
            mutableBinderItem._id = results._id;
            this.setState({
              mode: "view",
              errorMessage: "",
              binderItem: mutableBinderItem
            });
            this.props.setInBinder(true);
          }
        })
        .catch(error => {
          this.setState({ errorMessage: JSON.stringify(error) });
        });
    }
  };

  removeItemFromBinder = event => {
    event.preventDefault();
    if (this.props.user === null || this.props.user === "") {
      // there should be no way this can happen - but just in case
      this.props.onModalLogin(false);
    } else {
      // time to drop in the add to cannabinder feature
      UserService.removeBinderItem(this.state.binderItem, this.props.token)
        .then(results => {
          if (typeof results.expiredAt !== "undefined") {
            this.props.logout();
          } else {
            this.props.binderItemReload();
          }
        })
        .catch(error => {});
    }
  };

  toggleEdit = () => {
    this.setState({ mode: "edit" });
  };

  // visual components when disabled
  // userConvert = () =>{
  //   if(typeof this.props.user )
  // }

  disabledMode = () => {
    return (
      <div className="cb_rating cb_rating--disabled">
        <div className="cb_checkActivityCarrier">
          <p>
            Give this product a thumbs up or down based on how well it worked
            for you.
          </p>
          <div
            className="cb_rating__activity"
            style={{ display: "inline-block", position: "relative" }}
          >
            <select
              className="cb_rating__activity-dropdown form-control"
              id="usageSelect"
              placeholder="Select Activity"
            >
              <option selected disabled>
                Select Activity
              </option>
            </select>
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }}
            />
          </div>
          <div className="cb_rating__thumbs">
            <ThumbsUpDown disabled={true} ghostMode={true} />
          </div>
        </div>

        <p>Add an optional private note for this product rating.</p>
        <div className="cb_rating__note">
          <textarea
            className="cb_rating__note-input"
            name="userNote"
            id="userNote"
            disabled={true}
            cols={this.props.noteCols}
            placeholder="Add Note"
          />
        </div>
      </div>
    );
  };

  // visual components when viewing data
  viewMode = () => {
    return (
      <div
        className="cb_rating cb_rating--view-only"
        style={{ paddingbottom: "2px" }}
        onDoubleClick={this.toggleEdit}
      >
        <div className="cb_rating__thumbs">
          <div className="cb_rating__view-rating">
            <div className="cb_rating__thumbsUpDown">
              <ThumbsUpDown
                disabled={true}
                value={this.state.binderItem.rating}
              />
            </div>
            <div className="cb_rating__activity">
              {this.state.binderItem.usage}
            </div>
          </div>
          <h4 className="cb_rating__notes-heading">My Notes:</h4>
          <p className="cb_rating__note">{this.state.binderItem.note}</p>
        </div>

        {this.rateAgainButton()}
      </div>
    );
  };

  // visual components when editing data
  editMode = () => {
    // taking care of an error with null values in "value" area
    return (
      <div className="cb_rating">
        <div className="cb_checkActivityCarrier">
          <p>
            Give this product a thumbs up or down based on how well it worked
            for you.
          </p>
          <div className="cb_rating__activity-group">
            <div className="cb_rating__activity">{this.selectList()}</div>
            <div className="cb_rating__thumbs">
              <ThumbsUpDown
                onChange={this.thumbsOnChange}
                value={this.state.binderItem.rating}
              />
              <span className="cb_rating__activity">
                {this.state.binderItem.Activity}
              </span>
            </div>
          </div>
        </div>

        <div className="cb_rating__buttons">
          <button className="cb_rating__save" onClick={this.saveBinderItem}>
            Save My Rating
          </button>
          {this.deleteButton()}
        </div>
      </div>
    );
  };

  /**
   * user logged in - not saved = edit mode
   * user logged in - saved, view mode
   * user logged in - saved, edit mode
   * no user - disabled demo mode
   */
  render() {
    if (this.props.disabled === true) {
      return this.disabledMode();
    } else {
      if (this.state.mode === "edit") {
        return this.editMode();
      } else {
        return this.viewMode();
      }
    }
  }
}

export default RatingComponent;
