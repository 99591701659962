import * as React from "react";
import styled from "styled-components";
import { BorderRadius, Color, toPixel } from "../kioskUtilities/constants";

interface Props {
  key: any;
  onClick: () => void;
  variant?: "small" | "normal";
  actionTitle: string;
  actionImage: string;
  actionBody: string;
  actionColor?: string;
  maxHeight?: number;
}

export class ActionCard extends React.Component<Props> {
  public render() {
    const {
      variant = "normal",
      actionBody,
      actionImage,
      actionTitle,
      actionColor,
      onClick,
      maxHeight
    } = this.props;
    return (
      <StyledCard
        maxHeight={maxHeight}
        onClick={onClick}
        variant={variant}
        actionColor={actionColor}
      >
        <StyledImageWrapper featureImage={actionImage} variant={variant} />
        <h3>{actionTitle}</h3>
        <span>{actionBody}</span>
      </StyledCard>
    );
  }
}

const COLLAPSED_CARD: number = 200;

const StyledImageWrapper = styled.div<{
  featureImage: any;
  variant: "small" | "normal";
}>`
  width: ${props => (props.variant === "small" ? toPixel(60) : toPixel(160))};
  height: ${props => (props.variant === "small" ? toPixel(60) : toPixel(160))};
  background: url(${props => props.featureImage}) center;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  //box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
`;

const StyledCard = styled.div<{
  variant: "small" | "normal";
  actionColor: string;
  maxHeight?: number;
}>`
  cursor: pointer;
  width: ${props =>
    props.variant === "small" ? toPixel(100) : toPixel(COLLAPSED_CARD)};
  height: auto;
  ${props => (props.maxHeight ? `max-height:${props.maxHeight}px` : null)}
  background: ${props => (props.actionColor ? props.actionColor : Color.White)};
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
  border-radius: ${toPixel(BorderRadius.Medium)};
  & > * {
    margin-bottom: 10px;
  }
`;
