import {
  LoyaltyMessageCreateInterface,
  LoyaltyPointOfferCreateBody,
  LoyaltyAwardCreateInterface,
  LoyaltyOfferCreateBody,
  UserDocumentInterface,
  LoyaltyPointOfferInterface
} from "cbd-api-types";
import { oAuthMode } from "../configurator";
import { Paginate } from "../client/clientComponents/loyalty/MemberFilterList";
import { CbdError } from "cbd-api-types/types/baseInterfaces";
const remoteConfig = oAuthMode(window.location.href);
const baseAPI = remoteConfig.baseUrl + "loyalty";

export function userLoyaltySignup(
  token: string,
  entityId: string,
  phoneNumber: number,
  zip: string,
  age: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${baseAPI}/${token}/${entityId}/user/addToLoyalty/phone/${phoneNumber}/${zip}/${age}`,
      {
        method: "POST",
        headers
      }
    )
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}

export function userLoyaltyCheckin(
  token: string,
  entityId: string,
  phoneNumber: number
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/user/checkin/phone/${phoneNumber}`, {
      method: "POST",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}

export function userLoyaltyConfirmCode(
  token: string,
  entityId: string,
  phoneNumber: number,
  confirmationCode: string,
  categories: string[]
): Promise<unknown> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${baseAPI}/${token}/${entityId}/user/confirm/phone/${phoneNumber}/${confirmationCode}`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify({ categories })
      }
    )
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        console.warn(err);
        resolve(err);
      });
  });
}

// Route not created on server yet
export function userIsInLoyaltyPhone(
  token: string,
  entityId: string,
  phoneNumber: number
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${baseAPI}/${token}/${entityId}/user/isInLoyalty/phone/${phoneNumber}`,
      {
        method: "GET",
        headers
      }
    )
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}

export function resetValidationCode(
  token: string,
  entityId: string,
  phoneNumber: number
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/user/newCode/phone/${phoneNumber}`, {
      method: "PUT",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}

// loyaltyRouter.put( "/:token/:entityId/user/activate/:id",
export function activeUserLoyaltyById(
  token: string,
  entityId: string,
  userId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/user/activate/${userId}`, {
      method: "PUT",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}
// loyaltyRouter.post( "/:token/:entityId/awards/create",
export function createAwardPoints(
  token: string,
  entityId: string,
  loyaltyAward: LoyaltyAwardCreateInterface
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/awards/create`, {
      method: "POST",
      body: JSON.stringify(loyaltyAward),
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}
// loyaltyRouter.post( "/:token/:entityId/awards/consume",
export function createAwardsPointsConsumption(
  token: string,
  entityId: string,
  pointsConsumption: LoyaltyAwardCreateInterface
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/awards/consume`, {
      method: "POST",
      headers,
      body: JSON.stringify(pointsConsumption)
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}
// loyaltyRouter.post( "/:token/:entityId/awards/delete",
export function createAwardsPointsDeletion(
  token: string,
  entityId: string,
  pointsDeletion: LoyaltyAwardCreateInterface
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/awards/delete`, {
      method: "POST",
      body: JSON.stringify(pointsDeletion),
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}
// loyaltyRouter.post( "/:token/:entityId/awards/expire",
export function createAwardsPointsExpiration(
  token: string,
  entityId: string,
  pointsExpiration: LoyaltyAwardCreateInterface
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/awards/expire`, {
      method: "POST",
      body: JSON.stringify(pointsExpiration),
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}
// loyaltyRouter.post( "/:token/:entityId/point/offer",
export function createPointsOffer(
  token: string,
  entityId: string,
  pointsOffer: LoyaltyPointOfferInterface
): Promise<unknown> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/point/offer`, {
      method: "POST",
      body: JSON.stringify(pointsOffer),
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        resolve(err);
      });
  });
}
// loyaltyRouter.put( "/:token/:entityId/point/offer/:id",
export function updatePointsOffer(
  token: string,
  entityId: string,
  pointsAwardOffer: LoyaltyPointOfferCreateBody
): Promise<unknown> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/point/offer/:id`, {
      method: "PUT",
      headers,
      body: JSON.stringify(pointsAwardOffer)
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
// loyaltyRouter.post( "/:token/:entityId/offer",
export function createOffer(
  token: string,
  entityId: string,
  purchaseOffer: LoyaltyOfferCreateBody["body"]
): Promise<unknown> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/offer`, {
      method: "POST",
      body: JSON.stringify(purchaseOffer),
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
// loyaltyRouter.get( "/:token/:entityId/offers/inactive",
export function getOffersActive(entityId: string): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${entityId}/offers/active`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
export function getOffersInactive(
  token: string,
  entityId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/offers/inactive`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
// loyaltyRouter.get("/:token/:entityId/points/offers/inactive",
export function getPointsOffersActive(entityId: string): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/public/${entityId}/points/offers/active`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}

export function getPointsOffersExpired(
  token: string,
  entityId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/points/offers/expired`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}

export function getPointsOffersInactive(
  token: string,
  entityId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/points/offers/inactive`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
// loyaltyRouter.put("/:token/:entityId/offer/:id",
export function updateOffer(
  token: string,
  entityId: string,
  offer: LoyaltyOfferCreateBody["body"]
): Promise<unknown> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/offer/${offer._id}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(offer)
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
// loyaltyRouter.post("/:token/:entityId/message",
export function createLoyaltyMessage(
  token: string,
  entityId: string,
  loyaltyMessage: LoyaltyMessageCreateInterface
): Promise<unknown> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/message`, {
      method: "POST",
      headers,
      body: JSON.stringify(loyaltyMessage)
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
export function getLoyaltyMessage(
  token: string,
  entityId: string,
  messageId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/messages/${messageId}`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}

export function getAllLoyaltyMessages(
  token: string,
  entityId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/messages`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
// consumer routes ---- add to the consumer router
export function getAllAwardOffers(
  token: string,
  entityId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/public/${entityId}/offers/active`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}

export function getAllPointsPurchaseOffers(
  token: string,
  entityId: string
): Promise<unknown> {
  let headers = {
    Accept: "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${entityId}/offers/active`, {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}

// loyaltyRouter.get( "/:token/:entityId/offers/inactive",

export type GetLoyaltyMembersResponse = Response & {
  users?: UserDocumentInterface[];
  idList?: Array<{ _id: UserDocumentInterface["_id"] }>;
  pages?: number;
  error?: CbdError;
};

export function getLoyaltyMembers(
  token: string,
  entityId: string,
  paginate?: Paginate
): Promise<GetLoyaltyMembersResponse> {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return new Promise((resolve, reject) => {
    fetch(`${baseAPI}/${token}/${entityId}/members`, {
      method: "post",
      headers,
      body: JSON.stringify({ paginate })
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => {
        reject(err);
      });
  });
}
