import React, { Component } from "react";
import PropTypes from "prop-types";

export default class CannastampDownloader extends Component {
  static propTypes = {
    svgId: PropTypes.string,
    downloadName: PropTypes.string
  };

  static randomCanvasId = "random-" + Math.random() * 10000000;

  triggerDownload = imgURI => {
    var evt = new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true
    });

    var a = document.createElement("a");
    a.setAttribute("download", this.props.downloadName + ".png");
    a.setAttribute("href", imgURI);
    a.setAttribute("target", "_blank");

    a.dispatchEvent(evt);
  };

  onDownload = () => {
    // var canvas = document.getElementById(this.randomCanvasId);
    var newCanvas = document.createElement("canvas");
    newCanvas.setAttribute("width", 3600);
    newCanvas.setAttribute("height", 3600);
    var ctx = newCanvas.getContext("2d");
    var svg = document.getElementById(this.props.svgId);
    var data = new XMLSerializer().serializeToString(svg);
    var DOMURL = window.URL || window.webkitURL || window;

    var img = new Image();
    var svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
    var url = DOMURL.createObjectURL(svgBlob);

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      var imgURI = newCanvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      this.triggerDownload(imgURI);
    };

    img.src = url;
  };

  render() {
    return (
      <div>
        <a onClick={this.onDownload}>PNG</a>
      </div>
    );
  }
}
