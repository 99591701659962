import * as React from "react";
import styled from "styled-components";
import { BrandColor, Color, toPixel } from "../kioskUtilities/constants";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Lock from "@material-ui/icons/Lock";
import Badge from "@material-ui/core/Badge";
import { ProductInterface, EntityDocumentInterface } from "cbd-api-types";
import { ProductFilter } from "./ProductFilter";
import { Search } from "./Search";
import { CategoryItem } from "../kioskInterfaces";
import { set as setCookie, get as getCookie } from "es-cookie";

const { useEffect, useState } = React;
interface Props {
  clientData: EntityDocumentInterface;
  cart: Set<ProductInterface>;
  searchChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchClearHandler: () => void;
  searchString: string;
  filterOnClick: (value: string) => void;
  filters: CategoryItem[];
  disableCart?: boolean;
  disableHeader?: boolean;
  token: string;
}
export const WhiteLabelHeader: React.FC<Props> = (props: Props) => {
  const {
    clientData: {
      featureImage,
      backgroundImage,
      _id,
      premiumLevel,
      kioskConfig
    }, // name,
    cart,
    searchChangeHandler,
    searchClearHandler,
    searchString,
    filterOnClick,
    filters,
    disableCart,
    disableHeader
  } = props;
  const [headerState, setHeaderState] = useState("minimized");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        if (headerState !== "minimized") {
          setHeaderState("minimized");
        }
      } else {
        setHeaderState("minimized");
      }
    });
  }, []);
  // const dispensaryName = headerState === "standard" ? name : "";
  const loginAsKiosk = () => {
    setCookie("kioskUser", _id, { path: "/" });
    window.location.reload();
  };
  if (!disableHeader) {
    return (
      <StyledCenteredVStack
        background={!disableHeader && backgroundImage}
        headerState={headerState}
      >
        <StyledHeaderImage
          featureImage={featureImage}
          headerState={headerState}
        />
        <FilterArea>
          <Search
            onChange={searchChangeHandler}
            onClear={searchClearHandler}
            value={searchString}
          />
          <ProductFilter filterCategories={filters} onClick={filterOnClick} />
        </FilterArea>
        {!disableCart && (
          <StyledShoppingCartIcon>
            <Badge badgeContent={cart.size} color={"secondary"}>
              <ShoppingCartIcon />
            </Badge>
          </StyledShoppingCartIcon>
        )}
        {premiumLevel === "pro" &&
          !getCookie("kioskUser") &&
          kioskConfig.pin !== "" && (
            <StyledKioskUserButton onClick={loginAsKiosk}>
              <Lock />
              <span>{"Lock as Kiosk"}</span>
            </StyledKioskUserButton>
          )}
      </StyledCenteredVStack>
    );
  } else {
    return (
      <NoHeader>
        <FilterArea>
          <Search
            onChange={searchChangeHandler}
            onClear={searchClearHandler}
            value={searchString}
          />
          <ProductFilter filterCategories={filters} onClick={filterOnClick} />
        </FilterArea>
        {!disableCart && (
          <StyledShoppingCartIcon>
            <Badge badgeContent={cart.size} color={"secondary"}>
              <ShoppingCartIcon />
            </Badge>
          </StyledShoppingCartIcon>
        )}
      </NoHeader>
    );
  }
};

// const StyledHeaderName = styled.div`
//   background: linear-gradient(
//     0deg,
//     rgba(255, 255, 255, .85) 0%,
//     rgba(250, 250, 250, 0) 100%
//   );
//   width: 100%;
//   height: 60px;
//   text-align: center;
//   line-height: 60px;
//   font-size: 40px;
// `;

const StyledHeaderImage = styled.div<{
  featureImage: string;
  headerState: string;
}>`
  min-width: 100px;
  width: ${props => (props.headerState === "standard" ? "200px" : "200px")};
  height: ${props => (props.headerState === "standard" ? "160px" : "160px")};
  background-image: url("${props => props.featureImage}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0 !important;
  transition: all 200ms ease;
`;

const NoHeader = styled.div`
  min-width: 400px;
  height: 50px;
  margin-top: 50px !important;
`;

const FilterArea = styled.div`
  background: rgba(255, 255, 255, 0.85);
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > * {
    max-width: 680px;
    width: 100%;
  }
`;

const StyledCenteredVStack = styled.div<{
  background: string;
  headerState: string;
}>`
  ${props => {
    if (props.headerState === "standard") {
      return {
        height: toPixel(300),
        position: "fixed"
      };
    } else {
      return {
        height: toPixel(210)
      };
    }
  }};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  background-image: url("${props => props.background}");
  background-size: cover;
  background-position: center;
  z-index: 1;
  & > * {
    //margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    &:first-child {
      margin-top: ${props => (props.headerState === "standard" ? "40px" : 0)};
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledShoppingCartIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${BrandColor.Cyan};
  color: ${Color.Black};
  padding: 18px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const StyledKioskUserButton = styled.button`
  height: 60px;
  border-radius: 2rem;
  background: ${BrandColor.Cyan};
  color: ${Color.Black};
  padding: 18px;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  border: 0 none;
  & > * {
    padding: 0 2px;
  }
`;
