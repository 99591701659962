import React, { Component } from "react";
import PropTypes from "prop-types";
import UploadResultCard from "./UploadResultCard";

export default class UploadResults extends Component {
  static propTypes = {
    multipleMode: PropTypes.bool,
    uploadHistory: PropTypes.array
  };

  render() {
    return (
      <div>
        <h2>Your uploads!</h2>
        <div>
          {this.props.uploadHistory.map((upload, index) => {
            return (
              <UploadResultCard
                uploadNumber={index + 1}
                uploadFileArray={upload}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
