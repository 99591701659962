import React, { Component } from "react";
import PropTypes from "prop-types";

export default class VariabilityProblem extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    return (
      <div>
        <div className="cb_slide cb_slide--2-column">
          <div className="cb_slide__column">
            <h3 className="cb_slide__title">
              Strain names are a thing of the past.
            </h3>
            <p className="cb_slide__text">
              Strain names don’t mean anything anymore. Companies can name
              products whatever they want, but that doesn’t tell you what’s
              really in the package.
            </p>
            <p className="cb_slide__text">
              Just take a look at these three products, all called Blue Dream.
              The Cannastamps for each show you the difference between them.
            </p>
            <div className="cb_slide__utility">
              <a className="cb_slide__utility-primary" href="/signup">
                Sign Up and Find Your Experience
              </a>
            </div>
          </div>
          <div className="cb_slide__column">
            <img src="/assets/images/stamps.jpg" alt="" />
          </div>
        </div>

        <div className="cb_slide cb_slide--1-column">
          <span id="thecolors" />
          <div className="cb_slide__column">
            <h3 className="cb_slide__title centered">
              What do the colors on the Cannastamp mean?
            </h3>
            <p className="cb_slide__text centered">
              Each of the colors in the Cannastamp represent a different active
              compound found in cannabis. The more of that color you see, the
              more of that compound is in the package.
            </p>
          </div>
          <div className="cb_slide__column">
            <img src="/assets/images/explainer.jpg" alt="" />
          </div>
        </div>

        <div className="cb_slide cb_slide--2-column">
          <div className="cb_slide__column">
            <img src="/assets/images/products.jpg" alt="" />
          </div>
          <div className="cb_slide__column">
            <h3 className="cb_slide__title">
              Whatever you use cannabis for, we’ll help you find the right fit.
            </h3>
            <p className="cb_slide__text">
              Cannabis is complicated. With over 650 active compounds, it can be
              really difficult to know how a new strain will affect you. On top
              of that, everyone reacts a little differently to each product
              based on body chemistry.{" "}
            </p>
            <p className="cb_slide__text">
              We help make it easy to know what you’re buying by using the
              Cannastamp to tell what’s in products.
            </p>
            <div className="cb_slide__utility">
              <a className="cb_slide__utility-primary" href="/signup">
                Sign Up and Find Your Experience
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
