import * as React from "react";
import async from "async";
import { AuthenticationHOC } from "../../../components/AuthenticationHOC";
import { BrandColor } from "../../../kiosk/kioskUtilities/constants";
import CannastampDownloader from "../CannastampDownloader";
import CannastampIcon from "../../../components/product/CannastampIcon";
import ClientService from "../../../apiService/ClientService";
import { Entity } from "../../../apiService/serviceInterfaces";
import matchSorter from "match-sorter";
import MerchandisingCardPrinter from "../printing/MerchandisingCardPrinter";
import { MasterPageSpinner } from "../../../components/master-page-spinner/MasterPageSpinner";
import { ProductInterface, EntityInterface } from "cbd-api-types";
import ProductOwnerSelect from "../../../admin/adminComponents/ProductOwnerSelect";
import ReactTable, { Column, Filter, RowInfo } from "react-table";
import "react-table/react-table.css";
import Select from "react-select";
import styled from "styled-components";
import Switch from "@material-ui/core/Switch";

interface Props {
  changeOwner?: boolean;
  clientList?: [Entity];
  fetchData?: () => {};
  loading?: boolean;
  match: { params: { clientId: string } };
  pages?: number;
  productList?: ProductInterface[];
  reloadParent?: () => {};
  showDeleted: boolean;
  _showDeleted: () => {};
  token: string;
}

const ASYNC_LIMIT = 5;

class ProductList extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    // props validation - when used in an admin context, it must be provided its client list and parental reload
    if (
      props.changeOwner === true &&
      props.loading === false &&
      (props.clientList === undefined || props.reloadParent === undefined)
    ) {
      throw new Error(
        "ProductList implimented with changeOwner on and clientList and reloadParent undefined"
      );
    }
    this.state = {
      bulkAction: undefined,
      bulkMessage: "",
      downloadsDisabled: false,
      printQue: false,
      selected: {},
      selectAll: 0
    };

    this.bulkDistribute = this.bulkDistribute.bind(this);
    this.bulkDelete = this.bulkDelete.bind(this);
    this.onBulkChange = this.onBulkChange.bind(this);
    this.onDistributeChange = this.onDistributeChange.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
  }

  tableView() {
    const adminColumns: Column = this.props.changeOwner
      ? {
          Header: "Producer",
          id: "producer.name",
          accessor: d => (d.producer === null ? "none" : d.producer.name),
          filterable: false,
          sortable: false,
          filterMethod: (filter: Filter, rows: any[]) =>
            matchSorter(rows, filter.value, {
              keys: ["producer.name"]
            }),
          filterAll: true
        }
      : null;
    const bulkColumns: Column = {
      id: "checkbox",
      accessor: "",
      Cell: ({ original }) => {
        if (original.active === true) {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected[original._id] === true}
              onChange={() => this.toggleRow(original._id)}
            />
          );
        } else {
          return null;
        }
      },
      Header: () => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={this.state.selectAll === 1}
            ref={input => {
              if (input) {
                input.indeterminate = this.state.selectAll === 2;
              }
            }}
            onChange={() => this.toggleSelectAll()}
          />
        );
      },
      maxWidth: 50,
      filterable: false,
      sortable: false
    };
    const standardColumns: Array<Column> = [
      {
        Header: "Cannastamp",
        maxWidth: 45,
        id: "Cannastamp",
        filterable: false,
        sortable: false,
        Cell: ({ row }) => {
          if (
            typeof row._original.latestLot !== "undefined" &&
            row._original.latestLot !== null &&
            typeof row._original.latestLot.testResult !== "undefined" &&
            row._original.latestLot.testResult !== null
          ) {
            return (
              <div style={{ width: 50 }}>
                <CannastampIcon
                  key={row._original.latestLot._id}
                  size={100}
                  testResult={row._original.latestLot.testResult}
                />
              </div>
            );
          } else {
            return <div style={{ width: 50 }}>None</div>;
          }
        }
      },
      {
        Header: "Brand Name",
        id: "brand",
        accessor: d => d.brand.name,
        filterMethod: (filter: Filter, rows: any[]) =>
          matchSorter(rows, filter.value, {
            keys: ["brand"]
          }),
        filterAll: true
      },
      {
        Header: "Product Line",
        id: "productName",
        accessor: d => d.productName,
        filterMethod: (filter: Filter, rows: any[]) =>
          matchSorter(rows, filter.value, {
            keys: ["productName"]
          }),
        filterAll: true
      },
      {
        Header: "Product Name",
        id: "name",
        accessor: d => d.name,
        filterMethod: (filter: Filter, rows: any[]) =>
          matchSorter(rows, filter.value, {
            keys: ["name"]
          }),
        filterAll: true
      },
      {
        Header: "Product Category",
        id: "productCategory",
        accessor: d => d.productCategory,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="">Show All</option>
            <option value="Flower">Flower</option>
            <option value="Edible">Edible</option>
            <option value="Concentrate">Concentrate</option>
            <option value="Topical">Topical</option>
          </select>
        )
      },
      {
        Header: "PNG",
        maxWidth: 50,
        filterable: false,
        sortable: false,
        Cell: ({ row }) => (
          <div>
            {this.state.downloadsDisabled === false &&
            row._original.active === true &&
            typeof row._original.latestLot !== "undefined" &&
            row._original.latestLot !== null &&
            typeof row._original.latestLot.testResult !== "undefined" &&
            row._original.latestLot.testResult !== null ? (
              // <a onClick={() => this.downloadPng(row._original.latestLot._id)}>
              //   PNG
              // </a>
              <CannastampDownloader
                downloadName={
                  "cannastamp-" +
                  Math.round(Math.random() * 1000) +
                  "-batch-" +
                  row._original.latestLot.testResult.tracabilityParentLotId
                }
                svgId={
                  "cannastamp-test-id-" + row._original.latestLot.testResult._id
                }
              />
            ) : null}
          </div>
        )
      },
      {
        Header: "Hang Tag",
        maxWidth: 50,
        filterable: false,
        sortable: false,
        Cell: ({ row }) => (
          <div style={{ fontSize: 12 }}>
            {this.state.downloadsDisabled === false &&
            row._original.active === true &&
            typeof row._original.latestLot !== "undefined" &&
            row._original.latestLot !== null &&
            typeof row._original.latestLot.testResult !== "undefined" &&
            row._original.latestLot.testResult !== null ? (
              <a onClick={() => this.setState({ printQue: row._original })}>
                PDF
              </a>
            ) : null}
          </div>
        )
      },
      {
        Header: "Link",
        maxWidth: 75,
        id: "producerBrandName",
        filterable: false,
        sortable: false,
        Cell: ({ row }) =>
          row._original.producer !== null ? (
            <div>
              <a
                href={
                  "/client/" +
                  row._original.producer +
                  "/manageProduct/" +
                  row._original._id
                }
              >
                View Product
              </a>
            </div>
          ) : (
            <div>Dead Product</div>
          )
      },
      {
        Header: "Available",
        id: "available",
        accessor: d => {
          return typeof d.available === "undefined" ? false : d.available;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="">Show All</option>
            <option value="true">Available</option>
            <option value="false">Unavailable</option>
          </select>
        ),
        Cell: ({ row }) => (
          <div>
            <p>
              {row._original.available === true ? "Available" : "Unavailable"}
            </p>
          </div>
        )
      }
    ];
    if (adminColumns !== null) {
      standardColumns.unshift(adminColumns);
    } else {
      standardColumns.unshift(bulkColumns);
    }

    if (this.props.productList === null) {
      return <div>Empty Lot List</div>;
    } else if (this.props.changeOwner) {
      return (
        <div style={{ paddingTop: 80 }}>
          <ReactTable
            data={this.props.productList}
            filterable
            pages={this.props.pages}
            manual
            onFetchData={this.props.fetchData}
            loading={this.props.loading}
            columns={standardColumns}
            SubComponent={row => {
              if (this.props.changeOwner) {
                return (
                  <div style={{ padding: "20px" }}>
                    <em>
                      Will include a subtable for product lots
                      <ProductOwnerSelect
                        initiallyDisabled={true}
                        availableClients={this.props.clientList}
                        token={this.props.token}
                        product={row.original}
                        reloadParent={this.props.reloadParent} // should be a function
                      />
                    </em>
                    <br />
                    <br />
                  </div>
                );
              } else {
                return null;
              }
            }}
            defaultPageSize={10}
            className="-striped -highlight"
            getTrProps={(_: any, rowInfo: RowInfo) => {
              const style =
                typeof rowInfo !== "undefined" && !rowInfo.original.active
                  ? { background: BrandColor.Red }
                  : {};
              return {
                style
              };
            }}
          />
          <br />
        </div>
      );
    } else {
      return (
        <div style={{ paddingTop: 0 }}>
          <div
            className={
              this.state.printQue !== false
                ? "cb_grid-item--9"
                : "cb_grid-item--12"
            }
          >
            <div>
              Show Deleted
              <Switch
                checked={this.props.showDeleted}
                onChange={this.props._showDeleted}
              />
            </div>
            <ReactTable
              data={this.props.productList}
              filterable
              columns={standardColumns}
              defaultPageSize={10}
              className="-striped -highlight"
              getTrProps={(_: any, rowInfo: RowInfo) => {
                const style =
                  typeof rowInfo !== "undefined" && !rowInfo.original.active
                    ? { background: BrandColor.Red }
                    : {};
                return {
                  style
                };
              }}
            />
            <div>
              <StyledBulkRow1>
                <div>
                  <span>Bulk Edit</span>
                </div>
                <div>
                  <Select
                    isSearchable={false}
                    options={[
                      { value: "setAvailable", label: "Set available" },
                      { value: "setUnavailable", label: "Set unavailable" },
                      { value: "delete", label: "Delete selected" },
                      { value: "distribute", label: "Distribute to" }
                    ]}
                    onChange={this.onBulkChange}
                    value={this.state.bulkAction}
                  />
                </div>
                <StyledBulkMessage>
                  <span>{this.state.bulkMessage}</span>
                </StyledBulkMessage>
              </StyledBulkRow1>
              {this.bulkEditView()}
            </div>
          </div>
          <div className="cb_grid-item--3">
            <MerchandisingCardPrinter
              clientId={this.props.match.params.clientId}
              token={this.props.token}
              newCardProp={this.state.printQue}
            />
          </div>
        </div>
      );
    }
  }

  toggleRow(id: string) {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id] = !this.state.selected[id];
    this.setState({
      selected: newSelected,
      selectAll: 2
    });
  }

  toggleSelectAll() {
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.props.productList.forEach(x => {
        newSelected[x._id] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0
    });
  }

  bulkEditView() {
    if (this.state.bulkAction === "delete") {
      return (
        <StyledBulkRow2>
          <span>Are you sure you want to delete these products?</span>
          <StyledBulkButton onClick={this.bulkDelete}>Delete</StyledBulkButton>
        </StyledBulkRow2>
      );
    } else if (this.state.bulkAction === "distribute") {
      return (
        <StyledBulkRow2>
          <Select
            autoFocus
            options={this.state.clientList}
            onChange={this.onDistributeChange}
            placeholder="Select a dispensary to distribute to..."
            // value={this.state.distributeTo}
          />
          <StyledBulkButton onClick={this.bulkDistribute}>
            Distribute
          </StyledBulkButton>
        </StyledBulkRow2>
      );
    } else {
      return null;
    }
  }

  onBulkChange(action: any) {
    let products: string[] = [];
    Object.keys(this.state.selected).map(p => {
      if (this.state.selected[p]) {
        products.push(p);
      }
    });
    if (!products.length) {
      action = "";
    }
    switch (action.value) {
      case "setAvailable":
        this.bulkAvailability(true, products);
        break;
      case "setUnavailable":
        this.bulkAvailability(false, products);
        break;
      case "delete":
        this.setState({
          bulkAction: "delete",
          bulkMessage: "",
          bulkProducts: products
        });
        break;
      case "distribute":
        ClientService.loadDispensariesKeyPair().then(
          results => {
            const options = results.map((dispensary: EntityInterface) => {
              return {
                label: dispensary.name + " - " + dispensary.licenseNumber,
                value: dispensary._id
              };
            });
            this.setState({
              bulkAction: "distribute",
              bulkMessage: "",
              bulkProducts: products,
              clientList: options
            });
          },
          error => {
            console.warn(error);
          }
        );
        break;
      default:
        this.setState({
          bulkAction: "",
          bulkMessage: "Please select a product to edit."
        });
    }
  }

  bulkAvailability(available: boolean, products: string[]) {
    const {
      match: {
        params: { clientId }
      },
      token
    } = this.props;

    let queue = async.queue((product: string, callback) => {
      ClientService.saveProduct(
        token,
        clientId,
        JSON.stringify({ available: available }),
        product
      ).then(() => callback());
    }, ASYNC_LIMIT);
    queue.drain = function(this: any) {
      this.setState(
        {
          bulkAction: "",
          bulkMessage:
            "Marked " +
            products.length +
            " products as " +
            (available ? "available." : "unavailable."),
          selectAll: 0,
          selected: {}
        },
        () => {
          this.props.getProducts();
        }
      );
    }.bind(this);
    queue.push(products);
  }

  bulkDelete() {
    const {
      match: {
        params: { clientId }
      },
      token
    } = this.props;
    const { bulkProducts } = this.state;

    let queue = async.queue((product, callback) => {
      ClientService.deleteProduct(token, clientId, product).then(() =>
        callback()
      );
    }, ASYNC_LIMIT);
    queue.drain = function(this: any) {
      this.setState(
        {
          bulkAction: "",
          bulkMessage: "Deleted " + bulkProducts.length + " products.",
          bulkProducts: undefined,
          selectAll: 0,
          selected: {}
        },
        () => this.props.getProducts()
      );
    }.bind(this);
    queue.push(bulkProducts);
  }

  bulkDistribute() {
    const {
      match: {
        params: { clientId }
      },
      token
    } = this.props;
    const { bulkProducts } = this.state;
    const distribution = { dispensary: this.state.distributeTo };

    let queue = async.queue((product: string, callback) => {
      ClientService.saveProduct(
        token,
        clientId,
        JSON.stringify({
          available: true,
          distribution
        }),
        product
      ).then(() => callback());
    }, ASYNC_LIMIT);
    queue.drain = function(this: any) {
      this.setState(
        {
          bulkAction: "",
          bulkMessage: "Distributed " + bulkProducts.length + " products.",
          bulkProducts: undefined,
          selectAll: 0,
          selected: {}
        },
        () => this.props.getProducts()
      );
    }.bind(this);
    queue.push(bulkProducts);
  }

  onDistributeChange(e: any) {
    this.setState({ distributeTo: e.value });
  }

  render() {
    if (this.props.productList === null) {
      return <MasterPageSpinner />;
    }
    return this.tableView();
  }
}

export default AuthenticationHOC()(ProductList);

export const StyledBulkRow1 = styled.div`
  display: grid;
  grid-template: 45px / auto 2fr 2fr;
  grid-gap: 15px;
  margin: 15px 0;

  @media (max-width: 768px) {
    grid-template: 45px / auto 1fr;
  }

  & span {
    line-height: 45px;
  }
`;

export const StyledBulkRow2 = styled(StyledBulkRow1)`
  grid-template: 45px / 2fr 1fr;

  @media (max-width: 768px) {
    grid-template: 45px / 3fr 1fr;
  }

  & span {
    line-height: 45px;
  }
`;

export const StyledBulkButton = styled.button`
  border: 1px solid #01a2a6;
  border-radius: 3px;
  color: #0f252c;
`;

export const StyledBulkMessage = styled.div`
  @media (max-width: 768px) {
    grid-column: span 2;
  }
`;
