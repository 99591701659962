import React, { Component } from 'react';

class TermsOfService extends Component {
	componentDidMount() {
		const script = document.createElement('script');
	  script.src = "//js.hsforms.net/forms/shell.js";
	  document.body.appendChild(script);

	  script.addEventListener('load', () => {
		  if(window.hbspt) {
			window.hbspt.forms.create({
			  portalId: '2928682',
			formId: 'f3ddb1ac-23c8-4014-a5b2-eccdba5132e8',
			target: '#hubspotForm'
		  })
		}
	  });
	}

    render() {
        return (<div>
			  		<div className="hubspot-form-container cb_blank-page-bumper">

					<h1 className="cb_heading--1">Welcome Investors!</h1>
  		  <div id="hubspotForm"></div>
  		</div>
        </div>);

    }

}

export default TermsOfService;
