import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AssessmentSharp from "@material-ui/icons/AssessmentSharp";

const styles = theme => ({
  card: {
    maxWidth: 400,
    marginTop: 10
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "orange"
  },
  icon: {
    float: "left",
    paddingRight: 5,
    fontSize: 70
  },
  cardHeader: {
    fontSize: 20
  }
});

class WhatsACOA extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              C
            </Avatar>
          }
          titleTypographyProps={{ variant: "h5" }}
          subheaderTypographyProps={{ variant: "h8" }}
          title="COAs"
          subheader="What's a COA?"
        />
        <CardMedia
          className={classes.media}
          image="/assets/images/client/welcome/coa-image.jpg"
          title="A Lab Report"
        />
        <CardContent>
          <AssessmentSharp className={classes.icon} />
          <Typography component="p" className={classes.cardContent}>
            <b>COA's</b> are the test data associated with your products. These
            tests may be required by regulation, or you may have sourced them
            independently.
            <br />
            <br />
            <b>Cannabinder requires Terpene and Cannabinoid data</b>!
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

WhatsACOA.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WhatsACOA);
