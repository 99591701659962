export enum AlphaColor {
  VeryLightGray_75 = "rgba(250, 250, 250, 0.75)"
}
export enum BorderRadius {
  Small = 3,
  Medium = 5,
  Large = 10,
  XLarge = 15
}
export enum BrandColor {
  Blue = "#0078d7",
  Blue2 = "#5774d8",
  Cyan = "#6CEAF3",
  Magenta = "#FC2D9A",
  Lime = "#DFF333",
  Green = "#9CF255",
  Pink = "#ed6bd3",
  Indigo = "#5774D8",
  Violet = "#8246DB",
  Red = "#ff332d",
  Orange = "#ff7f37"
}
export enum BrandGradient {
  Main = "linear-gradient(101deg, #dff333 0%, #dff333 15%, #9bf254 15%, #9bf254 28%, #6ceaf3 28%, #6ceaf3 48%, #5774d8 48%, #5774d8 55%, #8255de 55%, #8245de 59%, #ed6bd3 59%, #ed6bd3 79%, #ff332d 79%, #ff332d 88%, #ff7f37 88%, #ff7f37 100%);"
}
export enum Color {
  White = "#FFFFFF",
  Black = "#000000",
  VeryLightGray = "#FAFAFA",
  LightGray = "#F0F0F0",
  DarkGray = "#607d8b",
  BlueGray = "#86b7dd",
  DarkBlueGray = "#00598B",
  InputOutline = "#919191",
  InputBackground = "#f6f6f6",
  PrimaryText = "#333"
}
// This is the timeout in ms to indicate the user is no longer using the app
// default: 45000 = 45s
export const GLOBAL_IDLE_TIMEOUT = 450000;
export const GLOBAL_DEBOUNCE = 350;
export enum ProductCategoryNames {
  flower = "Flower",
  edible = "Edible",
  extract = "Extract",
  topical = "Topical"
}
export function toPercentage(input: number): string {
  return `${input}%`;
}
export function toPixel(input: number): string {
  return `${input}px`;
}
export function validKioskPin(input: string): string {
  return input.split("").filter(letter => letter.match(/\d/)).slice(0, 4).join("");
}
export function isValidKioskPin(input: string): boolean {
  return !!input.match(/[0-9]{4}/);
}
