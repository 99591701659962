import { oAuthMode } from "../configurator";
const remoteConfig = oAuthMode(window.location.href);
const baseAPI = remoteConfig.baseUrl + "client";

export const ClientService = {
  validateUserOnEntity(user, entityId, token) {
    let body = { user, entityId, token };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + entityId + `/validateUserOnEntity/`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneById(productId, token, entityId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseAPI}/` + token + `/` + entityId + `/product/getById/` + productId
      )
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getMultipleById(productIds, token, entityId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + entityId + `/product/getAllById/`, {
        method: "POST",
        body: JSON.stringify(productIds),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getTestResultById(productId, token, entityId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + entityId + `/testResult/` + productId)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  loadProductTypes() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/productTypeList`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  loadProductStrains() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/strainList`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  loadClientList() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/clientList`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  loadBrandKeyPair(entityId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/client/` + entityId + `/keypairs/brands`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  loadLabsKeyPair() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/client/keypairs/labs`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  loadProducersKeyPair() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/client/keypairs/producers`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  loadDispensariesKeyPair() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/client/keypairs/dispensaries`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  loadKioskKeyPair(token, clientId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/kiosk/products`)
        .then(response => {
          if (response.status !== 200) {
            throw new Error(response);
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getDispensaryDistributions(token, clientId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/distributions/products/`)
        .then(response => {
          if (response.status !== 200) {
            throw new Error(response);
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getDispensaryInventory(token, clientId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseAPI}/` +
          token +
          `/` +
          clientId +
          `/distributions/products/approved/`
      )
        .then(response => {
          if (response.status !== 200) {
            throw new Error(response);
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  // handles both new products with POST and updated products with PUT
  saveProduct(token, clientId, formData, id = "") {
    let method = "post";
    if (id !== "") {
      method = "PUT";
    }
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    if (formData instanceof FormData) {
      headers = {
        Accept: "application/json"
      };
    }
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/product/` + id, {
        method: method,
        body: formData,
        headers
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the product");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteProduct(token, clientId, id) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/product/` + id, {
        method: "DELETE",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to delete the product");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * Create a test results and its lot with this promise. Can create
   * and update test results with one promise by including an ID you form a PUT request
   * @param {*} token users authentication token
   * @param {*} clientId mongo id of the client that the test result is being created by
   * @param {*} formData actual test result data
   * @param {*} id optional ID that can be passed to create a PUT request
   */
  saveTestResult(token, clientId, formData, id = null) {
    let method = "post";
    if (id !== null) {
      method = "PUT";
    }
    id = id !== null ? id : "";
    let fetchUrl = `${baseAPI}/` + token + `/` + clientId + `/testResult/` + id;
    return new Promise((resolve, reject) => {
      fetch(fetchUrl, {
        method: method,
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the test result");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * Users with premium accounts can use this route to save test results
   * @param {*} token users authentication token
   * @param {*} clientObject mongo id of the client that the test result is being created by
   * @param {*} formData actual test result data
   * @param {*} id optional ID that can be passed to create a PUT request
   */
  premiumSaveTestResult(token, clientId, formData) {
    let fetchUrl =
      `${baseAPI}/` + token + `/` + clientId + `/testResult/premium`;
    return new Promise((resolve, reject) => {
      fetch(fetchUrl, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the test result");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * saves a product lot from formData
   * @param {*} token token of the user conducting the request
   * @param {*} clientId id of client being accessed
   * @param {*} lotObject data being used to edit/create the lot
   * @param {*} id id of the productLot being updated, if it already exsists
   */
  saveProductLot(token, clientId, lotObject, id = false) {
    let method = "PUT";
    if (id === false) {
      id = "";
      method = "POST";
    }
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/lot/` + id, {
        method: method,
        body: JSON.stringify(lotObject),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the product lot");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteLot(token, clientId, id) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/lot/` + id, {
        method: "DELETE",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the product lot");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  claimCompany(token, formData, id = false) {
    let method = "PUT";
    if (id === false || id === "" || typeof id === "undefined" || id === null) {
      id = "";
      method = "POST";
    }
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/claim-entity/` + id, {
        method: method,
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to create the client identity");
          }
          return response.json(response);
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Checks for the optional id param and fires a PUT request if it is present, POST if not.
   * @param {string} token the users authentication token
   * @param {object} formData the form data coming in and being sent
   * @param {string} id the id of the entity that is being updated, if it is an update
   */
  entiyPut(token, formData, id) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/entity/` + id, {
        method: "PUT",
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to create the client identity");
          }
          return response.json(response);
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Fetches an entities data with a GET promise
   * @param {*} token The token of the logged in user for authorization checks
   * @param {*} id The id of the entity that is being retrieved
   */
  getEntityData(token, id) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/entity/` + id, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "the server failed to fetch the client entity data"
            );
          }
          return response.json(response);
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Fetches an entities data with a GET promise
   * @param {*} token The token of the logged in user for authorization checks
   * @param {*} id The id of the entity that is being retrieved
   */
  getEntityProductCount(token, entityId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + entityId + `/products/count`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "the server failed to fetch the client entity data"
            );
          }
          return response.json(response);
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Fetches an entities data with a GET promise
   * @param {*} token The token of the logged in user for authorization checks
   * @param {*} id The id of the entity that is being retrieved
   */
  getEntityTestResultCount(token, entityId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + entityId + `/testResults/count`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "the server failed to fetch the client entity data"
            );
          }
          return response.json(response);
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Fetches an entities data with a GET promise
   * @param {*} token The token of the logged in user for authorization checks
   * @param {*} id The id of the entity that is being retrieved
   */
  getEntityBrandCount(token, entityId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + entityId + `/brands/count`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "the server failed to fetch the client entity data"
            );
          }
          return response.json(response);
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Fetches all Brands for a given clientId
   * @param {string} token  token of the user conducting the request
   * @param {string} clientId id of client being accessed
   * @param {string} productId id of the product being accessed
   */
  getClientBrands(token, clientId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/brands/`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to fetch the brand list");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  saveBrand(token, clientId, formData, id = "") {
    let method = "post";
    if (id !== "") {
      method = "PUT";
    }
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/brand/` + id, {
        method: method,
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the brand");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getBrandById(brandId, token, entityId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + entityId + `/brand/` + brandId)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * Fetches all product lots for a given productId
   * @param {string} token  token of the user conducting the request
   * @param {string} clientId id of client being accessed
   * @param {string} productId id of the product being accessed
   */
  getClientProductLots(token, clientId, productId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseAPI}/` +
          token +
          `/` +
          clientId +
          `/product/` +
          productId +
          `/lots`,
        {
          method: "GET",
          headers: {
            Accept: "application/json"
          }
        }
      )
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "the server failed to get the client product lot list"
            );
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Fetches all product lots for a given client
   * @param {string} token  token of the user conducting the request
   * @param {string} clientId id of client being accessed
   * @param {string} productId id of the product being accessed
   */
  getAllClientLots(token, clientId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/lots`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to get all client lots");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  // handles both new productLots with POST and updated productLots with PUT
  getClientProducts(token, clientId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/products`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to fetch the client products");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  // TODO add in clientId ?
  getSimilarClientProducts(token, entityId, testResultId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseAPI}/${token}/${entityId}/similar/atLocation/${testResultId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json"
          }
        }
      )
        .then(response => {
          if (response.status !== 200) {
            throw new Error(
              "The server failed to fetch similar client products"
            );
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * Fetches all test results for a given productId
   * @param {string} token  token of the user conducting the request
   * @param {string} clientId id of client being accessed
   */
  getClientTestResults(token, clientId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/testResults/`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to load the test results");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  createPrintJob(token, clientId, printJob) {
    var body = {
      printJob
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/templateGenerator`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  printProductCards(token, clientId, products) {
    var body = {
      products
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/print/product-cards`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  getCannastampPdf(token, clientId, lotId, type, dimension1, dimension2) {
    var body = {
      lotId,
      type,
      dimension1,
      dimension2
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/` + token + `/` + clientId + `/getCannastampPdf`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getLotPng(token, clientId, lotId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseAPI}/` + token + `/` + clientId + `/lot/` + lotId + `/png/`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  manageDispensaryDistribution(token, clientId, id, status) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${baseAPI}/` +
          token +
          `/` +
          clientId +
          `/product/` +
          id +
          `/distribution/setstatus/` +
          status,
        {
          method: "GET",
          headers
        }
      )
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the product");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default ClientService;
