import React, { Component } from "react";
import PropTypes from "prop-types";

class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      deleteMessage: "Delete"
    };
  }

  delete = () => {
    if (this.state.confirmDelete === true) {
      this.props.removeJobFromParent(this.props.jobId);
    } else {
      this.setState({ deleteMessage: "Confirm?" });
    }
  };

  render() {
    return (
      <div style={{ width: 125 }}>
        <span>{this.props.sourceLot.s2sId}</span>
        <span>{this.props.targetLotS2sId}</span>
        <span>{this.props.targetClient.name}</span>
        <span>{this.props.stampCount}</span>
        <span>{this.props.printTemplate}</span>
        <span
          onClick={this.delete}
          style={{
            backgroundColor: this.state.confirmDelete ? "red" : "white"
          }}
        >
          {this.state.deleteMessage}
        </span>
      </div>
    );
  }
}

Job.propTypes = {
  jobId: PropTypes.number,
  sourceLot: PropTypes.object,
  targetLotS2sId: PropTypes.string,
  targetClient: PropTypes.object,
  printTemplate: PropTypes.string,
  stampCount: PropTypes.number,
  removeJobFromParent: PropTypes.func
};

export default Job;
