import React, { Component } from "react";
import PropTypes from "prop-types";
import BrandForm from "./clientComponents/brand/BrandForm";
import { clientApiHOCProps } from "./clientComponents/generic/ClientApiHOC";
import ClientServices from "../apiService/ClientService";

class ManageBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: false
    };
    if (typeof this.props.match.params.brandId !== "undefined") {
      let brandId = this.props.match.params.brandId;
      let clientId = this.props.match.params.clientId;
      ClientServices.getBrandById(brandId, props.token, clientId).then(json => {
        this.setState({ brand: json });
      });
    }
  }

  render() {
    if (
      typeof this.props.match.params.brandId !== "undefined" &&
      this.state.brand === false
    ) {
      return <div>Loading Data</div>;
    } else {
      return (
        <div>
          <div className="cb_account__content">
            <div className="cb_account__content-header">
              <ul className="cb_breadcrumb cb_breadcrumb--title">
                <li className="cb_breadcrumb__active">
                  <a className="cb_breadcrumb__link">Add/Edit Brands</a>
                </li>
                <li className="cb_breadcrumb__delimiter">
                  <i className="fal fa-arrow-right" />
                </li>
              </ul>
              <div className="cb_account__content-header-message">
                <p />
              </div>
            </div>
            <div className="cb_account__content-content">
              <BrandForm
                brandId={this.props.match.params.brandId}
                brand={this.state.brand}
                parentLots={this.state.parentLots}
                clientId={this.props.match.params.clientId}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

ManageBrand.propTypes = {
  match: {
    params: {
      brandId: PropTypes.string,
      clientId: PropTypes.string
    }
  }
};

export default ManageBrand;
