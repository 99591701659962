import React, { Component } from "react";
import Select from "react-select";
import formTools from "./clientComponents/generic/formTools";
import MenuList from "../components/generics/MenuList";
import YouTube from "react-youtube";

class TrainingPage extends Component {
  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__active">
                <a className="cb_breadcrumb__link">Cannabinder Training</a>
              </li>
              <li className="cb_breadcrumb__delimiter">
                <i className="fal fa-arrow-right" />
              </li>
              <li className="cb_breadcrumb__current" />
            </ul>
            <div className="cb_account__content-header-message">
              <p />
            </div>
          </div>
          <div className="cb_account__content-content">
            <div className="cb_article">
              <h4 className="cb_article__title">
                How To Use Cannabinder With Representative Terpene Testing
              </h4>
              <div className="cb_article__content">
                <YouTube videoId="PjnMDwWPZnk" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TrainingPage.propTypes = {};

export default TrainingPage;
