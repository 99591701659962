import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MessagePhoneDemo from "./MessagePhoneDemo";

export interface LoyaltyCardProps {
  avatar: string | JSX.Element;
  avatarColor: string;
  body: string | JSX.Element;
  cardColor: string;
  images: string[];
  onAction?: (id: any) => void;
  subHeader?: string | JSX.Element;
  title?: string | JSX.Element;
  chips?: JSX.Element[];
  mmsDemo?: {
    text: string;
    images: string[];
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatar: {
      backgroundColor: red[500]
    }
  })
);

const LoyaltyCardNew: React.FC<LoyaltyCardProps> = ({
  avatar,
  avatarColor,
  images,
  title,
  subHeader,
  chips,
  body,
  mmsDemo
}) => {
  const classes = useStyles({});
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            style={{ backgroundColor: avatarColor }}
          >
            {avatar}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={title}
        subheader={subHeader}
      />
      <CardMedia className={classes.media} image={images[0]} />
      <CardContent>
      {chips &&
          chips.map(chip => {
            return chip;
          })}
        <Typography variant="body2" color="textSecondary" component="p">
          {body}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        {mmsDemo && (
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
        
      </CardActions>
      {mmsDemo && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Message Preview</Typography>
            <MessagePhoneDemo
              messageContent={[
                {
                  text: mmsDemo.text,
                  images: mmsDemo.images
                }
              ]}
            />
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};

export default LoyaltyCardNew;
