import * as React from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  Chip,
  Checkbox
} from "@material-ui/core";
import MessagePhoneDemo from "./MessagePhoneDemo";
import {
  EntityDocumentInterface,
  LoyaltyPointOfferInterface,
  ProductInterface
} from "cbd-api-types";
import { createPointsOffer } from "../../../apiService/LoyaltyService";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import ClientService from "../../../apiService/ClientService";
import AwsImageUpload from "../generic/AwsImageUpload";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Color } from "../../../kiosk/kioskUtilities/constants";

export interface IPointOfferCreateProps extends WithStyles<typeof styles> {
  clientData: EntityDocumentInterface;
  token: string;
  onComplete: () => void;
}

export interface IPointOfferCreateState extends LoyaltyPointOfferInterface {
  activeImages: string[];
  allProducts: boolean;
  errorMessage?: string;
  imageShuffleLocation: number;
  messageBody: string;
  productsMap: Map<string, ProductInterface>;
  products: ProductInterface[];
  sendDate: Date;
  shuffle: boolean;
  selectedProducts: string[];
  saveDisabled: boolean;
  totalPointsRestriction: boolean;
}

const styles = createStyles({
  chip: {
    margin: ".2rem .1rem"
  },
  chipContainer: {
    backgroundColor: Color.VeryLightGray
  },
  formControl: {
    minWidth: 120
  },
  input: {},
  labelShrink: {
    transform: "translate(14px, 20px) scale(1) !important"
  }
});

class PointOfferCreate extends React.Component<
  IPointOfferCreateProps,
  IPointOfferCreateState
> {
  constructor(props: IPointOfferCreateProps) {
    super(props);

    this.state = { ...this.defaultState };

    ClientService.getClientProducts(props.token, props.clientData._id).then(
      (results: ProductInterface[]) => {
        const productsMap = new Map<string, ProductInterface>();
        results.map(product => productsMap.set(product._id, product));
        this.setState({ products: results, productsMap });
      }
    );
  }

  private defaultState: IPointOfferCreateState = {
    activeImages: [],
    allProducts: false,
    basePoints: 50,
    totalPoints: 5000,
    calculationMethod: "basePoints",
    client: this.props.clientData,
    endDate: moment()
      .add(1, "d")
      .toDate(),
    errorMessage: undefined,
    imageShuffleLocation: null,
    messageBody: `${
      this.props.clientData.loyaltyConfig.customWarningText
        ? this.props.clientData.loyaltyConfig.customWarningText
        : this.props.clientData.loyaltyConfig.defaultWarningText
    }`,
    pointOfferType: "checkIn",
    products: [],
    productsMap: new Map<string, ProductInterface>(),
    sendDate: null,
    sendMessage: false,
    shuffle: false,
    startDate: moment().toDate(),
    status: null,
    selectedProducts: [],
    saveDisabled: false,
    totalPointsRestriction: false
  };

  private savePointsOffer = () => {
    const { clientData, token } = this.props;
    const {
      activeImages,
      basePoints,
      totalPoints,
      calculationMethod,
      client,
      endDate,
      messageBody,
      selectedProducts,
      sendMessage,
      startDate,
      totalPointsRestriction
    } = this.state;
    let { pointOfferType } = this.state;
    if (basePoints <= 0) {
      return this.setState({
        errorMessage: "You must choose the number of points to award"
      });
    }

    const thePointsOffer: LoyaltyPointOfferInterface = {
      basePoints,
      totalPoints: totalPointsRestriction ? undefined : totalPoints,
      calculationMethod,
      client,
      endDate,
      productSpecific: (selectedProducts as unknown[]) as ProductInterface[],
      pointOfferType,
      sendMessage,
      startDate,
      status: moment(startDate).isSame(Date.now(), "day")
        ? "active"
        : "inactive",
      mmsMessage: messageBody,
      mmsImage: activeImages
    };
    this.setState({ saveDisabled: true }, () => {
      createPointsOffer(token, clientData._id, thePointsOffer)
        .then((response: any) => {
          console.log(response);
          if (response.userMessage) {
            this.setState({
              errorMessage: response.userMessage
            });
          } else {
            this.setState({ ...this.defaultState });
            this.props.onComplete();
          }
        })
        .catch(failed => {
          this.setState({ saveDisabled: false, errorMessage: failed });
          console.warn(failed);
        });
    });
  };

  private handlePointOfferType = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    this.setState({
      pointOfferType: event.target
        .value as LoyaltyPointOfferInterface["pointOfferType"]
    });
  };

  private handleAllProducts = (_: any, checked: boolean) => {
    if (checked === true) {
      this.setState({
        allProducts: checked,
        selectedProducts: this.state.products.map(product => product._id)
      });
    } else {
      this.setState({ allProducts: checked });
    }
  };

  private setImage = (files: string[]) => {
    if (files) {
      this.setState({
        activeImages: files,
        imageShuffleLocation: 0
      });
    } else {
      this.setState({
        activeImages: null,
        imageShuffleLocation: null
      });
    }
  };

  private setMessageText = (userText: string) => {
    const { loyaltyConfig } = this.props.clientData;
    let textout = `${userText}\n${
      loyaltyConfig.customWarningText
        ? loyaltyConfig.customWarningText
        : loyaltyConfig.defaultWarningText
    }`;
    this.setState({ messageBody: textout });
  };

  private handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // returns the results set as []
    console.log(event.target.value);
    this.setState({ selectedProducts: event.target.value as string[] });
  };

  public render() {
    const { classes } = this.props;
    const {
      activeImages,
      allProducts,
      basePoints,
      totalPoints,
      endDate,
      errorMessage,
      messageBody,
      pointOfferType,
      products,
      selectedProducts,
      startDate,
      sendMessage,
      saveDisabled
    } = this.state;

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Create a new Points Offer
        </Typography>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="subtitle1" gutterBottom>
              Type
            </Typography>
            <Select
              value={pointOfferType}
              onChange={this.handlePointOfferType}
              input={
                <OutlinedInput
                  name="type"
                  id="point-offer-type"
                  labelWidth={0}
                />
              }
            >
              <MenuItem
                style={{ padding: 5, display: "block" }}
                value={"checkIn"}
              >
                <ListItemText primary="Phone Check-In" />
              </MenuItem>
              <MenuItem
                style={{ padding: 5, display: "block" }}
                value={"purchase"}
              >
                <ListItemText primary="Product Checkin" />
              </MenuItem>
              <MenuItem
                style={{ padding: 5, display: "block" }}
                value={"general"}
              >
                <ListItemText primary="All Products/Codes" />
              </MenuItem>
            </Select>
            <Typography variant="subtitle1" gutterBottom>
              From
            </Typography>
            <TextField
              className={classes.input}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              value={moment
                .max([moment(startDate), moment()])
                .format("YYYY-MM-DD")}
              required
              onChange={e =>
                this.setState({
                  startDate: moment(e.target.value)
                    .hour(0)
                    .second(0)
                    .millisecond(0)
                    .toDate()
                })
              }
            />
            <Typography variant="subtitle1" gutterBottom>
              To
            </Typography>
            <TextField
              className={classes.input}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              value={moment
                .max([moment(endDate), moment(startDate).add(1, "d")])
                .format("YYYY-MM-DD")}
              required
              onChange={e =>
                this.setState({
                  endDate: moment(e.target.value)
                    .hour(0)
                    .second(0)
                    .millisecond(0)
                    .toDate()
                })
              }
            />
            <Typography variant="subtitle1" gutterBottom>
              Points Awarded
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ min: 0, step: 50 }}
              value={basePoints}
              onChange={e =>
                this.setState({
                  basePoints: (e.target.value as unknown) as number
                })
              }
            />
            <Typography variant="subtitle1" gutterBottom>
              Restrict Total Points Available
              <Checkbox
                onClick={() =>
                  this.setState({
                    totalPointsRestriction: !this.state.totalPointsRestriction
                  })
                }
              />
            </Typography>
            {this.state.totalPointsRestriction && (
              <TextField
                className={classes.input}
                type="number"
                disabled={!this.state.totalPointsRestriction}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ min: 0, step: 50 }}
                value={totalPoints}
                onChange={e =>
                  this.setState({
                    totalPoints: (e.target.value as unknown) as number
                  })
                }
              />
            )}

            {pointOfferType === "purchase" && (
              <Grid container direction="column">
                <FormControlLabel
                  className={classes.input}
                  control={
                    <Checkbox
                      color="default"
                      checked={allProducts}
                      onChange={this.handleAllProducts}
                    />
                  }
                  label={
                    <Typography variant="subtitle1" gutterBottom>
                      All Products
                    </Typography>
                  }
                />
                {!!products.length && (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel
                      htmlFor="selectProducts"
                      classes={{ shrink: classes.labelShrink }}
                    >
                      {"Select Products"}
                    </InputLabel>
                    <Select
                      id="product-select-list"
                      className={classes.input}
                      disabled={allProducts}
                      multiple
                      onChange={this.handleChange}
                      value={selectedProducts}
                      renderValue={element => ""}
                      input={
                        <OutlinedInput
                          name="products"
                          id="selectProducts"
                          notched={false}
                          labelWidth={0}
                        />
                      }
                    >
                      {products.map((product, ind) => (
                        <MenuItem
                          style={{ padding: 5, display: "block" }}
                          key={ind}
                          value={product._id}
                        >
                          <Checkbox
                            checked={selectedProducts.indexOf(product._id) > -1}
                          />
                          <ListItemText primary={product.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!!selectedProducts.length && (
                  <Grid container className={classes.chipContainer}>
                    {selectedProducts.map(productId => (
                      <Chip
                        className={classes.chip}
                        key={productId}
                        label={
                          this.state.productsMap.get(productId) &&
                          this.state.productsMap.get(productId).name
                        }
                      />
                    ))}
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container direction="column">
              <FormControlLabel
                className={classes.input}
                control={
                  <Checkbox
                    color="default"
                    value={sendMessage ? "checked" : ""}
                    onChange={(_, checked) =>
                      this.setState({ sendMessage: checked })
                    }
                  />
                }
                label={
                  <Typography variant="button" gutterBottom>
                    Send Text Message
                  </Typography>
                }
              />
              {sendMessage && (
                <FormGroup style={{ marginBottom: 20 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Text Body
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    The text that will be sent with your message
                  </Typography>
                  <textarea
                    className={classes.input}
                    name="messageBody"
                    id="messageBody"
                    onChange={e => {
                      this.setMessageText(e.target.value);
                    }}
                  />
                  <Typography variant="subtitle1" gutterBottom>
                    Image Select*
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    Try a transparent background!
                  </Typography>
                  <AwsImageUpload
                    onChange={this.setImage}
                    currentFiles={null}
                    multiple={true}
                    name={"messageMedia"}
                    accepted="image/png, image/jpeg"
                    token={this.props.token}
                    clientId={this.props.clientData._id}
                  />
                </FormGroup>
              )}
            </Grid>
            {!saveDisabled && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={this.savePointsOffer}
              >
                <i className="fal fa-comment-dots" />
                <div style={{ paddingLeft: 8 }}>{"Save Points Offer"}</div>
              </Button>
            )}
            <br />
            {typeof errorMessage !== "undefined" && (
              <Typography variant="caption" gutterBottom>
                {errorMessage}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {sendMessage && (
              <MessagePhoneDemo
                messageContent={[
                  {
                    text: messageBody,
                    images: activeImages,
                    key: "1"
                  }
                ]}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(PointOfferCreate);
