import * as React from "react";
import AdminService from "../../apiService/AdminService";
import { EntityDocumentInterface } from "cbd-api-types";
// import { ActionMeta } from "react-select/lib/types";

export interface ClientSetMogreetProps {
  // fully formed client object
  client: EntityDocumentInterface;
  initiallyDisabled?: boolean;
  token: string;
  reloadParent: (updated: Boolean) => void;
}

interface ClientSetMogreetState {
  disabled: boolean;
  loyaltyEnabled: EntityDocumentInterface["loyaltyEnabled"];
  twilioNumber: EntityDocumentInterface["twilioData"]["phoneNumber"];
  accountId: EntityDocumentInterface["twilioData"]["accountId"];
  authToken: EntityDocumentInterface["twilioData"]["authToken"];
}

/**
 * This component accepts an entity and a user list. it then manages the state of the entity
 * by setting the admin user on the entity and updating its rendered view.
 */
export default class ClientSetMogreet extends React.Component<
  ClientSetMogreetProps,
  ClientSetMogreetState
> {
  constructor(props: ClientSetMogreetProps) {
    super(props);
    let { loyaltyEnabled, twilioData } = props.client;
    this.state = {
      disabled: true,
      loyaltyEnabled: loyaltyEnabled,
      twilioNumber: twilioData ? twilioData.phoneNumber : "",
      accountId: twilioData ? twilioData.accountId : "",
      authToken: twilioData ? twilioData.authToken : ""
    };
    this.editEnable = this.editEnable.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  private editEnable(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({ disabled: !this.state.disabled });
  }

  private saveChanges(): void {
    let update = {
      loyaltyEnabled: this.state.loyaltyEnabled,
      twilioData: {
        phoneNumber: this.state.twilioNumber,
        accountId: this.state.accountId,
        authToken: this.state.authToken
      }
    };
    let { twilioNumber } = this.state;
    if (this.state.loyaltyEnabled === true && twilioNumber === "") {
      alert("dont break shit - fill this out compeltely");
    } else {
      AdminService.updateEntity(
        update,
        this.props.client._id,
        this.props.token
      ).then(
        fulfilled => {
          this.props.reloadParent(true);
        },
        rejected => {
          console.warn(rejected);
        }
      );
    }
  }
  private setPremiumForm(): JSX.Element {
    let { loyaltyEnabled, twilioNumber, accountId, authToken } = this.state;
    if (this.state.disabled === false) {
      return (
        <div>
          <div>
            Loyalty Enabled:
            <input
              name="loyaltyEnabled"
              type="checkbox"
              checked={loyaltyEnabled === true}
              onClick={() => {
                this.setState({ loyaltyEnabled: !loyaltyEnabled });
              }}
            />
          </div>

          <div>
            Twilio Number
            <input
              defaultValue={twilioNumber}
              name="twilioNumber"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ twilioNumber: event.target.value });
              }}
            />
          </div>
          <div>
            Twilio Account Id
            <input
              defaultValue={accountId}
              name="twilioAccountId"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ accountId: event.target.value });
              }}
            />
          </div>

          <div>
            Twilio Auth Token
            <input
              defaultValue={authToken}
              name="authToken"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ authToken: event.target.value });
              }}
            />
          </div>

          <div>
            <input type="button" value="update" onClick={this.saveChanges} />
          </div>
        </div>
      );
    } else
      return (
        <div>
          <div>
            twilio Number: {twilioNumber} <br />
            twilio Act ID: {accountId}
            {/* mogreetAppName:{mogreetAppName}
            <br /> shortcode:{shortcode}
            <br /> mainKeyword:{mainKeyword}
            <br /> smsCampaignId {smsCampaignId}
            <br /> mmsCampaignId:{mmsCampaignId} */}
          </div>
        </div>
      );
  }
  public render() {
    return (
      <div
        style={{
          width: 200,
          display: "inline-block",
          padding: 20,
          verticalAlign: "top"
        }}
      >
        Mogreet Settings
        <input type="button" value="MMS Settings" onClick={this.editEnable} />
        {this.setPremiumForm()}
      </div>
    );
  }
}
