import S3UploadService from "../../../apiService/S3UploadService";
import React from "react";
import ClientServices from "../../../apiService/ClientService";
import Select from "react-select";

const async = require("async");
class formTools {
  constructor(token, clientId) {
    this.token = token;
    this.clientId = clientId;
    this.producerList = false;
    this.labList = false;
    this.strainList = false;
    this.productTypes = false;
    this.productCategories = [
      { value: "Extract", label: "Extract" },
      { value: "Flower", label: "Flower" },
      { value: "Edible", label: "Edible" },
      { value: "Topical", label: "Topical" }
    ];
    this.productAvailability = [
      { value: true, label: "Available" },
      { value: false, label: "Unavailable" }
    ];
    this.entityTypesOptions = [
      { value: "dispensary", label: "Dispensary" },
      { value: "producer", label: "Producer/Processor" },
      { value: "lab", label: "Compliance Lab" },
      { value: "integrator", label: "Data Integrator" }
    ];
    this.labelTypes = [
      { value: "blank-full-000", label: "Icon Full PDF" },
      // { value: "square-full-000", label: "Full Square Sheet" },
      // { value: "rect-full-000", label: "Full Rect Sheet" },
      // { value: "circular-full-000", label: "Full Circular Sheet" },
      { value: "uline-circular-075", label: 'ULine Circular 0.75"' },
      { value: "uline-circular-100", label: 'ULine Circular 1"' },
      { value: "uline-circular-125", label: 'ULine Circular 1.25"' },
      { value: "uline-circular-150", label: 'ULine Circular 1.5"' },
      { value: "uline-circular-175", label: 'ULine Circular 1.75"' },
      { value: "avery-circular-100", label: 'Avery Circular 1.00"' }
      // { value: "tbd-square-100", label: 'Square 1"' },
      // { value: "tbd-square-125", label: 'Square 1.25"' },
      // { value: "tbd-tbd-square-150", label: 'Square 1.5"' },
      // { value: "tbd-rectangular-100-125", label: 'Rectangular 1"x1.25"' },
      // { value: "tbd-rectangular-100-150", label: 'Rectangular 1"x1.5"' },
      // { value: "tbd-rectangular-125-175", label: 'Rectangular 1.25"x1.75"' }
    ];
    this.weights = [
      { value: "0.5", label: "0.5" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "3.5", label: "3.5" },
      { value: "28.3", label: "28.3" }
    ];
  }
  uploadFilesToS3(fileInputId, callback) {
    const files = document.getElementById(fileInputId).files;
    let fileArray = [];
    let i = 0;
    while (i < files.length) {
      fileArray.push(files[i]);
      i++;
    }
    if (
      fileArray.some(element => {
        return (
          element.type === "application/pdf" ||
          element.type === "image/jpeg" ||
          element.type === "image/png"
        );
      }) === false
    ) {
      alert("Only PNG's, JPEG's, and PDF's are allowed");
      callback({
        error: "only PDF's are allowed",
        success: false,
        uploadLinks: null
      });
    } else {
      async.mapLimit(
        fileArray,
        1,
        (file, next) => {
          S3UploadService.uploadRequest(
            file,
            this.token,
            this.clientId,
            results => {
              next(null, results);
            }
          );
        },
        (err, results) => {
          if (err) {
            callback({
              error: "error: " + err.toString(),
              success: false,
              uploadLinks: null
            });
          } else {
            callback({
              error: undefined,
              success: true,
              uploadLinks: results
            });
          }
          document.getElementById(fileInputId).value = null;
        }
      );
    }
  }
  selectField(
    documentProperty,
    displayName,
    options,
    required,
    disabled,
    document,
    onChange
  ) {
    let currentValue =
      document && typeof document[documentProperty] !== "undefined"
        ? options.find(options => {
            return options.value === document[documentProperty];
          })
        : undefined;
    return (
      <div className="cb_input--group" style={{ paddingBottom: 14 }}>
        <label htmlFor="">
          {displayName}
          {required && "*"}
        </label>
        <Select
          className={"cb_input" + (!required ? "" : "-required")}
          placeholder={displayName + (!required ? "" : " (Required)")}
          required={required}
          options={options}
          isDisabled={disabled}
          name={documentProperty}
          defaultValue={currentValue}
          value={currentValue}
          onChange={onChange}
        />
      </div>
    );
  }

  inputField(
    documentProperty,
    displayName,
    required = false,
    disabled,
    document,
    maxLength = 128
  ) {
    return (
      <div className="cb_input--group">
        <label htmlFor="">
          {displayName}
          {required && "*"}
        </label>
        <input
          className={"cb_input" + (!required ? "" : "-required")}
          type="text"
          placeholder={displayName + (!required ? "" : " (Required)")}
          required={required}
          disabled={disabled}
          name={documentProperty}
          maxLength={maxLength}
          defaultValue={document ? document[documentProperty] : null}
        />
      </div>
    );
  }
  inputFieldNumeric(
    documentProperty,
    displayName,
    required = false,
    disabled,
    document,
    min,
    max
  ) {
    return (
      <div className="cb_input--group">
        <label htmlFor="">
          {displayName}
          {required && "*"}
        </label>
        <input
          className={"cb_input" + (!required ? "" : "-required")}
          type="number"
          placeholder={displayName + (!required ? "" : " (Required)")}
          required={required}
          disabled={disabled}
          name={documentProperty}
          min={min}
          max={max}
          step="0.001"
          defaultValue={document ? document[documentProperty] : null}
        />
      </div>
    );
  }
  hiddenField(documentProperty, required, document) {
    return (
      <div className="cb_input--group">
        <input
          type="hidden"
          required={required}
          name={documentProperty}
          defaultValue={document ? document[documentProperty] : null}
        />
      </div>
    );
  }

  textField(
    documentProperty,
    displayName,
    required = false,
    disabled,
    document,
    rows,
    columns,
    maxLength = 600
  ) {
    return (
      <div className="cb_input--group">
        <label htmlFor="">
          {displayName}
          {required && "*"}
        </label>
        <textarea
          className={"cb_input" + (!required ? "" : "-required")}
          placeholder={!required ? "" : "Required"}
          required={required}
          disabled={disabled}
          rows={rows}
          columns={columns}
          name={documentProperty}
          maxLength={maxLength}
          defaultValue={document ? document[documentProperty] : null}
        />
      </div>
    );
  }
  loadProductStrains(callback) {
    ClientServices.loadProductStrains().then(
      json => {
        let optionList = [];
        json.map(strain => {
          if (
            JSON.stringify(strain) !== "null" &&
            typeof strain !== "undefined"
          )
            optionList.push({ value: strain, label: strain });
        });
        this.strainList = optionList;
        callback(optionList);
      },
      rejected => {
        callback();
      }
    );
  }
  loadProductTypes(callback) {
    ClientServices.loadProductTypes().then(json => {
      let optionList = [];
      json.map(prodType => {
        // todo: figure out wtf the object is coming from that has no data in it
        if (
          JSON.stringify(prodType) !== "null" &&
          typeof prodType !== "undefined"
        ) {
          optionList.push({ value: prodType, label: prodType });
        }
      });
      this.productTypes = optionList;
      callback(optionList);
    });
  }

  loadBrands(clientId, callback) {
    ClientServices.getClientBrands(this.token, clientId).then(json => {
      let optionList = [];
      json.map(brand => {
        if (JSON.stringify(brand) !== "null" && typeof brand !== "undefined") {
          optionList.push({ value: brand._id, label: brand.name });
        }
      });
      callback(optionList);
    });
  }
}

export default formTools;
