import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropzoneArea } from "material-ui-dropzone";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import classNames from "classnames";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { MasterPageSpinner } from "../../../../components/master-page-spinner/MasterPageSpinner";
import ClientServices from "../../../../apiService/ClientService";
import S3UploadService from "../../../../apiService/S3UploadService";
import async from "async";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: "50%",
    margin: "auto",
    textAlign: "center"
  },
  button: {
    width: 100,
    marginLeft: 5,
    marginRight: 5
  },
  buttonBlock: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    marginTop: 10
  },
  infoBlocks: {
    paddingTop: 10
  },
  chip: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  }
});

class UploadYourCertificates extends Component {
  static propTypes = {
    multipleMode: PropTypes.bool,
    handleUpload: PropTypes.func,
    uploadHistory: PropTypes.array,
    clientId: PropTypes.string,
    token: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadArray: [],
      coaArray: 0,
      error: false,
      uploadingInProgress: false
    };
  }

  handleChange = files => {
    this.setState({ uploadArray: files });
  };
  handleSaveCoa = () => {
    if (this.props.multipleMode === true && this.state.uploadArray.length < 2) {
      // not enough documents
      this.setState({
        error: `Your results come in multiple PDFs and you have only
           attached 1. Click back to switch to single page mode.`
      });
    } else if (this.state.uploadArray.length === 0) {
      // no documents
      this.setState({
        error: "There are no documents attached. Lets try again."
      });
    } else {
      this.setState({ uploading: true });
      async.mapLimit(
        this.state.uploadArray,
        3,
        (fileUpload, callback) => {
          S3UploadService.uploadRequest(
            fileUpload,
            this.props.token,
            this.props.clientId,
            results => {
              callback(null, results);
            }
          );
        },
        (err, fileUploadArray) => {
          if (err) console.warn(err);
          else {
            // file uploads were succesfull, now we create the test data and save things
            let testObject = {
              dataOwner: this.props.clientId,
              producer: this.props.clientId,
              pdfFiles: fileUploadArray
            };
            const objectToFormData = require("object-to-formdata");

            ClientServices.premiumSaveTestResult(
              this.props.token,
              this.props.clientId,
              objectToFormData(testObject)
            ).then(
              results => {
                // do something cause things went goooood.

                this.props.handleUpload(testObject);
                this.setState({ uploading: false, uploadArray: [] });
              },
              rejected => {
                console.warn(rejected);
                // do something cause things went bad
              }
            );
          }
        }
      );
    }
  };
  handleCloseError = () => {
    this.setState({ error: false });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root} elevation={1}>
          {this.state.uploading === true ? (
            <MasterPageSpinner />
          ) : (
            <DropzoneArea
              onChange={this.handleChange}
              showAlerts={true}
              showPreviews={true}
              showPreviewsInDropzone={false}
              filesLimit={this.props.multipleMode === true ? 100 : 1}
              dropzoneText={
                this.props.multipleMode === true
                  ? `For each test batch, add its PDFs and click save`
                  : `For each test batch, add its PDF and click save`
              }
            />
          )}
          <Chip
            avatar={<Avatar>{this.state.uploadArray.length}</Avatar>}
            label="PDFs in COA"
            className={classes.chip}
          />
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            onClick={this.handleSaveCoa}
          >
            <SaveIcon
              className={classNames(classes.leftIcon, classes.iconSmall)}
            />
            Save
          </Button>
          {this.props.uploadHistory.length > 0 && (
            <Chip
              avatar={<Avatar>{this.props.uploadHistory.length}</Avatar>}
              label="COAs Uploaded"
              className={classes.chip}
            />
          )}
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.error}
          autoHideDuration={3000}
          onClose={this.handleCloseError}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.error}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(UploadYourCertificates);
