import React, { Component } from "react";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { LoyaltyPointOfferInterface } from "cbd-api-types";
import { BrandColor, Color } from "../../../kiosk/kioskUtilities/constants";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import AttachMoney from "@material-ui/icons/AttachMoney";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import LoyaltyCardNew from "./LoyaltyCardNew";

export interface OfferListProps extends WithStyles<typeof styles> {
  match: {
    params: {
      clientId: string;
    };
  };
  token: string;
  offerList: LoyaltyPointOfferInterface[] | null;
  errorMessage?: Error | undefined;
}

export enum PointOfferTypes {
  checkIn = "check in",
  purchase = "purchase",
  general = "general"
}

const styles = () =>
  createStyles({
    chip: {
      marginLeft: ".2rem"
    }
  });

class PointOfferList extends Component<OfferListProps> {
  public constructor(props: OfferListProps) {
    super(props);
  }

  public renderOfferCards(offers: LoyaltyPointOfferInterface[]) {
    let { classes } = this.props;
    return offers.map((offer, ind) => {
      let avatar =
        offer.pointOfferType === "checkIn" ? <Check /> : <AttachMoney />;
      let subHeader = "";
      if (offer.basePoints) {
        subHeader = offer.basePoints.toString() + "pts";
      } else if (offer.pointOfferType === "purchase") {
        subHeader = offer.multiple ? offer.multiple + "x" : "";
      }
      let avatarColor: BrandColor | Color =
        offer.status === "active" ? BrandColor.Green : BrandColor.Blue;
      if (moment().isBefore(offer.startDate)) {
        avatarColor = BrandColor.Cyan;
      } else if (
        moment().isAfter(offer.endDate) ||
        offer.status === "inactive"
      ) {
        avatarColor = Color.DarkGray;
      }
      console.log(
        offer.endDate,
        new Date(),
        offer.endDate.valueOf(),
        new Date().valueOf(),
        offer.endDate.valueOf() < new Date().valueOf()
      );
      let chips = [
        <Chip
          label={
            new Date(offer.endDate).valueOf() > new Date().valueOf()
              ? offer.status
              : "expired"
          }
          className={classes.chip}
          color={offer.status !== "active" ? "secondary" : "primary"}
          variant="outlined"
        />,
        <Chip
          label={PointOfferTypes[offer.pointOfferType]}
          className={classes.chip}
          variant="outlined"
        />
      ];
      if (!!offer.productSpecific.length) {
        chips.push(
          <Chip
            label={`${offer.productSpecific.length} product${
              offer.productSpecific.length === 1 ? "" : "s"
            }`}
            className={classes.chip}
            variant="outlined"
          />
        );
      }
      return (
        <Grid item xs={6} md={4} key={"PointOffer_" + ind}>
          <LoyaltyCardNew
            avatar={avatar}
            avatarColor={avatarColor}
            body={!!offer.mmsMessage && offer.mmsMessage.slice(0, 40) + "..."}
            mmsDemo={
              offer.mmsMessage
                ? {
                    text: offer.mmsMessage,
                    images: offer.mmsImage
                  }
                : undefined
            }
            cardColor="#fff"
            images={
              offer.mmsImage || [
                "/assets/images/generic/cbd-flower-default.png"
              ]
            }
            onAction={() => {}}
            subHeader={subHeader}
            title={
              moment(offer.startDate).format("MM-DD-YY") +
              " to " +
              moment(offer.endDate).format("MM-DD-YY")
            }
            chips={chips}
          />
        </Grid>
      );
    });
  }

  public render() {
    const { offerList, errorMessage } = this.props;
    return (
      <Grid container spacing={8 as GridSpacing}>
        {!!offerList && !!offerList.length && this.renderOfferCards(offerList)}
        {errorMessage && (
          <Grid item xs={6} md={4} key="PointOfferError">
            <LoyaltyCardNew
              avatar={<Add />}
              avatarColor={BrandColor.Red}
              body={"Click here to start a new Offer"}
              cardColor={Color.LightGray}
              images={["/assets/images/generic/cbd-flower-default.png"]}
              onAction={() =>
                window.location.replace(
                  `/client/${this.props.match.params.clientId}/loyalty/messages/create`
                )
              }
              title={errorMessage.message}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PointOfferList);
