import React, { Component } from "react";
import PropTypes from "prop-types";
import DispensaryDistributionList from "./clientComponents/product/DispensaryDistributionList";
import ClientService from "../apiService/ClientService";
import UpgradeCTA from "./clientComponents/UpgradeCTA";

const addToPrintQueRef = React.createRef();

class ManageDispensaryDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryLotList: false,
      salesCardQue: [],
      printResults: []
    };
    this.getDistirubtions();
  }

  getDistirubtions = () => {
    ClientService.getDispensaryDistributions(
      this.props.token,
      this.props.match.params.clientId
    ).then(distributions => {
      let cleanedDistrubitions = distributions.filter(dist => {
        return dist !== null;
      });
      this.setState({ inventoryLotList: cleanedDistrubitions });
    });
  };

  displayForm = () => {
    if (this.state.inventoryLotList !== false) {
      return (
        <div>
          <DispensaryDistributionList
            distributionList={this.state.inventoryLotList}
            queSalesCardPrint={addToPrintQueRef}
            reloadParent={this.getDistirubtions}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__current">Manage Your Inventory</li>
            </ul>
            <div className="cb_account__content-header-message">
              <p>
                You can see your stores entire inventory below. This inventory
                is submitted by you and any vendors that have designedated
                product as being in your inventory.
              </p>
            </div>
          </div>
          {this.props.clientData.premiumEnabled === false && (
            <div className="cb_grid-item--12">
              <UpgradeCTA
                linkUrl={
                  "/client/" +
                  this.props.match.params.clientId +
                  "/premium/signup"
                }
                linkText="Go Pro!"
                headline="Want Merchandising Cards?"
                body="Package your products with merchandising cards that communicate your products value with MSRP Pricing. Get a demo PDF here!"
                ctaImageUrl="/assets/images/client/merch-cards-example.jpg"
                ctaImageLink="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/merch-card-sample.pdf"
                ctaTarget="_blank"
              />
            </div>
          )}
          <div className="cb_account__content-content">
            {this.displayForm()}
          </div>
        </div>
      </div>
    );
  }
}

ManageDispensaryDistribution.propTypes = {};

export default ManageDispensaryDistribution;
