import React, { Component } from "react";
import PropTypes from "prop-types";
import SearchController from "../SearchController";
import { Element } from "react-scroll";

export default class SearchHeader extends Component {
  static propTypes = {
    manualSearchString: PropTypes.string
  };

  render() {
    return (
      <div className="cb_section cb_section--newmasthead">
        <div className="cb_new-masthead cb_masthead--full">
          <div className="cb_masthead__content">
            <span id="search" />
            <div className="cb_masthead__content-left">
              <p className="cb_masthead__subheading">
                Forget the strain name... We'll help find your experience.
              </p>
              <h1 className="cb_masthead__title">
                Find the cannabis experience you're looking for.
              </h1>
              <Element id="searchController" name="searchController">
                <SearchController
                  isHeader={false}
                  manualSearchString={this.props.manualSearchString}
                />
              </Element>
            </div>
            <div className="cb_masthead__content-right">
              <div className="cb_masthead__cannastamp">
                <img
                  className="cb_masthead__cannstamp-image"
                  src="/assets/images/masthead--cannastamp.png"
                  alt=""
                />
                <h2 className="cb_masthead__cannastamp-title">
                  The Cannastamp&trade;
                </h2>
                <a className="cb_masthead__cannastamp-learn" href="/about#thecolors">
                  Learn what the colors mean
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
