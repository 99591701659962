import * as React from "react";
import styled from "styled-components";
import { BorderRadius, Color, toPixel } from "../kioskUtilities/constants";
import Close from "@material-ui/icons/Close";

interface Props {
  onChange: (e: any) => void;
  onClear: (e: any) => void;
  value: string;
}
export function Search (props: Props) {
  const {
    onChange,
    onClear,
    value
  } = props;
  return (
    <SearchGroup>
      <StyledSearch
        type={"text"}
        placeholder={"Enter Product Name or Code"}
        onChange={onChange}
        value={value}
      />
      <Button
        onClick={onClear}
      >
        <Close />
      </Button>
    </SearchGroup>
  );
}

const Button = styled.button`
  background: transparent;
  border: 0 none;
  border-radius: 0 ${toPixel(BorderRadius.Medium)} ${toPixel(BorderRadius.Medium)} 0;
  color: ${Color.PrimaryText};
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;
const SearchGroup = styled.div`
  position: relative;
  margin: 0 0 1rem;
`;
const StyledSearch = styled.input`
  background: ${Color.InputBackground};
  border-radius: ${toPixel(BorderRadius.Medium)};
  border: 1px solid ${Color.InputOutline};
  color: ${Color.PrimaryText};
  padding-right: 50px;
  margin: 0;
`;
