import * as React from "react";
import ProductForm from "../product/ProductForm";
import { Brand, ProducerProduct } from "../../../apiService/serviceInterfaces";
import WhatsAProduct from "./WhatsAProduct";
import { styled } from "@material-ui/styles";

interface Props {
  clientId: string;
  onSave?: (createdProduct: ProducerProduct) => void;
  brandData: Brand;
}
interface State {
  product: any;
}
const ProductFormWrapper = styled("div")({
  background: "#FAFAFA",
  border: "1px solid #CCC",
  borderRadius: 3,
  padding: 20
});

export class AddNewProduct extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      product: {}
    };
  }

  public render(): React.ReactChild {
    const { clientId } = this.props;
    const { product } = this.state;
    return (
      <div>
        <div className="cb_grid-item--8" style={{ marginTop: 10 }}>
          <ProductFormWrapper>
            <ProductForm
              product={product}
              parentLots={[]}
              clientId={clientId}
              onSave={this.props.onSave}
              hideCancel={true}
            />
          </ProductFormWrapper>
        </div>
        <div className="cb_grid-item--4">
          <div>
            <WhatsAProduct />
          </div>
        </div>
      </div>
    );
  }
}
