import * as React from "react";
import { CenteredFullWidthHStack } from "./NumberEntry";
import { EntityInterface } from "cbd-api-types";
import {
  Button,
  Theme,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/styles";
import { AccountCircle, MyLocation } from "@material-ui/icons";

export interface CreateAccountProps extends WithStyles<typeof styles> {
  phoneNumberRaw: string;
  onSubmit: (
    zipCode: CreateAccountState["zipCode"],
    age: CreateAccountState["age"]
  ) => void;
  error?: string;
  clientData: EntityInterface | undefined;
  title: string;
  subTitle: string;
  logoUrl: string;
}

export interface CreateAccountState {
  zipCode: string;
  confirmationCode: string;
  age: number;
  errorMessage: string;
}

class CreateAccount extends React.Component<
  CreateAccountProps,
  CreateAccountState
> {
  constructor(props: CreateAccountProps) {
    super(props);

    this.state = {
      zipCode: null,
      confirmationCode: null,
      age: null,
      errorMessage: null
    };
  }

  private handleSubmit = (): void => {
    let errorMessage = "";
    const { zipCode, age } = this.state;
    if (zipCode === null) {
      errorMessage = "A Valid ZIP Code is Required. ";
    }
    if (age < 21) {
      errorMessage += "You Be Over 21. ";
    }

    if (errorMessage !== "") {
      this.setState({ errorMessage });
    } else {
      this.props.onSubmit(zipCode, age);
    }
  };

  private setAge = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ age: Number.parseInt(event.target.value) });
  };

  private validateZip = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(event.target.value);
    if (isValidZip) {
      this.setState({ zipCode: event.target.value });
    } else {
      this.setState({ zipCode: null });
    }
  };

  public render() {
    const { phoneNumberRaw, clientData } = this.props;
    const { age, zipCode } = this.state;
    return (
      <CenteredFullWidthHStack>
        {clientData && clientData.featureImage && (
          <img
            src={clientData.featureImage}
            style={{
              width: "auto",
              maxHeight: "22rem",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            alt={`${clientData.name} logo`}
          />
        )}
        <Typography variant="h5">Signup For Loyalty</Typography>
        {this.props.error && <Typography>{this.props.error}</Typography>}
        {this.state.errorMessage && (
          <Typography>{this.state.errorMessage}</Typography>
        )}

        <Typography variant="subtitle2">{phoneNumberRaw}</Typography>
        <TextField
          variant="outlined"
          type={"text"}
          name="ZIP"
          placeholder={"ZIP Code"}
          onChange={this.validateZip}
          className={this.props.classes.centeredBlock}
          margin="dense"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MyLocation />
              </InputAdornment>
            )
          }}
        />

        <TextField
          variant="outlined"
          type={"text"}
          placeholder={"Age"}
          onChange={this.setAge}
          margin="dense"
          className={this.props.classes.centeredBlock}
          InputProps={{
            style: {
              textAlign: "center"
            },
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            )
          }}
        />
        <Button
          className={this.props.classes.centeredBlock}
          onClick={this.handleSubmit}
          disabled={(age >= 21 && zipCode !== null) === false}
          variant="outlined"
        >
          Sign Up
        </Button>
      </CenteredFullWidthHStack>
    );
  }
}

// tslint:disable-next-line: one-variable-per-declaration
const styles = (theme: Theme) => ({
  centeredBlock: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "3px",
    display: "block",
    maxWidth: "300px"
  }
});

export default withStyles(styles)(CreateAccount);

// export const SignUp = styled.button<{ disabled: boolean }>`
//   // margin-bottom: 50px;
//   color: black;
//   pointer-events: all;
//   padding-top: 2px;
//   height: 30px;
//   border-color: ${Color.Black};
//   background-color ${props =>
//     props.disabled === true ? Color.VeryLightGray : Color.White};
//   & svg {
//     font-size: .75rem;
//     margin: 0 5px;
//     vertical-align: middle;
//   }

//   @media (max-width: 500px) {
//     & span {
//       display: none;
//     }
//   }
// `;

// export const SignupButton = styled.button`
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 10px;
//   display: block;
// `;

// export const Headertext = styled.span`
//   margin-left: auto;
//   margin-right: auto;
//   display: block;
//   padding: 5px;
// `;
// export const PhoneNumber = styled.span`
//   margin-left: auto;
//   margin-right: auto;
//   display: block;
//   padding: 5px;
// `;

// export const LocationData = styled.span`
//   margin-left: auto;
//   margin-right: auto;
//   display: block;
//   padding-bottom: 10px;
// `;

// export const StyledInputs = styled.input`
//   background: ${Color.InputBackground};
//   border-radius: ${toPixel(BorderRadius.Medium)};
//   border: 1px solid ${Color.InputOutline};
//   color: ${Color.PrimaryText};
//   width: 200px;
//   margin-left: auto;
//   margin-right: auto;
//   text-align: center;
//   display: block;
// `;

// export const ErrorMessage = styled.span`
//   font-weight: bolder;
//   color: ${BrandColor.Magenta};
//   margin-left: auto;
//   margin-right: auto;
//   display: block;
// `;
