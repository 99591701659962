import React, { Component } from "react";
import matchSorter from "match-sorter";
import CannastampIcon from "../../../components/product/CannastampIcon";
import PdfGenerator from "../../../svgTools/PdfGenerator";
import PropTypes from "prop-types";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

class TestResultList extends Component {
  static propTypes = {
    clientData: PropTypes.object,
    user: PropTypes.object
  };
  constructor(props) {
    super(props);
  }

  tableView() {
    if (this.props.testResultList === null) {
      return <div>Empty Test Result List</div>;
    } else {
      let adminColumns =
        this.props.user.adminUser !== true
          ? []
          : [
              {
                Header: "THC",
                id: "thc",
                accessor: d => d.thcTotal,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["thcTotal"]
                  }),
                filterAll: true
              },
              {
                Header: "CBD",
                id: "cbd",
                accessor: d => d.cbdTotal,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["cbdTotal"]
                  }),
                filterAll: true
              },
              {
                Header: "CBN",
                id: "cbn",
                accessor: d => d.cbn,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["cbn"]
                  }),
                filterAll: true
              },
              {
                Header: "Total CBG",
                id: "cbg",
                accessor: d => d.cbg,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["cbg"]
                  }),
                filterAll: true
              },
              {
                Header: "Total terpeneTotal",
                id: "terpeneTotal",
                accessor: d => d.terpeneTotal,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["terpeneTotal"]
                  }),
                filterAll: true
              },
              {
                Header: "caryophyllene",
                id: "caryophyllene",
                accessor: d => d.caryophyllene,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["caryophyllene"]
                  }),
                filterAll: true
              },
              {
                Header: "myrcene",
                id: "myrcene",
                accessor: d => d.myrcene,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["myrcene"]
                  }),
                filterAll: true
              },
              {
                Header: "terpinolene",
                id: "terpinolene",
                accessor: d => d.terpinolene,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: [""]
                  }),
                filterAll: true
              },
              {
                Header: "humulene",
                id: "humulene",
                accessor: d => d.humulene,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["humulene"]
                  }),
                filterAll: true
              },
              {
                Header: "betaPinene",
                id: "betaPinene",
                accessor: d => d.betaPinene,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["betaPinene"]
                  }),
                filterAll: true
              },
              {
                Header: "alphaPinene",
                id: "alphaPinene",
                accessor: d => d.alphaPinene,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["alphaPinene"]
                  }),
                filterAll: true
              },
              {
                Header: "limonene",
                id: "limonene",
                accessor: d => d.limonene,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["limonene"]
                  }),
                filterAll: true
              },
              {
                Header: "linalool",
                id: "linalool",
                accessor: d => d.linalool,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["linalool"]
                  }),
                filterAll: true
              }
            ];
      let standardColumns = [
        {
          Header: "Cannastamp",
          maxWidth: 75,
          id: "Cannastamp",
          accessor: d => d.cannastamp,
          filterable: false,
          sortable: false,
          Cell: ({ row }) => (
            <div style={{ width: 50 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: row._original.cannastamp
                }}
              />
            </div>
          )
        },
        {
          Header: "Test Date",
          id: "testDate",
          accessor: d => d.testDate,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["testDate"]
            }),
          filterAll: true
        },
        {
          Header: "Strain",
          id: "strain",
          accessor: d => d.strain,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["strain"]
            }),
          filterAll: true
        },
        {
          Header: "Parent Lot",
          id: "tracabilityParentLotId",
          accessor: d => d.tracabilityParentLotId,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["tracabilityParentLotId"]
            }),
          filterAll: true
        },
        {
          Header: "Sample Lot",
          id: "tracabilitySampleLotId",
          accessor: d => d.tracabilitySampleLotId,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["tracabilitySampleLotId"]
            }),
          filterAll: true
        },
        {
          Header: "Link",
          id: "Manage",
          filterable: false,
          Cell: ({ row }) => (
            <div>
              <a
                href={
                  "/client/" +
                  row._original.dataOwner +
                  "/testResult/" +
                  row._original._id
                }
              >
                Edit Test Result
              </a>
            </div>
          )
        }
      ];

      return (
        <div style={{ paddingTop: 80 }}>
          <ReactTable
            data={this.props.testResultList}
            filterable
            pages={this.props.pages}
            manual
            onFetchData={this.props.fetchData}
            loading={this.props.loading}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                Header: "Test Data",
                columns: [...standardColumns, ...adminColumns]
              }
            ]}
          />
          <br />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <br />
        <br />
        {this.tableView()}
      </div>
    );
  }
}

export default TestResultList;
