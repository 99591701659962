import * as React from "react";
import { BrandColor } from "../../../../kiosk/kioskUtilities/constants";
import { MarkunreadMailboxRounded, MailOutline } from "@material-ui/icons";
import { LoyaltyMessagesData } from "cbd-api-types";
import Chip from "@material-ui/core/Chip";
import { withStyles, createStyles, WithStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import LoyaltyCardNew from "../LoyaltyCardNew";

export interface IMmsMessageCardProps extends WithStyles<typeof styles> {
  message: LoyaltyMessagesData;
}

const styles = (_: Theme) =>
  createStyles({
    chip: {
      marginLeft: ".2rem"
    }
  });

class MmsMessageCard extends React.PureComponent<IMmsMessageCardProps> {
  public render() {
    let { message, classes } = this.props;
    const avatar =
      message.status === "pending" ? (
        <MarkunreadMailboxRounded />
      ) : (
        <MailOutline />
      );
    return (
      <LoyaltyCardNew
        avatar={avatar}
        avatarColor={BrandColor.Blue}
        body={message.messageBody.slice(0, 80) + "..."}
        cardColor="#fff"
        images={message.messageMediaAws}
        chips={[
          <Chip
            label={message.status}
            className={classes.chip}
            color={message.status !== "complete" ? "secondary" : "primary"}
            variant="outlined"
          />,
          <Chip
            label={`${message.deliveryPercent * 100} % Delivered`}
            className={classes.chip}
            variant="outlined"
          />
        ]}
      />
    );
  }
}

export default withStyles(styles)(MmsMessageCard);
