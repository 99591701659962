import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Blog from "../pages/Blog";

class RouteBlog extends Component {
  constructor(props) {
    super(props);
  }

  // already on the /client route
  render() {
    return (
      <div className="cb_template">
        <div className="cb_template__section">
          <div className="cb_surface">
            <Router>
              <div>
                <Switch>
                  <Route path="/cannatalk/:id" component={Blog} />
                  <Route path="/cannatalk" component={Blog} />
                </Switch>
              </div>
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

export default RouteBlog;
