import React, { Component } from "react";

class BlogMenu extends Component {
  // this.props.posts
  // this.props.setActivePost()
  render() {
    return (
      <div>
        <div className="cb__blog-menu-header">Recent Posts</div>
        <div className="cb__blog-menu-items">
          {this.props.posts.map((post, index) => (
            <li key={index} onClick={() => this.props.setActivePost(post.id)}>
              {post.title}
            </li>
          ))}
        </div>
      </div>
    );
  }
}

export default BlogMenu;
