import React, { Component } from "react";
import CannastampIcon from "./CannastampIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActivityIconMap from "../util/ActivityIconMap";

class MyCannabinderProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: this.props.binderItems[0].test,
      binderItem: this.props.binderItems[0],
      binderItems: this.props.binderItems,
    };
  }

  render() {
    let dedupedBinderItems = {};
    this.state.binderItems.forEach(function(item) {
      dedupedBinderItems[item.usage] = item;
    });

    let positiveRatings = [];
    let negativeRatings = [];

    Object.keys(dedupedBinderItems).forEach(function(key) {
      let item = dedupedBinderItems[key];
      if (item.rating === 1 && positiveRatings.indexOf(item.usage) === -1) {
        positiveRatings.push(item.usage);
      } else if (item.rating === -1 && negativeRatings.indexOf(item.usage) === -1){
        negativeRatings.push(item.usage);
      }
    });

    const backgroundStyle = {
      background: "rgba(255,255,255,0)",
      margin: 5
    };

    const renderPositive = function() {
      return (
        <span className="cb_rating__activity">
          <FontAwesomeIcon size="1x" icon="thumbs-up" color={"rgba(80,227,194,1)"} style={backgroundStyle} />
          {positiveRatings.map((usage) => (
            <FontAwesomeIcon size="1x" icon={ActivityIconMap[usage]} style={backgroundStyle} title={usage} />
          ))}
        </span>
      );
    };

    const renderNegative = function() {
      return (
        <span className="cb_rating__activity">
          <FontAwesomeIcon size="1x" icon="thumbs-down" color={"rgba(255,51,45,1)"} style={backgroundStyle} />
          {negativeRatings.map((usage) => (
            <FontAwesomeIcon size="1x" icon={ActivityIconMap[usage]} style={backgroundStyle} title={usage} />
          ))}
        </span>
      );
    };

    return (
      <div className="cb_cannastamp">
        <div className="cannastamp__graph-container">
          <a href={this.props.binderItems[0].product.cleanUrl}>
            <CannastampIcon testResult={this.state.test} size={"300"} />
          </a>

          {positiveRatings.length > 0 && renderPositive()}
          {negativeRatings.length > 0 && renderNegative()}

          <span className="cannastamp__product-name">
            {this.state.binderItem.product.name}
          </span>
          <span className="cannastamp__grower-name">
            {this.state.binderItem.product.brandName}
          </span>
        </div>
      </div>
    );
  }
}

export default MyCannabinderProductView;
