import React, { Component } from "react";
import UserService from "../apiService/UserService";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import Button from "@material-ui/core/Button";
import DialogModal from "../components/generics/DialogModal";
import { withStyles, createStyles } from "@material-ui/core";
import ReactPhoneInput from "react-phone-input-2";

const classes = createStyles({
  button: {
    padding: "0.2rem 0.75rem 0.35rem",
    color: "#0f252c",
    backgroundColor: "#6ceaf3",
    border: "0 none",
    fontWeight: "inherit"
  },
  form: {
    textAlign: "left"
  },
  h2: {
    margin: "1rem 0",
    fontSize: "1.5rem",
    textAlign: "left"
  },
  h3: {
    margin: "1rem 0"
  }
});

class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      errorPrompt: "",
      submitDisabled: false,
      changeSuccess: false,
      dialog: false,
      changePass: false,
      updateSubmitDisabled: false
    };
  }

  // Dialog handles
  handleClickOpen = () => {
    this.setState({
      dialog: {
        title: "Delete Your Account?",
        content: "This will permanently delete your personal information.",
        primary: {
          action: this.handleClose,
          text: "Cancel"
        },
        secondary: {
          action: this.processDeleteAccount,
          text: "Delete"
        }
      }
    });
  };

  handleError = () => {
    this.setState({
      dialog: true,
      title: "There was a problem deleting your account.",
      content: "Please try again later.",
      secondary: false
    });
  };

  handleClose = () => {
    this.setState({ dialog: false });
  };
  // Dialog handles

  processPasswordChange = event => {
    event.preventDefault();
    this.setState({ submitDisabled: true });
    const password = document.getElementById("password").value;
    const passwordNew = document.getElementById("passwordNew").value;
    const passwordVer = document.getElementById("passwordVer").value;
    const req = {
      body: {
        password: password,
        passwordNew: passwordNew,
        passwordVer: passwordVer,
        accountType: this.state.accountType
      }
    };
    UserService.userChangePassword(this.props.token, req)
      .then(results => {
        if (results.error === true) {
          this.setState({
            errorPrompt: results.errorMessage,
            submitDisabled: false
          });
        } else {
          this.setState({
            user: results.user,
            changeSuccess: true,
            errorPrompt: results.success,
            submitDisabled: false
          });
        }
      })
      .catch(err => {
        this.setState({
          errorPrompt: err.errorMessage,
          submitDisabled: false
        });
      });
  };

  processUserUpdate = event => {
    event.preventDefault();
    this.setState({ updateSubmitDisabled: true });
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const req = {
      body: {
        email: email,
        phone: phone
      }
    };
    UserService.updateUser(this.props.token, this.state.user._id, req)
      .then(results => {
        if (results.error === true) {
          this.setState({
            errorPrompt: results.errorMessage,
            updateSubmitDisabled: false
          });
        } else {
          this.setState(
            { errorPrompt: "Information Updated, Redirecting!" },
            () => {
              setTimeout(() => {
                this.props.onUserUpdate(results, this.props.token, "/");
              }, 2000);
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          errorPrompt: err.errorMessage,
          updateSubmitDisabled: false
        });
      });
  };

  processDeleteAccount = () => {
    UserService.deleteUser(this.props.user._id, this.props.token).then(
      results => {
        if (results.message === "deleted") {
          this.handleClose();
          this.props.logout();
        } else {
          this.handleError();
        }
      }
    );
  };

  renderPasswordForm = classes => {
    return (
      <form
        className={"cb_password_change-form" + classes.form}
        onSubmit={this.processPasswordChange}
      >
        <p className="cb_masthead__heading">
          {this.state.errorPrompt ? "*" + this.state.errorPrompt + "*" : " "}
        </p>
        <input
          className="cb_password_change-form__current_password"
          type="password"
          id="password"
          name="password"
          placeholder="Old Password (Required)"
        />
        <input
          className="cb_password_change-form__new_password"
          type="password"
          id="passwordNew"
          name="passwordNew"
          placeholder="New Password (Required)"
        />
        <input
          className="cb_password_change-form__verify_password"
          type="password"
          id="passwordVer"
          name="passwordVer"
          placeholder="Repeat New Password (Required)"
        />
        <button
          type="submit"
          className={"cb_password_change-form__submit " + classes.button}
          enabled="false"
          disabled={this.state.submitDisabled}
        >
          Change
        </button>
      </form>
    );
  };
  updateInfo = classes => {
    return (
      <form
        className={"cb_password_change-form" + classes.form}
        onSubmit={this.processUserUpdate}
        name="updateAccount"
        id="updateAccount"
      >
        <p className="cb_masthead__heading">
          {this.state.errorPrompt ? "*" + this.state.errorPrompt + "*" : " "}
        </p>
        <input
          className="cb_password_change-form__current_password"
          type="email"
          id="email"
          name="email"
          defaultValue={this.state.user.email}
        />
        <ReactPhoneInput
          name="phone"
          id="phone"
          disableCountryCode={true}
          disableDropdown={true}
          country={"us"}
          onlyCountries={["us"]}
          inputStyle={{
            width: "100%"
          }}
          value={this.state.user.phone || ""}
          inputProps={{
            name: "phone",
            id: "phone"
          }}
        />
        <button
          style={{
            marginTop: 10
          }}
          type="submit"
          className={"cb_password_change-form__submit " + classes.button}
          disabled={this.state.updateSubmitDisabled}
        >
          Submit
        </button>
      </form>
    );
  };

  renderChangeSuccess = () => {
    return (
      <div className="cb_change-password-success">
        <h2 className={"cb_masthead__heading " + classes.h2}>
          {this.state.errorPrompt}
        </h2>
      </div>
    );
  };

  renderChangePassword = classes => {
    return (
      <div className="cb_change-password">
        <h2 className={"cb_masthead__heading " + classes.h2}>
          Change Your Password
        </h2>
        {this.state.changeSuccess
          ? this.renderChangeSuccess()
          : this.renderPasswordForm(classes)}
      </div>
    );
  };

  renderDeleteAccount = classes => {
    const bussUser =
      this.props.user.adminUser === true ||
      this.props.user.dispensaryUser === true ||
      this.props.user.processorUser === true ||
      (typeof this.props.user.clientAssignment !== "undefined" &&
        this.props.user.clientAssignment !== null);
    return (
      <div className="cb_delete_account">
        <br />
        <h2>Delete Your Account</h2>
        {bussUser ? (
          <h3 className={classes.h3}>
            When deleting a business account, the history of that company's
            products will still be made available to users and your account will
            made available to users on a historic basis.
          </h3>
        ) : (
          <h3 className={classes.h3}>We're sorry to see you go.</h3>
        )}
        <button
          type="submit"
          className={"cb_delete_account-prompt " + classes.button}
          onClick={this.handleClickOpen}
        >
          Delete Account
        </button>
        <DialogModal
          open={this.state.dialog}
          onClose={this.handleClose}
          {...this.state.dialog}
        />
      </div>
    );
  };

  render() {
    let { classes } = this.props;
    return (
      <div className="cb_surface">
        <h1 className="cb_breadcrumb--title">Manage Your Account</h1>
        <p>
          {!this.state.changePass && (
            <>
              <h2>Update Your Info</h2>
              {this.updateInfo(classes)}
            </>
          )}
        </p>
        <br />
        <br />
        <p>
          <h2>Change your Password</h2>
          <button
            className={classes.button}
            onClick={() =>
              this.setState({ changePass: !this.state.changePass })
            }
          >
            {this.state.changePass ? "Cancel" : "Change Password"}
          </button>
          {this.state.changePass && this.renderChangePassword(classes)}
        </p>
        <p>{this.renderDeleteAccount(classes)}</p>
      </div>
    );
  }
}

export default AuthenticationHOC()(withStyles(classes)(AccountPage));
