import React, { Component } from "react";
import { GoogleLogin } from "react-google-login";
import UserService from "../apiService/UserService";
import * as Cookies from "es-cookie";
import { withRouter, Redirect } from "react-router-dom";
import { oAuthMode } from "../configurator";
import PropTypes from "prop-types";
import DialogModal from "./generics/DialogModal";

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      oAuthConfig: oAuthMode(window.location.href),
      resetPassword: false,
      error: undefined
    };
  }

  accountSettings = () => {
    this.setState({ redirect: "account" });
  };

  renderRedirect() {
    const { history } = this.props;
    if (this.state.redirect !== false) {
      this.setState({ redirect: false });

      // history.push("/" + this.state.redirect);
      this.props.closeModalLogin();
      return <Redirect to={"/" + this.state.redirect} />;
    }
  }

  closeLoginModal = () => {
    // this.props.closeModalLogin();
  };

  handleLogin = (user, token) => {
    const { history } = this.props;
    let d = new Date();
    d.setTime(d.getTime() + 30 * 60 * 60 * 1000);
    Cookies.set("token", token, { expires: d, path: "/" });
    Cookies.set("user", JSON.stringify(user), { expires: d, path: "/" });
    // Cookies.parse()

    // This is an example script - don't forget to change it!
    if (
      (user.processorUser || user.dispensaryUser) &&
      typeof user.clientAssignment !== "undefined"
    ) {
      history.push(`/client/${user.clientAssignment}/manageProducts`);
      window.location.reload();
    } else if (user.processorUser || user.dispensaryUser) {
      history.push("/ClaimYourCompany");
      window.location.reload();
    } else {
      window.location.reload();
    }

    // FS.identify(user._id, {
    //   displayName: user.email,
    //   email: user.email
    // });
  };

  facebookResponse = response => {
    const tokenBlob = new Blob(
      [JSON.stringify({ access_token: response.accessToken }, null, 2)],
      { type: "application/json" }
    );
    const options = {
      method: "POST",
      body: tokenBlob,
      mode: "cors",
      cache: "default"
    };

    fetch(this.state.facebookCallBack, options).then(r => {
      if (r.status == "401") {
        alert("Not Authorized");
      } else {
        const newToken = r.headers.get("x-auth-token");
        r.json().then(newUser => {
          if (newToken) {
            this.setState({
              user: newUser,
              token: newToken
            });
            // this.closeLoginModal();
            this.handleLogin(newUser, newToken);
          }
        });
      }
    });
  };

  googleResponse = response => {
    const tokenBlob = new Blob(
      [JSON.stringify({ access_token: response.accessToken }, null, 2)],
      { type: "application/json" }
    );
    const options = {
      method: "POST",
      body: tokenBlob,
      mode: "cors",
      cache: "default"
    };

    fetch(this.state.oAuthConfig.google.callbackURL, options).then(r => {
      const newToken = r.headers.get("x-auth-token");
      r.json().then(newUser => {
        if (newToken) {
          this.setState({
            user: newUser,
            token: newToken
          });
          // this.closeLoginModal();
          this.handleLogin(newUser, newToken);
        }
      });
    });
  };

  processSignIn = event => {
    event.preventDefault();
    const email = document.getElementById("email").value.toLowerCase();
    const password = document.getElementById("password").value;
    const req = {
      body: {
        email: email,
        password: password
      }
    };

    UserService.userSignIn(req)
      .then(results => {
        // succesfully signedup up an account, so lets log them in
        if (results.error === true) {
          this.setState({ error: results.errorMessage, email: email });
        } else {
          this.handleLogin(results.user, results.token);
        }
      })
      .catch(err => {
        if (err.error === true) {
          this.setState({ error: err.errorMessage, email: email });
        }
      });
  };

  _change = event => {
    const { dialog } = this.state;
    dialog.input.value = event.target.value;
    this.setState({ dialog });
  };

  modalForgotPassword = () => {
    this.setState({
      resetPassword: true
    });
  };

  modalForgotPasswordClose = () => {
    this.setState({ resetPassword: false });
  };

  processForgotPassword = () => {
    const email = document.getElementById("email").value.toLowerCase() || "";
    if (email.length > 0) {
      UserService.passwordReset(email)
        .then(results => {
          // succesfully signedup up an account, so lets log them in
          if (results.error === true) {
            this.setState({ error: results.errorMessage });
          } else {
            this.setState({
              error: "We have sent a passwod reset link",
              resetPassword: false
            });
          }
        })
        .catch(err => {
          if (err.error === true) {
            this.setState({ error: err.errorMessage });
          }
        });
    } else {
      this.setState({ error: "Please enter an email." });
    }
  };

  loginElements = () => (
    <div className="cb_modal cb_modal--open cb_modal--login">
      <div className="cb_modal__bg" />
      <div className="cb_modal__window">
        <a
          onClick={this.props.closeModalLogin}
          className="cb_modal__close-button"
        />
        <div className="cb_modal__content">
          <div>
            <div className="cb_modal__heading">Login to Cannabinder</div>
            <div className="cb_modal__copy">
              Login or create a new account to save Cannastamps to your
              Favorites.
            </div>
            <div>
              <div className="cb_modal__login-buttons">
                <div className="cb_facebook-login-button" />
                {/* todo: get this to look pretty again. default styles are clashing */}
                {/* <div className="cb_google-login-button"> */}
                <GoogleLogin
                  clientId={this.state.oAuthConfig.google.clientID}
                  buttonText="Login with Google"
                  onSuccess={this.googleResponse}
                  onFailure={this.onFailure}
                />
                {/* </div> */}
                <div>
                  <p className="cb_email-signin-message">Sign In With Email</p>
                  <form
                    className="cb_modal__signin-form"
                    id="userSignInForm"
                    onSubmit={this.processSignIn}
                  >
                    <b>
                      <p className="cb_masthead__heading">{this.state.error}</p>
                    </b>
                    <span>
                      <input
                        className="cb_signup-form__email"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email Address (Required)"
                      />
                      <input
                        className="cb_signup-form__password"
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password (Required)"
                      />
                    </span>
                    <span>
                      <button
                        className="cb_email-signup-button"
                        onClick={this.processSignIn}
                      >
                        Sign In
                      </button>
                    </span>
                  </form>
                  <p>
                    Need to create an account? <a href="/signup">Sign Up</a>.
                  </p>
                  <p>
                    <a href="#" onClick={this.modalForgotPassword}>
                      Forgot password?
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  resetElements = () => (
    <div className="cb_modal cb_modal--open cb_modal--login">
      <div className="cb_modal__bg" />
      <div className="cb_modal__window">
        <a
          onClick={this.props.closeModalLogin}
          className="cb_modal__close-button"
        />
        <div className="cb_modal__content">
          <div>
            <div className="cb_modal__heading">
              Reset Your Cannabinder Password
            </div>
            <div className="cb_modal__copy">
              We will send a reset email with a link to reset your password!
            </div>
            <div>
              <div className="cb_modal__login-buttons">
                <div>
                  <form className="cb_modal__signin-form" id="userSignInForm">
                    <b>
                      <p className="cb_masthead__heading">{this.state.error}</p>
                    </b>
                    <span>
                      <input
                        className="cb_signup-form__email"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email Address (Required)"
                      />
                    </span>
                    <span>
                      <a
                        className="cb_email-signup-button"
                        onClick={this.processForgotPassword}
                      >
                        Reset
                      </a>
                    </span>
                    <span>
                      <button
                        className="cb_email-signup-button"
                        onClick={this.modalForgotPasswordClose}
                      >
                        Cancel
                      </button>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    let { classes } = this.props;
    return !this.props.modalLoginTrigger ? (
      <div />
    ) : (
      <div>
        {this.renderRedirect()}
        {this.state.resetPassword ? this.resetElements() : this.loginElements()}
      </div>
    );
  }
}

export default withRouter(LoginModal);
