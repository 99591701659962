import React, { Component } from "react";
import PropTypes from "prop-types";
import ProductForm from "./clientComponents/product/ProductForm";
import ClientServices from "../apiService/ClientService";
import DistributeProduct from "./clientComponents/product/DistributeProduct";
import ProductDistribution from "./clientComponents/product/ProductDistribution";
import ProductShipments from "./clientComponents/product/ProductShipments";
import Switch from "rc-switch";
import formTools from "./clientComponents/generic/formTools";

class ManageProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: typeof props.match.params.productId !== "undefined" ? false : {},
      parentLots: false,
      distributionLots: false,
      shipmentLots: false,
      dispensaries: false
    };
    this.loadProduct();
    this.loadParentLots();
    this.loadClientList();
    // this.loadDistributionLots();
    // this.loadShipmentLots();
  }

  loadProduct = () => {
    let productId = this.props.match.params.productId;
    let clientId = this.props.match.params.clientId;
    if (productId) {
      ClientServices.getOneById(productId, this.props.token, clientId).then(
        json => {
          this.setState({ product: json });
        }
      );
    }
  };

  loadParentLots = () => {
    this.props.getClientParentLots(
      this.props.match.params.clientId,
      false,
      results => {
        this.setState({ parentLots: results });
      }
    );
  };

  loadClientList = () => {
    let formTool = new formTools(
      this.props.token,
      this.props.match.params.clientId
    );
    ClientServices.loadDispensariesKeyPair().then(
      results => {
        let options = results.map(dispensary => {
          return {
            label: dispensary.name + " - " + dispensary.licenseNumber,
            value: dispensary._id
          };
        });
        this.setState({
          dispensaryOptions: options,
          dispensaries: results
        });
      },
      error => {
        console.warn(error);
      }
    );
  };

  deleteDistribution = key => {
    let distribution = this.state.product.distribution;
    delete distribution[key];
    distribution.splice(key, 1);
    ClientServices.saveProduct(
      this.props.token,
      this.props.match.params.clientId,
      JSON.stringify({ distribution }),
      this.state.product._id
    ).then(
      response => {
        this.loadProduct();
      },
      rejected => {
        console.warn(rejected);
      }
    );
  };

  reloadProduct = (productId, callback) => {
    let clientId = this.props.match.params.clientId;
    ClientServices.getOneById(productId, this.props.token, clientId).then(
      json => {
        this.setState({ product: json }, () => {
          callback(json);
        });
      }
    );
  };

  render() {
    if (this.state.product === false || this.state.parentLots === false) {
      return (
        <div style={{ width: 200 }}>
          <div style={{ maxWidth: 20, maxHeight: 20, display: "inline-block" }}>
            <img
              style={{ maxHeight: 20, maxWidth: 20 }}
              src="/assets/images/loading.gif"
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: 180,
              height: 50,
              paddingLeft: 20
            }}
          >
            Loading Product Data
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="cb_account__content">
            <div className="cb_account__content-header">
              <ul className="cb_breadcrumb cb_breadcrumb--title">
                <li className="cb_breadcrumb__active">
                  <a className="cb_breadcrumb__link">Add/Edit Products</a>
                </li>
                <li className="cb_breadcrumb__delimiter">
                  <i className="fal fa-arrow-right" />
                </li>
              </ul>
              <div className="cb_account__content-header-message">
                <p />
              </div>
            </div>
            <div className="cb_account__content-content">
              <ProductForm
                productId={this.props.match.params.productId}
                product={this.state.product}
                parentLots={this.state.parentLots}
                clientId={this.props.match.params.clientId}
                reloadProduct={this.reloadProduct}
              />
            </div>

            {this.state.dispensaries !== false &&
            this.state.product.active === true && (
              <div>
                <div className="cb_data-table__table">
                  <DistributeProduct
                    product={this.state.product}
                    clientId={this.props.match.params.clientId}
                    onAdd={this.loadProduct}
                    dispensaryOptions={this.state.dispensaryOptions}
                    {...this.props}
                  />
                </div>
                <div className="cb_data-table__table">
                  <ProductDistribution
                    distributionLots={this.state.product.distribution}
                    clientId={this.props.match.params.clientId}
                    deleteDistribution={this.deleteDistribution}
                    dispensaries={this.state.dispensaries}
                    {...this.props}
                  />
                </div>
              </div>
            )}

            {/* {this.props.user.adminUser === true && (
              <div className="cb_data-table__table">
                <h1>Shipments</h1>
                Show Expired Shipments{" "}
                <Switch
                  onChange={() => {
                    this.setState(
                      {
                        loadInactiveShipments: !this.state.loadInactiveShipments
                      },
                      () => this.loadShipmentLots()
                    );
                  }}
                />
                {this.state.shipmentLots !== false ? (
                  <ProductShipments
                    shipmentLots={this.state.shipmentLots}
                    clientId={this.props.match.params.clientId}
                    expireLot={this.expireLot}
                    {...this.props}
                  />
                ) : (
                  <div>Shipments Loading</div>
                )}
              </div>
            )} */}
          </div>
        </div>
      );
    }
  }
}

ManageProduct.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
      clientId: PropTypes.string
    })
  }),
  user: PropTypes.object,
  location: PropTypes.object
};
export default ManageProduct;
