import React, { Component } from "react";
import matchSorter from "match-sorter";
import CannastampIcon from "../../../components/product/CannastampIcon";
import PdfGenerator from "../../../svgTools/PdfGenerator";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

class LotList extends Component {
  static defaultProps = {
    expireLot: false
  };
  constructor(props) {
    super(props);
    this.state = {
      childLots: false
    };
  }

  tableView() {
    if (this.props.lotList === null) {
      return <div>Empty Lot List</div>;
    } else {
      const adminColumns = this.props.admin
        ? [
            {
              Header: "Producer",
              id: "producerName",
              accessor: d => d.producer.name,
              filterable: false,
              sortable: false
            },
            {
              Header: "Type",
              id: "lotType",
              accessor: d => d.lotType,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["lotType"]
                }),
              filterAll: true
            }
          ]
        : null;

      const standardColumns = [
        {
          Header: "Cannastamp",
          maxWidth: 75,
          id: "Cannastamp",
          accessor: d => d,
          filterable: false,
          sortable: false,
          Cell: ({ row }) => {
            if (
              typeof row._original.testResult !== "object" &&
              typeof row._original.product.latestLot === "object"
            ) {
              return (
                <div style={{ width: 50 }}>
                  <CannastampIcon
                    key={row._original.product.latestLot.testResult._id}
                    size={100}
                    testResult={row._original.product.latestLot.testResult}
                  />
                </div>
              );
            } else if (typeof row._original.testResult === "object") {
              return (
                <div style={{ width: 50 }}>
                  <CannastampIcon
                    key={row._original.testResult._id}
                    size={100}
                    testResult={row._original.testResult}
                  />
                </div>
              );
            } else {
              return <div style={{ width: 50 }}>None</div>;
            }
          }
        },
        {
          Header: "S2S Lot",
          id: "s2sId",
          accessor: d => d.s2sId,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["s2sId"]
            }),
          filterAll: true
        },
        {
          Header: "Product",
          id: "productName",
          accessor: d =>
            typeof d.product !== "undefined" ? d.product.name : "",
          filterable: !this.props.admin,
          sortable: !this.props.admin,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["productName"]
            }),
          filterAll: true
        },
        {
          Header: "Strain Name",
          id: "strainName",
          accessor: d =>
            typeof d.product !== "undefined" ? d.product.strain : "",
          filterable: !this.props.admin,
          sortable: !this.props.admin,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["strainName"]
            }),
          filterAll: true
        },
        {
          Header: "Location",
          id: "dispensary",
          accessor: d => {
            if (d.dispensary === null) {
              return <div>deleted lot</div>;
            } else {
              return typeof d.dispensary !== "undefined" ? (
                d.dispensary.name
              ) : (
                <div>No Location</div>
              );
            }
          },
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["dispensary"]
            }),
          filterAll: true
        },
        {
          Header: "Date Added",
          id: "DateAdded",
          accessor: d => d.createdAt,
          sort: "desc",
          render: props => <span>{props.value}</span>
        },
        {
          Header: "On Market",
          id: "onMarket",
          accessor: d => {
            return d.available === true ? "Yes" : "No";
          },
          sort: "desc",
          render: props => <span>{props.value}</span>
        }
        // ,
        // {
        //   Header: "Expire Lot",
        //   Cell: ({ row }) => (
        //     <div>
        //       <a
        //         onClick={() =>
        //           this.props.expireLot(row._original.testResult._id)
        //         }
        //       >
        //         Expire
        //       </a>
        //     </div>
        //   )
        // }
      ];

      if (adminColumns !== null) {
        standardColumns.unshift(...adminColumns);
      }

      return (
        <div>
          <ReactTable
            data={this.props.lotList}
            filterable
            pages={this.props.pages}
            manual
            onFetchData={this.props.fetchData}
            loading={this.props.loading}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={standardColumns}
            // SubComponent={row => {
            //   return (
            //     <div>
            //       <PdfGenerator
            //         testResult={row.original.testResult}
            //         clientId={row.original.owner}
            //         lotId={row.original._id}
            //       />
            //     </div>
            //   );
            // }}
          />
          <br />
        </div>
      );
    }
  }

  render() {
    return <div>{this.tableView()}</div>;
  }
}

export default LotList;
