import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";

export interface DialogProps {
  onClose?: () => void;
  input?: {
    label: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    type?: string;
    inputProps?: OutlinedInputProps["inputProps"];
  };
  title: string;
  content: string;
  primary?: {
    text: string;
    action: () => void;
  };
  secondary?: {
    text: string;
    action: () => void;
  };
}

interface OwnProps extends DialogProps, WithStyles<typeof styles> {
  open: boolean;
}

type Props = RouteComponentProps & OwnProps;

const styles = {
  root: {
    color: "#696969",
    fontFamily: "Quattrocento Sans, sans-serif",
    fontSize: "1.1rem",
    zIndex: 9001
  },
  button: {
    borderRadius: 0,
    color: "#0f252c",
    outline: "none",
    textDecoration: "none",
    "&.cb_alert_dialog-secondary": {
      backgroundColor: "#6ceaf3"
    },
    "&:hover": {
      backgroundColor: "#13d8e6",
      // transition: "300ms ease-in"
    }
  },
  paper: {
    borderRadius: 3
  },
  title: {
    "& h2": {
      color: "#0f252c",
      fontFamily: "Quattrocento Sans, sans-serif",
      fontSize: "1.475rem",
      fontWeight: 700
    }
  }
};

class DialogModal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  Input = () => {
    const { input } = this.props;
    return (
      <TextField
        autoFocus
        fullWidth
        label={input.label}
        margin="dense"
        onChange={input.onChange}
        required
        type={input.type || "text"}
        value={input.value}
        inputProps={input.inputProps}
      />
    );
  };

  render() {
    const {
      classes,
      content,
      input,
      onClose,
      open,
      primary,
      secondary,
      title
    } = this.props;

    return (
      <div className="cb_alert_dialog">
        <Dialog
          classes={{ paper: classes.paper, root: classes.root }}
          open={open}
          onClose={onClose}
          aria-labelledby="cb_alert_dialog-title"
          aria-describedby="cb_alert_dialog-description"
        >
          <DialogTitle
            classes={{ root: classes.title }}
            children={title}
            id="cb_alert_dialog-title"
          />
          <DialogContent>
            {content && (
              <DialogContentText
                id="cb_alert_dialog-description"
                classes={{ root: classes.root }}
              >
                {content}
              </DialogContentText>
            )}
            {typeof input !== "undefined" && <this.Input />}
          </DialogContent>
          <DialogActions>
            {primary && (
              <Button
                className="cb_alert_dialog-primary"
                color="primary"
                onClick={primary.action}
              >
                {primary.text}
              </Button>
            )}
            {secondary && (
              <Button
                className="cb_alert_dialog-secondary"
                color="secondary"
                onClick={secondary.action}
              >
                {secondary.text}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DialogModal));
