import * as React from "react";
import styled from "styled-components";
import { Typography, Button, withStyles, Theme } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";
import { EntityInterface } from "cbd-api-types";

export interface ThankYouProps extends WithStyles<typeof styles> {
  title: string;
  subTitle: string;
  logoUrl: string;
  close?: () => void;
  clientData?: EntityInterface;
}

class ThankYou extends React.Component<ThankYouProps> {
  public render() {
    const { classes, close, clientData } = this.props;
    return (
      <CenteredFullWidthHStack>
        {clientData && clientData.featureImage && (
          <img
            src={clientData.featureImage}
            style={{
              width: "auto",
              maxHeight: "26rem",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            alt={`${clientData.name} logo`}
          />
        )}
        <CenteredNumber>
          <Typography variant="h4">Thanks For Signing Up</Typography>
          <Typography variant="subtitle1">
            We'll Close This Momentarily!
          </Typography>
          {close && (
            <Button onClick={close} className={classes.centeredBlock}>
              Close
            </Button>
          )}
        </CenteredNumber>
      </CenteredFullWidthHStack>
    );
  }
}

// tslint:disable-next-line: one-variable-per-declaration
const styles = (theme: Theme) => ({
  centeredBlock: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "3px",
    marginTop: "5px",
    display: "block",
    maxWidth: "300px"
  }
});

export default withStyles(styles)(ThankYou);
export const CenteredFullWidthHStack = styled.span`
  text-align: center;
  width: 100%;
`;

export const Biggertitle = styled.h1`
  font-size: 20px;
`;

export const UserPrompt = styled.span`
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
`;

const CenteredNumber = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
