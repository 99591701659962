import React, { Component } from "react";
import PropTypes from "prop-types";
import TestResultForm from "./clientComponents/testResult/TestResultForm";
class ContactSales extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { clientData } = this.props;
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__current">Get More with Pro</li>
            </ul>
            <div className="cb_account__content-header-message cb_go-pro-header">
              <p>
                Upgrade your free account today to get access to the suite of
                premium features that come with Cannabinder Pro Loyalty+
              </p>
              <a
                className="cb_button--cta"
                href="mailto:sales@cannabinder.com?subject=Sign Up For Producer Pro"
              >
                Go Pro Now
              </a>
              {clientData.type !== "producer" && (
                <div>
                  <br />
                  <div className="cb_badge-group">
                    <div className="cb_badge cb_badge--purple">
                      $400/mo +$50/ Additional Kiosk
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="cb_account__content-content">
            <div className="row cb_pro-features">
              <div className="col-sm-12">
                <h3 className="cb_heading--section">Sales Kiosk & Loyalty+</h3>
              </div>
              <div className="col-sm-4 cb_feature-card">
                <h4 className="cb_heading--card-title">Sales Kiosk</h4>
                {clientData.type === "producer" && (
                  <div className="cb_badge-group">
                    <div className="cb_badge cb_badge--purple">$200/mo</div>
                  </div>
                )}

                <p className="cb_text--base">
                  Our sales kiosk empowers your employees when they are
                  interacting with customers. Cannabinder helps answer the
                  hardest questions and foster a journy of exploration.
                </p>
              </div>
              <div className="col-sm-4 cb_feature-card">
                <img
                  className="cb_image"
                  src="/assets/images/tablet-loyalty-500.png"
                  alt=""
                />
              </div>
              <div className="col-sm-4 cb_feature-card">
                <h4 className="cb_heading--card-title">Pro Loyalty+</h4>
                {clientData.type === "producer" && (
                  <div className="cb_badge-group">
                    <div className="cb_badge cb_badge--purple">+$100/mo</div>
                  </div>
                )}

                <p className="cb_text--base">
                  Market to your loyal customers directly with your own loyalty
                  program. All Loyalty accounts come with 3000 message/mo and
                  its just $0.03/message after.
                </p>
              </div>
              <div className="col-sm-12 cb_feature-card">
                <h4 className="cb_heading--card-title">Premium Support</h4>
                <p className="cb_text--base">
                  Our Pro level account support team will be there to assist you
                  when you need it. Well respond to all support requests within
                  24 hours.
                </p>
              </div>
            </div>
            <div className="row cb_go-pro-cta">
              <div className="col-sm-12">
                <h3 className="cb_heading--section">Ready to go Pro?</h3>
                <a
                  className="cb_button--cta"
                  href="mailto:sales@cannabinder.com?subject=Sign Up For Producer Pro"
                >
                  Go Pro Now
                </a>
              </div>
            </div>
            <div className="row cb_account-managers">
              <div className="col-sm-12">
                <p className="cb_text--subhead">
                  Still not sure Producer Pro is right for you?
                </p>
                <h3 className="cb_heading--section">
                  Get the full story from one of our awesome Account Managers.
                </h3>
              </div>
              <div className="col-sm-3">
                <img
                  src="/assets/images/kayla-hall-web.jpg"
                  alt="Viyda Schatz | Cannabinder Account Manager"
                />
                <h5>Kayla Hall</h5>
                <p>
                  <a href="tel:6195732539">619.573.2539</a>
                </p>
                <a
                  className="cb_button--rounded-button"
                  href="mailto:kayla.hall@cannabinder.com?subject=Sign Up For Producer Pro"
                >
                  Email Kayla
                </a>
              </div>
              <div className="col-sm-3">
                <img
                  src="/assets/images/justin-cannell--profile.png"
                  alt="Justin Cannell | Cannabinder Account Manager"
                />
                <h5>Justin Cannell</h5>
                <p>
                  <a href="tel:3609772991">360.977.2991</a>
                </p>
                <a
                  className="cb_button--rounded-button"
                  href="mailto:justin.cannell@cannabinder.com?subject=Sign Up For Producer Pro"
                >
                  Email Justin
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactSales.propTypes = {
  match: {
    params: {
      testResultId: PropTypes.string,
      clientId: PropTypes.string
    }
  },
  getClientProducts: PropTypes.func
};

export default ContactSales;
