import React, { Component } from "react";
import PropTypes from "prop-types";
import TestResultForm from "../testResult/TestResultForm";
import Select from "react-select";
import formTools from "../generic/formTools";
import ProductOptions from "./ProductOptions";
import ProductOption from "./ProductOption";
import ProductLotOption from "../productLot/ProductLotOption";
import ClientService from "../../../apiService/ClientService";
import Papa from "papaparse";
import async from "async";

class DistributeProduct extends Component {
  static defaultProps = {
    product: false,
    onAdd: false
  };
  constructor(props) {
    super(props);

    // set the default product select option when a product is present
    let theProductOption = false;
    let theProductLotOption = false;
    if (typeof props.product.latestLot !== "undefined") {
      let aProductOption = new ProductOption(props.product);
      theProductOption = aProductOption.optionObject();
    }

    this.state = {
      productDefaultOption: theProductOption,
      dispensaryOptions: false,
      targetDispensary: false
    };
  }

  deployProduct = () => {
    if (this.state.targetDispensary !== false) {
      let distribution = this.props.product.distribution;
      distribution.push({
        dispensary: this.state.targetDispensary.value
      });
      ClientService.saveProduct(
        this.props.token,
        this.props.clientId,
        JSON.stringify({ distribution }),
        this.props.product._id
      ).then(
        response => {
          this.props.onAdd(response);
        },
        rejected => {
          console.warn(rejected);
        }
      );
    } else {
      this.setState({ message: "Select a dispensary!" });
    }
  };

  bulkUploadCsv = event => {
    const fileObject = event.target.files[0];
    Papa.parse(fileObject, { header: true, complete: this.processCsv });
  };

  processCsv(csvResults) {
    let data = csvResults.data;
    let total = data.length;
    let completed = 0;
    async.mapLimit(data, 3, (dispensary, next) => {
      completed++;
      next();
    });
  }

  form = () => {
    return (
      <div style={{ marginTop: 30, width: "100%" }}>
        <h1>Add New Product Distribution</h1>
        <h2>{this.state.message}</h2>

        <div className="cb_form">
          <form
            id="shipmentForm"
            className="cb_form__form"
            onSubmit={this.handleAddShipment}
            value="submit"
          >
            <div>
              <div className="cb_form__row">
                <div className="cb_form__column">
                  <div className="cb_input--group">
                    <label htmlFor="name">Destination</label>
                    <Select
                      styles={{ width: 300 }}
                      name={"dispensary"}
                      id={"dispensary"}
                      placeholder="Target Dispensary"
                      options={this.props.dispensaryOptions}
                      required={true}
                      onChange={value => {
                        this.setState({
                          targetDispensary: value,
                          message: null
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="cb_form__column">
                  <div className="cb_input--group">
                    <input
                      style={{ marginTop: 30 }}
                      type="button"
                      value="+"
                      accept=".csv"
                      onClick={this.deployProduct}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  render() {
    if (typeof this.props.product.latestLot !== "undefined") {
      return <div>{this.form()}</div>;
    } else if (typeof this.props.product.latestLot === "undefined") {
      return (
        <div style={{ width: 500 }}>
          <h3>Add Product Distribution</h3>
          <h2>{this.state.message}</h2>
          <h2> No Test Results</h2>
          <p>Add test results to distribute the product.</p>
        </div>
      );
    } else {
      return (
        <div style={{ width: 200 }}>
          <h3>Add Product Distribution</h3>
          <h2>{this.state.message}</h2>
          <div style={{ maxWidth: 20, maxHeight: 20, display: "inline-block" }}>
            <img
              style={{ maxHeight: 20, maxWidth: 20 }}
              src="/assets/images/loading.gif"
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: 180,
              height: 50,
              paddingLeft: 20
            }}
          >
            Loading Distribution Data
          </div>
        </div>
      );
    }
  }
}

export default DistributeProduct;
