import React, { Component } from "react";
import PropTypes from "prop-types";
import Job from "./Job";

class JobQue extends Component {
  static propTypes = {
    jobList: [PropTypes.object],
    clientId: PropTypes.string,
    token: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      jobList: props.jobList
    };
  }
  removeJob(jobLocation) {
    this.setState({ jobList: this.state.jobList.splice(jobLocation, 1) });
  }
  render() {
    return (
      <div>
        {this.state.jobList.map((job, index) => {
          return (
            <Job
              jobId={job.jobId}
              sourceLot={job.sourceLot}
              targetLotS2sId={job.targetLotS2sId}
              targetClient={job.targetClient}
              printTemplate={job.printTemplate}
              stampCount={job.stampCount}
              removeJobFromParent={() => {
                this.removeJob(index);
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default JobQue;
