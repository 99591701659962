import * as Cookies from "es-cookie";
import * as React from "react";
import { withRouter } from "react-router";
import UserService from "../apiService/UserService";
import AdminService from "../apiService/AdminService";
import ClientService from "../apiService/ClientService";
import PropTypes from "prop-types";

export const authenticationHOCPropTypes = {
  location: PropTypes.string,
  logout: PropTypes.func,
  redirect: PropTypes.func,
  checkAdminToken: PropTypes.func,
  checkToken: PropTypes.func,
  checkUserOnEntity: PropTypes.func,
  user: PropTypes.string,
  token: PropTypes.string
};

export const AuthenticationHOC = () => WrappedComponent => {
  // Do something with the options here or some side effects
  AuthenticationHOC.propTypes = authenticationHOCPropTypes;
  class CookieHOCWrapper extends React.Component {
    // Define how your HOC is shown in ReactDevTools

    constructor(props) {
      super(props);
      // because of mixed Cookie tools, we are having to parse the user with JSON. coming back as a string.
      const user =
        typeof Cookies.get("user") !== "undefined"
          ? JSON.parse(Cookies.get("user", { path: "/" }))
          : undefined;
      const token = Cookies.get("token", { path: "/" });
      let authenticated = false;
      if (user && token) {
        authenticated = true;
      }
      this.state = {
        user: user,
        token: token,
        redirectTrigger: false,
        redirectUrl: "",
        isAuthenticated: authenticated
      };
      this.logout = this.logout.bind(this);
      this.redirect = this.redirect.bind(this);
      this.checkToken = this.checkToken.bind(this);
      this.checkAdminToken = this.checkAdminToken.bind(this);
      this.checkUserOnEntity = this.checkUserOnEntity.bind(this);
    }

    logout(url = "/") {
      Cookies.remove("user", { path: "/" });
      Cookies.remove("token", { path: "/" });
      this.setState({ redirectTrigger: true, redirectUrl: url });
    }

    updateUser = (incommingUser, incommingToken) => {
      let d = new Date();
      d.setTime(d.getTime() + 30 * 60 * 60 * 1000);
      Cookies.set("token", incommingToken, { expires: d, path: "/" });
      Cookies.set("user", JSON.stringify(incommingUser), {
        expires: d,
        path: "/"
      });
      this.setState({ user: incommingUser, token: incommingToken });
    };

    redirect(url) {
      this.setState({ redirectTrigger: true, redirectUrl: url });
    }

    checkAdminToken() {
      return new Promise(resolve => {
        if (typeof Cookies.get("token") === "undefined") {
          this.logout();
        } else {
          AdminService.validateAdminSession(
            Cookies.get("token", { path: "/" }),
            Cookies.get("user", { path: "/" })
          )
            .then(response => {
              if (response === false) {
                // invalid session - could be malicious - email already sent froms erver
                this.logout();
                this.redirect("/goodbye");
              }
              resolve(response);
            })
            .catch(() => {
              this.logout();
              this.redirect("/goodbye");
              // window.location.reload();
            });
        }
      });
    }

    checkServiceToken = () => {
      return new Promise(resolve => {
        if (typeof Cookies.get("token") === "undefined") {
          this.logout();
        } else {
          AdminService.validateServiceAdminSession(
            Cookies.get("token", { path: "/" }),
            Cookies.get("user", { path: "/" })
          )
            .then(response => {
              if (response === false) {
                // invalid session - could be malicious - email already sent froms erver
                this.logout();
              }
              resolve(response);
            })
            .catch(reason => {
              console.warn("REASON", reason);
              // this.logout();
              this.redirect("/goodbye");
              window.location.reload();
            });
        }
      });
    };

    checkToken(redirectUrl) {
      // if there is a token, check it and logout if invalid. if no token. logout
      if (typeof this.state.token !== "undefined") {
        UserService.validateSession(Cookies.get("token")).then(response => {
          if (typeof response.message !== "undefined") {
            if (response.message === "jwt expired") {
              this.logout();
              this.redirect(redirectUrl);
            }
          }
        });
      } else {
        this.logout();
        this.redirect(redirectUrl);
        // window.location.reload();
      }
    }
    
    /**
     * Checks the access to an entity based on the path route.
     * Assumes the browser path is /client/:action/:entityid/
     */
    checkUserOnEntity() {
      return new Promise(resolve => {
        let pathArray = this.props.location.pathname.split("/");
        let entityId = pathArray[2];

        // this responds to a rejection by pushing the user back to the homepage
        ClientService.validateUserOnEntity(
          this.state.user,
          entityId,
          this.state.token
        )
          .then(response => {
            if (response === false) {
              // invalid session - could be malicious - email already sent froms erver
              this.logout();
            }
            const kioskUser = Cookies.get("kioskUser", { path: "/" });
            if (
              kioskUser &&
              this.props.location.pathname !== "/whitelabel/" + kioskUser
            ) {
              this.redirect(`whitelabel/${kioskUser}`);
              window.location.reload();
            } else {
              resolve(response);
            }
          })
          .catch(reason => {
            this.redirect("/");
            window.location.reload();
          });
      });
    }

    redirector = () => {
      if (this.state.redirectTrigger === true) {
        this.setState({ redirectTrigger: false });
        this.props.history.push("/" + this.state.redirectUrl);
        window.location.reload();
        return;
        //return <Redirect to={this.state.redirectUrl} />;
      }
      return;
    };

    // Implement other methods here

    render() {
      // Render all your added markup
      return (
        <div>
          {this.redirector()}
          {/* render the wrapped component like this, passing the props and state */}
          <WrappedComponent
            redirect={this.redirect}
            logout={this.logout}
            checkToken={this.checkToken}
            checkAdminToken={this.checkAdminToken}
            user={this.state.user}
            token={this.state.token}
            checkUserOnEntity={this.checkUserOnEntity}
            checkServiceToken={this.checkServiceToken}
            updateUser={this.updateUser}
            {...this.props}
          />
        </div>
      );
    }
  }
  return withRouter(CookieHOCWrapper);
};
