import React, { Component } from "react";
import UserService from "../apiService/UserService";
import { Redirect, withRouter } from "react-router-dom";
import * as Cookies from "es-cookie";
import { GoogleLogin } from "react-google-login";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { oAuthMode } from "../configurator";
import { withStyles } from "@material-ui/core";

const classes = {
  inputRadioGroup: {
    display: "inline-block",
    marginBottom: 20
  }
};

class SignUpPage extends Component {
  static defaultProps = {
    redirectUrl: "/binder"
  };
  constructor(props) {
    super(props);
    let { entityType } = props.match.params;
    this.state = {
      email: "yourEmail@mail.com",
      errorPrompt: "",
      submitDisabled: false,
      accountType: typeof entityType !== "undefined" ? entityType : "customer",
      redirect: false,
      oAuthConfig: oAuthMode(window.location.href)
    };
  }

  facebookResponse = response => {
    const tokenBlob = new Blob(
      [JSON.stringify({ access_token: response.accessToken }, null, 2)],
      { type: "application/json" }
    );
    const options = {
      method: "POST",
      body: tokenBlob,
      mode: "cors",
      cache: "default"
    };
    fetch(this.state.facebookCallBack, options).then(r => {
      if (r.status == "401") {
        alert("Not Authorized");
      } else {
        const newToken = r.headers.get("x-auth-token");
        r.json().then(newUser => {
          if (newToken) {
            this.handleLogin(newUser, newToken);
          }
        });
      }
    });
  };

  googleResponse = response => {
    const tokenBlob = new Blob(
      [
        JSON.stringify(
          {
            access_token: response.accessToken,
            accountType: this.state.accountType
          },
          null,
          2
        )
      ],
      { type: "application/json" }
    );
    const options = {
      method: "POST",
      body: tokenBlob,
      mode: "cors",
      cache: "default"
    };
    fetch(this.state.oAuthConfig.google.callbackURL, options).then(r => {
      const newToken = r.headers.get("x-auth-token");
      r.json().then(newUser => {
        if (newToken) {
          this.handleLogin(newUser, newToken);
        }
      });
    });
  };
  // ---------------------------------------------------------------------------

  handleLogin = (user, token) => {
    let d = new Date();
    const { redirectUrl } = this.props;
    const { accountType } = this.state;
    // this.props.updateUser(user, token);
    d.setTime(d.getTime() + 30 * 60 * 60 * 1000);
    Cookies.set("token", token, { expires: d, path: "/" });
    Cookies.set("user", JSON.stringify(user), { expires: d, path: "/" });
    if (
      accountType === "dispensary" ||
      accountType === "producer" ||
      user.dispensaryUser ||
      user.processorUser
    ) {
      this.props.receiveLogin(user, token, "/ClaimYourCompany");
    } else {
      this.props.receiveLogin(user, token);
    }
    // FS.identify(user._id, {
    //   displayName: user.email,
    //   email: user.email
    // });
  };
  // ------------ End of Validators ------------------//
  processSignUp = event => {
    event.preventDefault();
    this.setState({ submitDisabled: true });
    const email = document.getElementById("email").value.toLowerCase();
    const password = document.getElementById("password").value;
    const passwordVer = document.getElementById("passwordVer").value;
    var req = {
      body: {
        email: email,
        password: password,
        passwordVer: passwordVer,
        accountType: this.state.accountType
      }
    };
    UserService.userSignUp(req)
      .then(results => {
        if (results.error) {
          this.setState({
            errorPrompt: results.error.message,
            submitDisabled: false
          });
        } else
          UserService.userSignIn(req)
            .then(results => {
              // succesfully signedup up an account, so lets log them in
              if (results.user) this.handleLogin(results.user, results.token);
              else
                this.setState({
                  errorPrompt: "the server failed to return a user."
                });
            })
            .catch(err => {
              this.setState({ submitDisabled: false });
            });
      })
      .catch(err => {
        if (err.error.message) {
          this.setState({
            errorPrompt: err.error.message,
            submitDisabled: false
          });
        } else if (err.error) {
          this.setState({
            errorPrompt: err.error,
            submitDisabled: false
          });
        }
      });
  };

  changeAccountType = (event, value) => {
    this.setState({
      accountType: value
    });
  };

  render() {
    let { classes } = this.props;
    let { entityType } = this.props.match.params;
    return (
      <div id="cb_masthead--signup">
        <div className="cb_masthead__content">
          <div className="cb_masthead__content-inner">
            <div className="cb_signup-form">
              <div>
                <h1 className="cb_signup-form__header">
                  Sign Up For Cannabinder
                </h1>
                {this.state.redirect !== false && (
                  <Redirect to={this.state.redirect} />
                )}
                <h3 className={"cb_masthead__heading"}>Account Type</h3>
                <RadioGroup
                  className={classes.inputRadioGroup}
                  defaultValue={
                    typeof entityType !== "undefined" ? entityType : "customer"
                  }
                  onChange={this.changeAccountType}
                >
                  <FormControlLabel
                    value={"producer"}
                    control={<Radio />}
                    label={"Producer"}
                  />
                  <FormControlLabel
                    value={"dispensary"}
                    control={<Radio />}
                    label={"Dispensary"}
                  />
                  <FormControlLabel
                    value={"customer"}
                    control={<Radio />}
                    label={"Customer"}
                  />
                </RadioGroup>
              </div>
              <div className="cb_signup-form__left">
                {this.state.accountType === "customer" ? (
                  <div>
                    <h3 className="cb_masthead__heading">
                      Sign Up Using Google
                    </h3>
                    <div>
                      <GoogleLogin
                        clientId={this.state.oAuthConfig.google.clientID}
                        buttonText="Login with Google"
                        onSuccess={this.googleResponse}
                        onFailure={this.onFailure}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <h3 className="cb_masthead__heading">
                      Try Cannabinder Pro <br />
                      Free For 30 Days!
                    </h3>
                    <img
                      className="cb_image cb_image--producer-features-image--right"
                      src="/assets/images/tablet-loyalty-500.png"
                      style={{
                        width: 200,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                      alt=""
                    />
                  </div>
                )}
              </div>
              <div className="cb_signup-form__right">
                <h3 className="cb_masthead__heading">Sign Up With Email</h3>
                <form onSubmit={this.processSignUp}>
                  <p className="cb_masthead__heading">
                    {this.state.errorPrompt}
                  </p>
                  <input
                    className="cb_signup-form__email"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email Address (Required)"
                  />
                  <input
                    className="cb_signup-form__password"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password (Required)"
                  />
                  <input
                    className="cb_signup-form__password"
                    type="password"
                    id="passwordVer"
                    name="passwordVer"
                    placeholder="Repeat Password (Required)"
                  />
                  <button
                    type="submit"
                    className="cb_signup-form__submit"
                    enabled="false"
                    disabled={this.state.submitDisabled}
                  >
                    Sign up
                  </button>
                </form>
              </div>
              <div className="cb_signup-form__bottom">
                <div className={"cb_signup-form__signin"}>
                  If you already have an account, use the sign in button in the
                  upper right-hand corner.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(classes)(withRouter(SignUpPage));
