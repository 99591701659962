import React, { Component } from "react";
import ProductList from "./clientComponents/product/ProductList";
import { clientApiHOCProps } from "./clientComponents/generic/ClientApiHOC";
import PropTypes from "prop-types";
import BulkUploadProduct from "./clientComponents/product/BulkUploadProduct";
import UpgradeCTA from "./clientComponents/UpgradeCTA";

class ManageProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientProducts: null,
      showDeleted: false
    };
    this.getProducts.bind(this);
    this._showDeleted.bind(this);
    this.getProducts();
  }

  _showDeleted = () => {
    this.setState(
      {
        showDeleted: !this.state.showDeleted
      },
      () => {
        this.getProducts();
      }
    );
  }

  getProducts = () => {
    this.props.getClientProducts(this.props.match.params.clientId, false, results => {
      let clientProds = results.filter(product => {
        if (
          this.state.showDeleted === true ||
          product.active === true
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({ clientProducts: clientProds });
    });
  }

  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__current">Manage Products</li>
            </ul>
            <div className="cb_account__content-header-message">
              <p>You can see all of your products in the table below.</p>
              {this.props.clientData.premiumEnabled === false && (
                <div className="cb_grid-item--12">
                  <UpgradeCTA
                    linkUrl={
                      "/client/" +
                      this.props.match.params.clientId +
                      "/premium/signup"
                    }
                    linkText="Go Pro"
                    headline="Help Your Products Standout With Shelf Talkers"
                    body="Package your products with merchandising cards that communicate your products value with MSRP Pricing. Get a demo PDF here!"
                    ctaImageUrl="/assets/images/client/merch-cards-example.jpg"
                    ctaImageLink="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/merch-card-sample.pdf"
                    ctaTarget="_blank"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="cb_account__content-content">
            <div className="cb_data-table">
              <h4 className="cb_data-table__title">Your Products</h4>
              <div className="cb_data-table__toolbar">
                <div className="cb_data-table__toolbar-left">
                  <a
                    className="cb_data-table__toolbar-button"
                    href={
                      "/client/" +
                      this.props.match.params.clientId +
                      "/manageProduct"
                    }
                  >
                    <i className="fal fa-plus-circle" /> Add a Product
                  </a>
                  <BulkUploadProduct {...this.props} />
                </div>
              </div>
              <div className="cb_data-table__table">
                <ProductList
                  productList={this.state.clientProducts}
                  clientId={this.props.match.params.clientId}
                  clientData={this.props.clientData}
                  getProducts={this.getProducts}
                  showDeleted={this.state.showDeleted}
                  _showDeleted={this._showDeleted}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ManageProducts.propTypes = {
  getClientProducts: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.object,
  user: PropTypes.object,
  clientData: PropTypes.object
};

// ManageProducts.propTypes =
//   {
//     match: PropTypes.shape({
//       params: PropTypes.shape({
//         clientId: PropTypes.string
//       })
//     }),
//
//   },
//   clientApiHOCProps
// };
export default ManageProducts;
