import React, { Component } from "react";
import PropTypes from "prop-types";
import { clientApiHOCProps } from "./clientComponents/generic/ClientApiHOC";
import LotListParents from "./clientComponents/productLot/LotListParents";
import BulkUploadTest from "./clientComponents/testResult/BulkUploadTest";
import { MasterPageSpinner } from "../components/master-page-spinner/MasterPageSpinner";

class ManageLots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotList: false,
      masterLotList: false
    };
    props.getClientParentLots(
      this.props.match.params.clientId,
      false,
      results => {
        this.setState({ lotList: results });
      }
    );
    props.getClientLots(this.props.match.params.clientId, false, results => {
      this.setState({ masterLotList: results });
    });
  }

  render() {
    if (this.state.lotList === false) {
      return <MasterPageSpinner />;
    } else {
      return (
        <div>
          <div className="cb_account__content">
            <div className="cb_account__content-header">
              <ul className="cb_breadcrumb cb_breadcrumb--title">
                <li className="cb_breadcrumb__current">
                  Manage{" "}
                  {this.props.parentLotsOnly === true
                    ? "Parent/Test Lots "
                    : "Products In Stores "}{" "}
                </li>
              </ul>
              <div className="cb_account__content-header-message">
                <p>You can see all of your test results in the table below.</p>
              </div>
            </div>
            <div className="cb_account__content-content">
              <div className="cb_data-table">
                <h4 className="cb_data-table__title">
                  Your{" "}
                  {this.props.parentLotsOnly === true
                    ? "Parent/Test "
                    : "Child "}
                  Lots
                </h4>
                <div className="cb_data-table__toolbar">
                  <div className="cb_data-table__toolbar-left">
                    <a
                      className="cb_data-table__toolbar-button"
                      href={
                        "/client/" +
                        this.props.match.params.clientId +
                        "/testResult"
                      }
                    >
                      <i className="fal fa-plus-circle" /> Add a Test Result
                    </a>
                    <BulkUploadTest {...this.props} />
                  </div>
                </div>
                <div className="cb_data-table__table">
                  <LotListParents
                    lotList={this.state.lotList}
                    clientId={this.props.match.params.clientId}
                    masterLotList={this.state.masterLotList}
                    premiumCoaEnabled={
                      this.props.clientData.coaDataEntryService.enabled
                    }
                    {...this.props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

ManageLots.propTypes = {
  match: {
    params: {
      testResultId: PropTypes.string,
      clientId: PropTypes.string,
      parentLotsOnly: PropTypes.boolean
    }
  }
};

export default ManageLots;
