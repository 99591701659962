import React, { Component } from "react";
//import Product from './Product';
import PublicService from "../apiService/PublicService";
import SearchView from "./SearchView";
import SearchResultsView from "./product/SearchResultsView";
import PropTypes from "prop-types";
import { debounce } from "lodash";

class SearchController extends Component {
  static propTypes = {
    isHeader: PropTypes.bool.isRequired,
    hidden: PropTypes.bool,
    toggleSearch: PropTypes.func,
    manualSearchString: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      productsAvailable: [],
      productsUnavailable: [],
      ShowPrompt: false,
      MessagePrompt: null
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.scrollToRef = React.createRef();
    if (props.manualSearchString) {
      alert(props.manualSearchString);
      this.search(props.manualSearchString);
    }
    this.search = debounce(this.search, 400);
  }

  componentDidUpdate(prevProps) {
    if (this.props.manualSearchString !== prevProps.manualSearchString) {
      this.search(this.props.manualSearchString);
    }
  }

  search(searchString) {
    if (searchString.length > 1) {
      this.setState({
        searchString
      });

      PublicService.strainSearch({
        searchString: searchString
      }).then(json => {
        this.setState({
          productsAvailable: json.available,
          productsUnavailable: json.unavailable
        });
        if (json.available.length === 0 && json.unavailable.length === 0) {
          this.setState({
            ShowPrompt: true,
            MessagePrompt: "Try another search. We're still adding products."
          });
        } else {
          this.setState({
            ShowPrompt: false,
            MessagePrompt: null
          });
        }
      });
    } else if (searchString.length === 1) {
      this.setState({
        ShowPrompt: true,
        MessagePrompt: "Type a few more characters!"
      });
    } else {
      this.setState({
        productsAvailable: [],
        productsUnavailable: [],
        ShowPrompt: false,
        MessagePrompt: null
      });
    }
  }
  handleOnChange(event) {
    var searchString = event.target.value;
    this.search(searchString);
  }

  handleClick = event => {
    window.location.assign("/search/" + this.state.searchString);
  };
  handleKeyPress = event => {
    if (event.keyCode === 13) {
      this.navigateToSearch();
    }
  };

  navigateToSearch = () => {
    window.location.assign("/search/" + this.state.searchString);
  };

  scrollToSearch = () => {
    let boundingData = this.scrollToRef.current.getBoundingClientRect();
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = this.scrollToRef.current.getBoundingClientRect(),
      offset = this.scrollToRef.current.top - bodyRect.top;
    // this is how I feel about IE code. FO
    if (
      !(
        window.navigator.userAgent.indexOf("MSIE") > -1 ||
        window.navigator.userAgent.indexOf("Edge") > -1
      )
    ) {
      window.scrollTo({
        top: -(bodyRect.top - (boundingData.top - 100)),
        behavior: "smooth"
      });
    }
  };

  render() {
    let headerMargin = this.props.isHeader && { style: { marginTop: 135 } };
    if (this.props.hidden === false) {
      return <div />;
    } else if (
      (typeof this.state.productsAvailable !== "undefined" &&
        this.state.productsAvailable.length > 0) ||
      (typeof this.state.productsUnavailable !== "undefined" &&
        this.state.productsUnavailable.length > 0)
    ) {
      return (
        <div ref={this.scrollToRef} onClick={this.scrollToSearch}>
          <SearchView
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            handleKeyPress={this.handleKeyPress}
            handleClick={this.handleClick}
            onSearch={this.navigateToSearch}
            isHeader={this.props.isHeader}
            toggleSearch={this.props.toggleSearch}
            manualSearchString={this.props.manualSearchString}
          />
          <div onClick={this.preventBubble} {...headerMargin}>
            <SearchResultsView
              productsAvailable={this.state.productsAvailable}
              productsUnavailable={this.state.productsUnavailable}
              MessagePrompt={this.state.MessagePrompt}
              ShowPrompt={this.state.ShowPrompt}
            />
          </div>
          {this.props.hidden}
        </div>
      );
    } else {
      return (
        <div ref={this.scrollToRef} onClick={this.scrollToSearch}>
          <SearchView
            scrollToRef={this.scrollToRef}
            scrollTo={this.scrollToSearch}
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            isHeader={this.props.isHeader}
            toggleSearch={this.props.toggleSearch}
            manualSearchString={this.props.manualSearchString}
          />
          <div onClick={this.preventBubble} {...headerMargin}>
            <SearchResultsView
              productsAvailable={this.state.productsAvailable}
              productsUnavailable={this.state.productsUnavailable}
              MessagePrompt={this.state.MessagePrompt}
              ShowPrompt={this.state.ShowPrompt}
            />
          </div>
          {this.props.hidden}
        </div>
      );
    }
  }
}
export default SearchController;
