import React from "react";

export default class ProductOption {
  constructor(product) {
    this.product = product;
  }
  optionObject() {
    return {
      value: this.product._id,
      label: (
        <div style={{ verticalAlign: "top" }}>
          {typeof this.product.latestLot._id !== "undefined" ? (
            <div
              style={{ width: "20%", display: "inline-block" }}
              dangerouslySetInnerHTML={{
                __html: this.product.latestLot.testResult.cannastamp
              }}
            />
          ) : null}

          <div
            style={{
              width: "75%",
              verticalAlign: "top",
              display: "inline-block"
            }}
          >
            {this.product.productName} -{this.product.name}
            <br />
            By: {this.product.brand.name}
          </div>
        </div>
      )
    };
  }
}
