/**
 * provides a list view of all clients in the system (dispensaries, producers, labs)
 */
import React, { Component } from "react";
import AdminService from "../apiService/AdminService";
import matchSorter from "match-sorter";
import ClientAdminSelect from "./adminComponents/ClientAdminSelect";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { AdminApiHOC } from "./AdminApiHOC";
import ClientTransferProducts from "./adminComponents/ClientTransferProducts";
import ClientSetPremiumCoaLimit from "./adminComponents/ClientSetPremiumCoaLimit";
import ClientSetPremiumStatus from "./adminComponents/ClientSetPremiumStatus";
import JSONPretty from "react-json-pretty"; // Import React Table
import ReactTable from "react-table";
import { debounce } from "lodash";
import "react-table/react-table.css";
import ClientSetMogreet from "./adminComponents/ClientSetMogreet";

class ClientManage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // availableClients: {},
      availableUsers: {}
    };

    this.reloadData = this.reloadData.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.reloadData();
  }

  // onFetchData for ReactTable
  fetchData(state = {}, instance) {
    this.setState(
      {
        paginate: Object.assign(this.props.paginate, {
          page: state.page,
          pageSize: state.pageSize,
          sorted: state.sorted,
          filtered: state.filtered
        })
      },
      () => {
        this.props.getClients(true, this.state.paginate, results => {
          this.props.setClients(results);
        });
      }
    );
  }

  reloadData(updatedClient = false) {
    if (updatedClient) {
      this.props.getClients(true, this.state.paginate, results => {
        this.props.setClients(results);
      });
    }

    // this.props.getClients(true, undefined, results => {
    //   this.setState({ availableClients: results });
    // });

    this.props.getUsers(true, undefined, results => {
      this.props.setUsers(results);
      let filterUsers = results.filter(user => {
        return !(typeof user.clientAssignment === "string");
      });
      this.setState({ availableUsers: filterUsers });
    });
  }

  downloadClientListCsv() {
    AdminService.getUserCsv(this.props.token, this.props.user).then(results => {
      window.open(results.file);
    });
  }

  tableView(clientData) {
    return (
      <div style={{ paddingTop: 80 }}>
        <ReactTable
          data={clientData}
          filterable
          pages={this.props.paginate.pages}
          manual
          onFetchData={debounce(this.fetchData, 500)}
          loading={this.props.loading}
          defaultPageSize={10}
          className="-striped -highlight"
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Basic",
              columns: [
                {
                  Header: "Name",
                  id: "name",
                  accessor: d => d.name,
                  Cell: ({ row }) => (
                    <div>
                      <a
                        href={
                          "/client/" + row._original._id + "/manageProducts/"
                        }
                      >
                        {row._original.name}
                      </a>
                    </div>
                  ),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                  filterAll: true
                },
                {
                  Header: "Type",
                  id: "type",
                  accessor: d =>
                    d.type.charAt(0).toUpperCase() + d.type.slice(1),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="">Show All</option>
                      <option value="producer">Producer</option>
                      <option value="dispensary">Dispensary</option>
                      <option value="lab">Lab</option>
                      <option value="integrator">Integrator</option>
                    </select>
                  )
                },
                {
                  Header: "License #",
                  id: "licenseNumber",
                  accessor: d =>
                    typeof d.licenseNumber !== "undefined"
                      ? d.licenseNumber
                      : "None",
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["license"] }),
                  filterAll: true
                },
                {
                  Header: "Premium",
                  id: "premiumEnabled",
                  accessor: d => d.premiumEnabled,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["premiumEnabled"]
                    }),
                  filterAll: true
                },
                {
                  Header: "Premium Level",
                  id: "premiumLevel",
                  accessor: d =>
                    d.premiumLevel.charAt(0).toUpperCase() +
                    d.premiumLevel.slice(1),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="">Show All</option>
                      <option value="free">Free</option>
                      <option value="pro">Pro</option>
                    </select>
                  )
                }
              ]
            }
          ]}
          SubComponent={row => {
            return (
              <div>
                <ClientAdminSelect
                  initiallyDisabled={true}
                  userList={this.props.users}
                  availableUsers={this.state.availableUsers}
                  client={row.original}
                  token={this.props.token}
                  reloadParent={this.reloadData}
                />
                {/* <ClientTransferProducts
                  token={this.props.token}
                  reloadParent={this.reloadData}
                  availableClients={this.state.availableClients}
                  sourceClient={row.original}
                  initiallyDisabled={true}
                /> */}
                <ClientSetPremiumCoaLimit
                  client={row.original}
                  initiallyDisabled={true}
                  token={this.props.token}
                  reloadParent={this.reloadData}
                />
                <ClientSetPremiumStatus
                  client={row.original}
                  initiallyDisabled={true}
                  token={this.props.token}
                  reloadParent={this.reloadData}
                />
                <ClientSetMogreet
                  client={row.original}
                  initiallyDisabled={true}
                  token={this.props.token}
                  reloadParent={this.reloadData}
                />
              </div>
            );
          }}
        />
        <br />
      </div>
    );
  }

  render() {
    return (
      <div>
        <a className="cb_header-nav-item-link" href="/admin/addclient">
          Add a Client
        </a>
        {this.tableView(this.props.clients)}
      </div>
    );
  }
}

export default AuthenticationHOC()(AdminApiHOC()(ClientManage));
