import React, { Component } from "react";
import UserService from "../apiService/UserService";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { withStyles, createStyles } from "@material-ui/core";
import * as Cookies from "es-cookie";

const classes = createStyles({
  button: {
    padding: "0.2rem 0.75rem 0.35rem",
    color: "#0f252c",
    backgroundColor: "#6ceaf3",
    border: "0 none",
    fontWeight: "inherit"
  },
  form: {
    textAlign: "left"
  },
  h2: {
    margin: "1rem 0",
    fontSize: "1.5rem",
    textAlign: "left"
  },
  h3: {
    margin: "1rem 0"
  }
});

class PasswordRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  processPasswordChange = event => {
    event.preventDefault();
    this.setState({ submitDisabled: true });
    const email = document.getElementById("email").value;
    const passwordNew = document.getElementById("passwordNew").value;
    const passwordVer = document.getElementById("passwordVer").value;
    const req = {
      body: {
        email: email,
        passwordNew: passwordNew,
        passwordVer: passwordVer
      }
    };

    UserService.userRecoverPassword(this.props.match.params.resetToken, req)
      .then(results => {
        if (results.error === true) {
          this.setState({
            errorPrompt: results.errorMessage,
            submitDisabled: false
          });
        } else {
          this.setState({
            token: results.token,
            user: results.user,
            changeSuccess: true,
            errorPrompt: results.success,
            submitDisabled: false
          });
          this.handleLogin(results.user, results.token);
        }
      })
      .catch(err => {
        this.setState({
          errorPrompt: err.errorMessage,
          submitDisabled: false
        });
      });
  };

  // FIXME: Multiple components are using this. Should we prop it up?
  // eg. LoginModal
  handleLogin = (user, token) => {
    const { history } = this.props;
    let d = new Date();
    d.setTime(d.getTime() + 30 * 60 * 60 * 1000);
    Cookies.set("token", token, { expires: d, path: "/" });
    Cookies.set("user", JSON.stringify(user), { expires: d, path: "/" });
    // Cookies.parse()

    // This is an example script - don't forget to change it!
    if (
      (user.processorUser || user.dispensaryUser) &&
      typeof user.clientAssignment !== "undefined"
    ) {
      history.push(`/client/${user.clientAssignment}/manageBrands`);
      window.location.reload();
    } else if (user.processorUser || user.dispensaryUser) {
      history.push("/ClaimYourCompany");
      window.location.reload();
    } else {
      history.push("/");
      window.location.reload();
    }
  };

  renderRecoveryForm = classes => {
    return (
      <form
        className={"cb_password_change-form" + classes.form}
        onSubmit={this.processPasswordChange}
      >
        <p className="cb_masthead__heading">
          {this.state.errorPrompt ? "*" + this.state.errorPrompt + "*" : " "}
        </p>
        <input
          className="cb_password_change-form__email"
          type="email"
          id="email"
          name="email"
          placeholder="Email Address (Required)"
        />
        <input
          className="cb_password_change-form__new_password"
          type="password"
          id="passwordNew"
          name="passwordNew"
          placeholder="New Password (Required)"
        />
        <input
          className="cb_password_change-form__verify_password"
          type="password"
          id="passwordVer"
          name="passwordVer"
          placeholder="Repeat New Password (Required)"
        />
        <button
          type="submit"
          className={"cb_password_change-form__submit " + classes.button}
          enabled="false"
          disabled={this.state.submitDisabled}
        >
          Change
        </button>
      </form>
    );
  };

  render() {
    let { classes } = this.props;
    return (
      <div className="cb_surface">
        <h1 className="cb_breadcrumb--title">Password Recovery</h1>
        {this.renderRecoveryForm(classes)}
      </div>
    );
  }
}

export default AuthenticationHOC()(withStyles(classes)(PasswordRecovery));
