import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Profile from "../client/Profile";
import ManageProducts from "../client/ManageProducts";
import ManageProduct from "../client/ManageProduct";
import ClientHeader from "../client/clientComponents/generic/ClientHeader";
import PrimaryNav from "../client/clientComponents/generic/PrimaryNav";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { ClientApiHOC } from "../client/clientComponents/generic/ClientApiHOC";
import ManageTestResult from "../client/ManageTestResult";
import ManageParentLots from "../client/ManageParentLots";
import ManageShipmentLots from "../client/ManageShipmentLots";
import PrintStickers from "../client/PrintStickers";
import TrainingPage from "../client/TrainingPage";
import ManageBrand from "../client/ManageBrand";
import ManageBrands from "../client/ManageBrands";
import ManageDispensaryDistribution from "../client/ManageDispensaryDistribution";
import ManageKioskSettings from "../client/ManageKioskSettings";
import MaterialsPage from "../client/MaterialsPage";
import PremiumTestUpload from "../client/PremiumTestUpload";
import ProducerWelcome from "../client/ProducerWelcome";
import WelcomeRedirector from "../client/clientComponents/WelcomeRedirector";
import ContactSales from "../client/ContactSales";
// import ManageMessages from "../client/loyalty/ManageMessages";
import ManageLoyalty from "../client/loyalty/ManageLoyalty";

class RouteClient extends Component {
  constructor(props) {
    super(props);
  }

  // ...this.props passes the props fromt he authenticationHOC and the clientAPIHOC down
  render() {
    return (
      <div className="cb_template" style={{ paddingTop: 0 }}>
        <div className="cb_template__section">
          <div className="cb_surface">
            <ClientHeader {...this.props} />
            <Router>
              <div>
                <Route
                  path="/client/:clientId/:page"
                  render={routeProps => {
                    // this route places the redirect tool in path for producers
                    // we will want to expand this to include dispensaries at some point
                    if (
                      routeProps.match.params.page !== "profile" &&
                      routeProps.match.params.page !== "welcome" &&
                      this.props.clientData.type === "producer"
                    ) {
                      return (
                        <WelcomeRedirector
                          clientId={routeProps.match.params.clientId}
                          token={this.props.token}
                          {...routeProps}
                          {...this.props}
                        />
                      );
                    } else return <div />;
                  }}
                />
                <Route
                  exact
                  path="/client/:clientId/profile/"
                  render={routerProps => (
                    <Profile {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/inventory/"
                  render={routerProps => (
                    <ManageDispensaryDistribution
                      {...this.props}
                      {...routerProps}
                    />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/kiosk/settings/"
                  render={routerProps => (
                    <ManageKioskSettings {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/manageProducts/"
                  render={routerProps => (
                    <ManageProducts {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path={"/client/:clientId/welcome/producer"}
                  render={routerProps => <ProducerWelcome {...routerProps} />}
                />
                <Route
                  exact
                  path="/client/:clientId/manageProduct/:productId"
                  render={routerProps => (
                    <ManageProduct {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/manageBrands/"
                  render={routerProps => (
                    <ManageBrands {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/manageBrand/:brandId"
                  render={routerProps => (
                    <ManageBrand {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/manageBrand/"
                  render={routerProps => (
                    <ManageBrand {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/manageProduct"
                  render={routerProps => (
                    <ManageProduct {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/addProduct/"
                  render={routerProps => (
                    <ManageProduct {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/testResult/:testResultId"
                  render={routerProps => (
                    <ManageTestResult {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/testResult/"
                  render={routerProps => (
                    <ManageTestResult {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/parentLots"
                  render={routerProps => (
                    <ManageParentLots
                      {...this.props}
                      {...routerProps}
                      parentLotsOnly={true}
                    />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/shipmentLots"
                  render={routerProps => (
                    <ManageShipmentLots
                      {...this.props}
                      {...routerProps}
                      parentLotsOnly={true}
                    />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/printStickers/"
                  render={routerProps => (
                    <PrintStickers {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/training/"
                  render={routerProps => (
                    <TrainingPage {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/materials/"
                  render={routerProps => (
                    <MaterialsPage {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/premium-coa-entry/"
                  render={routerProps => (
                    <PremiumTestUpload {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/premium/signup"
                  render={routerProps => (
                    <ContactSales {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/loyalty/analytics"
                  render={routerProps => (
                    <ContactSales {...this.props} {...routerProps} />
                  )}
                />
                <Route
                  exact
                  path="/client/:clientId/loyalty/"
                  render={routerProps => (
                    <ManageLoyalty {...this.props} {...routerProps} />
                  )}
                />
              </div>
            </Router>
            <Router>
              <Route
                path="/client/:clientId/:currentPage/"
                render={routerProps => {
                  if (window.location.href.search("welcome") === -1)
                    return <PrimaryNav {...this.props} {...routerProps} />;
                  else return <div />;
                }}
              />
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthenticationHOC()(ClientApiHOC()(RouteClient));
