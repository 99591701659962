import { oAuthMode } from "../configurator";
const remoteConfig = oAuthMode(window.location.href);
const baseAPI = remoteConfig.baseUrl + "consumer";

export const PublicService = {
  getOneByIdLegacy(productId, user) {
    var body = { ProductId: productId, User: user };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/getProductLegacy/` + productId, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getSimilarProducts(productId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/productComps/` + productId)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getProductLot(productLotId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/productLot/` + productLotId)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  strainSearch(incommingState) {
    let filterUnknownProductConditional = function (item) {
      let isUnknown =
        item.brandName == "unknown" || item.brandName == "Unknown";
      let isEmpty = item.brandName == "";
      return !isUnknown && !isEmpty;
    };
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/search`, {
        method: "POST",
        body: JSON.stringify(incommingState),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => {
          let result = [];
          result.available = json.available.filter(
            filterUnknownProductConditional
          );
          result.unavailable = json.unavailable.filter(
            filterUnknownProductConditional
          );
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  tagSearch() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/search/tags`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getBrandProducts(brandId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/brand/` + brandId + `/products`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },
  getBrand(brandId) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/brand/` + brandId)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default PublicService;
