import React, { Component } from "react";
import PropTypes from "prop-types";
import { clientApiHOCProps } from "./clientComponents/generic/ClientApiHOC";
import TestResultForm from "./clientComponents/testResult/TestResultForm";
class ManageTestResult extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__active">
                <a className="cb_breadcrumb__link">Test Results</a>
              </li>
              <li className="cb_breadcrumb__delimiter">
                <i className="fal fa-arrow-right" />
              </li>
              <li className="cb_breadcrumb__current">
                {typeof this.props.match.params.testResultId === "undefined"
                  ? "New Test Result"
                  : "Test Result"}
              </li>
            </ul>
            <div className="cb_account__content-header-message">
              <p />
            </div>
          </div>
          <div className="cb_account__content-content">
            <div className="cb_article">
              <h4 className="cb_article__title">Test Result</h4>
              <div className="cb_article__content">
                <TestResultForm
                  testResultId={this.props.match.params.testResultId}
                  clientId={this.props.match.params.clientId}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ManageTestResult.propTypes = {
  match: {
    params: {
      testResultId: PropTypes.string,
      clientId: PropTypes.string
    }
  },
  getClientProducts: PropTypes.func
};

export default ManageTestResult;
