import * as React from "react";
import { withStyles, createStyles } from "@material-ui/styles";
import { CardMedia, Theme, WithStyles } from "@material-ui/core";
import { Color } from "../../../kiosk/kioskUtilities/constants";

export interface IMessagePhoneDemoProps extends WithStyles {
  messageContent?: {
    text: string;
    images?: string[];
    url?: string;
    key?: string;
  }[];
}

export interface IMessagePhoneDemoState {}

class MessagePhoneDemo extends React.Component<
  IMessagePhoneDemoProps,
  IMessagePhoneDemoState
> {
  constructor(props: IMessagePhoneDemoProps) {
    super(props);

    this.state = {};
  }

  public render = () => {
    let { classes, messageContent } = this.props;
    messageContent.reverse();
    return (
      <CardMedia
        className={classes.phoneCardMedia}
        image="/assets/images/blank-phone.png"
      >
        {messageContent &&
          messageContent.map((message, mesKey) => {
            return (
              <div className={classes.outboundMessage} key={mesKey}>
                {message.images &&
                  message.images.length &&
                  message.images.map((image, key) => {
                    return (
                      <img src={image} key={key} className={classes.photoTag} />
                    );
                  })}

                {(message.text || message.url) && (
                  <div className={classes.textBubble}>
                    {" "}
                    {message.text && (
                      <div
                        style={{
                          fontSize:
                            14 * Math.min(70 / message.text.length, 1) < 8
                              ? 8
                              : 14 * Math.min(70 / message.text.length, 1)
                        }}
                      >
                        {message.text.split("%0a").map((line, key) => {
                          return (
                            <div key={key}>
                              {line}
                              <br />
                            </div>
                          );
                        })}{" "}
                        {message.url}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </CardMedia>
    );
  };
}

const styles = (_: Theme) =>
  createStyles({
    phoneCardMedia: {
      height: 500,
      backgroundPosition: "center",
      backgroundSize: "contain",
      display: "flex",
      alignItems: "center"
    },
    outboundMessage: {
      width: "71%",
      marginLeft: "18%",
      paddingTop: "1%",
      maxHeight: "62%",
      overflowY: "scroll"
    },
    textBubble: {
      backgroundColor: Color.BlueGray,
      borderRadius: ".5rem",
      fontSize: ".65rem",
      padding: ".6rem",
      textAlign: "right",
      display: "inline-block"
    },
    photoTag: {
      width: "auto",
      maxWidth: 200,
      maxHeight: 150,
      height: "auto",
      float: "right",
      marginRight: 10,
      marginLeft: "100%",
      paddingBottom: 5,
      paddingTop: 5
    }
  });

export default withStyles(styles)(MessagePhoneDemo);
