import React, { Component } from "react";
import matchSorter from "match-sorter";
import CannastampIcon from "../../../components/product/CannastampIcon";
import PdfGenerator from "../../../svgTools/PdfGenerator";
import LotList from "./LotList";
import ClientService from "../../../apiService/ClientService";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

class LotListParents extends Component {
  static defaultProps = {
    lotList: false,
    masterLotList: false,
    premiumCoa: false
  };
  constructor(props) {
    super(props);
    this.state = {
      childLots: false,
      downloadsDisabled: false
    };
  }
  downloadPng(lotId, lotS2S) {
    this.setState({ downloadsDisabled: true });
    let lotPng = ClientService.getLotPng(
      this.props.token,
      this.props.clientId,
      lotId,
      lotS2S
    ).then(
      response => {
        this.setState({ downloadsDisabled: false });
        window.open(response.Location, "_blank");
      },
      reject => {
        console.warn(reject);
        this.setState({ downloadsDisabled: false });
      }
    );
  }

  tableView() {
    if (this.props.lotList === false) {
      return <div>Empty Lot List</div>;
    } else {
      const adminColumns = this.props.admin
        ? [
            {
              Header: "Producer",
              id: "producerName",
              accessor: d => d.producer.name,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["producerName"]
                }),
              filterAll: true
            }
          ]
        : null;
      const premiumColumns =
        this.props.admin || this.props.premiumCoaEnabled === true
          ? [
              {
                Header: "Status",
                id: "Manage",
                Cell: ({ row }) => (
                  <div>
                    {typeof row._original.processingInfo !== "undefined"
                      ? row._original.processingInfo.status
                      : ""}
                  </div>
                )
              }
            ]
          : null;

      const standardColumns = [
        {
          Header: "Cannastamp",
          maxWidth: 75,
          id: "Cannastamp",
          accessor: d => d.testResult._id,
          filterable: false,
          sortable: false,
          Cell: ({ row }) => (
            <div
              style={{ width: 50 }}
              dangerouslySetInnerHTML={{
                __html: row._original.testResult.cannastamp
              }}
            />
          )
        },
        {
          Header: "S2S Lot",
          id: "s2sId",
          accessor: d => d.s2sId,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["s2sId"]
            }),
          filterAll: true
        },
        {
          Header: "Strain",
          id: "strain",
          accessor: d => d.testResult.strain,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["strain"]
            }),
          filterAll: true
        },
        {
          Header: "Lab",
          id: "labName",
          accessor: d =>
            typeof d.testResult.testLab !== "undefined"
              ? d.testResult.testLab.name
              : null,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["labName"]
            }),
          filterAll: true
        },
        {
          Header: "Last Updated",
          id: "updatedAt",
          accessor: d => d.updatedAt,
          sort: "desc",
          render: props => <span>{props.value}</span>
        },

        {
          Header: "Manage",
          filterable: false,
          sortable: false,
          Cell: ({ row }) => (
            <div>
              <a
                href={
                  "/client/" +
                  this.props.clientId +
                  "/testResult/" +
                  row._original.testResult._id
                }
              >
                Test Results
              </a>
            </div>
          )
        }
      ];

      if (adminColumns !== null) {
        standardColumns.unshift(...adminColumns);
      }
      if (premiumColumns !== null) {
        standardColumns.push(...premiumColumns);
      }

      return (
        <div>
          <ReactTable
            data={this.props.lotList}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={standardColumns}
            SubComponent={row => {
              let subLots = "No Child Lots";
              if (this.props.masterLotList !== false) {
                let childLots = this.props.masterLotList.filter(lot => {
                  return typeof lot.parentLot !== "undefined"
                    ? lot.parentLot._id === row.original._id
                    : false;
                });
                subLots = <LotList lotList={childLots} pageSize={5} />;
              }
              return <div style={{ padding: 30 }}>{subLots}</div>;
            }}
          />
          <br />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <br />
        <br />
        {this.tableView()}
      </div>
    );
  }
}

export default LotListParents;
