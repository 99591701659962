import React, { Component } from "react";
//import fileDownload from "js-file-download"
//import PDFDocument from "pdfkit"
//import SVGtoPDF from "svg-to-pdfkit"
import ClientService from "../apiService/ClientService";
//import { Document, Page } from 'react-pdf';
import { AuthenticationHOC } from "../components/AuthenticationHOC";

class PdfGenerator extends Component {
  generatePdf(event, type, dimension1, dimension2) {
    event.preventDefault();
    var win = window.open();
    ClientService.getCannastampPdf(
      this.props.token,
      this.props.clientId,
      this.props.lotId,
      type,
      dimension1,
      dimension2
    ).then(results => {
      try {
        var link = results.link;
        setTimeout(function() {
          win.location = link;
          win.focus();
        }, 300);
      } catch (err) {
        alert("you need popups enabled " + JSON.stringify(err));
      }
    });
  }

  authorizeAccess() {
    if (this.props.user) {
      if (
        this.props.user.dispensaryUser === true ||
        this.props.user.processorUser === true ||
        this.props.user.adminUser === true
      ) {
        return (
          <div>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "full", null, null)}
            >
              Download PDF
            </button>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "circular", 1.5, null)}
            >
              Circular 1.5"
            </button>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "circular", 1.25, null)}
            >
              Circular 1.25"
            </button>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "circular", 1, null)}
            >
              Circular 1"
            </button>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "circular", 0.75, null)}
            >
              Circular 0.75"
            </button>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "square", 1, null)}
            >
              Square 1"
            </button>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "rectangular", 1.25, 1.5)}
            >
              Rectangular 1.25"x1.5"
            </button>
            <button
              id="labToggle"
              className="cb_data-table__toolbar-button"
              onClick={e => this.generatePdf(e, "rectangular", 1.25, 1.75)}
            >
              Rectangular 1.25"x1.75"
            </button>
          </div>
        );
      } else {
        return <div />;
      }
    } else {
      return <div />;
    }
  }

  render() {
    return this.authorizeAccess();
  }
}
export default AuthenticationHOC()(PdfGenerator);
