import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import ProductPage from "../pages/ProductPage";
import SignUpPage from "../pages/SignUpPage";
import PrivacyPage from "../pages/PrivacyPage";
import TermsOfService from "../pages/TermsOfService";
import ResultsPage from "../pages/ResultsPage";
import Invest from "../pages/InvestPage";
import ProducersPage from "../pages/ProducersPage";
import MyCannabinder from "../pages/MyCannabinder";
import ClaimYourCompany from "../pages/ClaimYourCompany";
import HomePage from "../pages/HomePage";
import BlogRouter from "../routers/BlogRouter";
import Terpestival from "../pages/Terpestival";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import AccountPage from "../pages/AccountPage";
import PasswordRecovery from "../pages/PasswordRecovery";
import BrandPage from "../pages/BrandPage";
import DispensaryPage from "../pages/DispensaryPage";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";

class RoutePublic extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/brands" render={props => <ProducersPage />} />
            <Route path="/dispensaries" render={props => <DispensaryPage />} />
            <Route
              path="/help"
              render={() => {
                setTimeout(() => {
                  window.location.assign(
                    "https://cannabinder.atlassian.net/servicedesk/customer/portal/2"
                  );
                }, 1500);

                return (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 100,
                      marginTop: 100
                    }}
                  >
                    Redirecting to our Service Desk
                  </div>
                );
              }}
            />
            <Route path="/invest" render={props => <Invest />} />
            <Route
              path="/user/:_id"
              render={props => (
                <AccountPage
                  user={this.props.user}
                  token={this.props.token}
                  logout={this.props.logout}
                  onUserUpdate={this.props.receiveLogin}
                />
              )}
            />
            <Route
              path="/binder"
              render={routerProps => (
                <MyCannabinder
                  user={this.props.user}
                  token={this.props.token}
                  checkToken={this.props.checkToken}
                  {...routerProps}
                  {...this.props}
                />
              )}
            />
            <Route path="/cannatalk" render={props => <BlogRouter />} />
            <Route
              path="/signup/:CTAText?/:entityType?"
              render={props => (
                <SignUpPage
                  sendLogin={this.props.sendLogin}
                  isAuthenticated={this.props.isAuthenticated}
                  user={this.props.user}
                  receiveLogin={this.props.receiveLogin}
                  accountType={props.entityType}
                />
              )}
            />
            <Route
              path="/ClaimYourCompany"
              render={props => (
                <ClaimYourCompany
                  user={this.props.user}
                  token={this.props.token}
                  // this prop is tunnelled all the way to the form
                  updateUser={this.props.updateUser}
                />
              )}
            />
            <Route
              path="/resetPassword/:resetToken"
              render={props => <PasswordRecovery />}
            />
            <Route
              path="/product/:_id/:referenceLotId?"
              render={props => (
                <ProductPage
                  onModalLogin={this.props.modalLogin}
                  modalAlert={this.props.modalAlert}
                  dispensaryLoginTool={this.dispensaryLoginTool}
                  user={this.props.user}
                  token={this.props.token}
                />
              )}
            />
            <Route
              path="/brand/:brandId"
              render={props => (
                <BrandPage brandId={props.match.params.brandId} />
              )}
            />
            <Route path="/privacy" render={() => <PrivacyPage />} />
            <Route path="/termsofservice" render={() => <TermsOfService />} />
            <Route
              path="/terp*"
              render={props => (
                <Terpestival
                  onModalLogin={this.props.modalLogin}
                  modalAlert={this.props.modalAlert}
                  user={this.props.user}
                  isAuthenticated={this.props.isAuthenticated}
                  token={this.props.token}
                />
              )}
            />
            <Route
              path="/search/:searchString"
              render={props => <ResultsPage />}
            />
            <Route
              path="/search"
              render={props => <ResultsPage />}
            />
            <Route
              path="/contact"
              render={props => <ContactPage />}
            />
            <Route
              path="/about"
              render={props => <AboutPage user={this.props.user} />}
            />
            <Route
              path="/"
              render={props => <HomePage user={this.props.user} />}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default AuthenticationHOC()(RoutePublic);
