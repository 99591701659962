import { oAuthMode } from "../configurator";
const remoteConfig = oAuthMode(window.location.href);
const baseAPI = remoteConfig.baseUrl + "service";

const AdminDataEntryService = {
  /**
   * Update a test results data entry results
   * @param {*} token users authentication token
   * @param {*} formData actual test result data
   * @param {*} id optional ID that can be passed to create a PUT request
   */
  saveTestResultDataEntry(token, formData, id) {
    let fetchUrl = `${baseAPI}/` + token + `/testResult/` + id;
    return new Promise((resolve, reject) => {
      fetch(fetchUrl, {
        method: "PUT",
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the test result");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * Sets a lots status back to pending after a rejection
   * @param {*} token users authentication token
   * @param {*} id optional ID that can be passed to create a PUT request
   */
  updateTestLot(token, lotId, update) {
    let fetchUrl = `${baseAPI}/` + token + `/lot/` + lotId;
    const objectToFormData = require("object-to-formdata");

    return new Promise((resolve, reject) => {
      fetch(fetchUrl, {
        method: "PUT",
        body: objectToFormData(update),
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error("the server failed to save the test result");
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default AdminDataEntryService;
