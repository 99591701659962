import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import * as Cookies from "es-cookie";

class NavigationMenus extends Component {
  constructor(props) {
    super(props);
  }

  consumerUserNav() {
    var listArray = [];
    const kioskUser = Cookies.get("kioskUser", { path: "/" });
    if (
      this.props.user !== "" &&
      this.props.user !== null &&
      typeof this.props.user !== "undefined" &&
      !kioskUser
    ) {
      listArray.push(
        <li key="1" className="cb_nav__menu-item">
          <a
            className="cb_nav__menu-item-link"
            href={"/user/" + this.props.user._id}
          >
            Account
          </a>
        </li>
      );
    } else if (this.props.user && kioskUser) {
      listArray.push(
        <li key="1" className="cb_nav__menu-item">
          <a
            className="cb_nav__menu-item-link"
            href={"/whitelabel/" + kioskUser}
          >
            Kiosk
          </a>
        </li>
      );
    }
    return listArray;
  }

  clientNav() {
    if (
      typeof this.props.user !== "undefined" &&
      typeof this.props.user.clientAssignment !== "undefined" &&
      this.props.user.clientAssignment !== null &&
      !Cookies.get("kioskUser", { path: "/" })
    ) {
      var listArray = [];
      listArray.push(
        <li key="1" className="cb_nav__menu-item">
          <a
            className="cb_nav__menu-item-link"
            href={
              "/client/" + this.props.user.clientAssignment + "/manageProducts"
            }
          >
            Company
          </a>
        </li>
      );
      return listArray;
    } else {
      return null;
    }
  }

  adminNav() {
    var listArray = [];
    if (this.props.user === null || typeof this.props.user === "undefined")
      return null;

    if (this.props.user.adminUser === true) {
      listArray.push(
        <li key="2" className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/users">
            Users
          </a>
        </li>
      );
      listArray.push(
        <li key="3" className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/products">
            Products
          </a>
        </li>
      );
      listArray.push(
        <li key="4" className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/clients">
            Clients
          </a>
        </li>
      );
      listArray.push(
        <li key="5" className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/lots">
            Lots
          </a>
        </li>
      );
      listArray.push(
        <li key="6" className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/testResults">
            Tests
          </a>
        </li>
      );
    }
    return listArray;
  }

  clientUserWithoutAssignment() {
    const { user } = this.props;
    const listArray = [];
    if (
      typeof user !== "undefined" &&
      user !== null &&
      user !== "" &&
      (user.processorUser === true || user.dispensaryUser === true) &&
      user.adminUser !== true &&
      typeof user.clientAssignment === "undefined"
    ) {
      listArray.push(
        <li key="7" className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/ClaimYourCompany">
            My Company
          </a>
        </li>
      );
    }
    return listArray;
  }
  unAuthenticatedNav() {
    var listArray = [];
    listArray.push(
      <li key="8" className="cb_nav__menu-item">
        <a className="cb_nav__menu-item-link" href="/">
          Home
        </a>
      </li>
    );
    listArray.push(
      <li key="9" className="cb_nav__menu-item">
        <a className="cb_nav__menu-item-link" href="/about">
          About
        </a>
      </li>
    );
    listArray.push(
      <li key="10" className="cb_nav__menu-item">
        <a className="cb_nav__menu-item-link" href="/brands">
          Producers & Processors
        </a>
      </li>
    );

    return listArray;
  }

  serveEmployeeNav() {
    var listArray = [];
    if (this.props.user === null || typeof this.props.user === "undefined")
      return null;

    if (
      this.props.user.serviceDataEntry === true ||
      this.props.user.adminUser === true
    ) {
      listArray.push(
        <li key="11" className="cb_nav__menu-item">
          <a
            className="cb_nav__menu-item-link"
            href="/admin/service/tests/entry"
          >
            Enter Data
          </a>
        </li>
      );
    }

    if (
      this.props.user.serviceReview === true ||
      this.props.user.adminUser === true
    ) {
      listArray.push(
        <li key="12" className="cb_nav__menu-item">
          <a
            className="cb_nav__menu-item-link"
            href="/admin/service/tests/review"
          >
            Review Data
          </a>
        </li>
      );
    }
    return listArray;
  }

  render() {
    return (
      <div className="cb_nav__left">
        <ul className="cb_nav__menu">
          {/*
                            <li className="cb_nav__menu-item"><a className="cb_nav__menu-item-link" href="#">How it Works</a></li>
                        */}

          {/*
                            <li className="cb_nav__menu-item"><a className="cb_nav__menu-item-link" href="#">Retailers</a></li>
                        */}

          {this.unAuthenticatedNav()}
          {this.adminNav()}
          {this.serveEmployeeNav()}
          {this.clientNav()}
          {this.clientUserWithoutAssignment()}
          {this.consumerUserNav()}
        </ul>
      </div>
    );
  }
}

export default NavigationMenus;
