import * as React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ProductOwnerSelect from "../../../admin/adminComponents/ProductOwnerSelect";
import { AuthenticationHOC } from "../../../components/AuthenticationHOC";
import matchSorter from "match-sorter";
import CannastampIcon from "../../../components/product/CannastampIcon";
import MerchandisingCardPrinter from "../printing/MerchandisingCardPrinter";
import Switch from "@material-ui/core/Switch";
import clientServices from "../../../apiService/ClientService";

class DispensaryDistributionList extends React.Component {
  static defaultProps = {
    distributionList: null,
    clientList: undefined,
    reloadParent: undefined
  };
  constructor(props) {
    super(props);
    this.state = {
      downloadsDisabled: false,
      printQue: false,
      showRejected: false
    };
  }

  deleteDistribution = productId => {
    clientServices
      .manageDispensaryDistribution(
        this.props.token,
        this.props.match.params.clientId,
        productId,
        "denied"
      )
      .then(
        response => {
          this.props.reloadParent();
        },
        rejected => {
          console.warn(rejected);
        }
      );
  };
  approveDistribution = productId => {
    clientServices
      .manageDispensaryDistribution(
        this.props.token,
        this.props.match.params.clientId,
        productId,
        "approved"
      )
      .then(
        response => {
          this.props.reloadParent();
        },
        rejected => {
          console.warn(rejected);
        }
      );
  };

  tableView() {
    const standardColumns = [
      {
        Header: "Cannastamp",
        maxWidth: 55,
        id: "Cannastamp",
        Cell: ({ row }) => {
          if (typeof row._original.latestLot !== "undefined") {
            return (
              <div style={{ width: 50 }}>
                <CannastampIcon
                  key={row._original.latestLot._id}
                  size={100}
                  testResult={row._original.latestLot.testResult}
                />
              </div>
            );
          } else {
            return <div style={{ width: 50 }}>None</div>;
          }
        }
      },
      {
        Header: "Brand Name",
        id: "brand",
        maxWidth: 100,
        accessor: d => d.brand.name,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["brand"]
          }),
        filterAll: true
      },
      {
        Header: "Product Line",
        id: "productName",
        maxWidth: 100,
        accessor: d => d.productName,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["productName"]
          }),
        filterAll: true
      },
      {
        Header: "Name                   ",
        id: "name",
        accessor: d => d.name,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["name"]
          }),
        filterAll: true
      },
      {
        Header: "Product Category",
        id: "productCategory",
        maxWidth: 100,
        accessor: d => d.productCategory,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["productCategory"]
          }),
        filterAll: true
      },

      {
        Header: "Hang Tag",
        maxWidth: 100,
        Cell: ({ row }) => (
          <div style={{ fontSize: 12 }}>
            {this.state.downloadsDisabled === false && (
              <a onClick={() => this.setState({ printQue: row._original })}>
                Download
              </a>
            )}
          </div>
        )
      },
      {
        Header: "Status",
        maxWidth: 100,
        Cell: ({ row }) => {
          let eleme = row._original.distribution.find(distributionElem => {
            return (
              distributionElem.dispensary === this.props.match.params.clientId
            );
          });
          if (eleme.status === "pending") {
            return (
              <div style={{ fontSize: 12 }}>
                <a onClick={() => this.approveDistribution(row._original._id)}>
                  Approve
                </a>
              </div>
            );
          } else if (eleme.status === "denied") {
            return <div>Rejected</div>;
          } else {
            return <div>Approved</div>;
          }
        }
      },
      {
        Header: "Action",
        maxWidth: 100,
        Cell: ({ row }) => {
          let eleme = row._original.distribution.find(distributionElem => {
            return (
              distributionElem.dispensary === this.props.match.params.clientId
            );
          });
          if (eleme.status === "approved") {
            return (
              <div style={{ fontSize: 12 }}>
                <a onClick={() => this.deleteDistribution(row._original._id)}>
                  Remove
                </a>
              </div>
            );
          }
          if (eleme.status === "denied") {
            return (
              <div style={{ fontSize: 12 }}>
                <a onClick={() => this.approveDistribution(row._original._id)}>
                  Approve
                </a>
              </div>
            );
          } else {
            return (
              <div style={{ fontSize: 12 }}>
                <a onClick={() => this.deleteDistribution(row._original._id)}>
                  Reject
                </a>
              </div>
            );
          }
        }
      }
    ];

    if (
      this.props.distributionList === null ||
      this.props.distributionList === undefined
    ) {
      return <div>No Products In Dispensary</div>;
    } else {
      let tableData = this.props.distributionList.filter(product => {
        let sourceDistribution = product.distribution.find(distributionElem => {
          return (
            distributionElem.dispensary === this.props.match.params.clientId
          );
        });
        if (this.state.showRejected === false) {
          return sourceDistribution.status !== "denied";
        } else {
          return true;
        }
      });
      return (
        <div>
          <div
            className={
              this.state.printQue !== false
                ? "cb_grid-item--9"
                : "cb_grid-item--12"
            }
          >
            Show Rejected
            <Switch
              checked={this.state.showRejected}
              onChange={() => {
                this.setState({
                  showRejected: !this.state.showRejected
                });
              }}
            />
            <ReactTable
              data={tableData}
              filterable
              columns={standardColumns}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          </div>

          <br />
          <div className="cb_grid-item--3">
            <MerchandisingCardPrinter
              clientId={this.props.match.params.clientId}
              token={this.props.token}
              newCardProp={this.state.printQue}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    return this.tableView();
  }
}

export default AuthenticationHOC()(DispensaryDistributionList);
