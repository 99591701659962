import React, { Component } from "react";
import PropTypes from "prop-types";
import { clientApiHOCProps } from "./clientComponents/generic/ClientApiHOC";
import LotListShipments from "./clientComponents/productLot/LotListShipments";
import LotListParents from "./clientComponents/productLot/LotListParents";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

class ManageShipmentLots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotList: false,
      masterLotList: false,
      loadExpiredShipments: false
    };
    this.loadShipments();
    props.getClientLots(this.props.match.params.clientId, false, results => {
      this.setState({ masterLotList: results });
    });
  }

  expireLot = lotId => {
    alert(lotId);
  };

  loadShipments = () => {
    if (this.state.loadExpiredShipments === true) {
      this.props.getClientShipmentsAll(
        this.props.match.params.clientId,
        false,
        results => {
          this.setState({ lotList: results });
        }
      );
    } else {
      this.props.getClientShipmentsActive(
        this.props.match.params.clientId,
        false,
        results => {
          this.setState({ lotList: results });
        }
      );
    }
  };

  render() {
    if (this.state.lotList === false) {
      return <div>Loading Lot Data</div>;
    } else {
      return (
        <div>
          <div className="cb_account__content">
            <div className="cb_account__content-header">
              <ul className="cb_breadcrumb cb_breadcrumb--title">
                <li className="cb_breadcrumb__current">Manage Shipments</li>
              </ul>
              <div className="cb_account__content-header-message">
                <p>You can see all of your test results in the table below.</p>
              </div>
            </div>
            <div className="cb_account__content-content">
              <div className="cb_data-table">
                <h4 className="cb_data-table__title">Your Shipments </h4>
                <span>Show Old Shipments</span>{" "}
                <Switch
                  onChange={() => {
                    this.setState(
                      {
                        loadExpiredShipments: !this.state.loadExpiredShipments
                      },
                      () => {
                        this.loadShipments();
                      }
                    );
                  }}
                  checkedChildren="on"
                  unCheckedChildren="off"
                />
                <div className="cb_data-table__toolbar" />
                <div className="cb_data-table__table">
                  <LotListShipments
                    lotListShipments={this.state.lotList}
                    clientId={this.props.match.params.clientId}
                    expireLot={this.expireLot}
                    {...this.props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

let theTypes = {
  match: {
    params: {
      testResultId: PropTypes.string,
      clientId: PropTypes.string,
      parentLotsOnly: PropTypes.boolean
    }
  }
};

export default ManageShipmentLots;
