import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = {
  card: {
    minWidth: 200,
    display: "inline-block"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  container: {
    width: "300",
    display: "inline-block",
    margin: 10,
    boxShadow: "0 0 8px rgba(0, 0, 0, .5)"
  },
  photos: {
    width: 250
  }
};

function UploadResultCard(props) {
  const { classes, uploadNumber, uploadFileArray, width, removeFile } = props;
  if (
    Array.isArray(uploadFileArray.pdfFiles) &&
    uploadFileArray.pdfFiles.length > 0
  ) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Upload #{uploadNumber}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Attached Document{uploadFileArray.pdfFiles.length > 1 && "s"}
          </Typography>
          <Typography component="p">
            {uploadFileArray.pdfFiles.map((pdf, index) => {
              return (
                <div key={index} className={classes.container}>
                  {pdf.search(".pdf") === -1 ? (
                    <img className={classes.photos} src={pdf} />
                  ) : (
                    <Document className={classes.document} file={pdf}>
                      <Page
                        width={width ? width : 400}
                        className={classes.page}
                        pageNumber={1}
                      />
                    </Document>
                  )}

                  <CardActions>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">
                      View File
                    </a>
                    {typeof removeFile !== "undefined"}
                    {typeof removeFile !== "undefined" ? (
                      <a onClick={removeFile} alt="delete upload">
                        delete{" "}
                      </a>
                    ) : (
                      <div>other things</div>
                    )}
                  </CardActions>
                </div>
              );
            })}
          </Typography>
        </CardContent>
      </Card>
    );
  } else return <div />;
}

UploadResultCard.propTypes = {
  classes: PropTypes.object.isRequired,
  uploadNumber: PropTypes.number.isRequired,
  uploadFileArray: PropTypes.array.isRequired,
  width: PropTypes.number,
  removeFile: PropTypes.func
};

export default withStyles(styles)(UploadResultCard);
