import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: "50%",
    margin: "auto"
  },
  button: {
    width: 100,
    marginLeft: 5,
    marginRight: 5
  },
  buttonBlock: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    marginTop: 10
  },
  infoBlocks: {
    paddingTop: 10
  }
});

class HowYouGetYourCOAs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" component="h3">
          Tell us how your lab sends your COA's
        </Typography>
        <Typography component="p" className={classes.infoBlocks}>
          We need your <b>terpene and cannabinoid data</b>, at a minimum.
        </Typography>
        <Typography component="p" className={classes.infoBlocks}>
          Do these test results for a batch or lot come on multiple pdf's or on
          a single document?
        </Typography>
        <div className={classes.buttonBlock}>
          <Button
            variant="outlined"
            className={classes.button}
            disabled={this.props.disabled}
            onClick={() => this.props.setMultiple(true)}
          >
            Multiple
          </Button>
          <Button
            variant="outlined"
            className={classes.button}
            disabled={this.props.disabled}
            onClick={() => this.props.setMultiple(false)}
          >
            Single
          </Button>
        </div>
      </Paper>
    );
  }
}

HowYouGetYourCOAs.propTypes = {
  classes: PropTypes.object.isRequired,
  setMultiple: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

export default withStyles(styles)(HowYouGetYourCOAs);
