import React, { Component } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
import async from "async";
import ClientServices from "../../../apiService/ClientService";
import { Redirect } from "react-router-dom";

class BulkUploadTest extends Component {
  constructor(props) {
    super(props);
    this.state = { messages: [], redirect: false };
  }

  bulkUploadTestCsv = event => {
    const fileObject = event.target.files[0];
    Papa.parse(fileObject, { header: true, complete: this.processCsv });
  };

  processCsv = csvResults => {
    let data = csvResults.data;
    let total = data.length;
    let completed = 0;
    let responseResults = [];

    async.eachOf(
      data,
      (row, key, next) => {
        completed++;
        row.dataOwner = this.props.match.params.clientId;
        let formMock = new FormData();
        let columnNames = Object.keys(row);
        columnNames.map(columnName => {
          formMock.append(columnName, row[columnName]);
        });
        ClientServices.saveTestResult(
          this.props.token,
          this.props.match.params.clientId,
          formMock
        ).then(
          results => {
            console.log(results.lotId, key);
          },
          rejected => {
            console.warn(rejected);
          }
        );
        next();
      },
      () => {
        this.setState({ redirect: true, message: responseResults });
      }
    );
  };

  render() {
    if (this.props.user.adminUser === true) {
      return (
        <span style={{ paddingTop: 20 }}>
          {!!this.state.redirect && (
            <Redirect
              to={
                "/client/" + this.props.match.params.clientId + "/parentLots/"
              }
            />
          )}
          <label style={{ display: "inline-block" }} htmlFor="csvSelect">
            <a className="cb_data-table__toolbar-button">
              <i className="fal fa-plus-circle" /> CSV Upload Results
            </a>
          </label>
          <input
            id="csvSelect"
            accept=".csv"
            type="file"
            style={{ position: "fixed", top: "-100em" }}
            onChange={this.bulkUploadTestCsv}
          />
          <a
            className="cb_data-table__toolbar-button"
            href="https://s3-us-west-2.amazonaws.com/cannabinder-public-uploads/files/sample-data-format.csv"
            target="_blank"
          >
            <i className="fal fa-plus-circle" /> CSV Template File
          </a>
          {this.state.messages.length > 0 && (
            <div>
              <span>Results From CSV Upload</span>
              <span>{this.state.message}</span>
            </div>
          )}
        </span>
      );
    } else {
      return <div />;
    }
  }
}

BulkUploadTest.propTypes = {};

export default BulkUploadTest;
