import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ProductDistribution extends Component {
  static propTypes = {
    distributionLots: PropTypes.array,
    deleteDistribution: PropTypes.func,
    dispensaries: PropTypes.array
  };
  constructor(props) {
    super(props);

    this.state = {};
  }
  distributionElement(distribution, key) {
    if (distribution !== null) {
      let dispensary = this.props.dispensaries.find(listDispensary => {
        return listDispensary._id === distribution.dispensary;
      });

      return (
        <div key={key} style={{ display: "inline-block", width: "100%" }}>
          <div style={{ display: "inline-block", width: "20%", margin: 5 }}>
            <label>Dispenary</label>
            {dispensary.name}
          </div>
          <div style={{ display: "inline-block", width: "20%", margin: 5 }}>
            <label>UBI</label>
            {typeof dispensary.ubi === "string" && dispensary.ubi}
          </div>
          <div style={{ display: "inline-block", width: "20%", margin: 5 }}>
            <label>License</label>
            {typeof dispensary.licenseNumber === "string" &&
              dispensary.licenseNumber}
          </div>
          <div style={{ display: "inline-block", width: "20%", margin: 5 }}>
            <label>Status</label>
            {typeof distribution.status === "string" && distribution.status}
          </div>
          <div style={{ display: "inline-block", width: "10%", margin: 5 }}>
            <label>Delete</label>
            <input
              type="button"
              value="Del"
              onClick={event => {
                event.preventDefault();
                this.props.deleteDistribution(key);
              }}
            />
          </div>
        </div>
      );
    } else return <div />;
  }

  render() {
    return (
      <div>
        <h1>Dispensary Distribution</h1>
        {typeof this.props.distributionLots !== "undefined" &&
          this.props.distributionLots.length > 0 &&
          this.props.distributionLots.map((distribution, index) => {
            return this.distributionElement(distribution, index);
          })}
      </div>
    );
  }
}
