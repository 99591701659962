const ActivityIconMap = {
  Thinking: "lightbulb",
  Working: "cogs",
  Relaxing: "couch",
  Sleeping: "bed",
  Romance: "heart",
  Socializing: "users",
  Exercise: "walking",
  Medicinal: "prescription-bottle-alt",
  Other: "user-edit"
}

export default ActivityIconMap;
