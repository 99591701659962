import React, { Component } from "react";
import CannastampIcon from "./CannastampIcon";

class SearchResultsView extends Component {
  constructor() {
    super();
  }

  followLink = () => {};

  displayResults(productsAvailable, productsUnavailable) {
    var searchListResult = [];
    var key = 0;
    productsAvailable.map(item => {
      searchListResult.push(
        <li key={key + "_" + item._id} className="cb_search-suggestions__item">
          <a className="cb_search-suggestions__link" href={item.cleanUrl}>
            <span className="cb_search-suggestions__cannastamp">
              <CannastampIcon
                testResult={item.latestLot.testResult}
                size={"100"}
              />
            </span>
            <div className="cb_search-suggestions__product-meta">
              {typeof item.productName !== "undefined" &&
              item.productName !== null ? (
                <span className="cb_search-suggestions__strain-name">
                  {item.productName} -{" "}
                </span>
              ) : null}

              <span className="cb_search-suggestions__strain-name">
                {item.name}
              </span>
              <br />
              {typeof item.brand.name !== "undefined" ? (
                <span className="cb_search-suggestions__producer-name">
                  By: {item.brand.name}
                </span>
              ) : null}
            </div>
          </a>
        </li>
      );

      key++;
    });

    productsUnavailable.map(item => {
      searchListResult.push(
        <li key={key + "_" + item._id} className="cb_search-suggestions__item">
          <a className="cb_search-suggestions__link" href={item.cleanUrl}>
            <span className="cb_search-suggestions__cannastamp">
              <CannastampIcon
                testResult={item.latestLot.testResult}
                size={"100"}
              />
            </span>
            <div className="cb_search-suggestions__product-meta">
              {typeof item.productName !== "undefined" ? (
                <span className="cb_search-suggestions__strain-name">
                  {item.productName} -
                </span>
              ) : null}
              <span className="cb_search-suggestions__strain-name">
                {item.name}
              </span>
              <br />
              {typeof item.brand.name !== "undefined" ? (
                <span className="cb_search-suggestions__producer-name">
                  By: {item.brand.name}
                </span>
              ) : null}
              <p>No Longer Available</p>
            </div>
          </a>
        </li>
      );
      key++;
    });

    return (
      <div id="cb_inline-search-results">
        <div className="cb_search-suggestions-dropdown show">
          <ul className="cb_search-suggestions">{searchListResult}</ul>
        </div>
      </div>
    );
  }

  displayMessagePrompt() {
    var promptRender = (
      <div id="cb_inline-search-results">
        <div className="cb_search-suggestions-dropdown cb_search-suggestions-dropdown--no-results show">
          <ul className="cb_search-suggestions">
            <li className="cb_search-suggestions__item">
              <div className="cb_search-suggestions__product-meta">
                <span className="cb_search-suggestions__strain-name">
                  {this.props.MessagePrompt}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
    return promptRender;
  }

  renderControl() {
    if (
      typeof this.props.MessagePrompt !== "undefined" &&
      this.props.MessagePrompt !== null
    ) {
      return this.displayMessagePrompt();
    } else if (
      this.props.productsAvailable.length > 0 ||
      this.props.productsUnavailable.length > 0 ||
      this.props.ShowPrompt == true
    ) {
      return this.displayResults(
        this.props.productsAvailable,
        this.props.productsUnavailable
      );
    } else {
      return <div />;
    }
  }

  render() {
    return this.renderControl();
  }
}

export default SearchResultsView;
