import * as React from "react";
import {
  Grid,
  CardContent,
  CardMedia,
  Card,
  CardActions,
  Button,
  Theme,
  CircularProgress
} from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { RefObject } from "react";
import formTools from "./formTools";
import { withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Left from "@material-ui/icons/KeyboardArrowLeft";
import Right from "@material-ui/icons/KeyboardArrowRight";
import config from "../../../config.json";
export interface IAwsImageUploadProps {
  currentFiles: string[];
  onChange: (files: IAwsImageUploadProps["currentFiles"]) => void;
  multiple: boolean;
  name: string;
  accepted: "image/png, image/jpeg";
  clientId: string;
  token: string;
  classes: any;
}

export interface IAwsImageUploadState {
  activeFiles: string[];
  uploadDisabled: boolean;
  error: string;
}

class AwsImageUpload extends React.Component<
  IAwsImageUploadProps,
  IAwsImageUploadState
> {
  private readonly uploadRef: RefObject<HTMLInputElement>;
  constructor(props: IAwsImageUploadProps) {
    super(props);

    this.state = {
      activeFiles: null,
      uploadDisabled: false,
      error: null
    };
    this.uploadRef = React.createRef();
  }

  private shiftLeft(key: number): void {
    const { activeFiles } = this.state;
    const { onChange } = this.props;
    if (key !== 0) {
      const swapped = activeFiles[key - 1];
      const moving = activeFiles[key];
      activeFiles[key - 1] = moving;
      activeFiles[key] = swapped;
      onChange(activeFiles);
    }
  }
  private shiftRight(key: number): void {
    const { activeFiles } = this.state;
    const { onChange } = this.props;
    if (key !== activeFiles.length - 1) {
      const swapped = activeFiles[key + 1];
      const moving = activeFiles[key];
      activeFiles[key + 1] = moving;
      activeFiles[key] = swapped;
      onChange(activeFiles);
    }
  }

  private imageCard = (url: string, key: number): JSX.Element => {
    let { multiple, classes } = this.props;
    const { activeFiles } = this.state;
    return (
      <Card className={classes.card} key={key}>
        <CardContent>
          <CardMedia className={classes.media} image={url} />
        </CardContent>
        <CardActions>
          {multiple && (
            <Button className={classes.button} size="small" color="primary">
              {key !== 0 && <Left onClick={() => this.shiftLeft(key)} />}
            </Button>
          )}
          <Button
            size="small"
            color="primary"
            onClick={() => this.deleteImage(key)}
          >
            <DeleteIcon />
          </Button>
          {multiple && (
            <Button className={classes.button} size="small" color="primary">
              {key !== activeFiles.length - 1 && (
                <Right onClick={() => this.shiftRight(key)} />
              )}
            </Button>
          )}
        </CardActions>
      </Card>
    );
  };

  private deleteImage = (imageKey: number): void => {
    let { activeFiles } = this.state;
    activeFiles.splice(imageKey, 1);
    // delete activeFiles[imageKey];
    if (activeFiles.length < 1) {
      activeFiles = null;
    }
    this.setState({ activeFiles }, () => {
      return this.props.onChange(activeFiles);
    });
  };

  private initiateUpload = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    return !this.state.uploadDisabled && this.uploadRef.current.click();
  };

  private handleUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let { token, clientId, multiple } = this.props;
    let { activeFiles } = this.state;
    let uploadField: HTMLInputElement = event.target;
    let files = uploadField.files;
    let fileSizePass = true;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size / 1000 > config.loyalty.maxLoyaltyMessageImagesizeKb) {
        fileSizePass = false;
      }
    }
    if (fileSizePass) {
      this.setState(
        {
          uploadDisabled: true
        },
        () => {
          let formTool = new formTools(token, clientId);
          formTool.uploadFilesToS3(
            "imageFileUploader",
            (results: { error?: any; uploadLinks: string[] }) => {
              if (results.error) {
                this.setState({ error: results.error });
              } else {
                let uploadLinks: string[] = activeFiles ? activeFiles : [];
                if (multiple === true) {
                  uploadLinks.push(...results.uploadLinks);
                } else {
                  uploadLinks = [results.uploadLinks[0]];
                }
                this.setState(
                  {
                    error: null,
                    activeFiles: uploadLinks,
                    uploadDisabled: false
                  },
                  () => {
                    this.props.onChange(uploadLinks);
                  }
                );
              }
            }
          );
        }
      );
    } else {
      this.setState({
        error: `File sizes cannot exceed ${config.loyalty.maxLoyaltyMessageImagesizeKb} KB`
      });
    }
  };

  private uploadButton = (): JSX.Element | void => {
    let { activeFiles, uploadDisabled } = this.state;
    let { multiple } = this.props;

    if (
      !Array.isArray(activeFiles) ||
      multiple === true ||
      activeFiles.length < 1
    ) {
      return (
        <Grid item xs={12}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            disabled={uploadDisabled}
            onClick={this.initiateUpload}
          >
            <i className="fal fa-plus-circle" />
            <div style={{ paddingLeft: 8 }}>Upload</div>
          </Button>
          <br />
          {uploadDisabled && <CircularProgress size={30} />}
        </Grid>
      );
    } else {
      return;
    }
  };

  public render = () => {
    let { activeFiles, error } = this.state;
    let { name, multiple, accepted, classes } = this.props;
    return (
      <Grid container spacing={10}>
        <input
          ref={this.uploadRef}
          type="file"
          id="imageFileUploader"
          multiple={multiple}
          accept={accepted}
          style={{ display: "none" }}
          onChange={this.handleUpload}
        />

        {this.uploadButton()}
        {error && (
          <div className={classes.error}>
            <p>{error}</p>
          </div>
        )}
        {activeFiles &&
          activeFiles.length > 0 &&
          activeFiles.map((image, key) => {
            return (
              <Grid item key={key}>
                <input
                  name={multiple ? `${name}[${key}]` : name}
                  value={image}
                  type="hidden"
                />
                {this.imageCard(image, key)}
              </Grid>
            );
          })}
      </Grid>
    );
  };
}
const styles = (_: Theme) =>
  createStyles({
    card: {
      maxWidth: 240,
      minWidth: 200
    },
    media: {
      height: 200
    },
    button: {
      maxWidth: 40
    },
    error: {
      width: "100%"
    }
  });

export default withStyles(styles)(AwsImageUpload);
