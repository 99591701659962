import React, { Component } from "react";
import AdminService from "../apiService/AdminService";
import matchSorter from "match-sorter";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { AdminApiHOC } from "./AdminApiHOC";
import JSONPretty from "react-json-pretty"; // Import React Table
import { CSVLink } from "react-csv";

class UserManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userBinderCount: null
    };

    this.reloadData = this.reloadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadUserBinders(props.user, props.token);
  }

  // onFetchData for ReactTable
  fetchData(state = {}, instance) {
    this.setState(
      {
        paginate: Object.assign(this.props.paginate, {
          page: state.page,
          pageSize: state.pageSize,
          sorted: state.sorted,
          filtered: state.filtered
        })
      },
      () => {
        this.props.getUsers(true, this.state.paginate, users => {
          this.props.setUsers(users);
        });
      }
    );
  }

  reloadData() {
    this.props.getUsers(false, undefined, users => {
      this.props.setUsers(users);
    });
    this.loadUserBinders(this.props.user, this.props.token);
  }

  loadUserBinders(user, token) {
    this.props.checkAdminToken().then(() => {
      var body = {
        token: token,
        user: user
      };
      AdminService.getUserBinderCount(body).then(results => {
        this.setState({ userBinderCount: results });
      });
    });
  }

  toggleStatus(statusName, userListKey) {
    let newUserList = this.props.users;
    newUserList[userListKey][statusName] = !newUserList[userListKey][
      statusName
    ];
    AdminService.updateUser(
      { [statusName]: newUserList[userListKey][statusName] },
      newUserList[userListKey],
      this.props.token
    ).then(response => {
      this.reloadData();
    });
  }

  deleteUser(user) {
    if (
      user.adminUser === true ||
      user.dispensaryUser === true ||
      user.processorUser === true
    ) {
      alert(
        "deleting of Admins, Dispensary accounts and Processor Accounts is not allowed"
      );
    } else if (
      typeof user.clientAssignment !== "undefined" &&
      user.clientAssignment !== null
    ) {
      alert("deleting users with client assignments is currently blocked");
    } else {
      // TODO: prompt for a double check on this
      // if (confirm("Confirm Deleting User:" + user.email)) {
      AdminService.deleteUser(user._id, this.props.token).then(() => {
        this.reloadData();
      });
      // }
    }
  }

  tableView(userData) {
    return (
      <div style={{ paddingTop: 80 }}>
        <div>
          <h2>
            User Binders Count:
            {this.state.userBinderCount !== null
              ? this.state.userBinderCount
              : "loading"}
          </h2>
          <CSVLink
            data={userData || ""}
            target="_blank"
            filename="user-list.csv"
          >
            Download Users
          </CSVLink>
        </div>
        <ReactTable
          data={userData}
          filterable
          pages={this.props.paginate.pages}
          manual
          onFetchData={this.fetchData}
          loading={this.props.loading}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Contact",
              columns: [
                {
                  Header: "First Name",
                  accessor: "firstName",
                  filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value) &&
                    row[filter.id].endsWith(filter.value)
                },
                {
                  Header: "Last Name",
                  id: "lastName",
                  accessor: d => d.lastName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["lastName"] }),
                  filterAll: true
                },
                {
                  Header: "Email",
                  id: "email",
                  accessor: d => d.email,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email"] }),
                  filterAll: true
                }
              ]
            },
            {
              Header: "Permissions",
              columns: [
                {
                  Header: "Disabled",
                  accessor: "disabled",
                  Cell: ({ value }) => (value === true ? "Yes" : "No"),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="">Show All</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )
                },
                {
                  Header: "Pass Expired",
                  accessor: "passwordExpired",
                  Cell: ({ value }) => (value === true ? "Yes" : "No"),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="">Show All</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )
                },
                {
                  Header: "Processor",
                  id: "processorUser",
                  accessor: d => d.processorUser,
                  Cell: ({ value }) => (value === true ? "Yes" : "No"),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="">Show All</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )
                },
                {
                  Header: "Disp",
                  id: "dispensaryUser",
                  accessor: d => d.dispensaryUser,
                  Cell: ({ value }) => (value === true ? "Yes" : "No"),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="">Show All</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )
                },
                {
                  Header: "Admin",
                  id: "adminUser",
                  accessor: d => d.adminUser,
                  Cell: ({ value }) => (value === true ? "Yes" : "No"),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="">Show All</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )
                }
              ]
            }
          ]}
          SubComponent={row => {
            return (
              <div>
                <div style={{ padding: "20px" }}>
                  <JSONPretty id="json-pretty" data={row.original} />
                  <button
                    className="cb_rating__save-rating-button"
                    onClick={this.toggleStatus.bind(
                      this,
                      "processorUser",
                      row.index
                    )}
                  >
                    {" "}
                    Processor: {row.original.processorUser.toString()}
                  </button>

                  <button
                    className="cb_rating__save-rating-button"
                    onClick={this.toggleStatus.bind(
                      this,
                      "dispensaryUser",
                      row.index
                    )}
                  >
                    dispensaryUser: {row.original.dispensaryUser.toString()}
                  </button>

                  <button
                    className="cb_rating__save-rating-button"
                    onClick={this.toggleStatus.bind(
                      this,
                      "adminUser",
                      row.index
                    )}
                  >
                    Admin: {row.original.adminUser.toString()}
                  </button>
                  <button
                    className="cb_rating__save-rating-button"
                    onClick={this.toggleStatus.bind(
                      this,
                      "serviceDataEntry",
                      row.index
                    )}
                  >
                    Data entry: {row.original.serviceDataEntry.toString()}
                  </button>
                  <button
                    className="cb_rating__save-rating-button"
                    onClick={this.toggleStatus.bind(
                      this,
                      "serviceReview",
                      row.index
                    )}
                  >
                    Data Review: {row.original.serviceReview.toString()}
                  </button>
                  <button
                    className="cb_rating__save-rating-button"
                    onClick={() => this.deleteUser(row.original)}
                  >
                    Delete User
                  </button>
                </div>
              </div>
            );
          }}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        <br />
      </div>
    );
  }

  render() {
    return (
      <div>
        <h1>User list</h1>
        {this.tableView(this.props.users)}
      </div>
    );
  }
}

export default AuthenticationHOC()(AdminApiHOC()(UserManage));
