import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import RouteClient from "./RouteClient";

class RouteClientSecurity extends Component {
  // this component explicility encodes the path so that the next route can
  // check the clients authentication on the entity being viewed.
  render() {
    return (
      <Router>
        <Route path="/client/:clientId/:page" component={RouteClient} />
      </Router>
    );
  }
}

export default RouteClientSecurity;
