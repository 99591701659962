import React, { Component } from "react";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { LoyaltyMessagesList } from "cbd-api-types";
import { BrandColor, Color } from "../../../kiosk/kioskUtilities/constants";
import Add from "@material-ui/icons/Add";
import MmsMessageCard from "./mmsMessaging/MmsMessageCard";
import LoyaltyCardNew from "./LoyaltyCardNew";

export interface MessageListProps {
  clientMessagesPending: LoyaltyMessagesList[] | null;
  clientMessagesSent: LoyaltyMessagesList[] | null;
  createMessageTrigger: () => void;
  // setLimit?: (limit: number) => void;
  // setFilter?: (filter: {
  //   title?: string;
  //   dateFrom?: Date;
  //   dateTo?: Date;
  //   pending?: boolean;
  // }) => void;
}

export interface MessageListState {}

export default class MessageList extends Component<
  MessageListProps,
  MessageListState
> {
  private constructor(props: MessageListProps) {
    super(props);
  }

  public renderMessageCards(messages: LoyaltyMessagesList[]) {
    return messages.map((message, key) => {
      return (
        <Grid item xs={6} md={4} key={key}>
          <MmsMessageCard message={message} />
        </Grid>
      );
    });
  }

  public render() {
    const {
      clientMessagesPending,
      clientMessagesSent,
      createMessageTrigger
    } = this.props;
    return (
      <Grid container spacing={8 as GridSpacing}>
        {clientMessagesPending && clientMessagesPending.length > 0
          ? this.renderMessageCards(clientMessagesPending)
          : ""}
        {clientMessagesSent && clientMessagesSent.length > 0
          ? this.renderMessageCards(clientMessagesSent)
          : ""}
        {!clientMessagesPending && !clientMessagesSent && (
          <Grid item xs={6} md={4} key="MessagesError">
            <LoyaltyCardNew
              avatar={<Add />}
              avatarColor={BrandColor.Red}
              body={"Click here to start a new Message"}
              cardColor={Color.LightGray}
              images={["/assets/images/generic/cbd-flower-default.png"]}
              onAction={() => createMessageTrigger()}
              title="Create Your First Message"
            />
          </Grid>
        )}
      </Grid>
    );
  }
}
