import * as React from "react";
import TestResultForm from "../testResult/TestResultForm";
import { TestResultPostResponse } from "../../../apiService/serviceInterfaces";
import WhatsACOA from "./WhatsACOA";
import { styled } from "@material-ui/styles";

interface Props {
  clientId: string;
  onSave?: (testResult: TestResultPostResponse) => void;
  hideCancel: boolean;
}
interface State {}

const TestFormWrapper = styled("div")({
  background: "#FAFAFA",
  border: "1px solid #CCC",
  borderRadius: 3,
  padding: 20
});

export class AddNewTest extends React.Component<Props, State> {
  public render(): React.ReactChild {
    const { onSave, hideCancel } = this.props;
    const { clientId } = this.props;
    return (
      <div>
        <div className="cb_grid-item--8" style={{ marginTop: 10 }}>
          <TestFormWrapper>
            <TestResultForm
              clientId={clientId}
              onSave={onSave}
              hideCancel={hideCancel}
            />
          </TestFormWrapper>
        </div>
        <div className="cb_grid-item--4">
          <div>
            <WhatsACOA />
          </div>
        </div>
      </div>
    );
  }
}
