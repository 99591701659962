import * as React from "react";
import ClientService from "../../apiService/ClientService";
import { Redirect } from "react-router-dom";
import { User } from "../../apiService/serviceInterfaces";

export interface welcomeRedirectorProps {
  clientId: string;
  token: string;
  user: User;
}

interface welcomeRedirectorState {
  redirect: boolean;
  resultsArray: number[];
}

export default class WelcomeRedirector extends React.Component<
  welcomeRedirectorProps,
  welcomeRedirectorState
> {
  private constructor(props: welcomeRedirectorProps) {
    super(props);
    this.state = { redirect: false, resultsArray: [] };
    if (props.user.adminUser !== true && props.user.processorUser === true) {
      const typeChecks: string[] = ["brands", "products", "tests"];
      typeChecks.map(async typeToCheck => {
        this.getCountByType(typeToCheck, (returnCount: number) => {
          if (returnCount === 0) {
            this.setState({ redirect: true });
          }
        });
      });
    }
  }

  private getCountByType(countType: string, callback: Function): void {
    let runningPromise: Promise<any>;
    switch (countType) {
      case "brands":
        runningPromise = ClientService.getEntityBrandCount(
          this.props.token,
          this.props.clientId
        );
        break;
      case "products":
        runningPromise = ClientService.getEntityProductCount(
          this.props.token,
          this.props.clientId
        );
        break;
      case "tests":
        runningPromise = ClientService.getEntityTestResultCount(
          this.props.token,
          this.props.clientId
        );
        break;
      default:
        runningPromise = ClientService.getEntityBrandCount(
          this.props.token,
          this.props.clientId
        );
        throw new Error("break here");
    }
    runningPromise.then(
      (results: number) => {
        callback(results);
      },
      (rejectValue: any) => {
        callback(0);
      }
    );
  }

  public render(): React.ReactChild | any {
    if (this.state.redirect)
      return (
        <div>
          <Redirect
            to={"/client/" + this.props.clientId + "/welcome/producer/"}
            push={true}
          />
        </div>
      );
    else return <div />;
  }
}
