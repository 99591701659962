import * as React from "react";
import BrandForm from "../brand/BrandForm";
import { styled } from "@material-ui/styles";
interface Props {
  clientId: string;
  onSave(brandData: any): void;
}
import WhatsABrandCard from "./WhatsABrandCard";

export const BrandFormWrapper = styled("div")({
  background: "#FAFAFA",
  border: "1px solid #CCC",
  borderRadius: 3,
  padding: 20
});
export const WhatsThisWrapper = styled("div")({
  padding: 10
});

export const WrapBrandAndWhatsThis = styled("div")({
  marginLeft: "auto",
  marginRight: "auto",
  display: "block",
  width: "90%"
});
export class AddNewBrand extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): React.ReactChild {
    const { clientId, onSave } = this.props;
    return (
      <div>
        <div className="cb_grid-item--8" style={{ marginTop: 10 }}>
          <BrandFormWrapper>
            <BrandForm
              clientId={clientId}
              brand={false}
              onSave={onSave}
              cancelRemoved={true}
            />
          </BrandFormWrapper>
        </div>
        <div className="cb_grid-item--4">
          <div>
            <WhatsABrandCard />
          </div>
        </div>
      </div>
    );
  }
}
