import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WhatsACOA from "./WhatsACOA";
import WhatsAProduct from "./WhatsAProduct";
import WhatsABrandCard from "./WhatsABrandCard";

const styles = theme => ({
  container: {
    display: "flex",
    width: "100%"
  }
});

class GettingStartedCards extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className="cb_grid-item--4">
          <WhatsABrandCard />
        </div>
        <div className="cb_grid-item--4">
          <WhatsAProduct />
        </div>
        <div className="cb_grid-item--4">
          <WhatsACOA />
        </div>
      </div>
    );
  }
}

GettingStartedCards.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GettingStartedCards);
