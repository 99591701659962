import React, { Component } from "react";
import AdminService from "../apiService/AdminService";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { AdminApiHOC } from "./AdminApiHOC";
import ProductList from "../client/clientComponents/product/ProductList";
import "react-table/react-table.css";

class ProductManage extends Component {
  constructor(props) {
    super(props);

    this.reloadData = this.reloadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  // onFetchData for ReactTable
  fetchData(state = {}, instance) {
    this.setState(
      {
        paginate: Object.assign(this.props.paginate, {
          page: state.page,
          pageSize: state.pageSize,
          sorted: state.sorted,
          filtered: state.filtered
        })
      },
      () => {
        this.props.getProducts(true, this.state.paginate, products => {
          this.props.setProducts(products);
        });
        this.props.getClients(false, undefined, clients => {
          this.props.setClients(clients);
        });
      }
    );
  }

  reloadData() {
    this.props.getProducts(true, this.state.paginate, products => {
      this.props.setProducts(products);
    });
    this.props.getClients(false, undefined, clients => {
      this.props.setClients(clients);
    });
  }

  // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
  //    in ProductOwnerSelect (at ProductList.js:245)
  //    in em (at ProductList.js:243)
  //    in div (at ProductList.js:242)
  // ------
  // Prevent memory leak from fetchData() after updating a product
  // _isMounted = false;
  // componentDidMount() {
  //   this._isMounted = true;
  // }
  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  reprocessComps = () => {
    AdminService.reloadProdComps(this.props.token);
  };

  render() {
    return (
      <div>
        <br />
        <br />
        <br />
        <a
          className="cb_nav__menu-item-link"
          onClick={() => this.reprocessComps()}
        >
          Recalculate Comparisons
        </a>
        <ProductList
          changeOwner={true}
          productList={this.props.products}
          clientList={this.props.clients}
          fetchData={this.fetchData}
          reloadParent={this.reloadData}
          loading={this.props.loading}
          pages={this.props.paginate.pages}
        />
      </div>
    );
  }
}

export default AuthenticationHOC()(AdminApiHOC()(ProductManage));
