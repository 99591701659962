import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ProductShipments extends Component {
  static propTypes = {
    shipmentLots: PropTypes.array
  };
  constructor(props) {
    super(props);

    this.state = {};
  }
  shipmentElement(productLot) {
    return (
      <div style={{ display: "inline-block", width: "100%" }}>
        <div>{productLot.dispensary.name}</div>
        <div>Delete</div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.shipmentLots.map(productLot => {
          return this.shipmentElement(productLot);
        })}
      </div>
    );
  }
}
