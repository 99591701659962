import React, { Component } from "react";
import EntityForm from "./clientComponents/entity/EntityForm";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { clientData: false };
    let clientId = this.props.match.params.clientId;
    this.props.getClientAccountData(clientId, results => {
      this.setState({ clientData: results });
    });
  }

  formRender = () => {
    if (this.state.clientData !== false) {
      return (
        <EntityForm
          redirectUrl={
            "/client/" + this.props.match.params.clientId + "/manageBrands"
          }
          claimEntity={false}
          clientData={this.state.clientData}
          {...this.props}
        />
      );
    } else {
      return (
        <div style={{ width: 200 }}>
          <span>
            <img src="/assets/images/loading.gif" />
          </span>
          <span>Loading..</span>
        </div>
      );
    }
  };
  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__current">Manage Account</li>
            </ul>
            <div className="cb_account__content-header-message">
              <p>Change your account details here</p>
            </div>
          </div>
          <div className="cb_account__content-content">{this.formRender()}</div>
        </div>
      </div>
    );
  }
}

export default Profile;
