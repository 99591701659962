import React, { Component } from "react";
import UserService from "../apiService/UserService";
import MyCannabinderProductView from "../components/product/MyCannabinderProductView";

class MyCannabinder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      binderList: [],
      redirect: false
    };
  }
  renderRedirect() {
    if (this.state.redirect || this.props.user === null) {
      // this.props.history.push("/home");
      // window.location.reload();
      // this.setState({ redirect: false });
      // return <Redirect push={true} to="/" />;
    }
  }
  componentDidMount() {
    // make sure we should even be here.
    this.props.checkToken("");
    if (typeof this.props.token !== "undefined") {
      UserService.getUserBinder(this.props.token, this.props.user)
        .then(results => {
          if (results.name !== "JsonWebTokenError") {
            this.setState({ binderList: results });
          } else {
            this.setState({ redirect: true });
          }
        })
        .catch(rejected => {
          this.setState({ redirect: true });
        });
    }
  }
  renderNoResults() {
    return <div>You currently have no favorites saved. <a href="/search">Search</a> for a product you enjoy, mark it as a favorite, and it will show up here. </div>;
  }

  render() {
    if (typeof this.state.binderList !== "undefined") {
      var finalList = {};
      this.state.binderList.forEach(function(item) {
        let id = item.product._id;
        if (finalList[id] === undefined) {
          finalList[id] = [item];
        } else {
          (finalList[id]).push(item);
        }
      });

      return (
        <div>
          <div className="cb_surface">
            <div className="cb_surface__section cb_section__favorites-list">
              {this.renderRedirect()}
              <div className="cb_grid">
                <h1 className="cb_heading--3">My Favorites</h1>
                <br />
                {Object.keys(finalList).length === 0 && this.renderNoResults()}
                {Object.keys(finalList).map((item, i) => (
                  <div className="cb_grid-item--3" key={i}>
                    <MyCannabinderProductView
                      binderItems={finalList[item]}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Loading!</div>;
    }
  }
}

export default MyCannabinder;
