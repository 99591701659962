import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class SearchView extends Component {
  static propTypes = {
    isHeader: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    toggleSearch: PropTypes.func,
    manualSearchString: PropTypes.string
  };

  componentDidUpdate(prevProps) {
    if (this.props.manualSearchString !== prevProps.manualSearchString) {
      document.getElementById(
        "searchStringInput"
      ).value = this.props.manualSearchString;
    }
  }

  render() {
    return (
      <div className="cb_product-search">
        <div className="cb_product-search__input-group">
          <input
            className="cb_product-search__input-text"
            type="text"
            autoFocus={true}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            onKeyPress={this.props.handleKeyPress}
            defaultValue={this.props.manualSearchString}
            placeholder="Find Your Product"
            name="searchString"
            id="searchStringInput"
          />
          {this.props.isHeader === false ? (
            <input
              className="cb_product-search__input-submit"
              type="submit"
              name=""
              value="Search"
              onClick={this.props.onSearch}
            />
          ) : (
            <div style={{ paddingTop: ".25rem", marginLeft: "1rem" }}>
              <FontAwesomeIcon
                size="2x"
                icon="search"
                onClick={this.props.toggleSearch}
              />
            </div>
          )}
        </div>
        <div className="cb_product-search__utility" />
      </div>
    );
  }
}
