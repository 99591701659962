import * as React from "react";
import { Button } from "@material-ui/core";
import GettingStartedCards from "./GettingStartedCards";

interface Props {
  nextStep: () => void;
}

export class GettingStarted extends React.Component<Props> {
  public render(): React.ReactChild {
    return (
      <div>
        <div
          style={{
            display: "grid",
            width: 800,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center"
          }}
        >
          <p>
            We'll go through a series of steps to get your first product into
            Cannabinder.
          </p>
          <GettingStartedCards />
          <Button
            variant={"contained"}
            color={"primary"}
            style={{
              marginTop: 20,
              width: 400,
              marginLeft: "auto",
              marginRight: "auto"
            }}
            onClick={this.props.nextStep}
          >
            Get Started!
          </Button>
        </div>
      </div>
    );
  }
}
