import * as React from "react";
import { Entity, ProducerProduct } from "../../apiService/serviceInterfaces";
import Select from "react-select";
import AdminService from "../../apiService/AdminService";
import { ActionMeta } from "react-select/lib/types";

export interface ProductOwnerSelectProps {
  // fully formed client object
  // list of users that can be selected from
  availableClients?: [Entity];
  // is the change initially enabled
  initiallyDisabled?: boolean;
  token: string;
  product: ProducerProduct;
  reloadParent: () => void;
}

interface ProductOwnerSelectState {
  disabled: boolean;
  product: ProducerProduct;
  warning?: string;
}

/**
 * This component accepts an entity and a user list. it then manages the state of the entity
 * by setting the admin user on the entity and updating its rendered view.
 */
export default class ProductOwnerSelect extends React.Component<
  ProductOwnerSelectProps,
  ProductOwnerSelectState
> {
  constructor(props: ProductOwnerSelectProps) {
    super(props);
    this.state = {
      disabled: this.props.initiallyDisabled
        ? this.props.initiallyDisabled
        : false,
      product: this.props.product
    };
    this.editEnable = this.editEnable.bind(this);
    this.setNewProductOwner = this.setNewProductOwner.bind(this);
  }

  private setNewProductOwner(value: any, action: ActionMeta) {
    const incomming = value;
    if (incomming.value === undefined) {
      throw new Error("Undefined option submitted to ClientAdminSelect");
    }
    AdminService.updateProductOwner(
      this.state.product,
      incomming.value,
      this.props.token
    ).then(
      (updatedProduct: ProducerProduct) => {
        this.props.reloadParent();
        this.setState({
          product: updatedProduct,
          warning: undefined,
          disabled: true
        });
      },
      rejection => {
        this.setState({
          warning: "Error Saving Entity. Rejection:" + rejection
        });
      }
    );
  }

  private editEnable(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({ disabled: !this.state.disabled });
  }

  public render() {
    const { availableClients } = this.props;

    return (
      <div
        style={{
          width: 400,
          display: "inline-block",
          padding: 20,
          verticalAlign: "top"
        }}
      >
        Owner Entity
        <input type="button" value="Change Owner" onClick={this.editEnable} />
        {availableClients &&
          Array.isArray(availableClients) &&
          availableClients.length > 0 && (
            <Select
              isDisabled={this.state.disabled}
              options={
                Array.isArray(this.props.availableClients) &&
                this.props.availableClients.map(client => {
                  return { value: client._id, label: client.name };
                })
              }
              onChange={this.setNewProductOwner}
            />
          )}
      </div>
    );
  }
}
