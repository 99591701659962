import React, { Component } from "react";
import AdminService from "../apiService/AdminService";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import LotList from "../client/clientComponents/productLot/LotList";
import { AdminApiHOC } from "./AdminApiHOC";
import "react-table/react-table.css";

class LotManage extends Component {
  constructor(props) {
    super(props);

    this.reloadData = this.reloadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  // onFetchData for ReactTable
  fetchData(state = {}, instance) {
    this.setState(
      {
        paginate: Object.assign(
          this.props.paginate,
          {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
          }
        )
      },
      () => {
        this.props.getLots(true, this.state.paginate, lots => {
          this.props.setLots(lots);
        });
      }
    );
  }

  reloadData() {

    this.props.getLots(false, undefined, lots => {
      this.props.setLots(lots);
    });
  }

  render() {
    return (
      <div>
        <LotList
          admin={true}
          lotList={this.props.lots}
          user={this.props.user}
          fetchData={this.fetchData}
          reloadData={this.reloadData}
          loading={this.props.loading}
          pages={this.props.paginate.pages}
        />
      </div>
    );
  }
}

export default AuthenticationHOC()(AdminApiHOC()(LotManage));
