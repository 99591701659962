import React, { Component } from "react";
import SearchHeader from "../components/front-page-tests/SearchHeader";
import ActivityBased from "../components/front-page-tests/ActivityBased";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manualSearchString: undefined
    };
  }

  setManualSearchString = searchString => {
    this.setState({ manualSearchString: searchString });
  };

  render() {
    return (
      <div>
        <SearchHeader manualSearchString={this.state.manualSearchString} />
        <ActivityBased setManualSearchString={this.setManualSearchString} />
      </div>
    );
  }
}

export default HomePage;
