import * as React from "react";
import { EntityDocumentInterface } from "cbd-api-types";
import { CenteredFullWidthHStack } from "./NumberEntry";
import styled from "styled-components";
import { ActionCard } from "../ActionCard";
import {
  Typography,
  Button,
  Theme,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/styles";
import { MobileScreenShare } from "@material-ui/icons";

export interface ConfigureAccountProps extends WithStyles<typeof styles> {
  clientData: EntityDocumentInterface | undefined;
  onSubmit: (activationCode: string, categories: string[]) => void;
  resendActivation: () => void;
  error: string | undefined;
  title: string;
  subTitle: string;
}

export interface ConfigureAccountState {
  errorMessage: string | undefined;
  activationCode: string;
  categorySelectList: string[];
  codeDisabled: boolean;
  submitDisabled: boolean;
}

export const ContentsFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > * {
    margin-right: 10px;
  }
  flex-wrap: wrap;
  max-width: 680px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  & > * {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;
class ConfigureAccount extends React.Component<
  ConfigureAccountProps,
  ConfigureAccountState
> {
  constructor(props: ConfigureAccountProps) {
    super(props);
    this.state = {
      errorMessage: props.error,
      activationCode: "",
      categorySelectList:
        props.clientData &&
        props.clientData.loyaltyConfig.signupCategories.map(
          category => category.title
        ),
      codeDisabled: false,
      submitDisabled: false
    };
  }

  private onCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ activationCode: event.target.value });
  };

  private categorySelect = (catName: string): void => {
    const { categorySelectList } = this.state;

    const searchLocation = categorySelectList.findIndex(element => {
      return element === catName;
    });
    if (searchLocation === -1) {
      categorySelectList.push(catName);
      this.setState({ categorySelectList });
    } else {
      categorySelectList.splice(searchLocation, 1);
      this.setState({ categorySelectList });
    }
  };

  private resendActivation = () => {
    this.setState({ codeDisabled: true }, () => {
      this.props.resendActivation();
      setTimeout(() => {
        this.setState({ codeDisabled: false });
      }, 10000);
    });
  };

  private onSubmit = () => {
    this.setState({ submitDisabled: true }, () => {
      this.props.onSubmit(
        this.state.activationCode,
        this.state.categorySelectList
      );
      setTimeout(() => {
        this.setState({ submitDisabled: false });
      }, 10000);
    });
  };

  public render = () => {
    const { clientData, classes } = this.props;
    const { categorySelectList, codeDisabled } = this.state;
    return (
      <CenteredFullWidthHStack>
        {this.props.error && <Typography>{this.props.error}</Typography>}
        {this.state.errorMessage && (
          <Typography>{this.state.errorMessage}</Typography>
        )}

        <Button
          className={classes.centeredBlock}
          onClick={this.resendActivation}
          disabled={codeDisabled}
          variant="outlined"
        >
          Send New Code
        </Button>
        <ContentsFlexWrapper>
          {clientData &&
            clientData.loyaltyConfig.signupCategories.map((category, key) => {
              categorySelectList.findIndex(selectedCat => {
                return selectedCat === category.title;
              });
              return (
                <ActionCard
                  maxHeight={window.innerHeight / 4}
                  key={"catCard-" + key}
                  actionBody={category.text}
                  actionTitle={category.title}
                  actionImage={category.image}
                  actionColor={
                    typeof categorySelectList.find(string => {
                      return string === category.title;
                    }) === "undefined" && " rgba(50, 50, 50, 0.5)"
                  }
                  onClick={() => this.categorySelect(category.title)}
                />
              );
            })}
        </ContentsFlexWrapper>
        <TextField
          type={"text"}
          placeholder={"Code"}
          onChange={this.onCodeChange}
          className={classes.centeredBlock}
          variant="outlined"
          InputProps={{
            style: {
              textAlign: "center"
            },
            startAdornment: (
              <InputAdornment position="start">
                <MobileScreenShare />
              </InputAdornment>
            )
          }}
        />
        <Button
          onClick={this.onSubmit}
          disabled={this.state.submitDisabled}
          className={classes.centeredBlock}
          variant="outlined"
        >
          Confirm Account
        </Button>
      </CenteredFullWidthHStack>
    );
  };
}

// tslint:disable-next-line: one-variable-per-declaration
const styles = (theme: Theme) => ({
  centeredBlock: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "3px",
    marginTop: "10px",
    marginBottom: "10px",
    display: "block",
    maxWidth: "300px"
  }
});

export default withStyles(styles)(ConfigureAccount);
