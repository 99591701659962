import * as React from "react";
import { Entity, User } from "../../apiService/serviceInterfaces";
import Select from "react-select";
import AdminService from "../../apiService/AdminService";
import { ActionMeta } from "react-select/lib/types";

export interface ClientAdminSelectProps {
  // fully formed client object
  client: Entity;
  // list of users that can be selected from
  userList: User[];
  availableUsers: User[];
  // is the change initially enabled
  initiallyDisabled?: boolean;
  token: string;
  reloadParent: (updated: Boolean) => void;
}

interface ClientAdminSelectState {
  disabled: boolean;
  client: Entity;
  warning?: string;
}

/**
 * This component accepts an entity and a user list. it then manages the state of the entity
 * by setting the admin user on the entity and updating its rendered view.
 */
export default class ClientAdminSelect extends React.Component<
  ClientAdminSelectProps,
  ClientAdminSelectState
> {
  constructor(props: ClientAdminSelectProps) {
    super(props);
    this.state = {
      disabled: this.props.initiallyDisabled
        ? this.props.initiallyDisabled
        : false,
      client: this.props.client
    };
    this.editEnable = this.editEnable.bind(this);
    this.findUserEmail = this.findUserEmail.bind(this);
    this.setNewAdmin = this.setNewAdmin.bind(this);
  }

  private setNewAdmin(value: any, action: ActionMeta) {
    const incomming = value;
    if (incomming.value === undefined) {
      throw new Error("Undefined option submitted to ClientAdminSelect");
    }
    AdminService.updateClientAdmin(
      this.props.client,
      incomming.value,
      this.props.token
    ).then(
      (updatedClient: Entity) => {
        this.props.reloadParent(true);
        this.setState({
          client: updatedClient,
          warning: undefined,
          disabled: true
        });
      },
      rejection => {
        this.setState({
          warning: "Error Saving Entity. Rejection:" + rejection
        });
      }
    );
  }

  private editEnable(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({ disabled: !this.state.disabled });
  }

  private findUserEmail(
    user: User | string | undefined
  ): { value: string; label: string } {
    if (user === null) {
      user = undefined;
    }
    let userId: string;
    switch (typeof user) {
      case "undefined":
        return { value: "", label: "none" };
      case "object":
        userId = user._id;
        break;
      case "string":
        userId = user;
        break;
    }

    const matchedUser = this.props.userList.find(searchUser => {
      return searchUser._id === userId;
    });
    if (matchedUser === undefined) {
      console.warn("admin not found for client");
      AdminService.updateClientAdmin(this.props.client, null, this.props.token);
      return { value: null, label: null };
    }
    return { value: matchedUser._id, label: matchedUser.email };
  }

  public render() {
    const { availableUsers } = this.props;
    return (
      <div
        style={{
          width: 400,
          display: "inline-block",
          padding: 20,
          verticalAlign: "top"
        }}
      >
        Admin User
        <input type="button" value="Change Admin" onClick={this.editEnable} />
        {availableUsers &&
          Array.isArray(availableUsers) &&
          availableUsers.length > 0 && (
            <Select
              isDisabled={this.state.disabled}
              options={this.props.availableUsers.map(user => {
                return { value: user._id, label: user.email };
              })}
              value={this.findUserEmail(this.state.client.adminUser)}
              onChange={this.setNewAdmin}
            />
          )}
      </div>
    );
  }
}
