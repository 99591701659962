import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import formTools from "../generic/formTools";

class JobGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotProductData: "Select A Source",
      printJob: {
        jobId: null,
        sourceLot: null,
        targetLotS2sId: null,
        targetClient: null,
        printTemplate: null,
        stampCount: null,
        removeJobFromParent: null
      }
    };
  }

  render() {
    let formTool = new formTools(this.props.token, this.props.clientId);
    return (
      <div>
        <Select
          name="sourceLot"
          id="sourceLot"
          placeholder="Source Lot"
          options={this.state.clientLots.map(lot => {
            return { value: lot._id, label: lot.s2sId };
          })}
          required={true}
        />
        <input type="text" value={this.state.lotProductData} disabled={true} />
        <Select
          name="targetClient"
          id="targetClient"
          placeholder="Destination"
          options={this.props.targetClientList.map(lot => {
            return { value: lot._id, label: lot.s2sId };
          })}
          defaultValue={{ value: null, label: "Inventory" }}
          required={true}
        />
        <Select name="printType" id="printType" options={formTool.labelTypes} />
        <input
          name="count"
          id="count"
          type="text"
          size="8"
          placeholder="# Count"
        />
        <Select name="weight" id="printType" options={formTool.weights} />
      </div>
    );
  }
}

JobGenerator.propTypes = {
  clientId: PropTypes.string,
  targetClientList: [PropTypes.object],
  printingTemplateList: [PropTypes.string],
  token: PropTypes.string
};

export default JobGenerator;
