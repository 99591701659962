import styled from "styled-components";
import { AppSpinner } from "../app-spinner/AppSpinner";
import React from "react";

const StyledLoaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MasterPageSpinner = () => {
  return (
    <StyledLoaderDiv>
      <StyledLoadingContainer>
        <div>Loading</div>
        <AppSpinner />
      </StyledLoadingContainer>
    </StyledLoaderDiv>
  );
};
