import React, { Component } from "react";

class ModalAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let modalAlertContents = "";
    if (this.props.modalAlertTrigger === true) {
      modalAlertContents = (
        <div className="cb_modal cb_modal--open cb_modal--login">
          <div className="cb_modal__bg" />
          <div className="cb_modal__window">
            <i onClick={this.closeModal} className="cb_modal__close-button" />
            <div className="cb_modal__content">{this.state.modalMessage}</div>
          </div>
        </div>
      );
    }

    return <div>{modalAlertContents}</div>;
  }
}
export default ModalAlert;
