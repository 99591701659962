import { oAuthMode } from "../configurator";
const remoteConfig = oAuthMode(window.location.href);
const baseAPI = remoteConfig.baseUrl + "client";

const S3UploadService = {
  uploadFile(file, signedRequest, url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedRequest);
    xhr.setRequestHeader("crossorigin", "anonumous");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          callback(url);
          return url;
          // document.getElementById("avatar-url").value = url;
        } else {
          alert("Could not upload file.");
        }
      }
    };
    xhr.send(file);
  },

  uploadRequest(file, token, clientId, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${baseAPI}/` +
        token +
        `/` +
        clientId +
        `/sign-s3?file-name=${encodeURIComponent(
          file.name
            .replace(/\s/g, "")
            .replace(".", "-" + Math.round(Math.random() * 10000000) + ".")
        )}&file-type=${file.type}`
    );
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);

          S3UploadService.uploadFile(
            file,
            response.signedRequest,
            response.url,
            results => {
              callback(results);
            }
          );
        } else {
          alert("Could not get signed URL.");
        }
      }
    };
    xhr.send();
  }
};

export default S3UploadService;
