import React, { Component } from "react";
import PropTypes from "prop-types";
import JobQue from "./clientComponents/shipAndPrint/JobQue";
import JobGenerator from "./clientComponents/shipAndPrint/JobGenerator";
import formTools from "./clientComponents/generic/formTools";
import Select from "react-select";
import MenuList from "../components/generics/MenuList";
import async from "async";

class PrintStickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productOptions: false,
      products: false,
      parentLotOptions: false,
      formTools: new formTools(props.token, props.match.params.clientId),
      printJobArray: [],
      parentLots: false,
      currentPrintJob: {
        parent: null,
        product: null,
        label: null,
        count: ""
      },
      printButtonMessage: "Click To Generate",
      message: "",
      printUrls: []
    };
    let formTool = new formTools(props.token, props.match.params.clientId);
    props.getClientProducts(props.match.params.clientId, false, results => {
      let options = results.map(product => {
        return {
          label:
            product.brand.name +
            " - " +
            product.productName +
            " - " +
            product.name,
          value: product._id
        };
      });
      this.setState({ productOptions: options, products: results });
    });
    props.getClientParentLots(props.match.params.clientId, false, results => {
      let options = results.map(parentLot => {
        let disabled =
          parentLot.processingInfo &&
          parentLot.processingInfo.status === "pending";
        return {
          label: parentLot.s2sId + " " + parentLot.testResult.strain,
          value: parentLot._id,
          isDisabled: disabled
        };
      });
      this.setState({ parentLots: results, parentLotOptions: options });
    });
  }

  removePrintJob = index => {
    let newArray = this.state.printJobArray;
    newArray.splice(index, 1);
    this.setState({
      printJobArray: newArray
    });
  };

  handleAddPrintJob = event => {
    event.preventDefault();
    let allRequired =
      !!this.state.currentPrintJob.count &&
      !!this.state.currentPrintJob.parent &&
      !!this.state.currentPrintJob.label;
    if (allRequired === true) {
      let newArray = this.state.printJobArray;
      let parentLot = this.state.parentLots.find((lot, index) => {
        return (lot._id = this.state.currentPrintJob.parent.value);
      });
      let preparedPrintJob = {
        available: true,
        owner: parentLot.owner,
        parentLot: this.state.currentPrintJob.parent.value,
        parentDisplay: this.state.currentPrintJob.parent.label,
        producer: parentLot.producer._id,
        count: this.state.currentPrintJob.count,
        label: this.state.currentPrintJob.label.value,
        labelDisplay: this.state.currentPrintJob.label.label,
        testResult: parentLot.testResult._id
      };
      newArray.push(preparedPrintJob);
      this.setState({
        printJobArray: newArray,
        currentPrintJob: {
          parent: null,
          product: null,
          label: null,
          count: ""
        }
      });
    } else {
      this.setState({ message: "All Fields Are Required" });
    }
  };
  deployPrintJobs = () => {
    if (this.state.printJobArray.length === 0) {
      this.setState({
        message: "You must create one printJob before generating"
      });
    } else {
      this.setState({
        printButtonMessage: (
          <div style={{ width: 50 }}>
            Loading <img src="/assets/images/loading.gif" />
          </div>
        )
      });
      let timeout = 0;
      let count = 1;
      async.mapSeries(
        this.state.printJobArray,
        (printJob, callback) => {
          setTimeout(() => {
            let newArray = this.state.printJobArray;
            let newUrlArray = this.state.printUrls;
            let parentLot = this.state.parentLots.find(parentLot => {
              return parentLot._id === printJob.parentLot;
            });

            printJob.parentLot = parentLot;
            printJob.testResult = parentLot.testResult;
            this.props.deployPrintJob(
              this.props.match.params.clientId,
              printJob,
              results => {
                if (typeof results.error !== "undefined") {
                  this.setState({
                    printButtonMessage: "Click To Generate",
                    message: JSON.stringify(results)
                  });
                } else {
                  this.setState({
                    message:
                      count +
                      " of " +
                      this.state.printJobArray.length +
                      " completed"
                  });
                  count++;
                  callback(null, {
                    location: results.Location,
                    label:
                      printJob.parentDisplay +
                      " " +
                      printJob.productDisplay +
                      " " +
                      printJob.labelDisplay
                  });
                }
              }
            );
          }, timeout);
          timeout = 3000;
        },
        (err, results) => {
          this.setState({
            message: "",
            printJobArray: [],
            printUrls: results,
            printButtonMessage: "Click To Generate"
          });
        }
      );
    }
  };

  printJobElement = (printJob, index) => {
    let parentLot = this.state.parentLots.find(parentLot => {
      return (parentLot._id = printJob.parentLot);
    });
    return (
      <div>
        <div style={{ width: "75%", display: "inline-block" }}>
          <h3>Item #{index + 1}</h3>
          <div
            style={{
              width: 100,
              verticalAlign: "middle",
              display: "inline-block"
            }}
          >
            <div
              style={{
                width: 100,
                verticalAlign: "middle",
                display: "inline-block"
              }}
              dangerouslySetInnerHTML={{
                __html: parentLot.testResult.cannastamp
              }}
            />
          </div>
          <div
            style={{
              width: "75%",
              verticalAlign: "middle",
              display: "inline-block"
            }}
          >
            <div
              style={{
                verticalAlign: "middle",
                paddingBottom: 15,
                paddingLeft: 15,
                display: "inline-block"
              }}
            >
              <b>Parent Lot: </b>
              {printJob.parentDisplay}
            </div>
            <div
              style={{
                verticalAlign: "middle",
                paddingBottom: 15,
                paddingLeft: 15,
                display: "inline-block"
              }}
            >
              <b> Product: </b>
              {printJob.productDisplay}
            </div>
            <div
              style={{
                verticalAlign: "middle",
                paddingBottom: 15,
                paddingLeft: 15,
                display: "inline-block"
              }}
            >
              <b>Label Count: </b>
              {printJob.count}
            </div>
            <div
              style={{
                verticalAlign: "middle",
                paddingBottom: 15,
                paddingLeft: 15,
                display: "inline-block"
              }}
            >
              <b>Label: </b>
              {printJob.labelDisplay}
            </div>
          </div>
        </div>
        <div
          style={{
            verticalAlign: "middle",
            paddingBottom: 15,
            paddingLeft: 15,
            width: "25%",
            display: "inline-block"
          }}
        >
          <div
            style={{
              verticalAlign: "middle",
              paddingBottom: 15,
              paddingLeft: 15,
              display: "inline-block"
            }}
          >
            <input
              style={{
                verticalAlign: "middle",
                width: 100,
                display: "inline-block"
              }}
              value="- Remove"
              type="button"
              onClick={() => this.removePrintJob(index)}
            />
          </div>
        </div>
      </div>
    );
  };

  printUrlElement = (printJob, index) => {
    let parentLot = this.state.parentLots.find(parentLot => {
      return (parentLot._id = printJob.parentLot);
    });
    return (
      <div>
        <h3>Item #{index + 1}</h3>
        <div
          style={{ width: 50, display: "inline-block" }}
          dangerouslySetInnerHTML={{
            __html: parentLot.testResult.cannastamp
          }}
        />
        <span style={{ paddingBottom: 15, paddingLeft: 15 }}>
          <b>Parent Lot: </b>
          {printJob.parentDisplay}
        </span>
        <span style={{ paddingBottom: 15, paddingLeft: 15 }}>
          <b> Product: </b>
          {printJob.productDisplay}
        </span>
        <span style={{ paddingBottom: 15, paddingLeft: 15 }}>
          <b>Label Count: </b>
          {printJob.count}
        </span>
        <span style={{ paddingBottom: 15, paddingLeft: 15 }}>
          <b>Label: </b>
          {printJob.labelDisplay}
        </span>
        <span style={{ paddingBottom: 15, paddingLeft: 15 }}>
          <input
            style={{ width: 100, display: "inline-block" }}
            value="- Remove"
            type="button"
            onClick={() => this.removePrintJob(index)}
          />
        </span>
      </div>
    );
  };

  form = () => {
    let formTool = this.state.formTools;
    if (!this.state.productOptions || !this.state.parentLotOptions) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <h3>Choose Lots to Generate Cannastamps&trade; For</h3>
          <br />
          <h2>We Do Not Support Avery Labels</h2>
          <p>
            Avery does not make the dimensions of their labels public. Because
            of this, we cannot provide templates for these products easily.
          </p>
          <h2>{this.state.message}</h2>

          <div className="cb_form">
            <form
              id="PrintJobForm"
              className="cb_form__form"
              onSubmit={this.handleAddPrintJob}
              value="submit"
            >
              <div>
                <div className="cb_form__row">
                  <div className="cb_form__column">
                    <div className="cb_input--group">
                      <label htmlFor="name">Parent Lot</label>
                      <Select
                        name={"parent"}
                        id={"parent"}
                        components={{ MenuList }}
                        placeholder="Parent Lot"
                        options={this.state.parentLotOptions}
                        required={true}
                        value={this.state.currentPrintJob.parent}
                        onChange={value => {
                          let currentPrintJob = this.state.currentPrintJob;
                          currentPrintJob.parent = value;
                          this.setState({ currentPrintJob });
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="cb_form__column">
                    <div className="cb_input--group">
                      <label htmlFor="name">Product</label>
                      <Select
                        name={"product"}
                        id={"product"}
                        components={{ MenuList }}
                        placeholder="Select A Product"
                        options={this.state.productOptions}
                        required={true}
                        value={this.state.currentPrintJob.product}
                        onChange={value => {
                          let currentPrintJob = this.state.currentPrintJob;
                          currentPrintJob.product = value;
                          this.setState({ currentPrintJob });
                        }}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="cb_form__row">
                  <div className="cb_form__column">
                    <div className="cb_input--group">
                      <label htmlFor="name"># of Stickers</label>
                      <input
                        type="text"
                        name="count"
                        id="count"
                        size="10"
                        placeholder="#"
                        required={true}
                        value={this.state.currentPrintJob.count}
                        onChange={() => {
                          let value = document.getElementById("count").value;
                          if (!isNaN(Number(value))) {
                            let currentPrintJob = this.state.currentPrintJob;
                            currentPrintJob.count = value;
                            this.setState({ currentPrintJob });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="cb_form__column">
                    <div className="cb_input--group">
                      <label htmlFor="name">Label Template</label>
                      <Select
                        name={"label"}
                        id={"label"}
                        placeholder="Select A Template"
                        options={formTool.labelTypes}
                        required={true}
                        components={{ MenuList }}
                        value={this.state.currentPrintJob.label}
                        onChange={value => {
                          let currentPrintJob = this.state.currentPrintJob;
                          currentPrintJob.label = value;
                          this.setState({ currentPrintJob });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="cb_form__row">
                  <div className="cb_form__column">
                    <div className="cb_input--group cb_input--group-submit">
                      <br />
                      <input type="submit" value="Save and Add Another" />
                    </div>
                  </div>
                </div>

                <div className="cb_form__row">
                  {this.state.printJobArray.length > 0 && (
                    <div className="cb_form__column">
                      <br />
                      <h4>Generate Cannastamp&trade; Sticker Templates</h4>
                      <br />
                      <div className="cb_input--group">
                        <a onClick={this.deployPrintJobs}>
                          {this.state.printButtonMessage}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
            <br />
            <br />
            {this.state.printJobArray.length > 0
              ? this.state.printJobArray.map((PrintJob, index) => {
                  return (
                    <div key={index}>
                      {this.printJobElement(PrintJob, index)}
                    </div>
                  );
                })
              : null}
            <ul>
              {this.state.printUrls.map((urlElement, index) => {
                return (
                  <li>
                    <a key={index} target="_blank" href={urlElement.location}>
                      {urlElement.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <div className="cb_account__content">
          <div className="cb_account__content-header">
            <ul className="cb_breadcrumb cb_breadcrumb--title">
              <li className="cb_breadcrumb__active">
                <a className="cb_breadcrumb__link">Print Cannastamps&trade;</a>
              </li>
              <li className="cb_breadcrumb__delimiter">
                <i className="fal fa-arrow-right" />
              </li>
              <li className="cb_breadcrumb__current" />
            </ul>
            <div className="cb_account__content-header-message">
              <p />
            </div>
          </div>
          <div className="cb_account__content-content">
            <div className="cb_article">
              <h4 className="cb_article__title">
                Create Cannastamp&trade; Sticker Templates
              </h4>
              <div className="cb_article__content">{this.form()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrintStickers.propTypes = {};

export default PrintStickers;
