import React, { Component } from "react";

class DispensaryModalUserSignup extends Component {
  constructor(props) {
    super(props);
  }

  handleAccountCreation = () => {
    this.props.closeModal();
  };
  closeModal = () => {
    this.props.closeModal();
  };
  render() {
    var modalAlertContents = "";
    if (
      typeof this.props.modalDispensaryLoginTrigger !== null &&
      this.props.modalDispensaryLoginTrigger === true
    ) {
      modalAlertContents = (
        <div className="cb_modal cb_modal--open cb_modal--dispensary-user-login">
          <div className="cb_modal__bg" />
          <div className="cb_modal__window">
            <a onClick={this.closeModal} className="cb_modal__close-button" />
            <div className="cb_modal__content">
              <div>
                <div className="cb_modal__heading">Create a New Account</div>
                <div className="cb_modal__copy">
                  We will notify you when your new Cannabinder is ready to view.
                </div>
                <input
                  className="cb_inline-search__query input input--text input--open stuff"
                  type="text"
                  onKeyPress={this.handleAccountCreation.bind(this)}
                  placeholder="Sign up Here"
                  name="dispensarySignupEmail"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div>{modalAlertContents}</div>;
  }
}
export default DispensaryModalUserSignup;
