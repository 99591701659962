import React from "react";
import { EntityInterface } from "cbd-api-types";

interface Props {
  clientData: EntityInterface;
}

export default function PremiumKioskCTA(props: Props): JSX.Element {
  const { clientData } = props;
  return (
    <div>
      <div>
        <div style={{ textAlign: "center" }}>
          <h1 style={{ fontSize: "2.25rem" }}>Get More with Pro</h1>
          <div className="cb_account__content-header-message cb_go-pro-header">
            <p>
              Upgrade your free account today to get access to the suite of
              premium features that come with Cannabinder Pro Loyalty+
            </p>
            <a
              className="cb_button--cta"
              href={"/client/" + clientData._id + "/premium/signup"}
            >
              Go Pro Now
            </a>
            {clientData.type !== "producer" && (
              <div>
                <br />
                <div className="cb_badge-group">
                  <div className="cb_badge cb_badge--purple">
                    $400/mo +$50/ Additional Kiosk
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
