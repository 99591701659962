import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
import { ClientApiHOC } from "../client/clientComponents/generic/ClientApiHOC";
import KioskView from "./views/KioskView";
import { EntityDocumentInterface } from "cbd-api-types";
// import { Authentication, ClientApi } from "../apiService/serviceInterfaces";

interface Props {
  clientData: EntityDocumentInterface;
  token: string;
}

class KioskRouter extends React.Component<Props> {
  public render() {
    return (
      <Router>
        <div>
          <Route
            path="/whitelabel/:clientId"
            render={routerProps => {
              let { clientData, token } = this.props;
              return (
                <KioskView
                  {...this.props}
                  {...routerProps}
                  clientData={clientData}
                  token={token}
                  disableCart={true}
                  disableHeader={false}
                />
              );
            }}
          />
        </div>
      </Router>
    );
  }
}

export default AuthenticationHOC()(ClientApiHOC()(KioskRouter));
