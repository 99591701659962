import * as React from "react";
import { Entity } from "../../apiService/serviceInterfaces";
import Select from "react-select";
import AdminService from "../../apiService/AdminService";
import MenuList from "../../components/generics/MenuList";

export interface ClientTransferProductsProps {
  // fully formed client object
  // list of users that can be selected from
  availableClients?: [Entity];
  sourceClient: Entity;
  // is the change initially enabled
  initiallyDisabled?: boolean;
  token: string;
  reloadParent: (updated: Boolean) => void;
}

interface ClientTransferProductsState {
  disabled: boolean;
  warning?: string;
}

/**
 * This component accepts an entity and a user list. it then manages the state of the entity
 * by setting the admin user on the entity and updating its rendered view.
 */
export default class ClientTransferProducts extends React.Component<
  ClientTransferProductsProps,
  ClientTransferProductsState
> {
  constructor(props: ClientTransferProductsProps) {
    super(props);
    this.state = {
      disabled: this.props.initiallyDisabled
        ? this.props.initiallyDisabled
        : false
    };
    this.editEnable = this.editEnable.bind(this);
    this.transferEntityProducts = this.transferEntityProducts.bind(this);
  }

  private transferEntityProducts(theEvent: React.FormEvent<HTMLFormElement>) {
    theEvent.preventDefault();
    const dataToSend = new FormData(theEvent.currentTarget);
    if (dataToSend.get("targetEntity").toString().length > 0)
      AdminService.transferEntityProducts(dataToSend, this.props.token).then(
        results => {
          this.props.reloadParent(true);
        },
        (rejection: any) => {
          this.setState({
            warning: "Error Saving Entity. Rejection:" + rejection
          });
        }
      );
  }

  private editEnable(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    this.setState({ disabled: !this.state.disabled });
  }

  public render() {
    const { availableClients } = this.props;
    return (
      <div
        style={{
          width: 400,
          display: "inline-block",
          padding: 20,
          verticalAlign: "top"
        }}
      >
        Entity Transfer
        <input type="button" value="Change Owner" onClick={this.editEnable} />
        <form onSubmit={this.transferEntityProducts}>
          <div>
            <label>Source: {this.props.sourceClient.name}</label>
            <input
              type="hidden"
              name="sourceEntity"
              value={this.props.sourceClient._id}
            />
          </div>
          <div>
            <label>Source</label>
            {availableClients &&
              Array.isArray(availableClients) &&
              availableClients.length > 0 && (
                <Select
                  name="targetEntity"
                  isDisabled={this.state.disabled}
                  required={true}
                  components={{ MenuList }}
                  options={
                    Array.isArray(this.props.availableClients) &&
                    this.props.availableClients.map(client => {
                      return {
                        value: client._id,
                        label:
                          client.name +
                          " - " +
                          client.licenseNumber +
                          " - " +
                          client.buisnessLicenseNumber
                      };
                    })
                  }
                />
              )}
          </div>
          {this.state.disabled === false && <input type="submit" />}
        </form>
      </div>
    );
  }
}
