import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthenticationHOC } from "../components/AuthenticationHOC";
// lot administration
import LotManage from "../admin/LotManage";
// administration page for users to be worked on
import ProductManage from "../admin/ProductManage";
// user administration page
import UserManage from "../admin/UserMaster";
// client administration
import ClientManage from "../admin/ClientManage";
import CreateEntity from "../admin/CreateEntity";
import TestResultsManage from "../admin/TestResultsManage";
import TestDataEntry from "../admin/TestDataEntry";

class AdminRouter extends Component {
  // already on the /client route
  render() {
    return (
      <Router>
        <div>
          <Route
            path="/admin/products"
            render={props => (
              <ProductManage
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
              />
            )}
          />
          <Route
            path="/admin/lots"
            render={props => (
              <LotManage
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
              />
            )}
          />
          <Route
            path="/admin/users"
            render={props => (
              <UserManage
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
              />
            )}
          />
          <Route
            path="/admin/addclient"
            render={props => (
              <CreateEntity
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
              />
            )}
          />
          <Route
            path="/admin/clients"
            render={props => (
              <ClientManage
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
              />
            )}
          />
          <Route
            path="/admin/testResults"
            render={props => (
              <TestResultsManage
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
              />
            )}
          />
          <Route
            path="/admin/service/tests/entry"
            render={props => (
              <TestDataEntry
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
                mode="entry"
              />
            )}
          />
          <Route
            path="/admin/service/tests/review"
            render={props => (
              <TestDataEntry
                logout={this.props.logout}
                onModalLogin={this.props.modalLogin}
                modalAlert={this.props.modalAlert}
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                token={this.props.token}
                mode="review"
              />
            )}
          />
        </div>
      </Router>
    );
  }
}

export default AuthenticationHOC()(AdminRouter);
