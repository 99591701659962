import React, { Component } from "react";
import CannastampIcon from "../components/product/CannastampIcon";
import PublicService from "../apiService/PublicService";
import UserService from "../apiService/UserService";
import { withRouter } from "react-router";
import RatingComponent from "../components/product/RatingComponent";
import SimilarProductDisplay from "../components/product/SimilarProductDisplay";
import ActivityIconMap from "../components/util/ActivityIconMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ThumbsUpDown from "../components/generics/ThumbsUpDown";

class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      lot: null,
      similarProducts: null,
      binderItems: [{}]
    };
    this.rateRef = React.createRef();
  }

  componentDidMount() {
    this.loadProduct();
  }

  setInBinder = (value) => {
    this.setState({ inBinder: value });
  };

  loadProduct = () => {
    this.setState({ product: null, binderItems: null, inBinder: null }, () => {
      UserService.getProduct(this.props.match.params._id).then(
        findProductJson => {
          if (findProductJson.error) {
            console.warn("product search query failed");
            console.warn(findProductJson.data);
          } else {
            if (this.props.match.params.referenceLotId) {
              PublicService.getProductLot(this.props.match.params.referenceLotId).then((resData) => {
                findProductJson.data.latestLot = resData.data;
                debugger;
                this.setState({
                  productTestResult: findProductJson.data.latestLot.testResult,
                  // inBinder: findProductJson.data.inBinder,
                  product: findProductJson.data,
                  lot: findProductJson.data.latestLot,
                  binderItems:
                    typeof findProductJson.data.binderItems === "undefined"
                      ? [{}]
                      : findProductJson.data.binderItems
                });
              }, (error) => {
                console.error(error);
              })

            } else {
              this.setState({
                productTestResult: findProductJson.data.latestLot.testResult,
                // inBinder: findProductJson.data.inBinder,
                product: findProductJson.data,
                lot: findProductJson.data.latestLot,
                binderItems:
                  typeof findProductJson.data.binderItems === "undefined"
                    ? [{}]
                    : findProductJson.data.binderItems
              });
            }
            PublicService.getSimilarProducts(
              findProductJson.data.latestLot.testResult._id
            ).then(similarProductResJson => {
              this.setState({ similarProducts: similarProductResJson });
            });
            if (
              this.props.user !== null &&
              this.props.user !== "" &&
              typeof this.props.user !== "undefined"
            ) {
              this.reloadBinderItems();
            }
          }
        }
      );
    });
  };

  reloadBinderItems = () => {
    this.setState({ binderItems: [], inBinder: null }, () => {
      UserService.getUserBinderOnProduct(
        this.props.token,
        this.props.user,
        this.props.match.params._id
      ).then(json => {
        this.setState({
          inBinder: json.length > 0,
          binderItems: json.length > 0 ? json : [{}]
        });
      });
    });
  };

  dispensaryLoginTool = () => {
    this.props.dispensaryLoginTool("this.state.productTestLot._id");
  };

  addToCannabinderButton() {
    if (
      this.props.user !== null &&
      typeof this.props.user !== "undefined" &&
      this.props.user.dispensaryUser === true
    ) {
      return (
        <a
          className="cb_binder-link__add-remove col-sm-12"
          onClick={this.dispensaryLoginTool}
        >
          <span className="fas fa-plus" /> Add to My Favorites
        </a>
      );
    } else if (this.state.inBinder === true) {
      return (
        <a className="cb_binder-link__add-remove col-sm-12" href="/binder">
          <span className="fas fa-heart" /> View My Favorites
        </a>
      );
    } else {
      return (
        <div onClick={this.scrollToRate}>
          {" "}
          <a
            className="cb_binder-link__add-remove col-sm-12"
            alt="Add to My Favorites"
          >
            <span className="fas fa-plus" /> Add to My Favorites
          </a>
        </div>
      );
    }
  }

  findOrCreateBinderItem = (usage, value) => {
    // check if there is already an binder item representing this rating
    let item;
    let binderItems = this.state.binderItems;

    // start from the end for most recent rating (for de-duping)
    for (let index = binderItems.length -1 ; index >= 0; index--) {
      let binderItem = binderItems[index];
      if (binderItem.usage === usage) {
        item = binderItem;
        item.rating = value;
        break;
      }
    }

    if (item !== undefined) {
      return item;
    } else {
      item = {
        usage: usage,
        rating: value,
        public: false,
        user: typeof this.props.user !== "undefined" ? this.props.user._id : null,
        product:
          typeof this.state.product === "object"
            ? this.state.product._id
            : this.state.product,
        lot: this.state.productTestResult.lot,
        currentNearest: null,
        test: this.state.productTestResult._id
      };
      return item;
    }
  };

  saveBinderItemHelper = (item) => {
    const that = this;
    UserService.rateBinderItem(
      that.props.token,
      that.props.user,
      item
    )
      .then(results => {
        // if this has occured a browser session went stale and the window just needs a reload
        if (typeof results.expiredAt !== "undefined")
          that.props.forceParentRender();
        else {
          if (results._id !== undefined) {
            item._id = results._id;
          }
          that.setState({
            errorMessage: "",
            updatingBinderItems: false
          });
          that.setInBinder(true);
        }
      })
      .catch(error => {
        that.setState({ errorMessage: JSON.stringify(error), updatingBinderItems: false });
      });
  };

  saveBinderItemFunction = (usage) => {
    const that = this;
    let saveFunction = function (value) {
      if (that.state.updatingBinderItems === true) { return; }
      that.setState(
        { updatingBinderItems: true },
        () => {
          const item = that.findOrCreateBinderItem(usage, value);
          that.setState(
            { binderItems: that.state.binderItems.concat([item]) },
            () => { that.saveBinderItemHelper(item); }
          );
        }
      );
    };
    return saveFunction;
  };

  ratings = () => {
    let activityRatingItems = {};
    this.state.binderItems.map((item) => {
      activityRatingItems[item.usage] = item;
    });

    let ratings = [];
    const backgroundStyle = {
      background: "rgba(255,255,255,0)",
      marginLeft: 20,
      marginRight: 10
    };

    Object.keys(ActivityIconMap).map((key, index) => {
      ratings.push(
        <div
          key={key}
          onClick={!this.props.user ? this.props.onModalLogin : null}
        >
          <div className="cb_grid-item--3" key={"ActivityRatings-" + index}>
            <div className="cb_rating__form-group">
              <div className="cb_rating__activity-group">
                <div className="cb_rating__activity">
                  <p>
                    <FontAwesomeIcon size="2x" icon={ActivityIconMap[key]} style={backgroundStyle} title={key} />
                    {key}
                  </p>
                </div>
                <div className="cb_rating__thumbs">
                  <ThumbsUpDown
                    onChange={this.saveBinderItemFunction(key)}
                    value={activityRatingItems[key] !== undefined ? activityRatingItems[key].rating : 0} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return ratings;
  }

  scrollToRate = () => {
    let boundingData = this.rateRef.current.getBoundingClientRect();
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = this.rateRef.current.getBoundingClientRect(),
      offset = this.rateRef.current.top - bodyRect.top;
    // this is how I feel about IE code. FO
    if (
      !(
        window.navigator.userAgent.indexOf("MSIE") > -1 ||
        window.navigator.userAgent.indexOf("Edge") > -1
      )
    ) {
      window.scrollTo({
        top: -(bodyRect.top - (boundingData.top - 200)),
        behavior: "smooth"
      });
    }
  };

  render() {
    var svgDownloadLink = <div />;
    if (
      typeof this.props.user !== "undefined" &&
      this.props.user !== null &&
      this.props.user.processorUser === true
    ) {
      svgDownloadLink = (
        <a href="javascript:javascript: (function () { var e = document.createElement('script'); e.setAttribute('src', 'https://nytimes.github.io/svg-crowbar/svg-crowbar-2.js'); e.setAttribute('class', 'svg-crowbar'); document.body.appendChild(e); })();">
          {" "}
          Download SVGs{" "}
        </a>
      );
    } else {
    }

    if (
      this.state.productTestResult != null &&
      this.state.similarProducts != null &&
      this.state.product !== null
    ) {
      return (
        <div className="cb_page__productDetail">
          <div className="cb_template__section cb_template__product-masthead">
            <div id="cb_masthead--product">
              <div className="cb_masthead__content container">
                <div className="row">
                  <div className="cb_product-cannastamp col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                    <CannastampIcon
                      testResult={this.state.productTestResult}
                      size={300}
                    />
                    <div className="cb_product-info__content">
                      <h1 className="cb_product-info__heading">
                        {this.state.product.name}
                      </h1>

                      <h4 className="cb_product-info__grower-name">
                        By:{" "}
                        <a href={"/brand/" + this.state.product.brand._id}>
                          {" "}
                          {this.state.product.brand.name}
                        </a>
                      </h4>
                      <p>
                        {this.state.product.productName && (<div>{this.state.product.productName}</div>)}
                        Type: {this.state.product.productCategory} <br />
                        Category:{this.state.product.productType}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cb_binder-links container-fluid">
                <div className=" row">
                  {this.addToCannabinderButton()}
                </div>
              </div>
            </div>
          </div>

          <div className="cb_template__section">
            <div className="cb_surface">
              <div className="cb_surface__section">
                <div className="cb_product-rating" ref={this.rateRef}>
                  <h3 className="cb_product-rating__heading">Rate & Save</h3>
                  <div className="cb_grid">

                    {this.ratings()}
                  </div>
                </div>
              </div>
              <div className="cb_surface__section">
                <h3 className="cb_product__similar-products-heading">
                  Similar Products
                </h3>

                <div className="cb_grid">
                  <SimilarProductDisplay similarProductArray={this.state.similarProducts} />
                </div>
              </div>
              <div className="cb_surface__section">
                <div className="cb_product__certification">
                  <h3 className="cb_product__certification-heading">Certified By</h3>
                  {this.state.productTestResult.testLab !== null &&
                    typeof this.state.productTestResult.testLab !==
                    "undefined" &&
                    this.state.productTestResult.testLab.name
                      .toLowerCase()
                      .includes("confidence") === true && (
                      <div>
                        <a href="https://www.conflabs.com/" target="_blank">
                          <img
                            className="cb_product__certifier-logo"
                            style={{
                              top: this.props.top,
                              left: this.props.left
                            }}
                            src="/assets/images/confidenceLogo.png"
                          />
                        </a>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
export default withRouter(ProductPage);
