import React, { Component } from "react";
import PropTypes from "prop-types";
import Select, { Creatable } from "react-select";
import ClientServices from "../../apiService/ClientService";
import { AuthenticationHOC } from "../../components/AuthenticationHOC";
import formTools from "../../client/clientComponents/generic/formTools";
import { Redirect } from "react-router-dom";
import ClientApiHOC from "../../client/clientComponents/generic/ClientApiHOC";
import MenuList from "../../components/generics/MenuList";
import AdminDataEntryService from "../../apiService/AdminDataEntryService";

class DataEntryTestResultForm extends Component {
  constructor(props) {
    super(props);
    let disabled = false;
    let message = null;
    let formTool = new formTools(props.token, props.clientId);

    this.state = {
      basic: false,
      sampleType: false,
      productCategories: formTool.productCategories,
      testResult: false,
      strainList: false,
      modifyOnReview: false,
      labList: false,
      message,
      disabled: this.props.mode !== "entry",
      redirect: false,
      strainBgcolor: "white",
      sampleTypeBgcolor: "white",
      sampleTypeCategoryBgcolor: "white",
      testLabBgColor: "white",
      submitButtonDisabled: false,
      pdfUploadBgColor: "white"
    };

    ClientServices.loadLabsKeyPair().then(
      labPairs => {
        let labList = labPairs.map(labPair => {
          return { value: labPair._id, label: labPair.name };
        });
        this.setState({ labList });
      },
      rejected => {
        console.warn(rejected);
      }
    );
    formTool.loadProductStrains(results => {
      this.setState({ strainList: results });
    });
    formTool.loadProductTypes(results => {
      this.setState({ sampleType: results });
    });
  }

  handleSave = event => {
    event.preventDefault();
    let dataToSend = new FormData(event.target);
    let sampleTypeCategory = dataToSend.get("sampleTypeCategory");
    let sampleType = dataToSend.get("sampleType");
    let strain = dataToSend.get("strain");
    let testLab = dataToSend.get("testLab");
    let errorColor = "#ffa0a0";
    let normalColor = "white";
    let errorState = new Object();
    let normalState = new Object();
    strain === ""
      ? (errorState.strainBgColor = errorColor)
      : (normalState.strainBgColor = normalColor);

    sampleType === ""
      ? (errorState.sampleTypeBgColor = errorColor)
      : (normalState.sampleTypeBgColor = normalColor);

    sampleTypeCategory === ""
      ? (errorState.sampleTypeCategoryBgColor = errorColor)
      : (normalState.sampleTypeCategoryBgColor = normalColor);

    testLab === ""
      ? (errorState.testLabBgColor = errorColor)
      : (normalState.testLabBgColor = normalColor);

    if (Object.keys(errorState).length > 0) {
      return this.setState({
        message: "All Required Fields Must Be Entered",
        ...errorState,
        ...normalState
      });
    } else {
      this.setState({ disabled: true });
      AdminDataEntryService.saveTestResultDataEntry(
        this.props.token,
        dataToSend,
        this.props.testResult._id
      ).then(
        () => {
          this.props.submitLotForReview();
        },
        rejected => {
          this.setState({
            message:
              "there was an error saving the testResult" +
              JSON.stringify(rejected),
            ...normalState
          });
        }
      );
    }
  };

  approveData = () => {
    this.setState({ disabled: true });
    this.props.approveData();
  };

  reject = () => {
    this.setState({ reject: true });
  };

  enabledReviewEdit = () => {
    this.setState({ modifyOnReview: true, disabled: false });
  };

  submitReject = () => {
    let reviewNotes = document.getElementById("reviewNotes").value;
    if (reviewNotes.length < 5) {
      alert(
        "you need to leave a more detailed message as to why you rejected the entry"
      );
    } else {
      this.setState({ disabled: true });
      this.props.rejectData(reviewNotes);
    }
  };

  approveDataWithEdits = event => {
    let dataToSend = new FormData(document.getElementById("dataEntryForm"));
    this.setState({ disabled: true });
    ClientServices.saveTestResult(
      this.props.token,
      this.props.clientId,
      dataToSend,
      this.props.testResult._id
    ).then(
      () => {
        this.props.approveDataWithEdits();
      },
      rejected => {
        this.setState({
          message:
            "there was an error saving the testResult" +
            JSON.stringify(rejected)
        });
      }
    );
  };

  render() {
    let formTool = new formTools(this.props.token, this.props.clientId);
    if (
      this.state.strainList === false ||
      this.state.sampleType === false ||
      this.state.labList === false
    ) {
      return <div>testResult form loading</div>;
    } else {
      return (
        <div className="cb_form">
          {!!this.state.redirect && (
            <Redirect to={"/client/" + this.props.clientId + "/parentLots"} />
          )}

          <p>
            <b>{this.state.message}</b>
          </p>
          <form
            className="cb_form__form"
            name="dataEntryForm"
            id="dataEntryForm"
            onSubmit={this.handleSave}
          >
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {this.props.testResult._id && (
                    <input
                      disabled={this.state.disabled}
                      type="hidden"
                      name="_id"
                      value={
                        this.props.testResult._id
                          ? this.props.testResult._id
                          : null
                      }
                    />
                  )}
                  {!this.state.basic && (
                    <input
                      disabled={this.state.disabled}
                      type="hidden"
                      name="approved"
                      value={true}
                    />
                  )}

                  <input
                    type="hidden"
                    name="dataOwner"
                    id="dataOwner"
                    value={this.props.clientId}
                  />
                </div>

                {
                  <div className="cb_input--group">
                    <label
                      style={{ backgroundColor: this.state.testLabBgColor }}
                      htmlFor="name"
                    >
                      Lab Source*
                    </label>
                    <Select
                      name="testLab"
                      id="testLab"
                      options={this.state.labList}
                      required={true}
                      isDisabled={this.state.disabled}
                      {...typeof this.props.testResult.testLab !==
                        "undefined" && {
                        defaultValue: {
                          label: this.state.labList.find(
                            x => x.value === this.props.testResult.testLab._id
                          ).label,
                          value: this.props.testResult.testLab._id
                        }
                      }}
                    />
                  </div>
                }
                {!this.state.basic && (
                  <div className="cb_input--group">
                    <label
                      style={{
                        backgroundColor: this.state.sampleTypeCategoryBgColor
                      }}
                    >
                      Product Categories*
                    </label>
                    <Select
                      name="sampleTypeCategory"
                      id="sampleTypeCategory"
                      required={true}
                      isDisabled={this.state.disabled}
                      options={this.state.productCategories}
                      {...typeof this.props.testResult.sampleTypeCategory !==
                        "undefined" && {
                        defaultValue: {
                          label: this.props.testResult.sampleTypeCategory,
                          value: this.props.testResult.sampleTypeCategory
                        }
                      }}
                    />
                  </div>
                )}
                {!this.state.basic && (
                  <div className="cb_input--group">
                    <label
                      style={{ backgroundColor: this.state.sampleTypeBgColor }}
                      htmlFor=""
                    >
                      Sample Type*
                    </label>
                    <Creatable
                      name="sampleType"
                      id="sampleType"
                      className="cb_input"
                      required={true}
                      isDisabled={this.state.disabled}
                      options={this.state.sampleType}
                      {...typeof this.props.testResult.sampleType !==
                        "undefined" && {
                        defaultValue: {
                          label: this.props.testResult.sampleType,
                          value: this.props.testResult.sampleType
                        }
                      }}
                    />
                  </div>
                )}

                {!this.state.basic && (
                  <div className="cb_input--group">
                    <label
                      style={{ backgroundColor: this.state.strainBgColor }}
                      htmlFor=""
                    >
                      Sample Strain*
                    </label>
                    <Creatable
                      name="strain"
                      id="strain"
                      className="cb_input"
                      required={true}
                      components={{ MenuList }}
                      isDisabled={this.state.disabled}
                      placeholder="Strain"
                      options={this.state.strainList}
                      {...typeof this.props.testResult.strain !==
                        "undefined" && {
                        defaultValue: {
                          label: this.props.testResult.strain,
                          value: this.props.testResult.strain
                        }
                      }}
                    />
                  </div>
                )}

                {formTool.inputField(
                  "tracabilitySampleLotId",
                  "Sample Lot ID",
                  true,
                  this.state.disabled,
                  this.props.testResult
                )}
                {formTool.inputField(
                  "tracabilityParentLotId",
                  "Source (Batch) Lot ID",
                  true,
                  this.state.disabled,
                  this.props.testResult
                )}
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbc",
                      "CBC",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbcA",
                      "CBCA",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbd",
                      "CBD",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbdA",
                      "CBDA",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbdV",
                      "CBDV",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbdVA",
                      "CBDVA",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                </div>
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbg",
                      "CBG",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbgA",
                      "CBGA",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cbl",
                      "CBL",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cblA",
                      "CBLA",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbn",
                      "CBN",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbnA",
                      "CBNA",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "d8Thc",
                      "D8THC",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "thc",
                      "THC",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "thcA",
                      "THCA",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thcAC4",
                      "THCAC4",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thcV",
                      "THCV",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thcVA",
                      "THCVA",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                </div>

                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "thcTotal",
                      "Total THC",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                </div>

                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "cbdTotal",
                      "Total CBD",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "caryophyllene",
                      "Caryophyllene",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "humulene",
                      "Humelene",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "limonene",
                      "Limonene",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "linalool",
                      "Linalool",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "myrcene",
                      "b-Myrcene",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "ocimene",
                      "Ocimene (total)",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                </div>
                <div className="cb_input--group">
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "alphaPinene",
                      "a-Pinene",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "betaPinene",
                      "b-Pinene",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    formTool.inputFieldNumeric(
                      "terpinolene",
                      "Terpinolene",
                      true,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
            </div>
            {!this.state.basic && this.state.advanced && (
              <h1>Extended Terpenes</h1>
            )}
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "",
                      "Aromadendrene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "bisabolol",
                      "a-Bisabolol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "",
                      "Borneol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "camphene",
                      "Camphene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "camphor",
                      "Camphor",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "carene",
                      "Carene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "caryophelleneOxide",
                      "Caryophyllene Oxide"
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "aCedrene",
                      "a-Cedrene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cedrol",
                      "Cedrol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "citronellol",
                      "Citronellol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "paraCymene",
                      "para-Cymene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "eucalyptol",
                      "Eucalyptol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "farnesene",
                      "a-Farnesene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "bFarnesene",
                      "b-Farnesene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "fenchol",
                      "Fenchol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "geraniol",
                      "Geraniol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "gernylAcetate",
                      "Gernyl Acetate",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "guaiol",
                      "Guaiol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "Isoborneol",
                      "Isoborneol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "isopulegol",
                      "Isopulegol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "bMaaliene",
                      "b-Malliene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "menthol",
                      "Menthol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "nerol",
                      "Nerol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "transNerolidol",
                      "trans-Nerolidol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "cisNerolidol",
                      "cis-Nerolidol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "aPhellandrene",
                      "a-Phellandrene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "phytol1",
                      "Phytol 1",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "phytol2",
                      "Phytol 2",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "pulegone",
                      "Pulegone",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "sabinene",
                      "Sabinene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "sabineneHydrate",
                      "Sabinene Hydrate",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "selinadiene",
                      "Selinadiene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "aTerpinene",
                      "a-Terpinene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "gTerpinene",
                      "gamma(y)-Terpinene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "terpineol",
                      "Terpineol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thujene",
                      "Thujene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "thymol",
                      "Thymol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputFieldNumeric(
                      "",
                      "valencene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      15
                    )}
                </div>
              </div>
            </div>
            <br />
            {!this.state.basic && this.state.advanced && (
              <h1>Residual Solvents</h1>
            )}
            <div className="cb_form__row">
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "acetone",
                      "Acetone",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "acetonitrile",
                      "Acetonitrile",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "benzene",
                      "Benzene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nButane",
                      "n-Butane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "carbonTetrachloride",
                      "Carbon Tetrachloride"
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "chloroform",
                      "Chloroform",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "cyclohexane",
                      "Cyclohexane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "dichloromethane",
                      "Dichloromethane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "ethanol",
                      "Ethanol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "ethylAcetate",
                      "EthylAcetate",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "ethyleneOxide",
                      "Ethylene Oxide",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nHeptane",
                      "n-Heptane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nHexane",
                      "n-Hexane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                </div>
              </div>
              <div className="cb_form__column">
                <div className="cb_input--group">
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "isobutane",
                      "Isobutane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "isopropanol",
                      "Isopropanol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methanol",
                      "Methanol",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methylbutane2",
                      "2-Methylbutane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methylcyclopentane",
                      "Methylcyclopentane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "methylpentane3",
                      "3-Methylpentane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "neopentane",
                      "Neopentane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "nPentane",
                      "n-Pentane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "propane",
                      "Propane",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "tetrahydrofuran",
                      "Tetrahydrofuran",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "toluene",
                      "Toluene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "mpXylene",
                      "m,p-Xylene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                  {!this.state.basic &&
                    this.state.advanced &&
                    formTool.inputField(
                      "oXylene",
                      "o-Xylene",
                      false,
                      this.state.disabled,
                      this.props.testResult,
                      0,
                      100
                    )}
                </div>
                {/* <input
                  type="button"
                  value={this.state.advanced ? "Short Form" : "Extended Data"}
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                /> */}
              </div>
            </div>
            <div className="cb_form__row">
              <div className="cb_form__column">{this.props.reviewNotes}</div>
              <div className="cb_form__column">
                <div className="cb_input--group cb_input--group-submit">
                  {this.props.mode === "entry" && (
                    <input
                      disabled={this.state.submitButtonDisabled}
                      type="submit"
                      value="Save Test Result"
                    />
                  )}

                  <p>{this.state.message}</p>
                </div>
              </div>
            </div>
          </form>
          {this.props.mode === "review" && this.state.modifyOnReview === false && (
            <>
              <input type="button" value="Approve" onClick={this.approveData} />
            </>
          )}
          {this.state.modifyOnReview === true && (
            <input
              type="button"
              value="Approved With Edits"
              onClick={this.approveDataWithEdits}
            />
          )}
          {this.props.mode === "review" && this.state.reject !== true && (
            <input onClick={this.reject} type="button" value="Reject" />
          )}
          {this.props.mode === "review" &&
            this.state.reject !== false &&
            this.state.modifyOnReview !== true && (
              <input
                onClick={this.enabledReviewEdit}
                type="button"
                value="Edit"
              />
            )}
          {this.props.mode === "review" && this.state.reject === true && (
            <div>
              <textarea
                type="text"
                id="reviewNotes"
                name="reviewNotes"
                placeholder="Enter notes explaining the rejection"
              />
              <input type="button" value="Reject" onClick={this.submitReject} />
            </div>
          )}
        </div>
      );
    }
  }
}

DataEntryTestResultForm.propTypes = {
  productId: PropTypes.string,
  clientId: PropTypes.string,
  mode: PropTypes.string.isRequired,
  rejectData: PropTypes.func,
  submitData: PropTypes.func,
  approveData: PropTypes.func
};

export default AuthenticationHOC()(DataEntryTestResultForm);
