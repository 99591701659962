import React from "react";
import { ProductInterface } from "cbd-api-types";
import styled from "styled-components";
import { BrandGradient, Color } from "../kioskUtilities/constants";
import CannastampIcon from "../../components/product/CannastampIcon";
import { ClientService } from "../../apiService/ClientService";
import { Spinner } from "../components/Spinner";
import { ProductCard } from "../components/ProductCard";

import { createPortal } from "react-dom";
import { HStack } from "./KioskView";
const { useState, useEffect } = React;
// import RefreshIcon from "@material-ui/icons/Refresh";

interface Props {
  item: ProductInterface;
  addToCart: (product: ProductInterface) => void;
  removeFromCart: (product: ProductInterface) => void;
  deactivateItem: () => void;
  cart: Set<ProductInterface>;
  token: string;
  clientId: string;
  similarOnClick: (encodedTracker: any) => void;
  startOver?: () => void;
  disableCart?: boolean;
  featureImage: string;
}
const KioskActiveItem: React.FC<Props> = (props: Props) => {
  const {
    item,
    deactivateItem,
    addToCart,
    removeFromCart,
    cart,
    token,
    clientId,
    similarOnClick,
    disableCart,
    featureImage
  } = props;
  const [similar, setSimilar] = useState<
    { product: ProductInterface }[] | null
  >(null);
  const testId = (item.latestLot as any).testResult._id;
  useEffect(() => {
    (ClientService as any)
      .getSimilarClientProducts(token, clientId, testId)
      .then(
        (json: any) => {
          setSimilar(json);
        },
        (error: PromiseRejectionEvent) => {
          console.warn("Error found", error);
        }
      );
  }, []);
  const actionButton = cart.has(item) ? (
    <button
      className={"cb_data-table__toolbar-button"}
      onClick={() => removeFromCart(item)}
    >
      Remove From Cart
    </button>
  ) : (
    <button
      className={"cb_data-table__toolbar-button"}
      onClick={() => addToCart(item)}
    >
      Add To Cart
    </button>
  );

  const similarProducts = (): JSX.Element => {
    if (similar && similar.length === 0) {
      return (
        <div>
          We're sorry but there are not any products similar to this one.
        </div>
      );
    } else if (similar && similar.length > 0) {
      const items = similar.map((similarElement, index) => {
        return (
          <ProductCard
            product={similarElement.product}
            variant={"small"}
            key={`simPrd_${index}`}
            onClick={similarOnClick}
          />
        );
      });
      return <>{items}</>;
    } else {
      return <Spinner />;
    }
  };

  const activeItemDialog = (
    <ActiveItemDialog>
      <DialogHeader />
      <StyledItemLayout>
        <div>
          <StyledImages>
            <StyledImage image={item.featureImage} />
            <StyledCannastamp>
              <CannastampIcon testResult={(item.latestLot as any).testResult} />
            </StyledCannastamp>
          </StyledImages>
        </div>
        <div>
          <ActiveItemHeaderName>{item.name}</ActiveItemHeaderName>
          <ActiveItemHeaderSubName>{item.productName}</ActiveItemHeaderSubName>
          <p>
            <b>
              {item.productCategory}
              {item.productCategory !== item.productType &&
                " - " + item.productType}
            </b>
          </p>

          <BrandLine>
            by{" "}
            <BrandName>
              {(typeof item.brand !== "string" && item.brand.name) ||
                item.brand}
            </BrandName>
          </BrandLine>
          {/*<Pricing>
            <h2>Purchase Options</h2>
            <div>
              {item.msrpPricing.length > 0 ? (
                (item as ProductInterface).msrpPricing.map(
                  (priceItem: { price: string; sizeGrams: number }) => {
                    return (
                      <div>
                        {priceItem.price}/{priceItem.sizeGrams}
                      </div>
                    );
                  }
                )
              ) : (
                <div>
                  No price information found. Please consult an available
                  budtender.
                </div>
              )}
            </div>
          </Pricing>*/}
          {item.shortDescription && (
            <p>
              <b>{item.shortDescription}</b>
            </p>
          )}
          {(item.longDescription && (
            <p>
              <b>Product Description:</b> {item.longDescription}
            </p>
          )) || <p>&nbsp;</p>}
        </div>
        <div>
          {/* <QRCode
            value={`${window.location.protocol}://${window.location.host}${item.cleanUrl}`}
            size={128}
            fgColor={BrandColor.Violet}
            renderAs={"svg"}
          /> */}
          <StyledHeaderImage featureImage={featureImage} />
          <div>
            Journal your experience and get personalized recommendations at
            <img
              src={"../assets/images/cannabinder-logo.svg"}
              alt={"Cannabinder"}
              style={{ width: "128px" }}
            />
          </div>
        </div>
      </StyledItemLayout>
      <SimilarProducts>
        <h1>Similar Products</h1>
        <HStack>{similarProducts()}</HStack>
      </SimilarProducts>
      <ButtonFooter>
        {/* <button
          className={"cb_data-table__toolbar-button"}
          onClick={props.startOver}
        >
          <span>{"Start Over"}</span>
          <RefreshIcon />
        </button> */}
        <div>
          <button
            className={"cb_data-table__toolbar-button"}
            onClick={deactivateItem}
          >
            Done
          </button>
          {!disableCart && actionButton}
        </div>
      </ButtonFooter>
    </ActiveItemDialog>
  );
  return createPortal(activeItemDialog, document.getElementById("modal-root"));
};
const SimilarProducts = styled.div`
  margin-top: 5px;
  padding-left: 30px;
  & > * {
    justify-content: flex-start !important;
  }
`;
// const Pricing = styled.div`
//   background: ${Color.White};
//   border-radius: 3px;
//   padding: 15px 30px;
// `;
const StyledHeaderImage = styled.div<{
  featureImage: string;
}>`
  min-width:  160px;
  min-height:  160px;
  max-width: 160px;
  background-image: url("${props => props.featureImage}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0 !important;
  transition: all 200ms ease;
`;

const ButtonFooter = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & button {
    margin-right: 0;
    margin-left: 20px;
    background: ${Color.White};
  }
  & svg {
    font-size: 1.25rem;
    margin: 0 5px;
    vertical-align: middle;
  }
`;

const StyledItemLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  p {
    padding-left: 30px;
  }
  & > * {
    margin-left: 30px;
    flex-grow: 1;
    width: 100%;
    &:first-child {
      margin-left: 0;
    }
    &:first-child,
    &:last-child {
      flex-grow: 0;
      width: auto;
    }
  }
`;

const ActiveItemDialog = styled.div`
  position: fixed;
  box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
  background: ${Color.VeryLightGray};
  border-radius: 3px;
  z-index: 300;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  & > * {
    &:first-child {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }
  h1 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  h2 {
    margin-bottom: 20px;
  }
`;
const DialogHeader = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: ${BrandGradient.Main};
  height: 60px;
`;
const ActiveItemHeaderName = styled.div`
  margin: 15px 30px;
  font-size: 36px;
  color: ${Color.PrimaryText};
`;
const ActiveItemHeaderSubName = styled(ActiveItemHeaderName)`
  font-size: 24px;
  color: ${Color.DarkBlueGray};
`;
const StyledImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // TODO add in backgroundImage from data
  & > * {
    margin-bottom: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
const StyledImage = styled.div<{ image: string }>`
  width: 160px;
  height: 160px;
  background: url("${props => props.image}");
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  border-radius: 50%;
  box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
`;

const StyledCannastamp = styled.div`
  width: 160px;
  height: 160px;
  background: ${Color.White};
  border-radius: 50%;
  box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
`;

const BrandName = styled.span`
  font-weight: bolder;
`;
const BrandLine = styled.div`
  padding-left: 30px;
  font-size: 18px;
  margin-bottom: 30px;
`;
export default KioskActiveItem;
