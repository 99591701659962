import * as React from "react";

export class ConciergeService extends React.Component {
  public render():React.ReactChild {
    return (
      <div>
        <h3>COA Concierge</h3>
        <h1>Never enter test data again.</h1>
        <p>Just upload your COA PDFs and our Cannabinder Support Specialists will do all the work for you!</p>
        <div>
          <div>
            <h2>30 Day Free Trial</h2>
            <h1>Totally Free!</h1>
            <p>
              Not ready to commit?
            </p>
            <p>
              Upload your COAs to Cannabinder and our specialists will enter your data for a full month, free of charge!
            </p>
            <ul>
              <li>Upload COAs to Cannabinder</li>
              <li>Up to 25 COAs</li>
            </ul>
            <p>
              Trial accounts will be converted to premium accounts at the end of the trial period. You may cancel before the end of the trial period with no payment necessary.
            </p>
            <button>Start Now</button>
          </div>
          <div>
            <h2>Standard</h2>
            <h1>$30 per month</h1>
            <p>
              Our basic entry-level package!
            </p>
            <ul>
              <li>Upload COAs to Cannabinder</li>
              <li>Up to 25 COAs per month</li>
              <li>Additional COAs billed at $2 per COA upload</li>
            </ul>
            <button>Get Started with Standard</button>
          </div>
          <div>
            <h2>Pro</h2>
            <h1>$50 per month</h1>
            <p>
              Best Value!
            </p>
            <ul>
              <li>Upload COAs to Cannabinder</li>
              <li>Up to 50 COAs per month</li>
              <li>Additional COAs billed at $2 per COA upload</li>
            </ul>
            <select>
              <option>Pro 50</option>
              <option>Pro 100</option>
              <option>Pro 150</option>
              <option>Pro 200</option>
              <option>Pro 250</option>
            </select>
            <button>Get Started with Pro</button>
          </div>
        </div>
        <button>Not right now. I am not ready to make a commitment.</button>
      </div>
    );
  }
}