import React, { Component } from "react";
import PropTypes from "prop-types";
import Select, { Creatable } from "react-select";
import ClientServices from "../../../apiService/ClientService";
import { AuthenticationHOC } from "../../../components/AuthenticationHOC";
import formTools from "../generic/formTools";
import { Redirect } from "react-router-dom";
import AwsPublicUpload from "../generic/AwsPublicUpload";

class BrandForm extends Component {
  constructor(props) {
    super(props);
    const { token, clientId, brandId, brand } = props;
    let formTool = new formTools(token, clientId);
    let disabled = false;
    let message = "Save a new test result";
    if (typeof brandId !== "undefined") {
      disabled = true;
      message = null;
    }
    this.state = {
      brandTypes: null,
      brand: brand, //TODO Do not save props to state
      strainList: false,
      producerList: false,
      newPrimaryPicture: false,
      showcaseImages: false,
      brandCategories: formTool.brandCategories,
      newGalleryPictures: false,
      message,
      disabled,
      unMutableCopy: null
    };
  }

  setPictures(pictureArray, varName) {
    if (!Array.isArray(pictureArray)) pictureArray = [pictureArray];
    this.setState({ [varName]: pictureArray });
  }

  handleChange = (event, strain) => {
    let brand = this.state.brand;
    brand[event.target.name] = event.target.value;
    this.setState({ brand: brand });
  };

  handleReactSelectChange(name, optionObject) {
    let brand = this.state.brand;
    brand[name] = optionObject.value;
    this.setState({ brand: brand }, function() {});
  }

  handleSubmit = event => {
    event.preventDefault();
    let dataToSend = new FormData(event.target);
    ClientServices.saveBrand(
      this.props.token,
      this.props.clientId,
      dataToSend,
      this.props.brand._id
    ).then(
      results => {
        if (typeof this.props.onSave !== "undefined") {
          // todo: I think this only returns the ID of the created brand?
          this.props.onSave(results);
        } else {
          this.setState({
            // testResult: updatedtestResult,
            redirect: true,
            message: "testResult saved",
            disabled: true
          });
        }
      },
      rejected => {
        this.setState({
          message: "there was an error saving the testResult"
        });
      }
    );
  };

  enableEdit = () => {
    let unMutableCopy = Object.assign({}, this.state.brand);
    this.setState({ disabled: false });
  };

  cancelEdit = () => {
    // if(typeof this.props.brand._id === "string")
    // let restoredbrand = Object.assign({}, this.state.unMutableCopy);
    this.setState({ redirect: true });
  };

  render() {
    const { redirect, disabled, message, brand } = this.state;
    const { clientId, token, onSubmit } = this.props;
    let formTool = new formTools(token, clientId);
    return (
      <div className="cb_form">
        {redirect === true && (
          <Redirect push to={"/client/" + clientId + "/managebrands"} />
        )}
        <h4 className="cb_form__title">Brand Information</h4>
        {disabled && (
          <h3>
            <a
              className="cb_form__enable-form-button"
              onClick={this.enableEdit}
            >
              Edit Brand{" "}
            </a>
          </h3>
        )}
        <h2>{message}</h2>
        <form className="cb_form__form" onSubmit={this.handleSubmit}>
          <div className="cb_form__row">
            <div className="cb_form__column">
              <div className="cb_input--group">
                {typeof brand._id !== "undefined" && (
                  <input
                    disabled={disabled}
                    type="hidden"
                    name="_id"
                    value={brand._id}
                  />
                )}
                <input
                  disabled={disabled}
                  type="hidden"
                  name="producer"
                  value={clientId}
                />
                {formTool.inputField(
                  "name",
                  "Brand Name",
                  true,
                  disabled,
                  brand
                )}
              </div>
              <div className="cb_input--group">
                {formTool.inputField(
                  "shortDescription",
                  "Short Description",
                  false,
                  disabled,
                  brand
                )}
              </div>
              <div className="cb_input--group">
                {formTool.textField(
                  "longDescription",
                  "Long Description",
                  false,
                  disabled,
                  brand,
                  3,
                  3
                )}
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Background Image</label>
                <AwsPublicUpload
                  name="backgroundImage"
                  token={token}
                  clientId={clientId}
                  disabled={disabled}
                  uploadLinks={
                    typeof brand.backgroundImage !== "undefined"
                      ? [brand.backgroundImage]
                      : []
                  }
                />
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Feature Image</label>
                <AwsPublicUpload
                  name="featureImage"
                  token={token}
                  clientId={clientId}
                  disabled={disabled}
                  uploadLinks={
                    typeof brand.featureImage !== "undefined"
                      ? [brand.featureImage]
                      : []
                  }
                />
              </div>
              <div className="cb_input--group">
                <label htmlFor="">Image Gallery</label>
                <AwsPublicUpload
                  name="mediaGallery"
                  token={token}
                  clientId={clientId}
                  disabled={disabled}
                  multiple={true}
                  uploadLinks={
                    typeof brand.mediaGallery !== "undefined"
                      ? brand.mediaGallery
                      : []
                  }
                />
              </div>
              <div className="cb_input--group">
                {!this.state.disabled && (
                  <div>
                    <input
                      disabled={this.state.disabled}
                      type="submit"
                      value="submit"
                    />
                    {this.props.cancelRemoved !== true && (
                      <a onClick={this.cancelEdit}>Cancel Edit</a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

BrandForm.propTypes = {
  brandId: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onSubmit: PropTypes.func
};

function stringifyFormData(fd) {
  const data = {};
  for (let key of fd.keys()) {
    data[key] = fd.get(key);
  }
  return JSON.stringify(data, null, 2);
}

export default AuthenticationHOC()(BrandForm);
