import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ThumbsUpDown extends React.Component {
    static defaultProps = {
        thumbsUpValue:1,
        thumbsDownValue:-1,
        onHoverTrue: null,
        onHoverFalse: null,
        disabled: false,
        disabledAction: null,
        wrapperClass:"cb_rating__thumbsUpDown",
        upClass:"cb_rating__thumbsUp",
        downClass:"cb_rating__thumbsDown",
        ghostMode: false,
        colorUp:"rgba(80,227,194,1)",
        maskUp:"rgba(255,255,255,0)",
        colorDown:"rgba(255,51,45,1)",
        maskDown:"rgba(255,255,255,0)",
        backgroundStyle:{
            background: "rgba(255,255,255,0)",
            borderRadius:4,
            padding:5
        },
        styleDown:{
            paddingLeft:5
        },
        styleUp:{
            paddingLeft:5
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            ghostMode: this.props.ghostMode, // forces the component into a display mode that is not interactive
            thumbsUpValue: this.props.thumbsUpValue,
            thumbsDownValue: this.props.thumbsDownValue,
            disabled: this.props.disabled,
            disbledAction: this.props.disabledAction,
            hoverValue: null,
            value: this.props.value,
        };
      }

    mouseEnter(value){
        if(this.props.disabled && !this.props.ghostMode) return;
        if(typeof this.props.onHoverTrue === 'function'){
            this.props.onHoverTrue(value);
        }
        else{
            this.setState({
                hoverValue: value
            });
        }
    }

    mouseExit(){
        if(this.props.disabled && !this.props.ghostMode) return;
        if(typeof this.props.onHoverFalse === 'function'){
            this.props.onHoverFalse();
        }
        else{
            this.setState({hoverValue: null});
        }
    }

    onClick(value){
        if(this.props.disabled) return;
        if(typeof this.props.onChange === 'function' ){
            this.props.onChange(value);
        }
        if(typeof this.props.value === 'undefined'){
            this.setState({value});
        }
    }
    icons(up= false, down= false){
        return(
            <div className={this.props.wrapperClass}>
                <span className={this.props.downClass} style={this.props.styleDown}>
                    <FontAwesomeIcon
						color={this.props.colorDown}
						mask={this.props.maskDow}
						style={this.props.backgroundStyle}
                        icon={[!down? "far": "fas","thumbs-down"]}
                        size="3x"
                        onMouseEnter={this.mouseEnter.bind(this, this.props.thumbsDownValue)}
                        onMouseLeave={this.mouseExit.bind(this)}
                        onClick={this.onClick.bind(this, this.props.thumbsDownValue)}
                    />
                </span>
                <span className={this.props.upClass} style={this.props.styleUp}>
					<FontAwesomeIcon
						style={this.props.backgroundStyle}
						color={this.props.colorUp}
						mask={this.props.maskUp}
						icon={[!up? "far": "fas","thumbs-up"]}
						size="3x"
						onMouseEnter={this.mouseEnter.bind(this, this.props.thumbsUpValue)}
						onMouseLeave={this.mouseExit.bind(this)}
						onClick={this.onClick.bind(this, this.props.thumbsUpValue)}
                    />
                </span>
            </div>
        )
    }
    thumbsIcons(){
        let activeValue;
        let icons;
        if(this.state.hoverValue !== null){
            activeValue = this.state.hoverValue
        }
        else activeValue = this.props.value? this.props.value : this.state.value;
        switch (activeValue){
            case -1:
                icons = this.icons(false, true)
                break;
            case 1:
                icons = this.icons(true, false)
                break;
            default:
                icons = this.icons();
                break;
        }
        return icons;
    }

    render(){
        return(
            <div>
                {this.thumbsIcons()}
          </div>
          )
    }
}

export default ThumbsUpDown;
