import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import KioskRouter from "./KioskRouter";

class KioskRouteSecurity extends React.Component {
  // this component explicility encodes the path so that the next route can
  // check the clients authentication on the entity being viewed.
  public render() {
    return (
      <Router>
        <Route path="/whitelabel/:clientId" component={KioskRouter} />
      </Router>
    );
  }
}

export default KioskRouteSecurity;
