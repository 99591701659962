import * as React from "react";
import styled from "styled-components";
import { BrandColor, Color } from "../kioskUtilities/constants";

interface Props {
  label: string;
  selected?: boolean;
  onClick: (value: string) => void;
  color?: BrandColor;
}

export class FilterItem extends React.Component<Props> {
  private onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };
  public render() {
    const { label, selected, color } = this.props;
    const pickedColor = color ? color : BrandColor.Blue;
    return (
      <StyledFilterItem
        onClick={this.onClick}
        color={pickedColor}
        selected={selected}
      >
        <SelectedMarker selected={selected} color={pickedColor} />
        {label}
      </StyledFilterItem>
    );
  }
}

const StyledFilterItem = styled.div<{ selected: boolean; color: BrandColor }>`
  cursor: pointer;
  user-select: none;
  border-radius: 30px;
  height: 30px;
  border: 2px solid;
  border-color: ${props => (props.selected ? props.color : Color.InputOutline)};
  background: ${Color.InputBackground};
  padding: 5px 10px 5px 5px;
  display: flex;
  flex-direction: row;
  & > *:first-child {
    margin-right: 4px;
    margin-top: -2px;
  }
`;
const SelectedMarker = styled.div<{ selected: boolean; color: BrandColor }>`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 2px solid;
  border-color: ${props => (props.selected ? props.color : Color.InputOutline)};
  background: ${props =>
    props.selected ? props.color : Color.InputBackground};
`;
