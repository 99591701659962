import React, { Component } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { Switch } from "react-router";
import * as Cookies from "es-cookie";
import UserService from "./apiService/UserService";
import LoginModal from "./components/LoginModal";
import ModalAlert from "./components/ModalAlert";
import DispensaryModalUserSignup from "./components/DispensaryModalUserSignup";
import Over21 from "./components/Over21";
import SearchController from "./components/SearchController";
import AdminRouter from "./routers/AdminRouter";
import RoutePublic from "./routers/RoutePublic";
import RouteClientSecurity from "./routers/RouteClientSecurity";
import NavigationMenus from "./components/NavigationMenus";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import KioskRouteSecurity from "./kiosk/KioskRouteSecurity";

library.add(faThumbsUp, faThumbsDown, faTimesCircle, faTimes);
library.add(faThumbsUp, faThumbsDown);

class App extends Component {
  constructor(props) {
    super(props);
    // carrier for the internal modal display
    this.state = {
      modalLoginTrigger: false,
      modalAlertTrigger: false,
      dispensaryMode: false,
      modalMessage: null,
      dispensaryProductSignupId: null,
      showSearch: false,
      appVersion: null
    };
  }
  componentDidMount() {
    fetch("/meta.json")
      .then(response => response.json())
      .then(meta => {
        this.setState({ appVersion: meta.appVersion });
      });
    this.getCookies();
  }

  getCookies() {
    if (
      Cookies.get("user", { path: "/" }) &&
      Cookies.get("token", { path: "/" }) &&
      Cookies.get("user", { path: "/" }) !== "undefined"
    ) {
      // alert("getcookies called");
      this.setState(
        {
          isAuthenticated: true,
          user: JSON.parse(Cookies.get("user", { path: "/" })),
          token: Cookies.get("token", { path: "/" })
        },
        () => {
          this.checkToken();
          return;
        }
      );
    } else {
      this.setState({ isAuthenticated: false, user: "", token: "" });
    }
  }
  // takes login details from the LoginModal component so as to update the base state
  receiveLogin = (incommingUser, incommingToken, pushLocation = "/binder") => {
    this.setState(
      {
        user: incommingUser,
        token: incommingToken
      },
      () => {
        this.props.history.push(pushLocation); // + redirectUrl);
        window.location.reload();
      }
    );
  };

  simpleLoginTrigger = () => {
    this.setState({ modalLoginTrigger: true });
  };
  modalLogin = (dispensaryMode, productId) => {
    this.getCookies();
    this.setState({ modalLoginTrigger: true });
    if (dispensaryMode === true) this.setState({ dispensaryMode: true });
    else this.setState({ dispensaryMode: false });
  };
  // causes the modal login to close.
  closeModalLogin = () => {
    this.setState({ modalLoginTrigger: false });
  };
  // this is going to be rapidly deprecated
  closeModal = () => {
    this.setState({ modalLoginTrigger: false });
    this.setState({ modalAlertTrigger: false });
    this.setState({ modalDispensaryLoginTrigger: false });
    this.setState({ modalText: "" });
  };
  modalAlert = input => {
    this.setState({ modalAlertTrigger: true });
    this.setState({ modalText: input });
  };
  dispensaryLoginTool = productId => {
    this.setState({
      modalDispensaryLoginTrigger: true,
      dispensaryProductSignupId: productId
    });
  };

  //  <a class="cb_nav__search-button-link"><svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" data-prefix="far" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path></svg><!-- <span class="far fa-search"></span> --></a>

  showSearch = event => {
    this.setState({ showSearch: !this.state.showSearch });
  };

  clearSearch = event => {
    if (
      !(
        event.relatedTarget !== null &&
        event.relatedTarget.className === "cb_search-suggestions__link"
      )
    ) {
      this.setState({ showSearch: !this.state.showSearch });
    }
  };

  // Prevent logout() crashing if user deletes account
  shouldComponentUpdate(nextProps, nextState) {
    if (typeof nextState.user === "undefined" || nextState.user === null) {
      return false;
    }
    return true;
  }

  logout = () => {
    Cookies.remove("token", { path: "/" });
    Cookies.remove("user", { path: "/" });
    Cookies.remove("legal", { path: "/" });
    this.setState({ isAuthenticated: false, token: "", user: null });
    this.setState({ dispensaryMode: null });
    this.closeModal();
    this.props.history.push("/goodbye");
    window.location.reload();
    // this.setState({ redirect: true, redirectLocation: "goodbye" }, function() {
    //   this.renderRedirect();
    //   this.forceUpdate();
    // });
  };

  checkToken = () => {
    let d = new Date();
    d.setTime(d.getTime() + 30 * 60 * 60 * 1000);
    UserService.validateSession(Cookies.get("token")).then(response => {
      if (typeof response.message !== "undefined") {
        alert("checktoken logout called");
        this.logout();
        return false;
      } else {
        Cookies.set("token", response, { expires: d, path: "/" });
        this.setState({ token: response });
        return true;
      }
    });
  };
  acceptRedirect = location => {
    this.setState({ redirect: true, redirectLocation: location });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      this.props.history.push("/" + this.state.redirectLocation);
      window.location.reload();
    }
  };

  adminNavigationList() {
    var listArray = [];
    if (this.state.user === null || typeof this.state.user === "undefined")
      return null;

    if (this.state.user.adminUser === true) {
      listArray.push(
        <li className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/users">
            Users
          </a>
        </li>
      );
      listArray.push(
        <li className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/products">
            Products
          </a>
        </li>
      );
      listArray.push(
        <li className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/clients">
            Clients
          </a>
        </li>
      );
      listArray.push(
        <li className="cb_nav__menu-item">
          <a className="cb_nav__menu-item-link" href="/admin/lots">
            Lots
          </a>
        </li>
      );
    }
    return listArray;
  }

  /* cant get the twitter component to work so its coming out
      <TwitterLogin loginUrl="https://localhost:3002/api/v1/auth/twitter"
                                       onFailure={this.onFailure} onSuccess={this.twitterResponse}
                                       requestTokenUrl="https://localhost:3002/api/v1/auth/twitter/reverse"/>
    */

  render() {
    // setup the modal login window
    // setup the login elements so that they can be rendered for appropriate users
    const { appVersion } = this.state;
    return (
      <Router>
        <Switch>
          <Route
            path={"/whitelabel"}
            render={props => {
              return (
                <KioskRouteSecurity
                  logout={this.logout}
                  onModalLogin={this.modalLogin}
                  modalAlert={this.modalAlert}
                  user={this.state.user}
                  isAuthenticated={this.state.isAuthenticated}
                  token={this.state.token}
                />
              );
            }}
          />
          <div>
            <LoginModal
              modalLoginTrigger={this.state.modalLoginTrigger}
              closeModalLogin={this.closeModalLogin}
            />
            <ModalAlert
              modalAlertTrigger={this.state.modalAlertTrigger}
              modalMessage={this.state.modalText}
              closeModal={this.closeModal}
            />
            <DispensaryModalUserSignup
              productId={this.state.dispensaryProductSignupId}
              modalDispensaryLoginTrigger={
                this.state.modalDispensaryLoginTrigger
              }
              closeModal={this.closeModal}
            />
            <Over21 />
            <div className="cb_header">
              <div className="cb_header__left">
                <a href="/">
                  <img
                    className="cb_cannabinder-logo"
                    src="/assets/images/cannabinder-logo_v2.png"
                    alt={"Cannabinder Logo"}
                  />
                </a>
              </div>
              <div className="cb_header__right">
                <div className="cb_nav">
                  <NavigationMenus user={this.state.user} />
                  <div className="cb_nav__right">
                    <a className="cb_nav__bars" href="#">
                      <span className="cb_nav__bar-icon fa fa-bars" />
                    </a>

                    {this.state.isAuthenticated ? (
                      <div className="cb_nav__account-buttons">
                        <a
                          onClick={this.logout}
                          className="cb_nav__signin-button"
                          href="#"
                        >
                          Logout
                        </a>
                        {!(
                          this.state.user.dispensaryUser ||
                          this.state.user.processorUser
                        ) && (
                          <a className="cb_nav__signup-button" href="/binder">
                            Favorites
                          </a>
                        )}
                      </div>
                    ) : (
                      <div className="cb_nav__account-buttons">
                        <a
                          onClick={this.simpleLoginTrigger}
                          className="cb_nav__signin-button"
                        >
                          Sign In
                        </a>
                        <a className="cb_nav__signup-button" href="/signup">
                          Sign Up
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="cb_nav__right" />
                </div>
              </div>
              {this.state.showSearch ? (
                <div className="cb_nav__search-bar">
                  <div id="application_search" onBlur={this.clearSearch}>
                    <SearchController
                      show={this.state.showSearch}
                      isHeader={true}
                    />
                  </div>
                </div>
              ) : null}
              {/* <Router>
                <div>
                  <Route
                    path="/product/*"
                    component={SearchController}
                    user={this.state.user}
                  />
                  <Route
                    path="/search/*"
                    component={SearchController}
                    user={this.state.user}
                  />
                  <Route
                    path="/binder"
                    component={SearchController}
                    user={this.state.user}
                  />
                  <Route
                    path="/investors"
                    component={SearchController}
                    user={this.state.user}
                  />
                  <Route
                    path="/account"
                    component={SearchController}
                    user={this.state.user}
                  />
                </div>
              </Router> */}
            </div>
            <div className="cb_stage">
              <div className="cb_template">
                <Router>
                  <div>
                    <Switch>
                      <Route
                        exact
                        path="/client/*"
                        render={props => <RouteClientSecurity />}
                      />
                      <Route
                        path="/admin"
                        render={props => (
                          <AdminRouter
                            onModalLogin={this.modalLogin}
                            modalAlert={this.modalAlert}
                            isAuthenticated={this.state.isAuthenticated}
                          />
                        )}
                      />
                      <Route
                        path="/*"
                        render={props => (
                          <RoutePublic
                            logout={this.logout}
                            modalLogin={this.simpleLoginTrigger}
                            modalAlert={this.modalAlert}
                            // sendLogin={this.receiveLogin}
                            receiveLogin={this.receiveLogin}
                            isAuthenticated={this.state.isAuthenticated}
                          />
                        )}
                      />
                    </Switch>
                  </div>
                </Router>

                <div className="cb_template__section cb_footer">
                  <div className="cb_surface">
                    <div className="cb_surface__section">
                      <img
                        className="cb_cannabinder-logo--footer"
                        src="/assets/images/cannabinder-logo_v2.png"
                        alt="Cannabinder Logo"
                        title={`AppVersion - ${appVersion}`}
                      />
                      <div className="cb_grid-item--12">
                        <ul className="cb_footer-nav">
                          <li className="cb_footer-nav-item">
                            <a
                              className="cb_footer-nav-item-link"
                              href="/about"
                            >
                              about
                            </a>
                          </li>
                          <li className="cb_footer-nav-item">
                            <a
                              className="cb_footer-nav-item-link"
                              href="/brands"
                            >
                              producers & processors
                            </a>
                          </li>
                          <li className="cb_footer-nav-item">
                            <a
                              className="cb_footer-nav-item-link"
                              href="/invest"
                            >
                              investors
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="cb_grid-item--12">
                        <ul className="cb_footer-nav">
                          <li className="cb_footer-nav-item">
                            <a
                              className="cb_footer-nav-item-link"
                              href="/contact"
                            >
                              get in touch
                            </a>
                          </li>
                          <li className="cb_footer-nav-item">
                            <a
                              className="cb_footer-nav-item-link"
                              href="/privacy"
                            >
                              privacy policy
                            </a>
                          </li>
                          <li className="cb_footer-nav-item">
                            <a
                              className="cb_footer-nav-item-link"
                              href="/termsofservice"
                            >
                              terms of use
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="cb_grid-item--12">
                        <p className="cb_copyright">
                          Copyright ® 2018-2019 Cannabinder
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Switch>
      </Router>
    );
  }
}

export default withRouter(App);

/*
*                This is an impresively ugly way to do this.
                todo: make this not suck
<Router>
  <Route
    path="/product/*"
    component={props => (
      <div
        className="cb_nav__search-button"
        onClick={this.showSearch}
      >
        <a className="cb_nav__search-button-link">
          <span className="far fa-search" />
        </a>
      </div>
    )}
  />
  <Route
    path="/search/*"
    component={props => (
      <div
        className="cb_nav__search-button"
        onClick={this.showSearch}
      >
        <a className="cb_nav__search-button-link">
          <span className="far fa-search" />
        </a>
      </div>
    )}
  />
  <Route
    path="/binder"
    component={props => (
      <div
        className="cb_nav__search-button"
        onClick={this.showSearch}
      >
        <a className="cb_nav__search-button-link">
          <span className="far fa-search" />
        </a>
      </div>
    )}
  />
  <Route
    path="/investors"
    component={props => (
      <div
        className="cb_nav__search-button"
        onClick={this.showSearch}
      >
        <a className="cb_nav__search-button-link">
          <span className="far fa-search" />
        </a>
      </div>
    )}
  />
  <Route
    path="/account"
    component={props => (
      <div
        className="cb_nav__search-button"
        onClick={this.showSearch}
      >
        <a className="cb_nav__search-button-link">
          <span className="far fa-search" />
        </a>
      </div>
    )}
  />
</Router>
</div>
<div className="cb_nav__right" />
</div>
</div>

<Router>
<div>
<Route
path="/product/*"
component={props => (
  <SearchController
    {...props}
    hidden={this.state.showSearch}
    toggleSearch={this.showSearch}
  />
)}
/>
<Route
  path="/search/*"
  component={props => (
    <SearchController {...props} hidden={this.state.showSearch} />
  )}
/>
<Route
path="/binder"
component={props => (
  <SearchController {...props} hidden={this.state.showSearch} />
)}
/>
<Route
  path="/investors"
  component={props => (
    <SearchController {...props} hidden={this.state.showSearch} />
  )}
/>
<Route
path="/account"
component={props => (
  <SearchController {...props} hidden={this.state.showSearch} />
)}
/>
</div>
</Router>
</div>
<div className="cb_stage">
  <div className="cb_template">
<Router>
<div>
<Switch>
=======

*
* */
