import React, { Component } from "react";
import VariabilityCTA from "../components/front-page-tests/VariabilityCTA";

class AboutPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <VariabilityCTA />
      </div>
    );
  }
}

export default AboutPage;