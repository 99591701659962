import * as React from "react";
// import { Color, toPixel, BorderRadius } from "../../kioskUtilities/constants";
// import styled from "styled-components";
import Number from "google-libphonenumber";
import PhoneNumberMuiWrapper from "../PhoneNumberMuiWrapper";
import styled from "styled-components";
import { EntityInterface } from "cbd-api-types";
import { Typography, Button, withStyles, Theme } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";

export interface NumberEntryProps extends WithStyles<typeof styles> {
  inLoyalty: (phoneNumber: number, phoneNumberRaw: string) => void;
  clientData: EntityInterface | undefined;
  title: string;
  subTitle: string;
  logoUrl: string;
}

export interface NumberEntryState {
  phone: string;
  validPhone?: string;
  natNumber?: number;
}

class NumberEntry extends React.Component<NumberEntryProps, NumberEntryState> {
  constructor(props: NumberEntryProps) {
    super(props);

    this.state = { phone: null };
  }

  public updatePhone = (inputValue: string): void => {
    const parser = Number.PhoneNumberUtil.getInstance();
    try {
      const rawNumber = parser.parseAndKeepRawInput(inputValue, "US");
      const natNumber = rawNumber.getNationalNumber();
      if (natNumber.toString().length === 10) {
        this.setState({ validPhone: inputValue, natNumber });
      }
    } catch (error) {
      console.warn(error);
      // now do nothing with this... cause it shouldnt error on first input
    }
    // tslint:disable-next-line: semicolon
  };

  public submitPhone = () => {
    this.props.inLoyalty(this.state.natNumber, this.state.validPhone);
  };

  public render() {
    const { clientData, title, subTitle, classes } = this.props;
    return (
      <CenteredFullWidthHStack>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1">{subTitle}</Typography>
        <CenteredNumber>
          {clientData && clientData.featureImage && (
            <img
              src={clientData.featureImage}
              style={{
                width: "auto",
                maxHeight: "22rem",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              alt={`${clientData.name} logo`}
            />
          )}
          <PhoneNumberMuiWrapper
            value={this.state.validPhone}
            onChange={this.updatePhone}
            maxWidth={200}
            height={30}
            fontSize={18}
            borderColor={"BlueGray"}
            alignment={"center"}
            placeHolder={"Phone Number"}
            borderThickness={1}
          />

          <Button
            onClick={this.submitPhone}
            disabled={!this.state.validPhone}
            className={classes.centeredBlock}
            variant="outlined"
          >
            Sign Up!
          </Button>
        </CenteredNumber>
      </CenteredFullWidthHStack>
    );
  }
}

// tslint:disable-next-line: one-variable-per-declaration
const styles = (theme: Theme) => ({
  centeredBlock: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "3px",
    marginTop: "5px",
    display: "block",
    maxWidth: "300px"
  }
});

export default withStyles(styles)(NumberEntry);
export const CenteredFullWidthHStack = styled.span`
  text-align: center;
  width: 100%;
`;

export const Biggertitle = styled.h1`
  font-size: 20px;
`;

export const UserPrompt = styled.span`
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
`;

const CenteredNumber = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
