import * as React from "react";
import {
  ProducerProduct,
  Brand,
  TestResult
} from "../../../apiService/serviceInterfaces";

interface Props {
  clientId: string;
  brandData?: Brand;
  productData?: ProducerProduct;
  testData?: TestResult;
}

export default class GettingStarted extends React.Component<Props> {
  public render(): React.ReactChild {
    return <div />;
  }
}
