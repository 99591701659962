const config = require("./config.json");

export function oAuthMode(urlString) {
  let setConfig;
  if (urlString.search("localhost") !== -1) {
    setConfig = config.local;
  } else if (urlString.search("staging") !== -1) {
    setConfig = config.staging;
  } else if (urlString.search("dev") !== -1) {
    setConfig = config.dev;
  } else {
    setConfig = config.production;
  }
  return setConfig;
}
