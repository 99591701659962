import * as React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { AuthenticationHOC } from "../../../components/AuthenticationHOC";
import matchSorter from "match-sorter";

class BrandList extends React.Component {
  static defaultProps = {
    brandList: null,
    changeOwner: false,
    clientList: undefined,
    reloadParent: undefined
  };
  constructor(props) {
    super(props);
    // props validation - when used in an admin context, it must be provided its client list and parental reload
    if (
      props.changeOwner === true &&
      (props.clientList === undefined || props.reloadParent === undefined)
    ) {
      throw new Error(
        "BrandList implimented with changeOwner on and clientList and reloadParent undefined"
      );
    }
  }

  tableView() {
    const adminColumns = this.props.changeOwner
      ? {
          Header: "Producer",
          id: "producerName",
          accessor: d => d.producer.name,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ["producerName"]
            }),
          filterAll: true
        }
      : null;
    const standardColumns = [
      {
        Header: "Brand Name",
        id: "name",
        accessor: d => d.name,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["name"]
          }),
        filterAll: true
      },
      {
        Header: "Link",
        id: "link",
        Cell: ({ row }) => (
          <div>
            <a
              href={
                "/client/" +
                row._original.producer._id +
                "/manageBrand/" +
                row._original._id
              }
            >
              View Brand
            </a>
          </div>
        )
      }
    ];
    if (adminColumns !== null) {
      standardColumns.unshift(adminColumns);
    }

    if (this.props.brandList === null) {
      return <div>Empty Lot List</div>;
    } else if (this.props.changeOwner) {
      return (
        <div style={{ paddingTop: 80 }}>
          <ReactTable
            data={this.props.brandList}
            filterable
            columns={standardColumns}
            // SubComponent={row => {
            //   if (this.props.changeOwner) {
            //     return (
            //       <div style={{ padding: "20px" }}>
            //         <em>
            //           Will include a subtable for product lots
            //           <ProductOwnerSelect
            //             initiallyDisabled={true}
            //             availableClients={this.props.clientList}
            //             token={this.props.token}
            //             product={row.original}
            //             reloadParent={this.props.reloadParent} // should be a function
            //           />
            //         </em>
            //         <br />
            //         <br />
            //       </div>
            //     );
            //   }
            // }}
            defaultPageSize={10}
            className="-striped -highlight"
          />
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <ReactTable
            data={this.props.brandList}
            filterable
            columns={standardColumns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
          <br />
        </div>
      );
    }
  }

  render() {
    return this.tableView();
  }
}

export default AuthenticationHOC()(BrandList);
