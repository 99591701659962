import * as React from "react";
import { CategoryItem } from "../kioskInterfaces";
import {
  Color,
  GLOBAL_IDLE_TIMEOUT,
  GLOBAL_DEBOUNCE,
  ProductCategoryNames,
  validKioskPin
} from "../kioskUtilities/constants";
import { ClientService } from "../../apiService/ClientService";
import EscapeRegex from "../kioskUtilities/escapeRegex";
import IdleTimer from "react-idle-timer";
import KioskActiveItem from "./KioskActiveItem";
import { ProductCard } from "../components/ProductCard";
import {
  ProductInterface,
  UserDocumentInterface,
  EntityDocumentInterface,
  EntityInterface
} from "cbd-api-types";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Spinner } from "../components/Spinner";
import styled from "styled-components";
import { WhiteLabelHeader } from "../components/WhiteLabelHeader";
import { ActionCard } from "../components/ActionCard";
import KioskModal from "../components/KioskModal";
import KioskCheckin from "./KioskCheckin";
import PremiumKioskCTA from "../components/PremiumKioskCTA";
import PublicService from "../../apiService/PublicService";
import DialogModal, {
  DialogProps
} from "../../components/generics/DialogModal";
import { get as getCookie, remove as removeCookie } from "es-cookie";

interface Props {
  clientData: EntityInterface;
  match: { params: { clientId: string } };
  token: string;
  disableHeader?: boolean;
  disableCart?: boolean;
}

interface State {
  activeItem: ProductInterface;
  filters: CategoryItem[];
  freshState: boolean;
  inventoryLotList: any;
  products?: ProductInterface[] | null;
  productSearch?: ProductInterface[] | null;
  searchString?: string;
  selectedItems: Set<ProductInterface>;
  // viewLabel: string | null;
  visibleLimit: number;
  loyaltyUser: UserDocumentInterface | null;
  checkinActive: boolean;
  promptForPIN: boolean;
  dialog?: DialogProps;
  logoutAttempt: {
    attempt: number;
    lastTry: number;
  };
  PINPrompt?: string;
}

const availableFilters = Object.values(ProductCategoryNames).map(
  (availFilter: string) => {
    return { selected: false, label: availFilter };
  }
);

const defaultState: State = {
  activeItem: undefined,
  filters: availableFilters,
  freshState: true,
  inventoryLotList: [],
  productSearch: undefined,
  searchString: "",
  selectedItems: new Set([]),
  // viewLabel: "Show new items",
  visibleLimit: 9,
  loyaltyUser: null,
  checkinActive: false,
  promptForPIN: false,
  logoutAttempt: {
    attempt: 1,
    lastTry: 0
  }
};

// TODO fix these props so they work correctly
// export type KioskViewProps = Props & Authentication & ClientApi & Entity;
// export type KioskViewProps = Props;

class KioskView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = defaultState;
    this.idleTimer = null;
    this.findAndUpdateProducts = this.findAndUpdateProducts.bind(this);
  }

  public idleTimer: IdleTimer;

  private onIdle = () => {
    if (!this.state.freshState) {
      this.findAndUpdateProducts();
    }
  };

  private findAndUpdateProducts(): void {
    const productsToKeep: ProductInterface[] = [];
    const productsToUpdate: string[] = [];
    if (typeof this.state.products !== "undefined") {
      (ClientService as any)
        .loadKioskKeyPair(this.props.token, this.props.match.params.clientId)
        .then((productKeyPairs: any): void => {
          this.state.products.map(product => {
            if (productKeyPairs[product._id] !== product.updatedAt) {
              productsToUpdate.push(product._id);
              delete productKeyPairs[product._id];
            } else if (productKeyPairs[product._id] === product.updatedAt) {
              productsToKeep.push(product);
              delete productKeyPairs[product._id];
            }
          });
          Object.keys(productKeyPairs).map((kp: string) => {
            productsToUpdate.push(kp);
          });
          if (productsToUpdate.length > 5) {
            this.getProducts();
          } else if (productsToUpdate.length) {
            (ClientService as any)
              .getMultipleById(
                productsToUpdate,
                this.props.token,
                this.props.match.params.clientId
              )
              .then((updatedProducts: ProductInterface[]): void => {
                productsToKeep.push(...updatedProducts);
                this.startOver({ products: productsToKeep });
              });
          } else {
            this.startOver();
          }
        });
    } else {
      this.getProducts();
    }
  }

  public componentDidMount(): void {
    this.findAndUpdateProducts();
  }

  private onFilterClick = (value: string): void => {
    this.setState(state => ({
      ...state,
      freshState: false,
      visibleLimit: defaultState.visibleLimit,
      filters: state.filters.map(filter => {
        if (filter.label === value) {
          filter.selected = !filter.selected;
        }
        return filter;
      })
    }));
  };

  private onProductClick = (focusProduct: ProductInterface): void => {
    // TODO if not found, we need to do a search on inventory for this.
    this.setState({ freshState: false, activeItem: null }, () => {
      this.setState({ activeItem: focusProduct });
    });
  };

  private getProducts(): void {
    this.startOver();
    const {
      clientData: { premiumLevel },
      match: {
        params: { clientId }
      },
      token
    } = this.props;

    (ClientService as any)
      .getDispensaryInventory(token, clientId)
      .then((results: any) => {
        this.setState({
          visibleLimit: defaultState.visibleLimit,
          products: premiumLevel === "pro" ? results : results.slice(0, 6)
        });
      })
      .catch((err: PromiseRejectionEvent) => {
        console.warn("Error found", err);
      });
  }

  // private getSimilarProducts(productId: string): void {
  //   // "5af210d7174262790c91e63e"
  //   (ProductService as any).getSimilarProducts("5af210d7174262790c91e63e")
  //     .then((json:any) => {
  //         console.log("PRODUCTS", json);
  //         this.setState(state => ({
  //           ...state,
  //           products: json,
  //         }));
  //       },
  //       (error:PromiseRejectionEvent) => {
  //         console.log("Error found", error);
  //       });
  // }

  private publicSearch = () => {
    PublicService.strainSearch({
      resultCount: 10,
      searchString: this.state.searchString
    })
      .then(
        (search: {
          available: ProductInterface[];
          unavailable: ProductInterface[];
        }) => {
          search.available = search.available.filter(product => {
            return !this.productIsLocalInventory(product);
          });
          search.unavailable = search.unavailable.filter(product => {
            return !this.productIsLocalInventory(product);
          });
          this.setState({
            productSearch: [...search.available, ...search.unavailable],
            visibleLimit: defaultState.visibleLimit,
            searchString: this.state.searchString,
            freshState: false
          });
        }
      )
      .catch(_ => {
        this.setState({
          productSearch: undefined,
          visibleLimit: defaultState.visibleLimit,
          searchString: this.state.searchString,
          freshState: false
        });
      });
  };

  private handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newSearch: string = e.target.value;
    // debounce(this.getProducts, GLOBAL_DEBOUNCE); // TODO I probably want to add this back in
    // BUG: debounce not working?
    this.setState({
      visibleLimit: defaultState.visibleLimit,
      searchString: newSearch,
      freshState: false
    });
    if (newSearch.length > 2) {
      this.publicSearch();
    } else {
      this.setState({
        productSearch: undefined
      });
    }
  };

  private handleSearchClear = (): void => {
    this.setState({
      searchString: ""
    });
  };

  private handleShowMore = (): void => {
    this.setState(state => ({
      visibleLimit: state.visibleLimit + defaultState.visibleLimit,
      freshState: false
    }));
  };

  private startOver(alterDefault = {}): void {
    this.setState(state => {
      state.selectedItems.clear();
      const newSet = new Set(state.selectedItems);
      return {
        ...defaultState,
        ...alterDefault,
        selectedItems: newSet
      };
    });
  }

  private productIsLocalInventory(theProduct: ProductInterface): boolean {
    const { clientData } = this.props;
    let isLocalInventory = false;
    if (
      theProduct.producer &&
      theProduct.producer._id &&
      theProduct.producer._id === clientData._id
    ) {
      isLocalInventory = true;
    } else if (
      theProduct.distribution.some(aDistribuion => {
        return aDistribuion.dispensary === clientData._id;
      })
    ) {
      isLocalInventory = true;
    }
    return isLocalInventory;
  }

  private drawProductResults = (products: ProductInterface[]): JSX.Element => {
    const { disableHeader, clientData } = this.props;
    const { visibleLimit } = this.state;
    const display =
      products.length > 0 ? (
        <>
          <Products headerDisabled={disableHeader}>
            {clientData.loyaltyEnabled === true &&
              this.loyaltyCheckinCard(clientData)}
            {(products &&
              products.map((product, index) => {
                return (
                  <ProductCard
                    product={product}
                    key={`prod_${index}`}
                    onClick={this.onProductClick}
                    notInStore={
                      // todo: bad data causing error on staging. remove this with cleanup
                      this.productIsLocalInventory(product) === false
                    }
                  />
                );
              })) || <Spinner />}
            {visibleLimit <= products.length && (
              <ShowMoreSpan>
                <ShowMore
                  className="cb_data-table__toolbar-button"
                  onClick={this.handleShowMore}
                >
                  Show More
                </ShowMore>
              </ShowMoreSpan>
            )}
            {clientData.premiumLevel === "free" && (
              <StyledPremiumKioskCTA clientData={this.props.clientData} />
            )}
          </Products>
        </>
      ) : (
        <div>
          <Products headerDisabled={disableHeader}>
            {clientData.loyaltyEnabled === true &&
              this.loyaltyCheckinCard(clientData)}
          </Products>
          <HStack>No Products Found</HStack>
        </div>
      );
    return display;
  };

  private drawLoadingOrFailure(
    products: ProductInterface[] | null
  ): JSX.Element {
    if (products === undefined) {
      return (
        <CenteredHStack>
          <CenteredVStack>
            <div>Loading Products...</div>
            <Spinner />
          </CenteredVStack>
        </CenteredHStack>
      );
    } else {
      return (
        <StyledOops>
          <h2>
            {
              "We're sorry, but we're having trouble with the wiring. Relax, and try again in a minute."
            }
          </h2>
        </StyledOops>
      );
    }
  }

  private loyaltyCheckinCard(
    clientData: EntityDocumentInterface
  ): JSX.Element | null {
    const { loyaltyUser } = this.state;
    if (clientData.loyaltyEnabled === true && loyaltyUser === null) {
      return (
        <ActionCard
          key="loyaltySignup_0"
          actionColor={Color.BlueGray}
          // actionHoverColor={Color.LightGray}
          onClick={this.initiateCheckin}
          actionTitle="Loyalty Checkin"
          actionBody="Stay in touch with education, deals and drops!"
          actionImage="../assets/images/masthead--cannastamp-solid.png"
        />
      );
    } else {
      return null;
    }
  }

  private addToCart = (item: ProductInterface): void => {
    this.setState(state => ({
      selectedItems: state.selectedItems.add(item),
      freshState: false
    }));
  };

  private removeFromCart = (item: ProductInterface): void => {
    this.setState(state => {
      state.selectedItems.delete(item);
      const newSet = new Set(state.selectedItems);
      return {
        selectedItems: newSet
      };
    });
  };

  private deactivateItem = (): void => {
    this.setState({
      activeItem: undefined
    });
  };

  private grabVisibleProducts = (): ProductInterface[] => {
    const {
      filters,
      products,
      searchString,
      visibleLimit,
      productSearch
    } = this.state;

    let visibleProducts: ProductInterface[] = [];
    if (typeof products !== "undefined") {
      let selectedFilters: string[] = [];
      filters.map((filter: CategoryItem): void => {
        if (filter.selected === true) {
          selectedFilters.push(filter.label);
        }
      });
      const matchReplace = /\W|_|\s|\//g;
      const matcher = new RegExp(
        EscapeRegex(searchString.replace(matchReplace, "")),
        "i"
      );
      let allProducts = products;
      // productSearch && allProducts.push(...productSearch);

      allProducts.map((product: ProductInterface): void => {
        const { name, strain, brand } = product;
        const brandName: string =
          typeof brand === "object" ? brand.name : brand;
        if (
          visibleProducts.length < visibleLimit &&
          ((selectedFilters.length === 0 && searchString.length < 3) ||
            selectedFilters.includes(product.productCategory.toString()) ||
            (searchString.length > 0 &&
              (matcher.test(name.replace(matchReplace, "")) ||
                matcher.test(strain.replace(matchReplace, "")) ||
                matcher.test(brandName.replace(matchReplace, "")))))
        ) {
          visibleProducts.push(product);
        }
      });
      productSearch &&
        productSearch.map((product: ProductInterface): void => {
          const { name, strain, brand } = product;
          const brandName: string =
            typeof brand === "object" ? brand.name : brand;
          if (
            visibleProducts.length < visibleLimit &&
            ((selectedFilters.length === 0 && searchString.length < 3) ||
              selectedFilters.includes(product.productCategory.toString()) ||
              (searchString.length > 0 &&
                (matcher.test(name.replace(matchReplace, "")) ||
                  matcher.test(strain.replace(matchReplace, "")) ||
                  matcher.test(brandName.replace(matchReplace, "")))))
          ) {
            visibleProducts.push(product);
          }
        });
    }

    return visibleProducts;
  };

  private initiateCheckin = (): void => {
    this.setState({ checkinActive: true });
  };

  private closeCheckin = (): void => {
    this.setState({ checkinActive: false });
  };

  private setCheckinUser = (loyaltyUser: UserDocumentInterface): void => {
    this.setState({ loyaltyUser });
    this.closeCheckin();
  };

  private checkinProcess(): JSX.Element {
    const { token, clientData } = this.props;
    return (
      <KioskModal
        deactivateItem={this.closeCheckin}
        mainContent={
          <KioskCheckin
            token={token}
            clientId={clientData._id}
            clientData={clientData}
            setCheckinUser={this.setCheckinUser}
            close={this.closeCheckin}
          />
        }
      />
    );
  }

  private clearCheckinUser = (): void => {
    this.setState({ loyaltyUser: null });
  };

  private closeLogout = () => {
    this.setState({ promptForPIN: false });
  };

  private logoutAttempt = () => {
    const {
      clientData: { premiumLevel }
    } = this.props;
    if (premiumLevel === "pro" && getCookie("kioskUser")) {
      let { logoutAttempt } = this.state;
      if (
        logoutAttempt.attempt > 4 &&
        logoutAttempt.lastTry - Date.now() < GLOBAL_DEBOUNCE
      ) {
        this.setState({
          logoutAttempt: {
            attempt: 1,
            lastTry: Date.now()
          },
          promptForPIN: true,
          dialog: {
            title: "Enter your PIN to log out of the Kiosk",
            content: undefined,
            input: {
              label: "Enter PIN",
              value: this.state.PINPrompt,
              onChange: this.pinChange,
              inputProps: {
                maxLength: "4",
                pattern: "[0-9]{4}"
              }
            },
            secondary: {
              text: "Logout",
              action: this.logoutKiosk
            },
            primary: {
              text: "Cancel",
              action: this.closeLogout
            }
          }
        });
      } else if (Date.now() - logoutAttempt.lastTry < GLOBAL_DEBOUNCE) {
        this.setState({
          logoutAttempt: {
            attempt: logoutAttempt.attempt + 1,
            lastTry: Date.now()
          }
        });
      } else {
        this.setState({
          logoutAttempt: {
            attempt: 1,
            lastTry: Date.now()
          }
        });
      }
    }
  };

  private pinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ PINPrompt: validKioskPin(event.target.value) });
  };

  private logoutKiosk = () => {
    if (this.state.PINPrompt === this.props.clientData.kioskConfig.pin) {
      removeCookie("kioskUser");
      window.location.href = `/client/${this.props.match.params.clientId}/manageProducts`;
    } else {
      const { dialog } = this.state;
      this.setState({
        logoutAttempt: {
          attempt: this.state.logoutAttempt.attempt + 1,
          lastTry: Date.now()
        },
        dialog: Object.assign({}, dialog, {
          content: "Incorrect PIN. Try again."
        })
      });
    }
  };

  public render(): JSX.Element {
    // viewLabel,

    const {
      activeItem,
      searchString,
      filters,
      selectedItems,
      checkinActive,
      promptForPIN,
      dialog
    } = this.state;
    const {
      clientData,
      disableCart,
      disableHeader,
      match: {
        params: { clientId }
      },
      token
    } = this.props;
    let visibleProducts: ProductInterface[] = this.grabVisibleProducts();
    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          onIdle={this.onIdle}
          timeout={GLOBAL_IDLE_TIMEOUT}
        />
        {checkinActive && this.checkinProcess()}
        {activeItem && (
          <>
            <KioskActiveItem
              item={activeItem}
              addToCart={this.addToCart}
              removeFromCart={this.removeFromCart}
              deactivateItem={this.deactivateItem}
              cart={selectedItems}
              token={token}
              clientId={clientId}
              similarOnClick={this.onProductClick}
              startOver={this.findAndUpdateProducts}
              disableCart={disableCart}
              featureImage={clientData.featureImage}
            />
            <StyledOverlay onClick={this.deactivateItem} />
          </>
        )}
        <StyledVStack>
          <WhiteLabelHeader
            token={token}
            clientData={clientData}
            cart={selectedItems}
            searchChangeHandler={this.handleSearchChange}
            searchClearHandler={this.handleSearchClear}
            searchString={searchString}
            filterOnClick={this.onFilterClick}
            filters={filters}
            disableCart={disableCart}
            disableHeader={disableHeader}
          />
          {/* <h1>{viewLabel}</h1> */}

          {visibleProducts
            ? this.drawProductResults(visibleProducts)
            : this.drawLoadingOrFailure(visibleProducts)}
        </StyledVStack>
        <CannabinderFooter>
          <StartOver
            className="cb_data-table__toolbar-button"
            onClick={() => {
              this.findAndUpdateProducts();
              this.clearCheckinUser();
            }}
          >
            <span>{"Start Over"}</span>
            <RefreshIcon />
          </StartOver>

          <CannabinderLogo
            src={"/assets/images/powered-by-cannabinder.png"}
            onClick={this.logoutAttempt}
          />
        </CannabinderFooter>
        <DialogModal open={promptForPIN} {...dialog} />
      </>
    );
  }
}

export default KioskView;

export const StyledOops = styled.div`
  background-size: cover;
  background: url("/assets/images/blur-bud-cannabis-1466335.jpg")
    ${Color.BlueGray} center;
  color: ${Color.White};
  padding-top: 190px;
  height: 300px;
  text-align: center;
`;

export const HStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > * {
    margin-right: 10px;
  }
`;

export const CenteredHStack = styled(HStack)`
  align-items: center;
  justify-content: center;
`;

export const Products = styled(HStack)<{ headerDisabled: boolean }>`
  flex-wrap: wrap;
  max-width: 680px;
  justify-content: center;
  margin: auto;
  padding: 10px;
  margin-top: ${props => (!props.headerDisabled ? 230 : 80)}px;
  & > * {
    margin: 10px;
  }
`;

export const StyledPremiumKioskCTA = styled(PremiumKioskCTA)`
  flex-grow: 3;
`;

export const VStack = styled.div`
  margin-left: auto;
  margin-right: auto;
  & > * {
    margin-bottom: 25px;
  }
`;

export const ShowMoreSpan = styled.span`
  width: 100%;
  text-align: center;
`;

export const ShowMore = styled.button`
  margin-left: auto;
  margin-right: auto;
  color: black;
  pointer-events: all;
  background-color ${Color.White};
`;

export const StartOver = styled.button`
  color: black;
  pointer-events: all;
  background-color ${Color.White};
  & svg {
    font-size: 1.25rem;
    margin: 0 5px;
    vertical-align: middle;
  }

  @media (max-width: 500px) {
    & span {
      display: none;
    }
  }
`;

export const CenteredVStack = styled(VStack)`
  align-items: center;
  justify-content: center;
  & > * {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledVStack = styled(VStack)`
  //max-width: 680px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
`;

const CannabinderFooter = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  padding: 20px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  & > * {
    flex-basis: 139px;
  }
`;

const CannabinderLogo = styled.img`
  pointer-events: auto;
  height: 38px;
  width: 139px;
`;
