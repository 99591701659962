import "react-app-polyfill/ie9";
import "mdn-polyfills/Array.prototype.includes";
import "mdn-polyfills/String.prototype.includes";
import "mdn-polyfills/String.prototype.trim";
import "mdn-polyfills/Array.prototype.some";
import "mdn-polyfills/Array.prototype.forEach";
import "mdn-polyfills/Array.prototype.find";
import "mdn-polyfills/Array.prototype.filter";
import "matchmedia-polyfill";
import "matchmedia-polyfill/matchMedia.addListener";
import * as React from "react";
import * as ReactDOM from "react-dom";

// import "./index.css";
// import App from "./App";
// shutting down registered service worker
import registerServiceWorker from "./registerServiceWorker";
// import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
// router for routing requests
import { BrowserRouter as Router, Route } from "react-router-dom";
// components for routes

// import ProductDisplay from './views/ProductDisplay';

import App from "./App.js";
// <Route path='/:productId' component = {ProductController} />

ReactDOM.render(
  <Router>
    <Route path="/" component={App} />
  </Router>,
  document.getElementById("application")
);
// unregisterServiceWorker();
registerServiceWorker();
