import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const styles = theme => ({
  card: {
    maxWidth: 400,
    marginTop: 10
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "block",
    fontSize: 24,
    textAlign: "right"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "green"
  }
});

class WhatsAProduct extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              P
            </Avatar>
          }
          titleTypographyProps={{ variant: "h5" }}
          subheaderTypographyProps={{ variant: "h8" }}
          title="Products"
          subheader="Whats a Product?"
        />
        <CardMedia
          className={classes.media}
          image="/assets/images/client/welcome/sublima-comfort-cream.jpg"
          title="Paella dish"
        />
        <CardContent>
          <Typography component="p">
            A product is part of a Brand. For example, Seattle Pure Extracts
            makes a line of infused topicals under the{" "}
            <i>
              brand name <b>Sublima </b>{" "}
            </i>
            that is known as the{" "}
            <b>
              product line <i>Comfort Cream.</i>{" "}
            </b>
            One of the product names in this line is called{" "}
            <b>
              <i>Lemon Cello</i>{" "}
            </b>
          </Typography>
          <Typography component="p">
            <i>Your product name could also be the plants strain name.</i>
          </Typography>
        </CardContent>
        <CardActions className={classes.actions} disableActionSpacing>
          More Examples
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: this.state.expanded
            })}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Learn more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              <b>You Don't Use a Product Line?</b>
            </Typography>
            <Typography paragraph>
              You sell flower in a package with a brand name and a strain. The
              brand name is your company name too. Your Brand is your company
              name. The best thing to call your product line "Whole Flower" and
              your product name would be the strain name.
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

WhatsAProduct.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WhatsAProduct);
