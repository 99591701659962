import React, { Component } from "react";
import PropTypes from "prop-types";
import formTools from "../generic/formTools";

export default class AwsPublicUpload extends Component {
  static propTypes = {
    class: PropTypes.string,
    token: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    uploadLinks: PropTypes.arrayOf(PropTypes.string),
    displayMode: PropTypes.bool
  };
  static defaultProps = {
    class: "awsUpload",
    name: "fileUpload",
    multiple: false,
    disabled: false,
    // multiple: false,
    uploadLinks: [],
    displayMode: false
  };

  constructor(props) {
    super(props);
    if (
      typeof props.token === "undefined" ||
      props.token === null ||
      props.token === "" ||
      typeof props.clientId === "undefined" ||
      props.clientId === null ||
      props.clientId === ""
    ) {
      throw new Error(
        "a token and clientId must be supplied to this component"
      );
    } else {
      let disabled = false;
      let message = "Save a new test result";
      if (typeof props.testResultId !== "undefined") {
        disabled = true;
        message = null;
      }
      let formTool = new formTools(props.token, props.clientId);

      this.state = {
        uploadLinks: props.uploadLinks
      };
    }
  }

  onFileChange() {
    this.setState({
      message: (
        <div style={{ width: 50 }}>
          <img src="/assets/images/loading.gif" />
          files are uploading
        </div>
      ),
      uploadDisabled: true
    });
    let formTool = new formTools(this.props.token, this.props.clientId);
    formTool.uploadFilesToS3(this.props.name, results => {
      if (results.error) {
        this.setState({ message: results.error });
      } else {
        let uploadLinks = this.state.uploadLinks;
        if (this.props.multiple === true) {
          uploadLinks.push(...results.uploadLinks);
        } else {
          uploadLinks = results.uploadLinks;
        }
        this.setState({
          message: null,
          uploadLinks: uploadLinks,
          uploadDisabled: false
        });
      }
    });
  }

  deleteFileLink(location) {
    let uploadLinksUpdate = this.state.uploadLinks;
    if (this.state.uploadLinks.length === 1) {
      uploadLinksUpdate = [];
    } else uploadLinksUpdate.splice(location, 1);
    this.setState({ uploadLinks: uploadLinksUpdate });
  }

  uploadedDocs() {
    let output = null;

    let theUploadLinks = this.state.uploadLinks;
    if (this.props.displayMode === true) {
      theUploadLinks = this.props.uploadLinks;
    }
    if (
      theUploadLinks.length > 0 &&
      !(
        theUploadLinks.length === 1 &&
        (theUploadLinks[0] === "" || theUploadLinks[0] === null)
      )
    ) {
      if (this.props.multiple === true) {
        output = theUploadLinks.map((link, i) => {
          if (link !== "" && link !== null) {
            return (
              <div
                key={i}
                style={{
                  maxHeight: 120,
                  maxWidth: 200,
                  display: "inline-block"
                }}
              >
                <input
                  name={this.props.name + "[" + i + "]"}
                  value={link}
                  type="hidden"
                />
                {this.props.disabled ? (
                  <div style={{ width: 120, display: "inline-block" }}>
                    <a href={link.toString()} target="_blank">
                      <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        src={this.state.uploadLinks[i].toString()}
                      />
                    </a>
                  </div>
                ) : (
                  <div style={{ width: 120, display: "inline-block" }}>
                    <a href={link.toString()} target="_blank">
                      <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        src={this.state.uploadLinks[i].toString()}
                      />
                    </a>
                    <input
                      type="button"
                      onClick={() => this.deleteFileLink(i)}
                      value={"Click To Delete"}
                    />
                  </div>
                )}
              </div>
            );
          }
        });
      } else {
        output = (
          <div>
            <input
              name={this.props.name}
              value={this.state.uploadLinks[0]}
              type="hidden"
            />
            {this.props.disabled ? (
              <div style={{ width: 120, display: "inline-block" }}>
                <a href={this.state.uploadLinks[0]} target="_blank">
                  <img
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    src={this.state.uploadLinks[0].toString()}
                  />
                </a>
              </div>
            ) : (
              <div style={{ width: 120, display: "inline-block" }}>
                <a href={this.state.uploadLinks[0]} target="_blank">
                  <img
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    src={this.state.uploadLinks[0].toString()}
                  />
                </a>
                <input
                  style={{ paddingLeft: 20 }}
                  type="button"
                  onClick={() => this.deleteFileLink(0)}
                  value={"Click To Delete"}
                />
              </div>
            )}
          </div>
        );
      }
    } else {
      let multipleFlag = this.props.multiple ? "[0]" : "";
      output = (
        <div>
          <input
            key={this.props.name + "killit"}
            id={this.props.name + multipleFlag}
            name={this.props.name + multipleFlag}
            value={""}
            type="hidden"
          />
        </div>
      );
    }

    return <div>{output}</div>;
  }

  render() {
    return (
      <div>
        {this.state.message}
        {!this.props.disabled ? (
          <input
            className={this.props.class}
            type="file"
            id={this.props.name}
            multiple={this.props.multiple}
            onChange={() => {
              this.onFileChange();
            }}
            disabled={
              this.props.disabled === true || this.state.uploadDisabled === true
            }
          />
        ) : (
          <div />
        )}

        {this.uploadedDocs()}
      </div>
    );
  }
}
