import React, { Component } from "react";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { EntityInterface, LoyaltyPointOfferInterface } from "cbd-api-types";
import PointOfferList from "../clientComponents/loyalty/PointOfferList";
import {
  getPointsOffersInactive,
  getPointsOffersExpired,
  getPointsOffersActive
} from "../../apiService/LoyaltyService";
import async from "async";
import { Checkbox, Typography } from "@material-ui/core";
import { MasterPageSpinner } from "../../components/master-page-spinner/MasterPageSpinner";

export interface ManageOffersProps {
  match: {
    params: {
      clientId: string;
    };
  };
  token: string;
  clientData: EntityInterface;
}

export interface ManageOffersState {
  clientOffersActive: LoyaltyPointOfferInterface[] | [];
  clientOffersInactive: LoyaltyPointOfferInterface[] | [];
  clientOffersExpired: LoyaltyPointOfferInterface[] | [];
  errorMessage?: Error | undefined;
  filter?: {
    title?: string;
    dateFrom?: Date;
    dateTo?: Date;
    active?: boolean;
    expired?: boolean;
  };
  limit?: number;
  loading: boolean;
}

export default class ManagePointOffers extends Component<
  ManageOffersProps,
  ManageOffersState
> {
  private constructor(props: ManageOffersProps) {
    super(props);

    this.state = {
      clientOffersActive: [],
      clientOffersInactive: [],
      clientOffersExpired: [],
      filter: {
        active: false,
        expired: false
      },
      loading: true
    };
    this.getClientOffers();
  }

  private getClientOffers() {
    const {
      token,
      match: {
        params: { clientId }
      }
    } = this.props;
    async.parallel(
      [
        callback => {
          getPointsOffersActive(clientId)
            .then(results => callback(null, results))
            .catch(err => callback(err));
        },
        callback => {
          getPointsOffersInactive(token, clientId)
            .then(results => callback(null, results))
            .catch(err => callback(err));
        },
        callback => {
          getPointsOffersExpired(token, clientId)
            .then(results => callback(null, results))
            .catch(err => callback(err));
        }
      ],
      (err, results: any) => {
        if (err) {
          this.setState({
            errorMessage: Error("There was an error"),
            loading: false
          });
        } else {
          let clientOffersActive: LoyaltyPointOfferInterface[] =
            results[0] || [];
          let clientOffersInactive: LoyaltyPointOfferInterface[] =
            results[1] || [];
          let clientOffersExpired: LoyaltyPointOfferInterface[] =
            results[2] || [];
          let errorMessage =
            clientOffersActive || clientOffersInactive
              ? undefined
              : new Error("You haven't created an offer yet");
          this.setState({
            clientOffersActive,
            clientOffersInactive,
            clientOffersExpired,
            errorMessage,
            loading: false
          });
        }
      }
    );
  }

  private showInactive = () => {
    this.setState(state => {
      const newFilter = state.filter;
      newFilter.active = !state.filter.active;
      return {
        filter: newFilter
      };
    });
  };

  private expired = () => {
    this.setState(state => {
      const newFilter = state.filter;
      newFilter.expired = !state.filter.expired;
      return {
        filter: newFilter
      };
    });
  };

  public render() {
    const {
      clientOffersActive,
      clientOffersInactive,
      clientOffersExpired,
      errorMessage,
      filter: { active, expired },
      loading
    } = this.state;
    let visibleOffers: LoyaltyPointOfferInterface[] = [];
    if (!!clientOffersActive.length) {
      visibleOffers = clientOffersActive;
    }
    if (active && !!clientOffersInactive.length) {
      visibleOffers = visibleOffers.concat(clientOffersInactive);
    }
    if (expired && !!clientOffersExpired.length) {
      visibleOffers = visibleOffers.concat(clientOffersExpired);
    }
    return (
      <Grid container spacing={16 as GridSpacing}>
        <Checkbox checked={active} onChange={this.showInactive} />
        <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
          Show Inactive Offers
        </Typography>
        <Checkbox checked={expired} onChange={this.expired} />
        <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
          Show Expired Offers
        </Typography>
        {!loading ? (
          <Grid item xs={12}>
            <PointOfferList
              errorMessage={errorMessage}
              offerList={visibleOffers}
              match={this.props.match}
              token={this.props.token}
            />
          </Grid>
        ) : (
          <MasterPageSpinner />
        )}
      </Grid>
    );
  }
}
