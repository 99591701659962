import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BrandColor, Color } from "../kioskUtilities/constants";

export default class PhoneNumberMuiWrapper extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeHolder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    borderColor: PropTypes.string,
    borderThickness: PropTypes.number,
    fontSize: PropTypes.number,
    alignment: "center" | "left" | "right"
  };

  render() {
    const {
      placeHolder,
      onChange,
      value,
      width,
      height,
      borderColor,
      borderThickness,
      fontSize,
      textAlign
    } = this.props;
    return (
      <div style={signupStyle}>
        <ReactPhoneInput
          defaultCountry="us"
          value={value}
          onlyCountries={["us"]}
          disableCountryCode={true}
          placeholder={placeHolder}
          buttonStyle={{ width: 0, display: "none" }}
          inputStyle={{
            paddingLeft: 10,
            width: width,
            borderRadius: 30,
            height: height,
            border: `${borderThickness}px solid ${borderColor}`,
            backgroundColor: "transparent",
            fontSize,
            textAlign
          }}
          containerStyle={{ width, textAlign }}
          onChange={onChange}
        />
      </div>
    );
  }
}

const signupStyle = {
  backgroundColor: "white",
  border: `0px solid ${Color.InputBackground}`,
  display: "inline-block",
  marginRight: 5
};
