import React from "react";
// import styled from "styled-components";
// import { BrandGradient, Color } from "../kioskUtilities/constants";
// import { createPortal } from "react-dom";
import Dialog from "@material-ui/core/Dialog";
import { TransitionProps } from "react-transition-group/Transition";
import Slide from "@material-ui/core/Slide";
interface Props {
  deactivateItem: () => void;
  mainContent: JSX.Element;
}

const KioskModal: React.FC<Props> = (props: Props) => {
  const { deactivateItem, mainContent } = props;

  const theTransition = (props: Partial<TransitionProps>) => (
    <Slide {...props} direction="up" />
  );

  // const activeItemDialog = (
  //   <ActiveItemDialog>
  //     <DialogHeader />
  //     <StyledItemLayout>{mainContent}</StyledItemLayout>
  //     <ButtonFooter>
  //       <button
  //         className={"cb_data-table__toolbar-button"}
  //         onClick={deactivateItem}
  //       >
  //         Done
  //       </button>
  //       {footerContent}
  //     </ButtonFooter>
  //   </ActiveItemDialog>
  // );
  // return createPortal(activeItemDialog, document.getElementById("modal-root"));
  return (
    <Dialog
      fullScreen={true}
      open={true}
      onClose={deactivateItem}
      TransitionComponent={theTransition}
    >
      {mainContent}
      <div
        style={{
          maxWidth: 100,
          marginLeft: "auto",
          marginRight: "auto",
          padding: 10
        }}
      >
        <img
          style={{ maxWidth: 100 }}
          src="https://www.cannabinder.com/assets/images/powered-by-cannabinder.png"
          alt="powered by cannabinder"
        />
      </div>
    </Dialog>
  );
};

// const ButtonFooter = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   padding: 5px 0;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   & button {
//     margin-right: 0;
//     margin-left: 20px;
//     background: ${Color.White};
//   }
//   & svg {
//     font-size: 1.25rem;
//     margin: 0 5px;
//     vertical-align: middle;
//   }
// `;

// const StyledItemLayout = styled.div`
//   display: flex;
//   flex-direction: row;
//   p {
//     padding-left: 30px;
//   }
//   & > * {
//     margin-left: 5px;
//     flex-grow: 1;
//     width: 100%;
//     &:first-child {
//       margin-left: 0;
//     }
//     &:first-child,
//     &:last-child {
//       // flex-grow: 0;
//       // width: auto;
//     }
//   }
// `;

// const ActiveItemDialog = styled.div`
//   position: fixed;
//   box-shadow: 0 1px 4px 1px rgba(15, 37, 44, 0.3);
//   background: ${Color.VeryLightGray};
//   border-radius: 3px;
//   z-index: 300;
//   top: 20px;
//   left: 20px;
//   right: 20px;
//   bottom: 20px;
//   display: flex;
//   flex-direction: column;
//   & > * {
//     margin-left: 5px;
//     margin-right: 5px;
//     margin-top: 5px;
//     &:first-child {
//       margin-left: 0;
//       margin-right: 0;
//       margin-top: 0;
//     }
//   }
//   h1 {
//     margin-top: 20px;
//     margin-bottom: 30px;
//   }
//   h2 {
//     margin-bottom: 20px;
//   }
// `;
// const DialogHeader = styled.div`
//   border-top-left-radius: 3px;
//   border-top-right-radius: 3px;
//   background: ${BrandGradient.Main};
//   height: 60px;
// `;

// export const ContentsFlexWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   & > * {
//     margin-right: 10px;
//   }
//   flex-wrap: wrap;
//   max-width: 680px;
//   justify-content: center;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 20px;
//   & > * {
//     margin-right: 20px;
//     margin-bottom: 20px;
//   }
// `;

export default KioskModal;
