import React, { Component } from "react";
import PropTypes from "prop-types";
import DispensaryDistributionList from "../product/DispensaryDistributionList";
import ClientService from "../../../apiService/ClientService";
import CannastampIcon from "../../../components/product/CannastampIcon";
import { SSL_OP_SINGLE_DH_USE } from "constants";

class MerchandisingCardPrinter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryLotList: false,
      salesCardQue: [],
      printResults: []
    };
    this.queSalesCardPrint.bind(props.queSalesCardPrint);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.newCardProp !== this.props.newCardProp) {
      this.queSalesCardPrint(this.props.newCardProp);
    }
  }
  queSalesCardPrint = productId => {
    let salesCardQue = this.state.salesCardQue;
    salesCardQue.push(productId);
    this.setState({ salesCardQue });
  };

  deletePrintJob = key => {
    if (this.state.salesCardQue.length === 0)
      this.setState({ salesCardQue: [] });
    else {
      let newQue = this.state.salesCardQue;
      newQue.splice(key, 1);
      this.setState({ salesCardQue: newQue });
    }
  };

  generatePrints = () => {
    ClientService.printProductCards(
      this.props.token,
      this.props.clientId,
      this.state.salesCardQue
    ).then(
      response => {
        let printResults = this.state.printResults;
        printResults.push(response.Location);
        this.setState({
          printResults,
          salesCardQue: []
        });
      },
      rejected => {
        console.warn(rejected);
      }
    );
  };

  printQueCards = () => {
    let printElements = this.state.salesCardQue.map((product, key) => {
      return (
        <div
          key={key}
          style={{
            verticalAlign: "top",
            textAlign: "center",
            display: "inline-block",
            minWidth: 100,
            maxWidth: 100,
            minHeight: 120,
            padding: 5,
            margin: 5,
            border: "2px solid lightgrey",
            wordWrap: "normal",
            borderRadius: 5
          }}
        >
          <div style={{ height: "20%" }}>{product.name}</div>
          <div style={{ width: 50, height: "60%", display: "inline-block" }}>
            <CannastampIcon
              key={product.latestLot._id}
              size={100}
              testResult={product.latestLot.testResult}
            />
          </div>
          <div style={{ height: "20%" }}>
            <button
              style={{ backgroundColor: "grey" }}
              onClick={() => {
                this.deletePrintJob(key);
              }}
            >
              delete
            </button>
          </div>
        </div>
      );
    });
    if (this.state.salesCardQue.length > 0) {
      return (
        <div>
          <div
            style={{
              display: "inline-block",
              width: "100%",
              verticalAlign: "top"
            }}
          >
            <div style={{ display: "inline-block", margin: 5, marginTop: 10 }}>
              <div>Print Que Length: {this.state.salesCardQue.length}</div>
              <div>Sheets {Math.ceil(this.state.salesCardQue.length / 10)}</div>
            </div>
          </div>
          {printElements}
          <div style={{ margin: 5 }}>
            <button
              style={{ backgroundColor: "lightblue" }}
              onClick={this.generatePrints}
            >
              Print
            </button>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  render() {
    let Controller = this.props.productController;
    return (
      <div>
        <div>
          {this.state.printResults.length > 0 &&
            this.state.printResults.map(url => {
              return (
                <a href={url} target="_blank">
                  {url}
                </a>
              );
            })}
        </div>
        <div>{this.printQueCards()}</div>
      </div>
    );
  }
}

MerchandisingCardPrinter.propTypes = {
  productController: PropTypes.func,
  newCardProp: PropTypes.object,
  clientData: PropTypes.object
};

export default MerchandisingCardPrinter;
