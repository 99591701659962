import React, { Component } from "react";

class ClientHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "yourEmail@mail.com" };
  }

  render() {
    return (
      <div />
      // <div className="cb_surface__section" style={{ padding: 30 }}>
      //   <div className="cb_account__header">
      //     <div className="cb_persona">
      //       <span className="cb_persona__name">
      //         <h1 style={{ fontSize: 36 }}>
      //           {this.props.clientData.name} - {this.props.clientData.ubi}
      //         </h1>
      //       </span>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default ClientHeader;
